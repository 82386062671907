import { statisticsGraphEntities } from "../entities/ReportCRMEntity";
import { extractTime } from "./dateUtils";
import { formatDateYear } from "./Formatter";

export interface FilterDay {
  startDate: Date;
  endDate: Date;
}

export interface ChartData {
  labels: string[];
  data: {
    countOrder: number;
    sumApprove: number;
    sumQuantity: number;
    sumWaitApprove: number;
  }[];
}

export const generateChartData = (
  filterDay: FilterDay | null,
  statisticsGraph: statisticsGraphEntities[],
  type: string,
): ChartData | undefined => {
  if (
    !filterDay ||
    !(filterDay.startDate instanceof Date) ||
    !(filterDay.endDate instanceof Date)
  ) {
    console.error("Invalid filterDay input");
    return;
  }

  const labels: string[] = [];
  const data: ChartData["data"] = [];
  const endDate = new Date(filterDay.startDate);
  const startDate = new Date(filterDay.endDate);

  const groupByTimeFrame = (data: statisticsGraphEntities[], timeFrame: string) => {
    const currentDate = new Date();
    let timeFrameStart: Date;
    let timeFrameEnd: Date;
    const dateKeys: string[] = [];
    let monthsCount = 0;
    switch (timeFrame) {
      case "custom": {
        if (!startDate || !endDate) {
          console.error("Start date and end date must be provided for custom timeFrame");
          return;
        }
        timeFrameStart = new Date(startDate);
        timeFrameEnd = new Date(endDate);
        const currentDate = new Date(startDate);
        while (currentDate <= timeFrameEnd) {
          currentDate.setDate(currentDate.getDate() + 1);
          dateKeys.push(currentDate.toISOString().slice(0, 10));
        }
        break;
      }
      case "todays": {
        currentDate.setFullYear(2024, 10, 1);
        currentDate.setHours(0, 0, 0, 0);

        timeFrameStart = new Date(currentDate);
        timeFrameEnd = new Date(currentDate);
        timeFrameEnd.setHours(23, 59, 59, 999);

        for (let hour = 0; hour < 24; hour++) {
          const startTime = new Date(currentDate);
          startTime.setHours(hour, 0, 0, 0);

          const timeString = `${startTime.toISOString().slice(0, 10)} ${String(hour).padStart(
            2,
            "0",
          )}:00`;
          dateKeys.push(timeString);
        }
        break;
      }

      case "7days": {
        timeFrameStart = new Date();
        timeFrameStart.setDate(currentDate.getDate() - 7);
        dateKeys.push(
          ...Array.from({ length: 7 }, (_, i) => {
            const date = new Date();
            date.setDate(currentDate.getDate() - i);
            return date.toISOString().slice(0, 10);
          }),
        );
        break;
      }
      case "30days": {
        timeFrameStart = new Date();
        timeFrameStart.setDate(currentDate.getDate() - 30);
        dateKeys.push(
          ...Array.from({ length: 30 }, (_, i) => {
            const date = new Date();
            date.setDate(currentDate.getDate() - i);
            return date.toISOString().slice(0, 10);
          }),
        );
        break;
      }
      case "3months": {
        monthsCount = 3;
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        dateKeys.push(
          ...Array.from({ length: monthsCount + 1 }, (_, i) => {
            const monthIndex = (currentMonth - i + 12) % 12;
            const year = currentYear - Math.floor((currentMonth - i) / 12);
            return `${year}-${String(monthIndex + 1).padStart(2, "0")}`;
          }),
        );

        timeFrameStart = new Date(currentYear, currentMonth - monthsCount, 1);
        break;
      }
      case "6months": {
        monthsCount = 6;
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        dateKeys.push(
          ...Array.from({ length: monthsCount + 1 }, (_, i) => {
            const monthIndex = (currentMonth - i + 12) % 12;
            const year = currentYear - Math.floor((currentMonth - i) / 12);
            return `${year}-${String(monthIndex + 1).padStart(2, "0")}`;
          }),
        );

        timeFrameStart = new Date(currentYear, currentMonth - monthsCount, 1);
        break;
      }
      case "12months": {
        monthsCount = 12;
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        dateKeys.push(
          ...Array.from({ length: monthsCount }, (_, i) => {
            const monthIndex = (currentMonth - i + 12) % 12;
            const year = currentYear - Math.floor((currentMonth - i) / 12);
            return `${year}-${String(monthIndex + 1).padStart(2, "0")}`;
          }).reverse(),
        );

        timeFrameStart = new Date(currentYear, currentMonth - monthsCount + 1, 1);
        break;
      }

      default:
        throw new Error("Invalid timeFrame specified");
    }

    const groupedData: Record<
      string,
      { sumApprove: number; sumWaitApprove: number; sumQuantity: number; countOrder: number }
    > = {};

    data.forEach((entry) => {
      const entryDate = new Date(timeFrame === "todays" ? entry.date_trunc || "" : entry.date);
      if (entryDate >= timeFrameStart && !isNaN(entryDate.getTime())) {
        const dateKey = timeFrame.includes("months")
          ? entryDate.toISOString().slice(0, 7)
          : entryDate.toISOString().slice(0, 10) + " " + entryDate.toISOString().slice(11, 16);

        if (!groupedData[dateKey]) {
          groupedData[dateKey] = {
            sumApprove: 0,
            sumWaitApprove: 0,
            sumQuantity: 0,
            countOrder: 0,
          };
        }

        groupedData[dateKey].sumApprove += entry.sumApprove || 0;
        groupedData[dateKey].sumWaitApprove += entry.sumWaitApprove || 0;
        groupedData[dateKey].sumQuantity += entry.sumQuantity || 0;
        groupedData[dateKey].countOrder += entry.countOrder || 0;
      }
    });

    dateKeys.forEach((dateKey) => {
      if (!groupedData[dateKey]) {
        groupedData[dateKey] = { sumApprove: 0, sumWaitApprove: 0, sumQuantity: 0, countOrder: 0 };
      }
    });
    return groupedData;
  };

  let timeFrame: string;
  if (type === "Today") {
    timeFrame = "todays";
  } else if (type === "7Day") {
    timeFrame = "7days";
  } else if (type === "30Day") {
    timeFrame = "30days";
  } else if (type === "3Month") {
    timeFrame = "3months";
  } else if (type === "6Month") {
    timeFrame = "6months";
  } else if (type === "12Month") {
    timeFrame = "12months";
  } else {
    timeFrame = "custom";
  }

  const results = groupByTimeFrame(statisticsGraph, timeFrame) || {};
  const isDayFormat = timeFrame === "7days" || timeFrame === "30days" || timeFrame === "custom";

  const sortedKeys = Object.keys(results).sort((a, b) => {
    return a.localeCompare(b);
  });

  for (const dateKey of sortedKeys) {
    const entry = results[dateKey];
    const formattedDate =
      timeFrame === "todays" ? extractTime(dateKey) : formatDateYear(dateKey, isDayFormat);
    labels.push(formattedDate);
    data.push({
      countOrder: entry.countOrder,
      sumApprove: entry.sumApprove,
      sumQuantity: entry.sumQuantity,
      sumWaitApprove: entry.sumWaitApprove,
    });
  }

  if (labels.length > 16) {
    const step = Math.ceil(labels.length / 15);
    const averagedLabels: string[] = [];

    for (let i = 0; i < labels.length; i += step) {
      if (averagedLabels.length >= 15) break;

      averagedLabels.push(labels[i]);
    }

    return {
      labels: averagedLabels,
      data: data,
    };
  }

  return {
    labels: labels,
    data: data,
  };
};
