import {
  BarChartOutlined,
  DownOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Col, Modal, Row, Table, Tag, Form, Tabs, Switch } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { CardContainer } from "../../../components/Card/CardContainer";
import { RangePicker } from "../../../components/DatePicker/DatePicker";
import Input from "../../../components/Input/Input";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import moment from "moment";
import { color } from "../../../resource";
import { useNavigate } from "react-router-dom";
import {
  getCampaign,
  getPartner,
  reportCampaignShop,
  reportOrderpointShop,
} from "../../../datasource/CampaignDatasource";
import { useEffectOnce } from "react-use";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import Select from "../../../components/Select/Select";
import { DealerType, dealerTypeName } from "../../../definitions/campaign";
import icon from "../../../resource/icon";
import { numberFormatter } from "../../../utility/Formatter";
import GreyTable from "../../../components/Table/GreyTable";

type FixedType = "left" | "right" | boolean;
const REQUEST_DMY = "YYYY-MM-DD";

export const ShopReportIndex: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company, firstname, lastname } = userProfile;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [sum, setSum] = useState<any>({});

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [keyword, setKeyword] = useState<string>();
  const [userType, setUserType] = useState<string>("ALL");
  const [zone, setZone] = useState<string>();

  const [zoneData, setZoneData] = useState<{ label: string; value: string; key: string }[]>([]);

  const defaultShowPartner = {
    userType: null,
    id: null,
    loading: false,
    partners: [],
    data: null,
  } as any;
  const [showPartner, setShowPartner] = useState(defaultShowPartner);

  const defaultShowPointDetail = {
    userType: null,
    id: null,
    loading: false,
    data: null,
    count: 0,
    points: [],
  } as any;
  const [showPointDetails, setShowPointDetail] = useState(defaultShowPointDetail);

  useEffectOnce(() => {
    if (zoneData.length <= 0) {
      getZoneByCompany();
    }
  });
  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZoneData(data);
  };

  const resetPage = () => setPage(1);

  useEffect(() => {
    if (!loading) fetchCampaign();
  }, [keyword, userType, page, zone]);

  const fetchCampaign = async () => {
    await reportOrderpointShop({
      company,
      search: keyword,
      userType,
      zone,
      page,
      take: 10,
    })
      .then((res) => {
        console.log(res);
        setData(res.data || []);
        setCount(res.count || 0);
        setSum(res.summary || {});
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!showPartner.loading) {
      fetchPartner();
    }
  }, [showPartner.userType, showPartner.id]);

  const fetchPartner = async () => {
    setShowPartner({
      ...showPartner,
      loading: true,
    });
    if (showPartner.userType && showPartner.id)
      await getPartner(showPartner.userType, showPartner.id).then((res) => {
        // console.log("partner:", res);
        setShowPartner({
          ...showPartner,
          partners: res,
          loading: false,
        });
      });
  };

  useEffect(() => {
    if (!showPointDetails.loading) {
      fetchPointDetail();
    }
  }, [showPointDetails.userType, showPointDetails.id]);
  const fetchPointDetail = async () => {
    setShowPointDetail({
      ...showPointDetails,
      loading: true,
    });
    await reportCampaignShop({
      dealerId:
        showPointDetails?.userType?.toUpperCase() === DealerType.DEALER
          ? parseInt(showPointDetails.id)
          : undefined,
      subDealerId:
        showPointDetails?.userType?.toUpperCase() === DealerType.DEALER
          ? undefined
          : parseInt(showPointDetails.id),
      page,
      take: 1000,
      allCampaign: true,
    }).then((res) => {
      setShowPointDetail({
        ...showPointDetails,
        loading: false,
        points: res?.data,
        count: res?.count,
      });
    });
  };

  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col span={6}>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };
  const tabsItems = [
    {
      label: `ทั้งหมด (${sum?.allType || 0})`,
      key: "ALL",
    },
    {
      label: `Dealer (${sum?.dealer || 0})`,
      key: "DEALER",
    },
    {
      label: `Sub-Dealer (${sum?.subDealer || 0})`,
      key: "SUB_DEALER",
    },
  ];

  const PageTitle = () => {
    return (
      <Form>
        <Row gutter={16}>
          <Col className='gutter-row' span={14}>
            <div>
              <span
                className='card-label font-weight-bolder text-dark'
                style={{ fontSize: 20, fontWeight: "bold" }}
              >
                รายงานรายร้าน
              </span>
            </div>
          </Col>
          <Col span={6}>
            <Input
              placeholder='รหัสร้านค้า / ชื่อร้านค้า / เลขนิติบุคคล'
              suffix={<SearchOutlined />}
              defaultValue={keyword}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setKeyword(value);
                resetPage();
              }}
              onChange={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                if (!value) {
                  setKeyword("");
                  resetPage();
                }
              }}
            />
          </Col>
          <Col className='gutter-row' span={4}>
            <Select
              placeholder='เขตทั้งหมด'
              data={zoneData}
              value={zone}
              style={{ width: 150 }}
              onChange={(z) => {
                setZone(z);
                resetPage();
              }}
            />
          </Col>
        </Row>
      </Form>
    );
  };
  const columns = [
    {
      title: "รหัสร้านค้า",
      dataIndex: "dealerNo",
      key: "dealerNo",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{row.userType === DealerType.SUB_DEALER ? row.subDealerNo : row.dealerNo}</Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "dealerName",
      key: "dealerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>
                {row.userType === DealerType.SUB_DEALER ? row.subDealerName : row.dealerName}
              </Text>
              <Text level={6} color='Text3'>
                {row.userType === DealerType.SUB_DEALER ? row.subDealerTaxNo : row.dealerTaxNo}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "ประเภทคู่ค้า",
      dataIndex: "userType",
      key: "userType",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>
                {dealerTypeName[value]}&nbsp;
                <img
                  src={icon.info}
                  style={{ width: "16px", cursor: "pointer" }}
                  onClick={() =>
                    setShowPartner({
                      ...showPartner,
                      data: row,
                      userType: row.userType,
                      id: row.userType === DealerType.SUB_DEALER ? row.subDealerId : row.dealerId,
                      loading: false,
                    })
                  }
                />
              </Text>
              <Text level={6} color='Text3'>
                {row.userType === DealerType.SUB_DEALER ? row.subDealerZone : row.dealerZone}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "แคมเปญ",
      dataIndex: "sumCampaign",
      key: "sumCampaign",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value || 0}</Text>
              <Text level={6} color='Text3'>
                แคมเปญ
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "currentPoint",
      key: "currentPoint",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>
                {numberFormatter(value || 0, 2)}&nbsp;
                <img
                  src={icon.info}
                  style={{ width: "16px", cursor: "pointer" }}
                  onClick={() =>
                    setShowPointDetail({
                      ...showPointDetails,
                      data: row,
                      userType: row.userType,
                      id: row.userType === DealerType.SUB_DEALER ? row.subDealerId : row.dealerId,
                    })
                  }
                />
              </Text>
              <Text level={6} color='Text3'>
                แต้ม
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      fixed: "right" as FixedType | undefined,
      width: "15%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Row>
                <ActionBtn
                  onClick={() =>
                    navigate(
                      "/crm/customerPointReport/" +
                        row.userType +
                        "/" +
                        (row.userType === DealerType.SUB_DEALER ? row.subDealerId : row.dealerId),
                    )
                  }
                  icon={<UnorderedListOutlined />}
                />
              </Row>
            </>
          ),
        };
      },
    },
  ];
  return (
    <CardContainer>
      <PageTitle />
      <br />
      <Tabs
        items={tabsItems}
        onChange={(key: string) => {
          setUserType(key);
          resetPage();
        }}
      />
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          current: page,
          total: count,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
        loading={loading}
      />
      <Modal
        open={!!showPartner.userType && !!showPartner.id}
        onCancel={() => setShowPartner(defaultShowPartner)}
        footer={null}
        width={800}
      >
        <FlexCol>
          <Text fontWeight={700}>
            คู่ค้ากับ&nbsp;
            {showPartner.userType === DealerType.DEALER
              ? dealerTypeName[DealerType.SUB_DEALER]
              : dealerTypeName[DealerType.DEALER]}
          </Text>
          <Text level={6} color='Text3'>
            ทั้งหมด&nbsp;{showPartner?.partners?.length || 0}&nbsp;ร้าน
          </Text>
        </FlexCol>
        <br />
        <Row style={{ background: "#F3F6F9", padding: 12 }}>
          <Col span={10}>
            <Text>
              รหัสร้านค้า:&nbsp;
              {showPartner?.data?.userType === DealerType.SUB_DEALER
                ? showPartner?.data?.subDealerNo
                : showPartner?.data?.dealerNo}
            </Text>
          </Col>
          <Col span={14}>
            <Text>
              ชื่อร้านค้า: ({dealerTypeName[showPartner.userType]},{" "}
              {showPartner?.data?.userType === DealerType.SUB_DEALER
                ? showPartner?.data?.subDealerZone
                : showPartner?.data?.dealerZone}
              )&nbsp;
              {showPartner?.data?.userType === DealerType.SUB_DEALER
                ? showPartner?.data?.subDealerName
                : showPartner?.data?.dealerName}
            </Text>
          </Col>
        </Row>
        <br />
        <Table
          showHeader={false}
          columns={[
            {
              dataIndex: "no",
              key: "no",
              render: (v, r, i) => i + 1,
            },
            {
              dataIndex: "customer_no",
              key: "customer_no",
            },
            {
              dataIndex: "customer_name",
              key: "customer_name",
            },
            {
              dataIndex: "zone",
              key: "zone",
            },
            {
              dataIndex: "province",
              key: "province",
            },
          ]}
          dataSource={showPartner?.partners}
          pagination={false}
          loading={showPartner.loading}
        />
      </Modal>
      <Modal
        open={!!showPointDetails.userType && !!showPointDetails.id}
        onCancel={() => setShowPointDetail(defaultShowPointDetail)}
        footer={null}
        width={1000}
      >
        <FlexCol>
          <Text fontWeight={700}>แต้มที่ได้รับ</Text>
          <Text level={6} color='Text3'>
            ทั้งหมด&nbsp;{showPointDetails?.count || 0}&nbsp;แคมเปญ
          </Text>
        </FlexCol>
        <br />
        <Row style={{ background: "#F3F6F9", padding: 12 }}>
          <Col span={10}>
            <Text>
              รหัสร้านค้า:&nbsp;
              {showPointDetails?.data?.userType === DealerType.SUB_DEALER
                ? showPointDetails?.data?.subDealerNo
                : showPointDetails?.data?.dealerNo}
            </Text>
          </Col>
          <Col span={14}>
            <Text>
              ชื่อร้านค้า: ({dealerTypeName[showPointDetails.userType]},{" "}
              {showPointDetails?.data?.userType === DealerType.SUB_DEALER
                ? showPointDetails?.data?.subDealerZone
                : showPointDetails?.data?.dealerZone}
              )&nbsp;
              {showPointDetails?.data?.userType === DealerType.SUB_DEALER
                ? showPointDetails?.data?.subDealerName
                : showPointDetails?.data?.dealerName}
            </Text>
          </Col>
        </Row>
        <br />
        <Table
          showHeader={false}
          columns={[
            {
              dataIndex: "no",
              key: "no",
              width: 50,
              render: (v, r, i) => <Text fontWeight={700}>{i + 1}</Text>,
            },
            {
              dataIndex: "campaignCode",
              key: "campaignCode",
              render: (v) => <Text fontWeight={700}>{v}</Text>,
            },
            {
              dataIndex: "campaignName",
              key: "campaignName",
              width: 270,
              render: (v) => <Text fontWeight={700}>{v}</Text>,
            },
            {
              dataIndex: "sumCampaignPoint",
              key: "sumCampaignPoint",
              width: 226,
              render: (v) => <Text fontWeight={700}>{v}&nbsp;แต้ม</Text>,
            },
            Table.EXPAND_COLUMN,
          ]}
          dataSource={showPointDetails?.points?.map((e, key) => ({ ...e, key }))}
          pagination={false}
          loading={showPointDetails.loading}
          expandable={{
            fixed: "right",
            expandedRowRender: (r: any) => {
              return (
                <GreyTable
                  columns={[
                    {
                      dataIndex: "campaignProgramName",
                      key: "campaignProgramName",
                    },
                    {
                      dataIndex: "sumQuantity",
                      key: "sumQuantity",
                      width: 270,
                      render: (v) => <Text>{v}&nbsp;ตัน</Text>,
                    },
                    {
                      dataIndex: "sumPoint",
                      key: "sumPoint",
                      width: 226 + 48,
                      render: (v) => <Text>{v}&nbsp;แต้ม</Text>,
                    },
                  ]}
                  dataSource={r?.campaignProgram}
                  pagination={false}
                  showHeader={false}
                />
              );
            },
            rowExpandable: (r) => r?.campaignProgram?.length,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }}
        />
      </Modal>
    </CardContainer>
  );
};

export default ShopReportIndex;
