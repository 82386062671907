export const checkTodayInRange = (startDate: string, endDate: string): string => {
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (today < start) {
    return "Later";
  } else if (today > end) {
    return "Earlier";
  } else {
    return "Within the time range";
  }
};
