import React, { useEffect, useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Col, Row } from "antd";
import Input from "../../../components/Input/Input";
import { SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import Select from "../../../components/Select/Select";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import { MAP_STATUS, MAP_STATUS_COLOR, MAP_STATUS_WORD } from "../../../definitions/approvePoint";
import { RangePicker } from "../../../components/DatePicker/DatePicker";
import color from "../../../resource/color";
import styled from "styled-components";
import StatusBadge from "../../../components/Badge/StatusBadge";
import TablePagination from "../../../components/Table/TablePagination";
import SortFieldCustom from "../../../components/SortFieldTable/SortFieldCustom";
import { useNavigate } from "react-router-dom";
import { dateFormatter, numberFormatter } from "../../../utility/Formatter";
import { PointApproveEntity } from "../../../entities/PointApproveEntity";
import { getPointApprove } from "../../../datasource/OrderPointDatasource";
import { EstimatePointFormatter } from "../../../utility/EstimatePointFormatter";

const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;
const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.placeholder};
  font-weight: 400;
`;
const CircleDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${color.placeholder};
  border-radius: 50%;
  margin: 0 5px;
`;

function IndexApprovePoint() {
  const company = JSON.parse(localStorage.getItem("company")!);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [searchZone, setSearchZone] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [dateFilter, setDateFilter] = useState<any>();
  const [zone, setZone] = useState<any>([]);
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<string | undefined>(undefined);
  const pageSize = 10;
  const [data, setData] = useState<{
    count: number;
    data: PointApproveEntity[];
  }>({
    count: 0,
    data: [],
  });

  const getDataPointApprove = async () => {
    setLoading(true);
    try {
      const res = await getPointApprove({
        page: page,
        take: pageSize,
        company: company.companyCode,
        search: searchText,
        zone: searchZone,
        status: status,
        startDate:
          dateFilter && dateFilter[0] ? dateFilter[0].format("YYYY-MM-DD 00:00:00") : undefined,
        endDate:
          dateFilter && dateFilter[1] ? dateFilter[1].format("YYYY-MM-DD 23:59:59") : undefined,
        sortBy: sortDirection ? sortField : undefined,
        sortDirection: sortDirection,
      });
      setData(res);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataPointApprove();
  }, [page, searchText, searchZone, status, dateFilter, sortField, sortDirection]);

  const handleSortChange = (field: string) => {
    setSortField((prevSortField) => {
      if (prevSortField === field) {
        setSortDirection((prevDirection) => {
          if (prevDirection === "ASC") return "DESC";
          if (prevDirection === "DESC") return undefined;
          return "ASC";
        });
      } else {
        setSortField(field);
        setSortDirection("ASC");
      }
      return field;
    });
  };

  useEffect(() => {
    const getZone = async () => {
      await zoneDatasource.getAllZoneByCompany(company?.companyCode).then((res) => {
        setZone(res);
      });
    };
    getZone();
  }, []);

  const columns: any[] = [
    {
      title: "เลขที่ส่งเอกสาร",
      dataIndex: "orderPointCode",
      key: "orderPointCode",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value || "-"}</BaseText>
              <DescText>{dateFormatter(row.dateDelivery, true)}</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ร้านที่ซื้อ (Sub-Dealer)'
          sortDirection={sortField === "order_point.subDealerName" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("order_point.subDealerName")}
        />
      ),
      dataIndex: "subDealerName",
      key: "subDealerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value || "-"}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>
                {row.subDealerNo} {row.subDealerNo || row.subDealerZone ? <CircleDot /> : null}
                {row.subDealerZone}
              </DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ร้านที่ขาย (Dealer)'
          sortDirection={sortField === "order_point.dealerName" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("order_point.dealerName")}
        />
      ),
      dataIndex: "dealerName",
      key: "dealerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>
                {row.dealerNo} {row.dealerNo || row.dealerZone ? <CircleDot /> : null}
                {row.dealerZone}
              </DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='จำนวนสินค้า'
          sortDirection={sortField === "count" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("count")}
        />
      ),
      dataIndex: "count",
      key: "count",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 0)}</BaseText>
              <DescText>รายการ</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='แต้มที่ได้รับ'
          sortDirection={sortField === "order_point.sumPoint " ? sortDirection : undefined}
          onSortChange={() => handleSortChange("order_point.sumPoint ")}
        />
      ),
      dataIndex: "sumPoint",
      key: "sumPoint",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>
                <EstimatePointFormatter historyOrderPoint={row.historyOrderPoint} />
                &nbsp;
                {numberFormatter(value, 2)}
              </BaseText>
              <DescText>แต้ม</DescText>
            </>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any, index: number) => {
        const lastHistoryOrderPoint = row.historyOrderPoint.slice(-1)[0];
        const updateAt = lastHistoryOrderPoint?.updateAt;
        return {
          children: (
            <>
              <StatusBadge label={MAP_STATUS_WORD[value]} bgColor={MAP_STATUS_COLOR[value]} />
              <DescText>{updateAt ? dateFormatter(updateAt, true) : undefined}</DescText>
            </>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                <UnorderedListOutlined
                  style={{ color: color["primary"] }}
                  onClick={() => navigate("/crm/approvePointList/" + row.orderPointId)}
                />
              </div>
            </>
          ),
        };
      },
    },
  ];
  const PageTitle = () => {
    return (
      <Row align='middle' gutter={16} justify='start' style={{ marginBottom: "58px" }}>
        <Col className='gutter-row' span={5}>
          <div>
            <span
              className='card-label font-weight-bolder text-dark'
              style={{ fontSize: 20, fontWeight: "700" }}
            >
              รายการอนุมัติแต้ม
            </span>
          </div>
        </Col>
        <Col span={19}>
          <Row gutter={16} justify='end'>
            <Col span={4}>
              <Select
                allowClear
                placeholder='สถานะทั้งหมด'
                data={MAP_STATUS}
                value={status}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setStatus(e);
                  setPage(1);
                }}
              />
            </Col>
            <Col span={4}>
              <Select
                value={searchZone}
                allowClear
                placeholder='เขตทั้งหมด'
                data={
                  zone.map((z) => ({
                    label: z.zoneName,
                    key: z.zoneId,
                    value: z.zoneName,
                  })) || []
                }
                style={{
                  width: "100%",
                  fontFamily: "Sarabun",
                }}
                onChange={(e) => {
                  setSearchZone(e);
                  setPage(1);
                }}
              />
            </Col>
            <Col span={8}>
              <Input
                placeholder='เลขที่เอกสาร / รหัส / ชื่อร้าน'
                suffix={<SearchOutlined />}
                defaultValue={searchText}
                onPressEnter={(e) => {
                  const value = (e.target as HTMLTextAreaElement).value;
                  setSearchText(value);
                  setPage(1);
                }}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  if (!value) {
                    setSearchText(undefined);
                    setPage(1);
                  }
                }}
              />
            </Col>
            <Col span={6}>
              <RangePicker
                allowEmpty={[true, true]}
                enablePast
                value={dateFilter}
                onChange={(dates, dateString) => {
                  setDateFilter(dates);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  return (
    <CardContainer>
      <PageTitle />
      <TablePagination
        columns={columns}
        data={(data.data || []).map((item: any, index: number) => {
          return {
            ...item,
            id: index + 1,
          };
        })}
        isLoading={loading}
        scroll={{ x: "max-content" }}
        pagination={{
          current: page,
          pageSize: 10,
          total: data.count,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </CardContainer>
  );
}

export default IndexApprovePoint;
