import { PromotionType } from "../definitions/promotion";

export const getQuotaUnit = (promotionType: PromotionType) => {
  return !promotionType || promotionType === PromotionType.OTHER
    ? ""
    : [PromotionType.DISCOUNT_MIX, PromotionType.DISCOUNT_NOT_MIX].includes(promotionType)
      ? "บาท"
      : "สิทธิ์";
};

export const getQuotaString = (isQuota: any) => {
  return isQuota ? "จำกัดโควตา" : "ไม่จำกัดโควตา";
};
