import { Modal } from "antd";
import styled from "styled-components";
import color from "../../resource/color";

const GreyModal = styled(Modal)`
  .ant-modal-body {
    background: ${color.Grey};
  }
  .ant-modal-close-x {
    color: ${color.white};
  }
`;

export default GreyModal;
