import moment, { Moment } from "moment";

const SLASH_DMY = "DD/MM";
const SLASH_DMYHM = "HH:mm น.";

export const dateFormatter = (d: string, withTime?: boolean) => {
  let date = moment(d).format(SLASH_DMY) + "/" + moment(d).add(543, "year").year();
  withTime && (date = date + " " + moment(d).format(SLASH_DMYHM));
  return date;
};
export const convertBuddhistYear = {
  toBuddhistYear: (moment: Moment, format: string) => {
    const christianYear = moment.format("YYYY");
    const buddhishYear = (parseInt(christianYear) + 543).toString();
    return moment
      .format(format.replace("YYYY", buddhishYear).replace("YY", buddhishYear.substring(2, 4)))
      .replace(christianYear, buddhishYear);
  },
};

export const priceFormatter = (
  price: number | string,
  fractionDigits?: number,
  reverseUnit?: boolean,
  hideUnit?: boolean,
) => {
  const digit = !fractionDigits && fractionDigits !== 0 ? 2 : fractionDigits;
  const formatter = new Intl.NumberFormat("th-TH", {
    // style: 'currency',
    currency: "THB",
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  });
  const num = typeof price === "number" ? price : parseFloat(price);
  const result = formatter.format(num);

  return isNaN(num)
    ? "-"
    : hideUnit
      ? `${formatter.format(num)}`
      : reverseUnit
        ? `${formatter.format(num)} ฿`
        : `฿ ${formatter.format(num)}`;
};

export const numberFormatter = (price: number | string, fractionDigits?: number) => {
  return priceFormatter(price, fractionDigits, false, true);
};

export const nameFormatter = (input: string) => {
  if (!input) return "";
  const upperCaseFirstLetter = `${input.slice(0, 1).toUpperCase().slice(1)}`;

  const lowerCaseAllWordsExceptFirstLetters = input?.replaceAll(
    /\S*/g,
    (word) => `${word?.slice(0, 1)}${word.slice(1).toLowerCase()}`,
  );

  return upperCaseFirstLetter + lowerCaseAllWordsExceptFirstLetters;
};

export const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const thaiDate = date.toLocaleDateString("th-TH", options);
  const year = date.getFullYear();
  return thaiDate.replace(/(\d{4})/, year.toString());
};

export const formatDateRange = (startDate: Date, endDate: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
  };

  const formatWithYear = (date: Date) =>
    `${date.toLocaleDateString("th-TH", options)} ${date.getFullYear()}`;

  return `${formatWithYear(endDate)} - ${formatWithYear(startDate)}`;
};
export const formatDateYear = (date: string, isDayFormat: boolean): string => {
  const monthNames = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];

  const dateObj = new Date(date);
  const month = monthNames[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  if (isDayFormat) {
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${day} ${month} ${year}`;
  } else {
    return `${month} ${year}`;
  }
};
