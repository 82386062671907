import React from "react";
import styled from "styled-components";
import Text from "../../components/Text/Text";
import { ReactSVG } from "react-svg";
import { defaultPropsForm } from "../../utility/DefaultProps";
import { Form } from "antd";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import color from "../../resource/color";
import { useLocation, useNavigate } from "react-router-dom";
import icon from "../../resource/icon";
import image from "../../resource/image";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  background-color: ${color.background1};
`;
const CardStyled = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  background-color: #ffffff;
  border-radius: 12px;
  height: fit-content;
  margin-top: 6%;
`;
const ImageStyled = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 16px;
`;

function ResetPasswordSentSuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickBackToLogin = () => {
    navigate("/");
  };
  return (
    <Container>
      <CardStyled>
        {/* <ReactSVG
          onError={(error) => console.log(error)}
          src={`${process.env.PUBLIC_URL}/logo_sellcoda.svg`}
        /> */}

        <img src={image.sellcodaLogo} style={{ marginBottom: 12 }} />
        <ImageStyled src={icon.checkCircle} />

        <Text
          fontSize={24}
          style={{
            marginTop: 16,
          }}
          fontWeight={700}
        >
          ส่งคำขอตั้งค่ารหัสผ่านใหม่เรียบร้อย
        </Text>
        <Text
          fontSize={16}
          align='center'
          style={{
            marginBottom: 16,
          }}
        >
          หากบัญชีของคุณ
          <Text fontSize={16} fontWeight={600}>
            {` ${location.state?.email} `}
          </Text>
          มีอยู่ในระบบ
          <br />
          คุณจะได้รับลิงค์การตั้งค่ารหัสผ่านใหม่ที่ถูกส่งเข้าอีเมลดังกล่าว
        </Text>
        <Text
          fontSize={16}
          align='center'
          style={{
            marginBottom: 0,
          }}
        >
          หากไม่พบอีเมลสามารถตรวจสอบได้ในหัวข้อ Spam email
          <br />
          หรือตรวจสอบความถูกต้องของอีเมลอีกครั้ง
        </Text>

        <Button
          onClick={onClickBackToLogin}
          title='กลับสู่หน้าล็อกอิน'
          style={{
            width: "100%",
            marginTop: 32,
          }}
        />
      </CardStyled>
    </Container>
  );
}

export default ResetPasswordSentSuccessPage;
