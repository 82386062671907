import { BarChartOutlined, SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Table, Tag, Form, Tabs, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { CardContainer } from "../../../components/Card/CardContainer";
import { RangePicker } from "../../../components/DatePicker/DatePicker";
import Input from "../../../components/Input/Input";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import moment from "moment";
import { color } from "../../../resource";
import { useNavigate } from "react-router-dom";
import { getCampaign, updateStatusCampaign } from "../../../datasource/CampaignDatasource";
import icon from "../../../resource/icon";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../../store/RoleAtom";
import { checkPermission } from "../../../components/Permission/Permission";

type FixedType = "left" | "right" | boolean;
const REQUEST_DMY = "YYYY-MM-DD";

export const IndexCampaignPoint: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company, firstname, lastname } = userProfile;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>();

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countStatus, setCountStatus] = useState<any>({});
  const [keyword, setKeyword] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>("ALL");
  const [dateFilter, setDateFilter] = useState<any>();

  const resetPage = () => setPage(1);

  const roleData = useRecoilValue(roleAtom);
  const hasPermission = checkPermission(["campaignPoint", "approve"], roleData);

  useEffect(() => {
    if (!loading) fetchCampaign();
  }, [keyword, dateFilter, statusFilter, page]);

  const fetchCampaign = async () => {
    await getCampaign({
      company,
      searchText: keyword,
      status: statusFilter,
      startDate: dateFilter && dateFilter[0] ? dateFilter[0].format(REQUEST_DMY) : undefined,
      endDate: dateFilter && dateFilter[1] ? dateFilter[1].format(REQUEST_DMY) : undefined,
      page,
      take: 10,
    })
      .then((res) => {
        console.log(res);
        setData(res.data || []);
        setCount(res.count || 0);
        setCountStatus(res.statusCount);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col span={6}>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };
  const tabsItems = [
    {
      label: `ทั้งหมด (${countStatus?.ALL || 0})`,
      key: "ALL",
    },
    {
      label: `เปิดใช้งาน (${countStatus?.ENABLE || 0})`,
      key: "ONLY_ENABLED",
    },
    {
      label: `ปิดใช้งาน (${countStatus?.DISABLE || 0})`,
      key: "ONLY_DISABLED",
    },
  ];

  const PageTitle = () => {
    return (
      <Form>
        <Row align='middle' gutter={16}>
          <Col className='gutter-row' span={10}>
            <div>
              <span
                className='card-label font-weight-bolder text-dark'
                style={{ fontSize: 20, fontWeight: "bold" }}
              >
                แคมเปญพอยท์
              </span>
            </div>
          </Col>

          <Col span={5}>
            <Input
              placeholder='รหัส/ชื่อแคมเปญ'
              suffix={<SearchOutlined />}
              defaultValue={keyword}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setKeyword(value);
                resetPage();
              }}
              onChange={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                if (!value) {
                  setKeyword("");
                  resetPage();
                }
              }}
            />
          </Col>
          <Col span={5}>
            <RangePicker
              enablePast
              allowEmpty={[true, true]}
              onChange={(dates, dateString) => {
                setDateFilter(dates);
              }}
              value={dateFilter}
            />
          </Col>
          <Col className='gutter-row' xl={4} sm={6}>
            <Button
              type='primary'
              title='+ สร้างแคมเปญ'
              height={40}
              onClick={() => navigate("/crm/createCampaignPoint")}
              size='large'
            />
          </Col>
        </Row>
      </Form>
    );
  };
  const columns = [
    {
      title: "ชื่อแคมเปญพอยท์",
      dataIndex: "campaignName",
      key: "campaignName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{row.campaignName}</Text>
              <Text level={6} color='Text3'>
                {row.campaignCode}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "ระยะเวลา",
      dataIndex: "period",
      key: "period",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{`${row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : ""} - ${
                row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : ""
              }`}</Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "พอยท์โปรแกรม",
      dataIndex: "campaignPrograms",
      key: "campaignPrograms",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value?.length || 0}</Text>
              <Text level={6} color='Text3'>
                โปรแกรม
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "จำนวนร้านค้า",
      dataIndex: "campaignProgramDealers",
      key: "campaignProgramDealers",
      render: (value: any[], row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>
                {value?.filter(
                  (item1, pos) =>
                    value.findIndex((item2) => item1.dealerId === item2.dealerId) == pos,
                ).length || 0}
              </Text>
              <Text level={6} color='Text3'>
                ร้านค้า
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "อัปเดทโดย",
      dataIndex: "updateAt",
      key: "updateAt",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{moment(value).format("DD/MM/YYYY")}</Text>
              <Text level={6} color='Text3'>
                {row.updateBy}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {!moment(row.endDate).isBefore(moment()) ? (
                value ? (
                  <Tag color={color.success} style={{ fontWeight: 700 }}>
                    เปิดใช้งาน
                  </Tag>
                ) : (
                  <Tag color={color.Disable} style={{ fontWeight: 700 }}>
                    ปิดใช้งาน
                  </Tag>
                )
              ) : (
                <Text level={6} color='Text3'>
                  หมดอายุ
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "อนุมัติ",
      dataIndex: "isApproved",
      key: "isApproved",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Switch
                checked={value}
                onClick={async (checked) => {
                  await updateStatusCampaign({
                    campaignId: row.campaignId,
                    approved: checked,
                    updateBy: `${firstname} ${lastname}`,
                  })
                    .then((res) => {
                      console.log(res);
                      setData(
                        data?.map((d) =>
                          d.campaignId === row.campaignId ? { ...d, isApproved: checked } : d,
                        ),
                      );
                      message.success(
                        "แก้ไขการอนุมัติแคมเปญเป็น '" +
                          (checked ? "อนุมัติ" : "ไม่อนุมัติ") +
                          "' แล้ว",
                      );
                    })
                    .catch((e) => {
                      console.log(e);
                      message.error("แก้ไขการอนุมัติแคมเปญไม่สำเร็จ");
                    });
                }}
                disabled={!hasPermission}
              />
              {/* {!moment(row.endDate).isBefore(moment()) ? (
                <Text level={6} color={value ? "success" : "error"}>
                  {value ? "เปิดการใช้งาน" : "ปิดการใช้งาน"}
                </Text>
              ) : (
                <Text level={6} color='Text3'>
                  หมดอายุ
                </Text>
              )} */}
            </>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      fixed: "right" as FixedType | undefined,
      width: "15%",
      render: (value: any, row: any, index: number) => {
        const isExpired = moment(row.endDate).isBefore(moment());
        return {
          children: (
            <>
              <Row>
                <ActionBtn
                  onClick={() => navigate("/crm/campaignPoint/" + row.campaignId)}
                  icon={
                    <UnorderedListOutlined style={{ width: 16, height: 16, fontWeight: 700 }} />
                  }
                />
                &nbsp;
                <ActionBtn
                  onClick={() => navigate("/crm/campaignPoint/history/" + row.campaignId)}
                  icon={<img src={icon.crmPointTransaction} style={{ width: 16, height: 16 }} />}
                />
              </Row>
            </>
          ),
        };
      },
    },
  ];
  return (
    <CardContainer>
      <PageTitle />
      <br />
      <Tabs
        items={tabsItems}
        onChange={(key: string) => {
          setStatusFilter(key);
          resetPage();
        }}
      />
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          current: page,
          total: count,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
        loading={loading}
      />
    </CardContainer>
  );
};

export default IndexCampaignPoint;
