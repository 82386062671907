import {
  Col,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Input,
  Table,
  Tag,
  Modal,
  Button as ButtonAntd,
  Image,
  Divider,
} from "antd";
import Text from "../../components/Text/Text";
import {
  CalendarOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import color from "../../resource/color";
import { Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import Button from "../../components/Button/Button";
import { STATUS_COLOR_MAPPING } from "../../definitions/product";
import { numberFormatter } from "../../utility/Formatter";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // them
import "./../../index.css";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { da, th } from "date-fns/locale";
import { useQuery } from "react-query";
import { zoneDatasource } from "../../datasource/ZoneDatasource";
import {
  getReportPromotion,
  getExcelProReportZone,
  getExcelProReportPromo,
} from "../../datasource/PromotionDatasource";
import image from "../../resource/image";
import { useNavigate } from "react-router-dom";
import { PROMOTION_TYPE_NAME } from "../../definitions/promotion";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../../config/develop-config";
import { CardContainer } from "../../components/Card/CardContainer";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  ArcElement,
  Tooltip,
  Legend,
);

export const PromotionInfo: React.FC = () => {
  const navigate = useNavigate();
  const initDate = new Date();
  const company = JSON.parse(localStorage.getItem("company")!);
  const [graphShow, setGraphShow] = useState("ALL");
  const [zoneData, setZoneData] = useState<{ label: string; value: string; key: string }[]>([]);
  useEffect(() => {
    const getZone = async () => {
      const res = await zoneDatasource.getAllZoneByCompany(company.companyCode);
      const result = res.map((data) => {
        return {
          label: data.zoneName,
          value: data.zoneName,
          key: data,
        };
      });
      setZoneData(result);
    };
    getZone();
  }, []);
  const [filter, setFilter] = useState("30Day");
  const [calendar, setCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: initDate,
    endDate: initDate,
    key: "selection",
  });
  const [sortSell, setSortSell] = useState({
    sortBy: "zone",
    sortDirection: "asc",
  });

  const [sortPromotion, setSortPromotion] = useState({
    sortBy: "promotion_status",
    sortDirection: "desc",
  });

  const [filterQuery, setFilterQuery] = useState<any>({
    startDate: moment(initDate).subtract(30, "d").format().toString(),
    endDate: moment(initDate).format().toString(),
    search: "",
    zone: [],
    labelsGraph: [],
    dataPriceGraph: [],
    dataDiscountGraph: [],
    dataFreebieGraph: [],
    piedata: [],
    hasPieData: false,
  });

  const options = [
    {
      label: `${
        dateRange.startDate === dateRange.endDate
          ? "กำหนดเอง"
          : `${moment(dateRange.startDate).format("L")}-${moment(dateRange.endDate).format("L")}`
      }`,
      value: "Custom",
    },
    { label: "วันนี้", value: "Today" },
    { label: "7 วัน", value: "7Day" },
    { label: "30 วัน", value: "30Day" },
    { label: "3 เดือน", value: "3Month" },
    { label: "6 เดือน", value: "6Month" },
    { label: "12 เดือน", value: "12Month" },
  ];

  const handleCalendar = (ranges) => {
    setDateRange(ranges.selection);
  };

  const report = useQuery(
    [
      filter,
      filterQuery.startDate,
      filterQuery.endDate,
      filterQuery.search,
      filterQuery.zone,
      sortSell.sortBy,
      sortSell.sortDirection,
      sortPromotion.sortBy,
      sortPromotion.sortDirection,
    ],
    async () => {
      const res = await getReportPromotion(
        filterQuery.startDate,
        filterQuery.endDate,
        filterQuery.search,
        filterQuery.zone,
        company.companyCode,
      );
      const piedata = res.types;
      const freebiesnotmix = piedata.filter((el: any) => el.promotion_type === "FREEBIES_NOT_MIX");
      const discountnotmix = piedata.filter((el: any) => el.promotion_type === "DISCOUNT_NOT_MIX");
      const freebiesmix = piedata.filter((el: any) => el.promotion_type === "FREEBIES_MIX");
      const discountmix = piedata.filter((el: any) => el.promotion_type === "DISCOUNT_MIX");
      const other = piedata.filter((el: any) => el.promotion_type === "OTHER");

      if (filter === "Custom" || filter === "30Day" || filter === "Today" || filter === "7Day") {
        const data = res.priceGraph.map((el) => {
          return {
            ...el,
            date: moment(el.date).format(),
          };
        });
        const firstData =
          data.length === 0
            ? {
                date: filterQuery.startDate,
                total_discount: "0",
                total_price: "0",
                total_freebies_value: "0",
              }
            : filterQuery.startDate === data[0].date
              ? data[0]
              : {
                  date: filterQuery.startDate,
                  total_discount: "0",
                  total_price: "0",
                  total_freebies_value: "0",
                };

        const endData =
          data.length === 0
            ? {
                date: filterQuery.startDate,
                total_discount: "0",
                total_price: "0",
                total_freebies_value: "0",
              }
            : filterQuery.endDate === data[data.length - 1].date
              ? data[data.length - 1]
              : {
                  date: filterQuery.endDate,
                  total_discount: "0",
                  total_price: "0",
                  total_freebies_value: "0",
                };
        const len = data.length;
        const multiplyNum = parseInt((len / 6).toString());
        const result =
          data.length != 0
            ? data.length > 7
              ? [
                  firstData,
                  data[multiplyNum],
                  data[multiplyNum * 2],
                  data[multiplyNum * 3],
                  data[multiplyNum * 4],
                  data[multiplyNum * 5],
                  endData,
                ]
              : data.length === 1
                ? [
                    {
                      date: data[0].date,
                      total_discount: "0",
                      total_price: "0",
                      total_freebies_value: "0",
                    },
                    data[0],
                  ]
                : [firstData, ...data, endData]
            : [];
        setFilterQuery({
          ...filterQuery,
          labelsGraph: result.map((el) => moment(el.date).format("D MMM YYYY")),
          dataPriceGraph: result.map((el) => parseFloat(el.total_price)),
          dataDiscountGraph: result.map((el) => parseFloat(el.total_discount)),
          dataFreebieGraph: result.map((el) => parseFloat(el.total_freebies_value)),
          piedata: [
            discountnotmix.length != 0 ? Number(discountnotmix[0].total_order) : 0,
            discountmix.length != 0 ? Number(discountmix[0].total_order) : 0,
            freebiesmix.length != 0 ? Number(freebiesmix[0].total_order) : 0,
            freebiesnotmix.length != 0 ? Number(freebiesnotmix[0].total_order) : 0,
            other.length != 0 ? Number(other[0].total_order) : 0,
          ],
          hasPieData:
            discountnotmix.length != 0 ||
            discountmix.length != 0 ||
            freebiesmix.length != 0 ||
            freebiesnotmix.length != 0 ||
            other.length != 0,
        });
      } else {
        const loopArr: any = [];
        const loopMonth = filter === "3Month" ? 3 : filter === "6Month" ? 6 : 12;
        for (let i = loopMonth; i > 0; i--) {
          const element = moment(initDate)
            .subtract(i - 1, "month")
            .format()
            .toString()
            .substring(0, 7);
          loopArr.push(element);
        }
        const datesArray = res.priceGraph.map((el) => {
          return {
            ...el,
            date: moment(el.date).format(),
          };
        });
        const groupedByMonth = datesArray.reduce((acc, data) => {
          const yearMonth = data.date.substring(0, 7);
          if (!acc[yearMonth]) {
            acc[yearMonth] = [];
          }
          acc[yearMonth].push({ ...data, date: data.date });
          return acc;
        }, {});

        const lastDates = Object.values(groupedByMonth).map((dates: any) => {
          dates.sort((a: any, b: any) => a - b);
          const lastDate = dates[dates.length - 1];
          return lastDate;
        });
        const filterArr = lastDates.map((el) =>
          moment(el.date).format().toString().substring(0, 7),
        );
        loopArr.map((el: any) => {
          if (!filterArr.includes(el)) {
            lastDates.push({
              date: moment(el).endOf("month").format(),
              total_discount: 0,
              total_price: 0,
            });
          }
        });
        setFilterQuery({
          ...filterQuery,
          labelsGraph: lastDates
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            .map((el) => moment(el.date).format("MMM YYYY")),
          dataPriceGraph: lastDates
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            .map((el) => parseFloat(el.total_price)),
          dataDiscountGraph: lastDates
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            .map((el) => parseFloat(el.total_discount)),
          dataFreebieGraph: lastDates
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            .map((el) => parseFloat(el.total_freebies_value)),
          piedata: [
            discountnotmix.length != 0 ? Number(discountnotmix[0].total_order) : 0,
            discountmix.length != 0 ? Number(discountmix[0].total_order) : 0,
            freebiesmix.length != 0 ? Number(freebiesmix[0].total_order) : 0,
            freebiesnotmix.length != 0 ? Number(freebiesnotmix[0].total_order) : 0,
            other.length != 0 ? Number(other[0].total_order) : 0,
          ],
          hasPieData:
            discountnotmix.length != 0 ||
            discountmix.length != 0 ||
            freebiesmix.length != 0 ||
            freebiesnotmix.length != 0 ||
            other.length != 0,
        });
      }
      return res;
    },
  );
  const onChangeFilter = ({ target: { value } }: RadioChangeEvent) => {
    if (value === "Custom") {
      setCalendar(true);
    } else {
      setFilter(value);
      switch (value) {
        case "Today":
          setFilterQuery({
            ...filterQuery,
            startDate: moment(initDate).set({ hour: 0, minute: 0, second: 0 }).format().toString(),
            endDate: moment(initDate).format().toString(),
          });
          break;
        case "7Day":
          setFilterQuery({
            ...filterQuery,
            startDate: moment(initDate)
              .subtract(7, "d")
              .set({ hour: 0, minute: 0, second: 0 })
              .format()
              .toString(),
            endDate: moment(initDate).format().toString(),
          });
          return "7 วันล่าสุด";
        case "30Day":
          setFilterQuery({
            ...filterQuery,
            startDate: moment(initDate).subtract(1, "M").format().toString(),
            endDate: moment(initDate).format().toString(),
          });
          break;
        case "3Month":
          setFilterQuery({
            ...filterQuery,
            startDate: moment(initDate).subtract(3, "M").format().toString(),
            endDate: moment(initDate).format().toString(),
          });
          return "3 เดือนล่าสุด";
        case "6Month":
          setFilterQuery({
            ...filterQuery,
            startDate: moment(initDate).subtract(6, "M").format().toString(),
            endDate: moment(initDate).format().toString(),
          });
          return "6 เดือนล่าสุด";
        case "12Month":
          setFilterQuery({
            ...filterQuery,
            startDate: moment(initDate).subtract(12, "M").format().toString(),
            endDate: moment(initDate).format().toString(),
          });
          return "12 เดือนล่าสุด";
        default:
          return "30 วันล่าสุด";
      }
    }
  };

  const getTimeFilter = (): string => {
    switch (filter) {
      case "Custom":
        return `${moment(dateRange.startDate).format("L")} - ${moment(dateRange.endDate).format(
          "L",
        )}`;
      case "Today":
        return "วันนี้";
      case "7Day":
        return "7 วันล่าสุด";
      case "30Day":
        return "30 วันล่าสุด";
      case "3Month":
        return "3 เดือนล่าสุด";
      case "6Month":
        return "6 เดือนล่าสุด";
      case "12Month":
        return "12 เดือนล่าสุด";
      default:
        return "30 วันล่าสุด";
    }
  };

  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col span={6}>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };
  const columnZone = [
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            เขต
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "zone",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "zone",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "zone",
      key: "zone",
      width: "80px",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.zone}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          จำนวนร้าน
        </Text>
      ),
      dataIndex: "sum",
      key: "sum",
      width: "100px",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.totalcustomer} ร้านค้า
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            ยอดขายที่ใช้โปร (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "total_price",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "total_price",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "sum",
      key: "sum",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.total_price > 0 ? numberFormatter(row.total_price, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            ยอดส่วนลดที่ถูกใช้ (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "total_discount",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "total_discount",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "discount",
      key: "discount",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.total_discount > 0 ? numberFormatter(row.total_discount, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            ยอดของแถมที่ใช้ (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "total_freebies_value",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortSell({
                  sortBy: "total_freebies_value",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "discount",
      key: "discount",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.total_freebies_value > 0 ? numberFormatter(row.total_freebies_value, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          เพิ่มเติม
        </Text>
      ),
      dataIndex: "other",
      key: "other",
      width: "80px",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <ActionBtn
              onClick={() =>
                navigate(
                  `/report/salelist/${row.zone}/${moment(filterQuery.startDate).format(
                    "YYYY-MM-DD",
                  )}/${moment(filterQuery.endDate).format("YYYY-MM-DD")}`,
                )
              }
              icon={<UnorderedListOutlined />}
            />
          ),
        };
      },
    },
  ];

  const columnPromotion = [
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          รหัสโปรโมชัน
        </Text>
      ),
      dataIndex: "promotionCode",
      key: "promotionCode",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.promotion_code}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          ชื่อโปรโมชัน
        </Text>
      ),
      dataIndex: "promotionName",
      key: "promotionName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.promotion_name}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          ประเภทโปรโมชัน
        </Text>
      ),
      dataIndex: "typePromotion",
      key: "typePromotion",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {PROMOTION_TYPE_NAME[row.promotion_type]}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          ช่วงเวลาโปรโมชัน
        </Text>
      ),
      dataIndex: "timePromotion",
      key: "timePromotion",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>{`${moment(row.start_date).format(
              "L",
            )} - ${moment(row.end_date).format("L")}`}</Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          สถานะ
        </Text>
      ),
      dataIndex: "status",
      key: "status",
      width: "6%",
      render: (value: any, row: any, index: number) => {
        const STATUS_NAME_MAPPING: any = {
          ACTIVE: "เปิดใช้งาน",
          INACTIVE: "ปิดใช้งาน",
          HOLD: "ปิดการใช้งานชั่วคราว",
        };
        const now = moment().unix();
        const expiredDate = moment(row.end_date).unix();
        return {
          children: (
            <Tag
              style={{
                color: expiredDate - now < 0 ? color.Text1 : color.white,
              }}
              color={
                expiredDate - now < 0
                  ? color.white
                  : STATUS_COLOR_MAPPING[row.promotion_status ? "ACTIVE" : "INACTIVE"]
              }
            >
              {expiredDate - now < 0
                ? "หมดอายุ"
                : STATUS_NAME_MAPPING[row.promotion_status ? "ACTIVE" : "INACTIVE"]}
            </Tag>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            จำนวนคำสั่งซื้อ
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_order",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_order",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "sum",
      key: "sum",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {numberFormatter(row.total_order, 0)} รายการ
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            ยอดขายที่ใช้โปร (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_price",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_price",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "sum",
      key: "sum",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.total_price > 0 ? numberFormatter(row.total_price, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            ยอดส่วนลดที่ถูกใช้ (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_discount",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_discount",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "discount",
      key: "discount",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.total_discount > 0 ? numberFormatter(row.total_discount, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={14}>
            ยอดของแถมที่ใช้ (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_freebies_value",
                  sortDirection: "asc",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setSortPromotion({
                  sortBy: "total_freebies_value",
                  sortDirection: "desc",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "discount",
      key: "discount",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={14}>
              {row.total_freebies_value > 0 ? numberFormatter(row.total_freebies_value, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          เพิ่มเติม
        </Text>
      ),
      dataIndex: "other",
      key: "other",
      width: "87px",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <ActionBtn
              onClick={() => navigate(`/PromotionPage/promotion/detail/${row.promotion_id}`)}
              icon={<UnorderedListOutlined />}
            />
          ),
        };
      },
    },
  ];

  const downloadExcelZone = async (
    startDate: string,
    endDate: string,
    search: string,
    zone: string[],
    company: string,
  ) => {
    getExcelProReportZone(startDate, endDate, search, zone, company)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Promotion Report_All-area_${moment(startDate).format(
          "YYYY-MM-DD",
        )} - ${moment(endDate).format("YYYY-MM-DD")}`;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => console.log(err));
  };
  const downloadExcelPromo = async (
    startDate: string,
    endDate: string,
    search: string,
    zone: string[],
    company: string,
  ) => {
    getExcelProReportPromo(startDate, endDate, search, zone, company)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Promotion Report_Lasted-promotion_${moment(startDate).format(
          "YYYY-MM-DD",
        )} - ${moment(endDate).format("YYYY-MM-DD")}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <CardContainer style={{ width: "100%" }}>
        <Text fontSize={20} fontWeight={600}>
          โปรโมชัน
        </Text>
        <Divider />
        <Row justify={"space-between"} gutter={8}>
          <Col span={filter === "Custom" ? 18 : 20}>
            <Radio.Group onChange={onChangeFilter} value={filter} optionType='button'>
              {options.map((val) => (
                <Radio.Button
                  onClick={() => {
                    if (val.value === "Custom") {
                      setCalendar(true);
                    } else {
                      setDateRange({
                        startDate: initDate,
                        endDate: initDate,
                        key: "selection",
                      });
                    }
                  }}
                  key={val.value}
                  value={val.value}
                >
                  {val.value === "Custom" ? (
                    <>
                      <CalendarOutlined />
                      <Text style={{ marginLeft: "5px" }} fontSize={14}>
                        {val.label}
                      </Text>
                    </>
                  ) : (
                    <>{val.label}</>
                  )}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
          <Col span={4}>
            <Select
              maxTagCount={"responsive"}
              mode='multiple'
              options={zoneData}
              placeholder='ค้นหาเขต'
              style={{
                width: "100%",
                marginRight: "20px",
                fontWeight: "lighter",
              }}
              value={filterQuery.zone}
              onChange={(value, index) => {
                setFilterQuery({
                  ...filterQuery,
                  zone: value,
                });
              }}
            />
          </Col>
          <Col>
            {filter === "Custom" && (
              <ButtonAntd
                onClick={() => {
                  setDateRange({
                    startDate: initDate,
                    endDate: initDate,
                    key: "selection",
                  });
                  setFilter("30Day");
                  setFilterQuery({
                    ...filterQuery,
                    zone: [],
                  });
                }}
                type='text'
              >
                <Text
                  fontSize={14}
                  style={{
                    color: color.error,
                    textDecorationLine: "underline",
                  }}
                >
                  ค่าเริ่มต้น
                </Text>
              </ButtonAntd>
            )}
          </Col>
        </Row>
      </CardContainer>
      <br />
      <Row justify={"space-between"} gutter={16}>
        <Col span={8}>
          <Row gutter={[10, 10]}>
            <Col sm={24}>
              <div
                style={{
                  height: "220px",
                  backgroundColor: "#FFF",
                  borderRadius: "12px",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    fontSize={24}
                    fontWeight={600}
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    ยอดขายที่มีการใช้โปรโมชัน (บาท)
                  </Text>
                </div>
                <Text
                  fontSize={12}
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  {getTimeFilter()}
                </Text>
                {report.isLoading ? (
                  0
                ) : report.isError ? (
                  0
                ) : !report.data.total_price ? (
                  <Text
                    fontSize={14}
                    style={{
                      fontFamily: "Sarabun",
                      paddingLeft: "10px",
                      color: color.Grey,
                      textAlign: "center",
                      marginTop: "30px",
                    }}
                  >
                    ไม่พบข้อมูล
                  </Text>
                ) : (
                  <Text
                    fontSize={40}
                    fontWeight={600}
                    style={{
                      paddingLeft: "10px",
                      color: "#1DD57C",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {numberFormatter(report.data.total_price, 2)}
                  </Text>
                )}
                {report.isLoading
                  ? 0
                  : report.isError
                    ? 0
                    : report.data.total_price && (
                        <Text
                          fontSize={12}
                          style={{
                            paddingLeft: "10px",
                            textAlign: "center",
                            marginTop: "20px",
                          }}
                        >
                          {numberFormatter(
                            report.isLoading ? 0 : report.isError ? 0 : report.data.total_order,
                            0,
                          )}{" "}
                          คำสั่งซื้อ
                        </Text>
                      )}
              </div>
            </Col>
            <Col sm={24} style={{ height: "225px", justifyContent: "center" }}>
              <div
                style={{
                  height: "100%",
                  backgroundColor: "#FFF",
                  borderRadius: "12px",
                  padding: "30px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Row gutter={8}>
                  <Col span={10}>
                    <Text fontSize={18} fontWeight={600}>
                      ยอดส่วนลดที่ถูกใช้ (บาท)
                    </Text>
                    <br />
                    <Text fontSize={12}>{getTimeFilter()}</Text>
                  </Col>
                  <Col span={14}>
                    {report.isLoading ? (
                      0
                    ) : report.isError ? (
                      0
                    ) : !report.data.total_discount ? (
                      <div style={{ textAlign: "right", paddingTop: "8px" }}>
                        <Text
                          fontSize={14}
                          style={{
                            fontFamily: "Sarabun",
                            paddingLeft: "10px",
                            color: color.Grey,
                            textAlign: "right",
                            marginTop: "30px",
                          }}
                        >
                          ไม่พบข้อมูล
                        </Text>
                      </div>
                    ) : (
                      <div style={{ textAlign: "right", paddingTop: "18px" }}>
                        <Text fontSize={26} fontWeight={600} color='primary'>
                          {numberFormatter(
                            report.isLoading ? 0 : report.isError ? 0 : report.data.total_discount,
                            2,
                          )}
                        </Text>
                      </div>
                    )}
                  </Col>
                </Row>
                <Divider />
                <Row gutter={8}>
                  <Col span={10}>
                    <Text fontSize={18} fontWeight={600}>
                      ยอดของแถมที่ถูกใช้ (บาท)
                    </Text>
                    <br />
                    <Text fontSize={12}>{getTimeFilter()}</Text>
                  </Col>
                  <Col span={14}>
                    {report.isLoading ? (
                      0
                    ) : report.isError ? (
                      0
                    ) : !report.data.total_freebies_value ? (
                      <div style={{ textAlign: "right", paddingTop: "8px" }}>
                        <Text
                          fontSize={14}
                          style={{
                            fontFamily: "Sarabun",
                            paddingLeft: "10px",
                            color: color.Grey,
                            marginTop: "30px",
                          }}
                        >
                          ไม่พบข้อมูล
                        </Text>
                      </div>
                    ) : (
                      <div style={{ textAlign: "right", paddingTop: "18px" }}>
                        <Text fontSize={26} fontWeight={600} color='warning'>
                          {numberFormatter(
                            report.isLoading
                              ? 0
                              : report.isError
                                ? 0
                                : report.data.total_freebies_value,
                            2,
                          )}
                        </Text>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <CardContainer style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text fontSize={24} fontWeight={600} style={{}}>
                  กราฟเปรียบเทียบล่าสุด
                </Text>
                <Text fontSize={12} style={{}}>
                  {getTimeFilter()}
                </Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#76FFBD80",
                      border: "1px solid #006937",
                    }}
                  />
                  <Text
                    fontSize={12}
                    style={{
                      marginLeft: "5px",
                      marginRight: "10px",
                      fontFamily: "Sarabun",
                    }}
                  >
                    ยอดขายโปรโมชัน
                  </Text>
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#82BBFF99",
                      border: "1px solid #0075FF",
                    }}
                  />
                  <Text
                    fontSize={12}
                    style={{
                      marginLeft: "5px",
                      marginRight: "10px",
                      fontFamily: "Sarabun",
                    }}
                  >
                    ยอดส่วนลดโปรโมชัน
                  </Text>
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#f8dcbc",
                      border: "1px solid #E77417",
                    }}
                  />
                  <Text
                    fontSize={12}
                    style={{
                      marginLeft: "5px",
                      marginRight: "10px",
                      fontFamily: "Sarabun",
                    }}
                  >
                    ยอดของแถม
                  </Text>
                </div>
              </div>
              <Select
                options={[
                  {
                    value: "ALL",
                    label: "ภาพรวม",
                  },
                  {
                    value: "PROMOTION",
                    label: "ยอดขายโปรโมชัน",
                  },
                  {
                    value: "DISCOUNT",
                    label: "ยอดส่วนลดโปรโมชัน",
                  },
                  {
                    value: "FREEBIE",
                    label: "ยอดของแถม",
                  },
                ]}
                placeholder='ยี่ห้อ : ทั้งหมด'
                style={{
                  width: "20%",
                  marginRight: "20px",
                  fontWeight: "lighter",
                }}
                value={graphShow}
                onChange={(value, option) => setGraphShow(value)}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {filterQuery.dataPriceGraph.length === 0 ? (
                <Image
                  src={image.emptyLineChart}
                  style={{
                    width: "100%",
                    height: "300px",
                  }}
                  preview={false}
                />
              ) : (
                <Line
                  data={{
                    labels: filterQuery.labelsGraph,
                    datasets:
                      graphShow === "ALL"
                        ? [
                            {
                              label: "ยอดส่วนลดโปรโมชัน",
                              backgroundColor: "#82BBFF99",
                              borderColor: "#0075FF",
                              borderWidth: 1,
                              tension: 0.1,
                              pointRadius: 0,
                              data: filterQuery.dataDiscountGraph,
                              fill: true,
                            },
                            {
                              label: "ยอดขายโปรโมชัน",
                              backgroundColor: "#76FFBD80",
                              borderColor: "#006937",
                              borderWidth: 1,
                              tension: 0.1,
                              pointRadius: 0,
                              data: filterQuery.dataPriceGraph,
                              fill: true,
                            },
                            {
                              label: "ยอดของแถม",
                              backgroundColor: "#f8dcbc",
                              borderColor: "#E77417",
                              borderWidth: 1,
                              tension: 0.1,
                              pointRadius: 0,
                              data: filterQuery.dataFreebieGraph,
                              fill: true,
                            },
                          ]
                        : graphShow === "PROMOTION"
                          ? [
                              {
                                label: "ยอดขายโปรโมชัน",
                                backgroundColor: "#76FFBD80",
                                borderColor: "#006937",
                                borderWidth: 1,
                                tension: 0.1,
                                pointRadius: 0,
                                data: filterQuery.dataPriceGraph,
                                fill: true,
                              },
                            ]
                          : graphShow === "FREEBIE"
                            ? [
                                {
                                  label: "ยอดของแถม",
                                  backgroundColor: "#f8dcbc",
                                  borderColor: "#E77417",
                                  borderWidth: 1,
                                  tension: 0.1,
                                  pointRadius: 0,
                                  data: filterQuery.dataFreebieGraph,
                                  fill: true,
                                },
                              ]
                            : [
                                {
                                  label: "ยอดส่วนลดโปรโมชัน",
                                  backgroundColor: "#82BBFF99",
                                  borderColor: "#0075FF",
                                  borderWidth: 1,
                                  tension: 0.1,
                                  pointRadius: 0,
                                  data: filterQuery.dataDiscountGraph,
                                  fill: true,
                                },
                              ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        min: 0,
                        max:
                          graphShow === "DISCOUNT"
                            ? Math.max(...filterQuery.dataDiscountGraph) * 1.3
                            : graphShow === "FREEBIE"
                              ? Math.max(...filterQuery.dataFreebieGraph) * 1.3
                              : Math.max(...filterQuery.dataPriceGraph) * 1.3,
                        ticks: {
                          font: {
                            family: "Sarabun, sans-serif", // Custom font family for Y-axis ticks
                          },
                        },
                      },
                      x: {
                        ticks: {
                          font: {
                            family: "Sarabun, sans-serif", // Custom font family for Y-axis ticks
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </CardContainer>
        </Col>
      </Row>
      <br />
      <Row justify={"space-between"} gutter={16}>
        <Col sm={12}>
          <div
            style={{
              height: "500px",
              backgroundColor: "#FFF",
              borderRadius: "12px",
              padding: "20px",
            }}
          >
            <Text fontSize={24} fontWeight={600}>
              ประเภทโปรโมชันที่ถูกใช้งาน
            </Text>
            <Row>
              <Col sm={18}>
                <div
                  style={{
                    height: "350px",
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  {!filterQuery.hasPieData ? (
                    <Image
                      src={image.emptyPie}
                      style={{
                        width: "100%",
                        height: "300px",
                      }}
                    />
                  ) : (
                    <Pie
                      data={{
                        datasets: [
                          {
                            data: filterQuery.piedata,
                            backgroundColor: [
                              "#469AFC",
                              "#FF9E2C",
                              "#F86969",
                              "#2EAE4B",
                              "#FEDD2F",
                            ],
                          },
                        ],
                      }}
                    />
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "3px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#469AFC",
                      }}
                    />
                    <Text
                      fontSize={12}
                      style={{
                        marginLeft: "5px",
                        marginRight: "10px",
                        fontFamily: "Sarabun",
                      }}
                    >
                      ส่วนลดแบบคละ SKU
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "3px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#FF9E2C",
                      }}
                    />
                    <Text
                      fontSize={12}
                      style={{
                        marginLeft: "5px",
                        marginRight: "10px",
                        fontFamily: "Sarabun",
                      }}
                    >
                      ส่วนลดแบบไม่คละ SKU
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "3px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#F86969",
                      }}
                    />
                    <Text
                      fontSize={12}
                      style={{
                        marginLeft: "5px",
                        marginRight: "10px",
                        fontFamily: "Sarabun",
                      }}
                    >
                      ของแถมแบบคละ SKU
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "3px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#2EAE4B",
                      }}
                    />
                    <Text
                      fontSize={12}
                      style={{
                        marginLeft: "5px",
                        marginRight: "10px",
                        fontFamily: "Sarabun",
                      }}
                    >
                      ของแถมแบบไม่คละ SKU
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "3px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#FEDD2F",
                      }}
                    />
                    <Text
                      fontSize={12}
                      style={{
                        marginLeft: "5px",
                        marginRight: "10px",
                        fontFamily: "Sarabun",
                      }}
                    >
                      อื่นๆ
                    </Text>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={12}>
          <div
            style={{
              height: "500px",
              backgroundColor: "#FFF",
              borderRadius: "12px",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text fontSize={24} fontWeight={600}>
                    เขตการขาย
                  </Text>
                  <Text
                    fontSize={16}
                    style={{
                      paddingLeft: "5px",
                      color: color.Grey,
                    }}
                  >
                    ({report.isLoading ? 0 : report.isError ? 0 : report.data.zones.length} เขต)
                  </Text>
                </div>
                <Text fontSize={12}>{getTimeFilter()}</Text>
              </div>
              <Button
                style={{
                  width: "140px",
                }}
                title='ดาวน์โหลด'
                icon={<DownloadOutlined style={{ color: "white", fontSize: "18px" }} />}
                onClick={() =>
                  downloadExcelZone(
                    filterQuery.startDate,
                    filterQuery.endDate,
                    filterQuery.search,
                    filterQuery.zone,
                    company.companyCode,
                  )
                }
              />
            </div>
            <div>
              <Table
                className='rounded-lg'
                pagination={false}
                dataSource={
                  report.isLoading
                    ? []
                    : report.isError
                      ? []
                      : report.data.zones.sort((a: any, b: any) => {
                          const val1 = a[sortSell.sortBy];
                          const val2 = b[sortSell.sortBy];
                          if (val1 < val2) {
                            if (sortSell.sortDirection === "asc") {
                              return -1;
                            } else {
                              return 1;
                            }
                          }
                          if (val1 > val2) {
                            if (sortSell.sortDirection === "asc") {
                              return 1;
                            } else {
                              return -1;
                            }
                          }
                          return 0;
                        })
                }
                columns={columnZone}
                size='large'
                scroll={{ y: "300px" }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row justify={"space-between"} gutter={16}>
        <Col span={24}>
          <div
            style={{
              height: "600px",
              backgroundColor: "#FFF",
              borderRadius: "12px",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text fontSize={24} fontWeight={600}>
                  รายการโปรโมชันล่าสุด
                </Text>
                <Text
                  fontSize={14}
                  style={{
                    color: color.Grey,
                  }}
                >
                  ล่าสุด {report?.data?.promotions?.length} รายการ
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Button
                  typeButton='primary-light'
                  style={{
                    width: "100px",
                  }}
                  title='ดูทั้งหมด'
                  onClick={() => navigate(`/report/promotionReportList`)}
                />
                <div style={{ width: "10px" }} />
                <Button
                  style={{
                    width: "140px",
                  }}
                  title='ดาวน์โหลด'
                  icon={<DownloadOutlined style={{ color: "white", fontSize: "18px" }} />}
                  onClick={() =>
                    downloadExcelPromo(
                      filterQuery.startDate,
                      filterQuery.endDate,
                      filterQuery.search,
                      filterQuery.zone,
                      company.companyCode,
                    )
                  }
                />
              </div>
            </div>
            <div>
              <Table
                className='rounded-lg'
                pagination={false}
                dataSource={
                  report.isLoading
                    ? []
                    : report.isError
                      ? []
                      : report.data.promotions.sort((a: any, b: any) => {
                          const val1 = a[sortPromotion.sortBy];
                          const val2 = b[sortPromotion.sortBy];
                          if (val1 < val2) {
                            if (sortPromotion.sortDirection === "asc") {
                              return -1;
                            } else {
                              return 1;
                            }
                          }
                          if (val1 > val2) {
                            if (sortPromotion.sortDirection === "asc") {
                              return 1;
                            } else {
                              return -1;
                            }
                          }
                          return 0;
                        })
                }
                columns={columnPromotion}
                size='large'
                scroll={{ y: "400px" }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title={
          <Text fontSize={16} fontWeight={600}>
            กำหนดช่วงเวลา
          </Text>
        }
        open={calendar}
        width={400}
        onCancel={() => setCalendar(false)}
        onOk={() => {
          setCalendar(false);
          setFilter("Custom");
          setFilterQuery({
            ...filterQuery,
            startDate: moment(dateRange.startDate).format().toString(),
            endDate: moment(dateRange.endDate).format().toString(),
          });
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Input
              value={
                dateRange.startDate === dateRange.endDate
                  ? ""
                  : moment(dateRange.startDate).format("L")
              }
              disabled
              style={{
                fontFamily: "Sarabun",
                width: "140px",
                backgroundColor: "#FFF",
                color: color.Text1,
              }}
              placeholder='วันเรื่มต้น'
            />
            <Input
              value={
                dateRange.startDate === dateRange.endDate
                  ? ""
                  : moment(dateRange.endDate).format("L")
              }
              disabled
              style={{
                fontFamily: "Sarabun",
                width: "140px",
                backgroundColor: "#FFF",
                color: color.Text1,
              }}
              placeholder='วันสิ้นสุด'
            />
            <Button
              onClick={() =>
                setDateRange({
                  startDate: initDate,
                  endDate: initDate,
                  key: "selection",
                })
              }
              style={{
                width: "60px",
              }}
              title='ล้าง'
            />
          </div>
          <DateRangePicker
            key={"calendar"}
            onChange={handleCalendar}
            ranges={[dateRange]}
            maxDate={new Date()}
            locale={th}
          />
        </div>
      </Modal>
    </>
  );
};
