import styled from "styled-components";
import color from "../../resource/color";
import { Table } from "antd";

const GreyTable = styled(Table)`
  .ant-table-cell {
    background: #fafafa !important;
  }
`;
export default GreyTable;
