export default {
  logoHeader: require("./media/logos/logo.png"),
  logoSellcoda: require("./media/logos/logoSellcoda-1.png"),
  backIcon: require("./media/icons/backIcon.png"),
  downGrayIcon: require("./media/icons/iconDownGray.png"),
  upWhiteIcon: require("./media/icons/iconUpWhite.png"),
  iconDoubleRight: require("./media/icons/iconDoubleRight.png"),
  iconDoubleLeft: require("./media/icons/iconDoubleLeft.png"),
  iconDoubleRightDisable: require("./media/icons/iconDoubleRightDisable.png"),
  iconDoubleLeftDisable: require("./media/icons/iconDoubleLeftDisable.png"),
  listIcon: require("./media/icons/listIcon.png"),
  syncIcon: require("./media/icons/iconsync.png"),
  writeIcon: require("./media/icons/writeIcon.png"),
  trashIcon: require("./media/icons/trashIcon.png"),
  backLeft: require("./media/icons/backLeft.png"),
  iconClose: require("./media/icons/iconClose.png"),
  iconWaiting: require("./media/icons/hourglass.png"),
  iconCliboard: require("./media/icons/clipboard.png"),
  iconTruck: require("./media/icons/truck.png"),
  iconCheckedTruck: require("./media/icons/checked-truck.png"),
  iconAddImage: require("./media/icons/iconAddImage.png"),
  resultSuccess: require("./media/icons/resultSuccess.png"),
  resultFailed: require("./media/icons/resultFailed.png"),
  infoIcon: require("./media/icons/infoIcon.png"),
  pdfIcon: require("./media/icons/pdfIcon.svg"),
  downloadFileIcon: require("./media/icons/downloadFile.png"),
  ribbonBadgeBlue: require("./media/icons/Ribbon_badge_blue.png"),
  ribbonBadgeRed: require("./media/icons/Ribbon_badge_red.png"),
  viewFileIcon: require("./media/icons/view-doc.png"),
  sellcodaBo: require("./media/icons/sellcoda-bo.png"),
  sellcodaShop: require("./media/icons/sellcoda-shop.png"),
  sellcodaSale: require("./media/icons/sellcoda-sale.png"),
  iconEdit: require("./media/icons/icon-edit.png"),
  iconFrontTruck: require("./media/icons/iconFrontTruck.png"),
  iconRearTruck: require("./media/icons/iconRearTruck.png"),
  iconCheck: require("./media/icons/iconCheck.png"),
  iconCircle: require("./media/icons/iconCircle.png"),
  checkCircle: require("./media/icons/checkCircle.png"),
  googleLogo: require("./media/icons/googleLogo.png"),
  iconMicrosoft: require("./media/icons/iconMicrosoft.png"),
  popup: require("./media/icons/Popup.png"),
  ArrowUpRight: require("./media/icons/ArrowUpRight.png"),
  iconLineOa: require("./media/icons/icon_line_oa.png"),
  iconTarget: require("./media/icons/target-icon.png"),
  iconMegaphone: require("./media/icons/megaphone-icon.png"),
  addPointProgram: require("./media/icons/AddPointProgram.png"),
  star: require("./media/icons/Star.png"),
  info: require("./media/icons/Info.png"),
  iconDown: require("./media/icons/down-chevron.png"),
  iconUp: require("./media/icons/up-chevron.png"),
  viewDoc: require("./media/icons/view-document.png"),
  rating: require("./media/icons/rating.png"),
  emptyHistoryPoint: require("./media/icons/historyPointEmpty.png"),
  emptyProduct: require("./media/icons/emptyProduct.png"),
  downloadCP: require("./media/icons/download_report_camp.png"),
  getPoint: require("./media/icons/get_point.png"),
  pointPending: require("./media/icons/point_pending.png"),
  pointCancel: require("./media/icons/point_cancel.png"),
  redeemReward: require("./media/icons/redeem_reward.png"),
  expandUp: require("./media/icons/expand_up.png"),
  expandDown: require("./media/icons/expand_down.png"),
  activeTime: require("./media/icons/activeTime.png"),
  pendingTime: require("./media/icons/pendingTime.png"),
  viewFile: require("./media/icons/viewfile.png"),
  appPreviewIcon: require("./media/icons/app-preview-icon.png"),
  downloadFile: require("./media/icons/download-file.png"),
  crmPointActive: require("./media/icons/CRM/point-active.png"),
  crmPointInactive: require("./media/icons/CRM/point-inactive.png"),
  crmProductActive: require("./media/icons/CRM/product-active.png"),
  crmProductInactive: require("./media/icons/CRM/product-inactive.png"),
  crmStoreActive: require("./media/icons/CRM/store-active.png"),
  crmStoreInactive: require("./media/icons/CRM/store-inactive.png"),
  crmRedeemActive: require("./media/icons/CRM/redeem-active.png"),
  crmRedeemInactive: require("./media/icons/CRM/redeem-inactive.png"),
  crmPointTransaction: require("./media/icons/CRM/point-transaction.png"),
  crmCalendar: require("./media/icons/CRM/calendar.png"),
  crmGoal: require("./media/icons/CRM/Goal.png"),
  crmStore: require("./media/icons/CRM/store.png"),
};
