import color from "../resource/color";

export const USER_SHOP_MAPPING: any = {
  WAIT_APPROVE: "รออนุมัติ",
  APPROVED: "อนุมัติแล้ว",
  REJECTED: "ไม่อนุมัติ",
  INACTIVE: "ปิดการใช้งาน",
};
export const USER_SHOP_MAPPING_COLOR: any = {
  WAIT_APPROVE: "warning",
  APPROVED: "success",
  REJECTED: "error",
  INACTIVE: "error",
};
