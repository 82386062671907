export interface CampaignProgramEntities {
  campaignProgramId: string;
  campaignProgramName: string;
}
export const CampaignProgramEntities_INIT: CampaignProgramEntities = {
  campaignProgramId: "",
  campaignProgramName: "",
};
export interface CampaignShopEntities {
  campaignId: string;
  campaignCode: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  daysPassed: number;
  campaignProgram: CampaignProgramEntities[];
  shopId: string;
  shopCode: string;
  shopName: string;
  shopType: string;
  sumQuantity: number;
  sumPoint: number;
}
export const CampaignShopEntities_INIT: CampaignShopEntities = {
  campaignId: "",
  campaignCode: "",
  campaignName: "",
  startDate: "",
  endDate: "",
  daysPassed: 0,
  campaignProgram: [CampaignProgramEntities_INIT],
  shopId: "",
  shopCode: "",
  shopName: "",
  shopType: "",
  sumQuantity: 0,
  sumPoint: 0,
};

//data in table (detail shop crm)
export interface CampaignProductEntities {
  point: number;
  amount: number;
  saleUom: string;
  quantity: number;
  unitName: string;
  productId: number;
  productCode: string;
  productName: string;
  programPoint: number;
  saleUomProgram: string;
  saleUomProgramTh: string;
  productCommonName: string;
  productImage: string;
}
export interface OrderEntities {
  orderPointId: string;
  orderPointCode: string;
  soNo: string;
  pdoNo: string;
  dateDelivery: string;
  createAt: string;
  countProduct: number;
  sumAmount: number;
  unitName: string;
  sumQuantity: number;
  saleUom: string;
  status: string;
  sumPoint: number;
  campaignProduct: CampaignProductEntities[];
}
export interface ProgramEntities {
  campaignId: string;
  campaignProgramId: string;
  campaignProgramName: string;
  campaignProgramType: string;
  campaignProgramCondition: string;
  countOrder: number;
  sumQuantity: number;
  sumPoint: number;
}
export interface ProductEntities {
  productId: number;
  productName: string;
  commonName: string;
  productCode: string;
  saleUom: string;
  saleUomTh: string;
  sumQuantity: number;
  sumPoint: number;
  productImage: string;
}
