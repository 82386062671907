import { Divider, Dropdown, Image, Input, Select, Table } from "antd";
import { CardContainer } from "../../components/Card/CardContainer";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import Text from "../../components/Text/Text";
import { color, image } from "../../resource";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  ContainerOutlined,
  DownloadOutlined,
  DownOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { numberFormatter } from "../../utility/Formatter";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { useQuery } from "react-query";
import { zoneDatasource } from "../../datasource/ZoneDatasource";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  getExcelProReportByZone,
  getReportPromotionByZone,
  getReportWithCustomer,
} from "../../datasource/PromotionDatasource";
import { PROMOTION_TYPE_NAME } from "../../definitions/promotion";
import moment from "moment";
import Button from "../../components/Button/Button";
import { RangePicker } from "../../components/DatePicker/DatePicker";
import dayjs from "dayjs";

const SaleList: React.FC<any> = () => {
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;

  const items: any = [
    {
      key: "1",
      label: (
        <p
          style={{
            padding: 0,
            margin: 0,
            fontFamily: "Sarabun",
            fontSize: 16,
          }}
        >
          ไฟล์ .csv
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p
          style={{
            padding: 0,
            margin: 0,
            fontFamily: "Sarabun",
            fontSize: 16,
          }}
        >
          ไฟล์ .xls
        </p>
      ),
    },
  ];

  const zone = useQuery(["zone"], async () => {
    const company = JSON.parse(localStorage.getItem("company")!);
    const res = await zoneDatasource.getAllZoneByCompany(company.companyCode);
    setZoneData(
      res.map((data) => {
        return {
          label: data.zoneName,
          value: data.zoneName,
          key: data.zoneName,
        };
      }),
    );
    return res;
  });
  const [count, setCount] = useState<number>(0);
  const [zoneData, setZoneData] = useState<any>([]);
  const [filter, setFilter] = useState({
    zone: pathSplit[3],
    search: "",
    sortDirections: "ASC",
    sortFields: "customer_name",
    page: 1,
    startDate: pathSplit[4],
    endDate: pathSplit[5],
  });
  const navigate = useNavigate();
  const { isLoading, isError, data } = useQuery(
    [
      "data",
      filter.zone,
      filter.sortDirections,
      filter.sortFields,
      filter.search,
      filter.page,
      filter.startDate,
      filter.endDate,
    ],
    async () => {
      const getPromotion = await getReportPromotionByZone(
        filter.zone,
        filter.search,
        filter.sortFields,
        filter.sortDirections,
        filter.page,
        filter.startDate,
        filter.endDate,
      );
      setCount(getPromotion.data.length);
      const result = await Promise.all(
        await getPromotion.data.map(async (el) => {
          const getCustomer = await getReportWithCustomer(
            el.customer_company_id,
            filter.startDate,
            filter.endDate,
          );
          return {
            key: el.id,
            shopID: el.customer_no,
            shopName: el.customer_name,
            promotion: getCustomer.length,
            price: el.total_price,
            discount: el.total_discount,
            freebieDiscount: el.total_freebies_value,
            subPromotion: getCustomer.map((item) => {
              return {
                promotionName: item.promotion_name,
                promotionCode: item.promotion_code,
                promotionType: PROMOTION_TYPE_NAME[item.promotion_type],
                price: item.total_price,
                discount: item.total_discount,
                freebieDiscount: item.total_freebies_value,
                datePromotion: `${moment(item.start_date).format("L")} - ${moment(
                  item.end_date,
                ).format("L")}`,
                order: item.total_order,
                image: image,
              };
            }),
          };
        }),
      );
      return result;
    },
  );

  const columnSaleList = [
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={16}>
            รหัสร้านค้า
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "customer_no",
                  sortDirections: "ASC",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "customer_no",
                  sortDirections: "DESC",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "customerCode",
      key: "customerCode",
      width: "12%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.shopID}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={16}>
            ชื่อร้านค้า
          </Text>
        </div>
      ),
      width: "35%",
      dataIndex: "customerName",
      key: "customerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text fontFamily='Sarabun' fontSize={16}>
                {row.shopName}
              </Text>
              <Text
                fontFamily='Sarabun'
                style={{
                  color: color.Grey,
                }}
                fontSize={14}
              >
                {row.promotion + " โปรโมชัน"}
              </Text>
            </div>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={16}>
            ยอดขายที่ใช้โปร (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "total_price",
                  sortDirections: "ASC",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "total_price",
                  sortDirections: "DESC",
                })
              }
            />
          </div>
        </div>
      ),
      //width: "15%",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.price > 0 ? numberFormatter(row.price, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={16}>
            ยอดส่วนลดที่ใช้ (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "total_discount",
                  sortDirections: "ASC",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "total_discount",
                  sortDirections: "DESC",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "discount",
      key: "discount",
      //width: "35%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.discount > 0 ? numberFormatter(row.discount, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Text fontWeight={600} fontSize={16}>
            ยอดของแถมที่ใช้ (บาท)
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <CaretUpOutlined
              style={{
                position: "relative",
                top: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "total_freebies_value",
                  sortDirections: "ASC",
                })
              }
            />
            <CaretDownOutlined
              style={{
                position: "relative",
                bottom: 2,
              }}
              onClick={() =>
                setFilter({
                  ...filter,
                  sortFields: "total_freebies_value",
                  sortDirections: "DESC",
                })
              }
            />
          </div>
        </div>
      ),
      dataIndex: "freebieDiscount",
      key: "freebieDiscount",
      // width: "20%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.freebieDiscount > 0 ? numberFormatter(row.freebieDiscount, 2) : "-"}
            </Text>
          ),
        };
      },
    },
  ];

  const downloadExcelZone = async (
    zone: string,
    search: string,
    startDate: string,
    endDate: string,
  ) => {
    getExcelProReportByZone(zone, search, 1, startDate, endDate)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Promotion Report_Area-${zone}${moment(startDate).format(
          "YYYY-MM-DD",
        )} - ${moment(endDate).format("YYYY-MM-DD")}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <CardContainer>
        <PageTitleNested
          onBack={() => navigate(-1)}
          title={"เขตการขาย"}
          showBack
          extra={
            <div className='d-flex'>
              <Select
                value={filter.zone}
                onChange={(val) =>
                  setFilter({
                    ...filter,
                    zone: val,
                  })
                }
                size='large'
                options={zoneData}
                placeholder='ค้นหาเขต'
                style={{
                  width: "142px",
                  marginRight: "10px",
                  fontWeight: "lighter",
                }}
              />
              <Input
                value={filter.search}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    search: e.target.value,
                  })
                }
                style={{
                  width: "250px",
                }}
                placeholder='ค้นหารหัส / ชื่อร้านค้า'
                suffix={<SearchOutlined />}
              />
              <RangePicker
                style={{
                  marginLeft: "10px",
                  width: "250px",
                }}
                enablePast
                allowEmpty={[true, true]}
                value={
                  !filter.startDate ? undefined : [dayjs(filter.startDate), dayjs(filter.endDate)]
                }
                onChange={(date) => {
                  if (date) {
                    setFilter({
                      ...filter,
                      startDate: moment(date[0]?.$d).format(),
                      endDate: moment(date[1]?.$d).format(),
                    });
                  } else {
                    setFilter({
                      ...filter,
                      startDate: "",
                      endDate: "",
                    });
                  }
                }}
              />
              <Button
                style={{
                  width: "140px",
                  marginLeft: "20px",
                }}
                title='ดาวน์โหลด'
                icon={<DownloadOutlined style={{ color: "white", fontSize: "18px" }} />}
                onClick={() =>
                  downloadExcelZone(filter.zone, filter.search, filter.startDate, filter.endDate)
                }
              />
            </div>
          }
          customBreadCrumb={
            <BreadCrumb
              data={[
                {
                  text: "รายงาน",
                  path: "/PromotionPage/promotion",
                  isState: true,
                },
                {
                  text: "โปรโมชัน",
                  path: window.location.pathname,
                  isState: true,
                  callState: () => navigate(-1),
                },
                {
                  text: "เขตการขาย",
                  path: window.location.pathname,
                  isState: true,
                },
              ]}
            />
          }
        />
        <Divider />
        <Text
          fontSize={14}
          style={{
            color: color.Grey,
          }}
        >
          ร้านค้าทั้งหมดในเขต : {count} ร้าน
        </Text>
        <Table
          pagination={{
            pageSize: 10,
            current: 1,
            total: count,
            showSizeChanger: false,
            position: ["bottomCenter"],
            onChange: (page) => {
              setFilter({
                ...filter,
                page,
              });
            },
          }}
          expandable={{
            rowExpandable: (record) => record.promotion !== 0,
            expandIcon: (props: any) => {
              if (props.record.promotion === 0) {
                return null;
              } else {
                if (props.expanded) {
                  return <UpOutlined size={20} onClick={(e) => props.onExpand(props.record, e)} />;
                } else {
                  return (
                    <DownOutlined size={20} onClick={(e) => props.onExpand(props.record, e)} />
                  );
                }
              }
            },
            expandedRowRender: (record) => {
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                    }}
                  >
                    <Text
                      fontSize={14}
                      style={{
                        color: color.Grey,
                      }}
                    >
                      ชื่อรายการ
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      {record.subPromotion.map((item, index) => (
                        <div
                          key={index.toString()}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "50px",
                          }}
                        >
                          <Image
                            style={{
                              width: "43px",
                              height: "32px",
                            }}
                            src={!item.image ? item.image : image.emptyPromotion}
                          />
                          <div
                            style={{
                              marginLeft: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              fontSize={14}
                              style={{
                                color: color.Text1,
                                fontFamily: "Sarabun",
                              }}
                            >
                              {item.promotionName}
                            </Text>
                            <Text
                              fontSize={14}
                              style={{
                                color: color.Grey,
                                fontFamily: "Sarabun",
                              }}
                            >
                              {item.promotionCode}
                            </Text>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "17%",
                    }}
                  >
                    <Text
                      fontSize={14}
                      style={{
                        color: color.Grey,
                        marginBottom: "20px",
                      }}
                    >
                      ประเภทโปรโมชัน
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      {record.subPromotion.map((item, index) => (
                        <div
                          key={index.toString()}
                          style={{
                            height: "50px",
                          }}
                        >
                          <Text
                            fontSize={14}
                            style={{
                              color: color.Text1,
                              fontFamily: "Sarabun",
                            }}
                          >
                            {item.promotionType}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "15%",
                    }}
                  >
                    <Text
                      fontSize={14}
                      style={{
                        color: color.Grey,
                        marginBottom: "20px",
                      }}
                    >
                      จำนวนคำสั่งซื้อ
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      {record.subPromotion.map((item, index) => (
                        <div
                          key={index.toString()}
                          style={{
                            height: "50px",
                          }}
                        >
                          <Text
                            fontSize={14}
                            style={{
                              color: color.Text1,
                              marginBottom: "20px",
                              fontFamily: "Sarabun",
                            }}
                          >
                            {numberFormatter(item.order, 0)} รายการ
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "17%",
                    }}
                  >
                    <Text
                      fontSize={14}
                      style={{
                        color: color.Grey,
                        marginBottom: "20px",
                      }}
                    >
                      ยอดขายที่ใช้โปร
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      {record.subPromotion.map((item, index) => (
                        <div
                          key={index.toString()}
                          style={{
                            height: "50px",
                          }}
                        >
                          <Text
                            fontSize={14}
                            style={{
                              color: color.Text1,
                              marginBottom: "20px",
                              fontFamily: "Sarabun",
                            }}
                          >
                            {item.price > 0 ? numberFormatter(item.price, 2) : "-"}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "15%",
                    }}
                  >
                    <Text
                      fontSize={14}
                      style={{
                        color: color.Grey,
                        marginBottom: "20px",
                      }}
                    >
                      ยอดส่วนลดที่ใช้
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      {record.subPromotion.map((item, index) => (
                        <div
                          key={index.toString()}
                          style={{
                            height: "50px",
                          }}
                        >
                          <Text
                            fontSize={14}
                            style={{
                              color: color.Text1,
                              marginBottom: "20px",
                              fontFamily: "Sarabun",
                            }}
                          >
                            {item.discount > 0 ? numberFormatter(item.discount, 2) : "-"}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "15%",
                    }}
                  >
                    <Text
                      fontSize={14}
                      style={{
                        color: color.Grey,
                        marginBottom: "20px",
                      }}
                    >
                      ยอดของแถมที่ใช้
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      {record.subPromotion.map((item, index) => (
                        <div
                          key={index.toString()}
                          style={{
                            height: "50px",
                          }}
                        >
                          <Text
                            fontSize={14}
                            style={{
                              color: color.Text1,
                              marginBottom: "20px",
                              fontFamily: "Sarabun",
                            }}
                          >
                            {item.freebieDiscount > 0
                              ? numberFormatter(item.freebieDiscount, 2)
                              : "-"}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    <Text
                      fontSize={14}
                      style={{
                        color: color.Grey,
                        marginBottom: "20px",
                      }}
                    >
                      ช่วงเวลาโปรโมชัน
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      {record.subPromotion.map((item, index) => (
                        <div
                          key={index.toString()}
                          style={{
                            height: "50px",
                          }}
                        >
                          <Text
                            fontSize={14}
                            style={{
                              color: color.Text1,
                              marginBottom: "20px",
                              fontFamily: "Sarabun",
                            }}
                          >
                            {item.datePromotion}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            },
          }}
          expandIconColumnIndex={5}
          dataSource={
            isLoading
              ? []
              : isError
                ? []
                : data?.map((item, index) => {
                    return {
                      ...item,
                      key: index.toString(),
                    };
                  })
          }
          columns={columnSaleList}
          size='large'
        />
      </CardContainer>
    </>
  );
};

export default SaleList;
