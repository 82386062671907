import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";
import { updateFactoryEntities } from "../entities/MasterDataEntity";

const getFactory = async (params: object) => {
  return await httpClient
    .get(`${BASE_URL}/auth/factory`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
//master data
const getMasterFactory = async (params: object) => {
  return await httpClient
    .get(`${BASE_URL}/auth/factory/all`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const getMasterFactoryById = async (id: string) => {
  return await httpClient
    .get(`${BASE_URL}/auth/factory/${id}`)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const postMasterFactory = async (data: object) => {
  return await httpClient
    .post(`${BASE_URL}/auth/factory`, data)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const patchMasterFactory = async (data: updateFactoryEntities) => {
  return await httpClient
    .patch(`${BASE_URL}/auth/factory/` + data.id, data)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export {
  getFactory,
  getMasterFactory,
  getMasterFactoryById,
  postMasterFactory,
  patchMasterFactory,
};
