import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import { Form } from "antd";
import Input from "../../components/Input/Input";
import { defaultPropsForm } from "../../utility/DefaultProps";
import icon from "../../resource/icon";
import jwtDecode from "jwt-decode";
import { AuthDatasource } from "../../datasource/AuthDatasource";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import image from "../../resource/image";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f3f6f9;
`;
const CardStyled = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  background-color: ${color.white};
  border-radius: 12px;
  min-width: 360px;
`;
const Footer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 32px;
  justify-content: center;
`;
const ErrorCard = styled.div`
  min-height: 36px;
  width: 100%;
  margin-top: 18px;
  padding: 10px 12px;
  border-radius: 4px;
  background: ${color.error}1A;
`;

const staticConditions = [
  {
    id: 1,
    condition: "ต้องมีตัวอักษรภาษาอังกฤษ อักษรพิเศษ และตัวเลข รวมกันอย่างน้อย 8 ตัว",
    isPassed: false,
  },
  {
    id: 2,
    condition: "ต้องมีตัวอักษรภาษาอังกฤษในรหัสผ่านอย่างน้อย 1 ตัว",
    isPassed: false,
  },
  {
    id: 4,
    condition: "ต้องมีอักษรพิเศษอย่างน้อย 1 ตัว (เช่น * # @ % - +)",
    isPassed: false,
  },
  {
    id: 3,
    condition: "ต้องมีตัวเลขอย่างน้อย 1 ตัว",
    isPassed: false,
  },
];
function ResetPasswordSettingPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [showPass, setShowPass] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [conditionList, setConditionList] = React.useState<
    {
      id: number;
      condition: string;
      isPassed: boolean;
    }[]
  >(staticConditions);

  useEffect(() => {
    const checkIsReset = async () => {
      const decodedToken = jwtDecode(token!);
      console.log(decodedToken);
      AuthDatasource.checkIsReset(decodedToken!["userStaffId"]).then((res) => {
        if (res.isReset) {
          navigate("/");
        } else {
          setIsLoading(false);
        }
      });
    };
    checkIsReset();
  }, []);

  const [isExpired, setExpired] = useState(false);

  const handleInputChange = (event) => {
    const charCode = event.which || event.keyCode;
    if ((charCode >= 0x0e01 && charCode <= 0x0e5b) || charCode === 0x0020) {
      event.preventDefault();
    }
  };
  const onFieldsChange = () => {
    const password = form.getFieldValue("password");

    const conditionList = staticConditions.map((el) => {
      if (!password) {
        return el;
      }

      if (el.id === 1) {
        el.isPassed = password.length > 7;
      }
      if (el.id === 2) {
        el.isPassed = new RegExp("^(?=.*[a-zA-Z])").test(password);
      }
      if (el.id === 3) {
        el.isPassed = new RegExp("^(?=.*[0-9])").test(password);
      }
      if (el.id === 4) {
        el.isPassed = new RegExp("^(?=.*[-_+=!@#$%^&*])").test(password);
      }
      return el;
    });

    setConditionList(conditionList);
  };
  const onFinish = async (values: { password: string; confirmPassword: string }) => {
    if (token) {
      const decodedToken: {
        email: string;
      } = jwtDecode(token);
      if (!decodedToken) {
        return;
      }
      const email = decodedToken?.email;
      form.setFieldsValue({ email });

      await AuthDatasource.updatePassword({
        email,
        token,
        password: values.password,
      })
        .then((result) => {
          console.log(result);
          if (result.success) {
            navigate("/reset-password/success", {
              state: { email },
            });
          } else {
            setExpired(true);
          }
        })
        .catch(() => {
          setExpired(true);
        });
    } else {
      console.log("EXPIRED (no-token)");
      setExpired(true);
    }
  };
  const onClickBackToLogin = () => {
    navigate("/");
  };

  if (loading) {
    return <></>;
  } else {
    return (
      <Container>
        <CardStyled>
          <ReactSVG
            onError={(error) => console.log(error)}
            src={`${process.env.PUBLIC_URL}/logo_sellcoda.svg`}
          />
          <Text
            fontSize={24}
            style={{
              marginTop: 16,
            }}
            fontWeight={700}
          >
            รีเซ็ตรหัสผ่าน
          </Text>
          <Text
            fontSize={14}
            style={{
              marginBottom: 16,
            }}
          >
            รีเซ็ตรหัสผ่านด้วยอีเมลที่ใช้งาน
          </Text>
          <Form
            {...defaultPropsForm}
            style={{
              width: "100%",
            }}
            onFieldsChange={onFieldsChange}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label='รหัสผ่าน'
              name='password'
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกรหัสผ่าน",
                },
                {
                  min: 8,
                  message: "ต้องมีตัวอักษรภาษาอังกฤษ อักษรพิเศษ และตัวเลข รวมกันอย่างน้อย 8 ตัว",
                },
                {
                  pattern: new RegExp("^(?=.*[a-zA-Z])"),
                  message: "ต้องมีตัวอักษรภาษาอังกฤษในรหัสผ่านอย่างน้อย 1 ตัว",
                },
                {
                  pattern: new RegExp("^(?=.*[0-9])"),
                  message: "ต้องมีตัวเลขอย่างน้อย 1 ตัว",
                },
                {
                  pattern: new RegExp("^(?=.*[-_+=!@#$%^&*])"),
                  message: "ต้องมีอักษรพิเศษอย่างน้อย 1 ตัว (เช่น * # @ % - +)",
                },
              ]}
            >
              <Input
                onKeyPress={handleInputChange}
                type={showPass ? "text" : "password"}
                placeholder='ระบุรหัสผ่าน'
                suffix={
                  showPass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowPass(false);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowPass(true);
                      }}
                    />
                  )
                }
              />
            </Form.Item>
            <div
              style={{
                height: 16,
              }}
            />
            <Form.Item
              label='ยืนยันรหัสผ่าน'
              name='confirmPassword'
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกยืนยันรหัสผ่าน",
                },
                {
                  validator: async (_, value) => {
                    if (value !== form.getFieldValue("password")) {
                      return Promise.reject("รหัสผ่านไม่ตรงกัน");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                onKeyPress={handleInputChange}
                type={showPass ? "text" : "password"}
                placeholder='ระบุยืนยันรหัสผ่าน'
                suffix={
                  showPass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowPass(false);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowPass(true);
                      }}
                    />
                  )
                }
              />
            </Form.Item>

            {conditionList.map((el) => {
              const isPassed = el.isPassed;
              return (
                <div
                  key={el.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={isPassed ? icon.iconCheck : icon.iconCircle}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 4,
                    }}
                  />
                  <Text fontSize={12} color={isPassed ? "success" : "Text1"} fontFamily='Sarabun'>
                    {el.condition}
                  </Text>
                </div>
              );
            })}
            {isExpired && (
              <ErrorCard>
                <Text color='error' level={6}>
                  สิทธิ์ของคุณได้ถูกใช้ไปแล้ว หรือหมดอายุ
                </Text>
              </ErrorCard>
            )}
            <Button
              type='primary'
              htmlType='submit'
              title='ยืนยันรีเซ็ตรหัสผ่าน'
              style={{
                width: "100%",
                marginTop: 16,
                marginBottom: 16,
              }}
            />
            <Footer>
              <Text fontSize={12} fontFamily='Sarabun'>
                กลับไปหน้า
              </Text>
              <Text color='primary' fontSize={12} onClick={onClickBackToLogin} fontFamily='Sarabun'>
                ล็อกอินเข้าสู่ระบบ
              </Text>
            </Footer>
          </Form>
        </CardStyled>
      </Container>
    );
  }
}

export default ResetPasswordSettingPage;
