import { Col, Row } from "antd";
import React, { useState } from "react";
import Text from "../../../../components/Text/Text";
import styled from "styled-components";
import color from "../../../../resource/color";
import { InfoCircleFilled } from "@ant-design/icons";
import { CampaignShopEntities } from "../../../../entities/CampaignShopEntity";
import moment from "moment";
import { numberFormatter } from "../../../../utility/Formatter";
import ModalPartnerTypeCRM from "../../../../components/Modal/ModalPartnerTypeCRM";

const HeaderText = styled.div`
  font-family: "IBM Plex Sans Thai";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 700;
`;
const LabelText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text3};
  font-weight: 400;
`;

interface Props {
  type: string;
  data: CampaignShopEntities;
  loading: boolean;
}
function ParticipateCampaign({ type, data, loading }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [shopId, setShopId] = useState<string>("");

  const InfoRow = ({ label1, value1, label2, value2, marginBottom }: any) => (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: marginBottom || 8 }}>
      <Col className='gutter-row' span={4}>
        <LabelText>{label1}</LabelText>
      </Col>
      <Col className='gutter-row' span={8}>
        <LabelText style={{ color: color.Text1 }}>{value1}</LabelText>
      </Col>
      <Col className='gutter-row' span={4}>
        <LabelText>{label2}</LabelText>
      </Col>
      <Col className='gutter-row' span={8}>
        <LabelText style={{ color: color.Text1 }}>{value2}</LabelText>
      </Col>
    </Row>
  );
  const handleModal = (id: string) => {
    setShopId(id);
    setShowModal(true);
  };
  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: 20 }}>
        <Col className='gutter-row' span={12}>
          <HeaderText>ข้อมูลแคมเปญที่เข้าร่วม</HeaderText>
        </Col>
        <Col className='gutter-row' span={12}>
          <HeaderText>ข้อมูลร้านค้า</HeaderText>
        </Col>
      </Row>

      <InfoRow
        label1='รหัสแคมเปญ'
        value1={data.campaignCode}
        label2='รหัสร้านค้า'
        value2={data.shopCode}
      />
      <InfoRow
        label1='ชื่อแคมเปญ'
        value1={data.campaignName}
        label2='ชื่อร้านค้า'
        value2={data.shopName}
      />
      <InfoRow
        label1='ระยะเวลาแคมเปญ'
        value1={`${moment(data.startDate).format("DD/MM/YYYY")} - ${moment(data.endDate).format(
          "DD/MM/YYYY",
        )} ${
          data.daysPassed > 0 && data.daysPassed !== null
            ? `(ผ่านมาแล้ว ${data.daysPassed} วัน)`
            : ""
        }`}
        label2='ประเภทคู่ค้า'
        value2={
          <Row>
            <>{type === "DEALER" ? "Dealer" : "Sub-Dealer"}</>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
                cursor: "pointer",
              }}
            >
              <InfoCircleFilled
                style={{ fontSize: "16px", color: "#464E5F" }}
                onClick={() => handleModal(data.shopId)}
              />
            </div>
          </Row>
        }
      />
      <InfoRow
        label1='พอยท์โปรแกรมที่เข้าร่วม'
        value1={
          <>
            {data.campaignProgram.map((program, index) => (
              <span key={program.campaignProgramId}>
                {index + 1}. {program.campaignProgramName}
                <br />
              </span>
            ))}
          </>
        }
        label2='แต้มสะสม'
        value2={`${numberFormatter(data.sumPoint || 0, 2)} แต้ม`}
      />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className='gutter-row' span={12} />
        <Col className='gutter-row' span={4}>
          <LabelText>ตันสะสม</LabelText>
        </Col>
        <Col className='gutter-row' span={8}>
          <LabelText style={{ color: color.Text1 }}>{`${numberFormatter(
            data.sumQuantity,
            2,
          )} ตัน`}</LabelText>
        </Col>
      </Row>

      <ModalPartnerTypeCRM open={showModal} onCancel={() => setShowModal(false)} shopId={shopId} typePartner={type} />
    </div>
  );
}

export default ParticipateCampaign;
