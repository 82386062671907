export interface historyOrderPoint {
  statusAfter: string;
  statusBefore: string;
  updateAt: string;
}
export const historyOrderPoint_INIT: historyOrderPoint = {
  statusAfter: "",
  statusBefore: "",
  updateAt: "",
};
export interface PointApproveEntity {
  orderPointId: string;
  orderPointCode: string;
  subDealerName: string;
  subDealerNo: string;
  subDealerZone: string;
  dealerName: string;
  dealerNo: string;
  dealerZone: string;
  historyOrderPoint: historyOrderPoint;
  dateDelivery: string;
  status: string;
  sumPoint: string;
  updateAt: string;
  count: string;
}
export interface campaignsEntity {
  campaignId: string;
  campaignName: string;
}
export const campaignEntity_INIT: campaignsEntity = {
  campaignId: "",
  campaignName: "",
};
export interface OrderProductEntity {
  amount: number;
  image: string;
  point: number;
  productCommonName: string;
  productId: number;
  productName: string;
  programPoints: number;
  quantity: number;
  saleUom: string;
  saleUomTh: string;
  unitName: string;
}
export const OrderProductEntity_INIT: OrderProductEntity = {
  amount: 0,
  image: "",
  point: 0,
  productCommonName: "",
  productId: 0,
  productName: "",
  programPoints: 0,
  quantity: 0,
  saleUom: "",
  saleUomTh: "",
  unitName: "",
};

export interface PointDetailEntity {
  dateDelivery: string;
  dealerName: string;
  dealerNo: string;
  dealerType: string;
  dealerZone: string;
  deliveryNo: string;
  orderPointCode: string;
  deliverySheet: string;
  reason: string;
  historyOrderPoint: historyOrderPoint[];
  orderProduct: OrderProductEntity[];
  orderProductWithOutCampaign: OrderProductEntity[];
  campaigns: campaignsEntity[];
  orderProductCount: number;
  status: string;
  subDealerName: string;
  subDealerNo: string;
  subDealerZone: string;
  sumAmount: string;
  sumPoint: string;
  sumQuantity: string;
}
export const PointDetailEntity_INIT: PointDetailEntity = {
  dateDelivery: "",
  dealerName: "",
  dealerNo: "",
  dealerType: "",
  dealerZone: "",
  deliveryNo: "",
  orderPointCode: "",
  deliverySheet: "",
  reason: "",
  historyOrderPoint: [historyOrderPoint_INIT],
  orderProduct: [OrderProductEntity_INIT],
  orderProductWithOutCampaign: [OrderProductEntity_INIT],
  campaigns: [campaignEntity_INIT],
  orderProductCount: 0,
  status: "",
  subDealerName: "",
  subDealerNo: "",
  subDealerZone: "",
  sumAmount: "",
  sumPoint: "",
  sumQuantity: "",
};
export interface PointHistoryEntity {
  activity: string;
  statusAfter: string;
  point: string;
  updateBy: string;
  updateAt: string;
  userType: string;
}
