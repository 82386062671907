import {
  DownOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleFilled,
  SearchOutlined,
  StarOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import React, { ReactNode, useEffect, useState } from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Tabs from "../../../components/AntdTabs/AntdTabs";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import Text from "../../../components/Text/Text";
import {
  Avatar,
  Button as AntdButton,
  Col,
  Collapse,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Table,
  Tag,
  Space,
  Tooltip,
  Modal,
} from "antd";
import image from "../../../resource/image";
import styled from "styled-components";
import { Campaign, CampaignProgram } from "../../../entities/CampaignEntity";
import color from "../../../resource/color";
import { FileItem } from "../../OrderManagement/orderComponent/fileItem";
import {
  getCampaignById,
  trackingCampaign,
  trackingCampaignDetail,
} from "../../../datasource/CampaignDatasource";
import {
  campaignProgramConditionLabel,
  campaignProgramTypeLabel,
  dealerTypeName,
} from "../../../definitions/campaign";
import Button from "../../../components/Button/Button";
import { StoreEntity } from "../../../entities/StoreEntity";
import Select from "../../../components/Select/Select";
import { useEffectOnce } from "react-use";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import TableContainer from "../../../components/Table/TableContainer";
import { ProductEntity } from "../../../entities/PoductEntity";
import icon from "../../../resource/icon";
import { CollapseContainer, ProgramTabsContainer, TabBody, TabHeader } from "./ProgramComponents";
import { tabId } from "../../../store/createCampaign";
import { convertBuddhistYear, numberFormatter } from "../../../utility/Formatter";
import { countUnique } from "../../../utility/ArrayHelper";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import GreyModal from "../../../components/Modal/GreyModal";
import dayjs from "dayjs";
import { TablePaginationConfig } from "antd/es/table";
import { getZones } from "../../../datasource/CustomerDatasource";
import { mapSortingKey } from "../../../definitions/tableFilter";
import { onInputNumberKeyPress } from "../../../utility/validator";
import { getPartnerDealer, getPartnerSubdealer } from "../../../datasource/PartnerDatasource";

const Container = styled.div`
  margin: 32px 0px 10px 0px;
`;

const Desc = ({ label, value, icon }: { label: string; value: ReactNode; icon?: ReactNode }) => {
  return (
    <Row align='middle' style={{ padding: "8px 0px" }}>
      <Col xl={6} sm={8}>
        <Text level={5} color='Text3'>
          {icon && <>{icon}&nbsp;</>}
          {label}
        </Text>
      </Col>
      <Col xl={18} sm={16}>
        <Text level={5} color='Text1'>
          {value || "-"}
        </Text>
      </Col>
    </Row>
  );
};

const TrackingItem = ({
  title,
  number,
  unit,
}: {
  title: ReactNode;
  number: number | string;
  unit: string;
}) => {
  return (
    <div
      style={{ padding: 16, background: "#FAFAFA", border: "1px solid #E5EAEE", borderRadius: 4 }}
    >
      <Text fontWeight={700}>{title}</Text>
      <br />
      <Text level={2} fontWeight={700}>
        {number}
      </Text>
      &nbsp;&nbsp;<Text fontWeight={700}>{unit}</Text>
    </div>
  );
};

export const CampaignDetail: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState<Campaign>();

  const [zone, setZone] = useState<{ label: string; value: string; key: string }[]>([]);

  useEffectOnce(() => {
    if (zone.length <= 0) {
      getZoneByCompany();
    }
  });

  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res?.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZone(data);
  };

  useEffectOnce(() => {
    if (!loading) fetchCampaign();
  });

  const fetchCampaign = async () => {
    setLoading(true);
    const id = pathSplit[3];
    await getCampaignById(id)
      .then((res) => {
        setCampaignData(res);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={"รายละเอียดแคมเปญพอยท์"}
        showBack
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการแคมเปญพอยท์", path: "/crm/campaignPoint" },
              {
                text: "รายละเอียดแคมเปญพอยท์",
                path: window.location.pathname,
              },
            ]}
          />
        }
        extra={
          <Button
            title='แก้ไขแคมเปญ'
            icon={<EditOutlined />}
            onClick={() => navigate(`/crm/createCampaignPoint/${pathSplit[3]}`)}
            loading={loading}
            disabled={campaignData && moment(campaignData.endDate).isBefore(moment())}
            typeButton={
              campaignData && moment(campaignData.endDate).isBefore(moment())
                ? "disabled"
                : "primary"
            }
          />
        }
      />
    );
  };

  const TabDetail = () => {
    const details = [
      {
        label: "สถานะ",
        value: (
          <Tag color={campaignData?.isEnabled ? color.success : color.Disable}>
            {campaignData?.isEnabled ? "เปิดใช้งาน" : "ปิดใช้งาน"}
          </Tag>
        ),
      },
      {
        label: "รหัสแคมเปญ",
        value: campaignData?.campaignCode,
      },
      {
        label: "ชื่อแคมเปญ",
        value: campaignData?.campaignName,
      },
      {
        label: "ระยะเวลาแคมเปญ",
        value: `${
          campaignData?.startDate
            ? convertBuddhistYear.toBuddhistYear(
                moment(campaignData?.startDate),
                "DD MMM YYYY, HH:mm น.",
              )
            : ""
        } - ${
          campaignData?.endDate
            ? convertBuddhistYear.toBuddhistYear(
                moment(campaignData?.endDate),
                "DD MMM YYYY, HH:mm น.",
              )
            : ""
        }`,
      },
      {
        label: "กติกาและเงื่อนไขแคมเปญ",
        value: (
          <div
            className='view ql-editor'
            style={{
              display: "block",
              wordBreak: "break-word",
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{ __html: campaignData?.campaignCondition || "" }}
          ></div>
        ),
      },
      {
        label: "เป้าหมายในแคมเปญ",
        value: "เพื่อ Rebate",
      },
      {
        label: "",
        value: (
          <Button
            title='ดูภาพตัวอย่าง'
            onClick={() => setShowPreview(true)}
            typeButton='primary-light'
            icon={<img src={icon.appPreviewIcon} style={{ width: 16, height: 16 }} />}
            style={{ width: 140, height: 28, padding: 0 }}
            size='small'
          />
        ),
      },
    ];
    const previewSize = 290;
    const [showPreview, setShowPreview] = useState(false);
    return (
      <>
        <div style={{ padding: 16 }}>
          <Text fontWeight={700}>ข้อมูลแคมเปญ</Text>
          <Row style={{ padding: 16 }}>
            <Image
              src={campaignData?.imageFilename || image.emptyProductBrand}
              style={{
                width: "234",
                height: "136px",
                objectFit: "contain",
              }}
            />
          </Row>
          <Container>
            {details.map((p, i) => (
              <Desc {...p} key={i} />
            ))}
          </Container>
          {campaignData?.documentFilename && (
            <Row>
              <Col span={12}>
                <br />
                <Text fontWeight={700}>ไฟล์เอกสารอ้างอิง</Text>
                <FileItem
                  file={{ filePath: campaignData?.documentFilename }}
                  image={
                    <img src={image.pdfIcon} style={{ width: 24, height: 30, marginRight: 12 }} />
                  }
                  viewButton={
                    <Button
                      title='ดูรายละเอียด'
                      typeButton='primary'
                      icon={<img src={icon.viewFile} style={{ width: 16, height: 16 }} />}
                      style={{ width: 160 }}
                    />
                  }
                />
              </Col>
            </Row>
          )}
          <GreyModal
            open={showPreview}
            footer={null}
            onCancel={() => setShowPreview(false)}
            width={900}
          >
            <FlexRow style={{ width: "100%" }} justify='center'>
              <div>
                {campaignData?.imageFilename && (
                  <div
                    style={{
                      position: "absolute",
                      margin: `${previewSize * 0.244}px 0px 0px`,
                      width: previewSize,
                      height: previewSize * (675 / 1200),
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundImage: `url(${campaignData?.imageFilename})`,
                      backgroundColor: "#FFFFFF40",
                    }}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    marginTop: previewSize * 0.244 + previewSize * (675 / 1200) + 64,
                    width: previewSize,
                    padding: "2px 8px",
                  }}
                >
                  <Text level={6} fontWeight={600}>
                    <img src={icon.iconMegaphone} style={{ width: "22px" }} />
                    &nbsp;{campaignData?.campaignName || `ชื่อแคมเปญ`}
                  </Text>
                </div>
                <div
                  style={{
                    position: "absolute",
                    marginTop: previewSize * 0.244 + previewSize * (675 / 1200) + 93,
                    width: previewSize,
                    padding: "2px 8px",
                    background: "#FAFAFA",
                  }}
                >
                  <Text fontWeight={600} level={6}>
                    ระยะเวลา :{" "}
                    {campaignData?.startDate &&
                      `${dayjs(campaignData?.startDate).format("DD/MM/YYYY")} - `}
                    {campaignData?.endDate && dayjs(campaignData?.endDate).format("DD/MM/YYYY")}
                  </Text>
                </div>
                <div
                  style={{
                    position: "absolute",
                    marginTop: previewSize * 0.244 + previewSize * (675 / 1200) + 124,
                    width: previewSize,
                    padding: "2px 8px",
                  }}
                >
                  <Text color='Text1' fontWeight={600} level={6}>
                    กติกาและเงื่อนไข
                  </Text>
                  <div
                    className='view ql-editor'
                    style={{
                      display: "block",
                      wordBreak: "break-word",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      height: 244,
                      width: 260,
                      position: "absolute",
                      objectFit: "contain",
                    }}
                    dangerouslySetInnerHTML={{ __html: campaignData?.campaignCondition || "" }}
                  />
                </div>
                <div
                  style={{
                    width: previewSize,
                    height: previewSize * 2.18,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: `${previewSize}px ${previewSize * 2.18}px`,
                    backgroundImage: `url(${image.campaignDetail})`,
                  }}
                />
              </div>
            </FlexRow>
          </GreyModal>
          <Divider />
          <>
            {campaignData?.campaignPrograms.map((program: CampaignProgram, i) => {
              const prog = {
                ...program,
                products: program.campaignProgramProducts,
                stores: program.campaignProgramDealers,
              };
              return (
                <>
                  <CollapseContainer>
                    <Collapse
                      collapsible='icon'
                      expandIconPosition='end'
                      expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                    >
                      <Collapse.Panel
                        header={<ProgramPanelHeader i={i} program={prog} />}
                        key={prog.campaignProgramId}
                      >
                        <ProgramPanelBody i={i} program={prog} />
                      </Collapse.Panel>
                    </Collapse>
                  </CollapseContainer>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  };

  const TabTracking = () => {
    const tabs = {
      pointProgram: "PROGRAM",
      store: "SHOP",
      products: "PRODUCT",
      redeem: "REWARD",
    };
    const [loading, setLoading] = useState(false);
    const [trackingData, setTrackingData] = useState<any>();
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [detailData, setDetailData] = useState<any>();

    const [acttiveTab, setActiveTab] = useState(tabs.pointProgram);
    const [page, setPage] = useState(1);
    const resetPage = () => setPage(1);
    const pageSize = 8;

    const [minMaxQtyStore, setMMStore] = useState<any[]>([null, null]);
    const [filterStore, setFilterStore] = useState<{
      search: string;
      userType: string;
      zone: string | undefined;
      minQuantity: number | undefined;
      maxQuantity: number | undefined;
    }>({
      search: "",
      userType: "ALL",
      zone: undefined,
      minQuantity: undefined,
      maxQuantity: undefined,
    });

    const [zoneData, setZoneData] = useState();

    const [sortField, setSortField] = useState();
    const [sortOrder, setSortOrder] = useState();

    const [modalParner, setModalParner] = useState<boolean>(false);
    const [dataParner, setDataParner] = useState<any>();

    const handleTableChange = (pagination, filters, sorter) => {
      const { field, order } = sorter;
      setSortField(field);
      setSortOrder(mapSortingKey[order]);
    };

    const pagination = {
      position: ["bottomCenter"],
      pageSize,
      current: page,
      total: detailData?.count,
      onChange: (p) => setPage(p),
    } as TablePaginationConfig;

    const details = [
      {
        label: "รหัสแคมเปญ",
        value: trackingData?.campaignCode,
      },
      {
        label: "ชื่อแคมเปญ",
        value: trackingData?.campaignName,
      },
      {
        label: "ระยะเวลาแคมเปญ",
        icon: <img src={icon.crmCalendar} style={{ width: 16, height: 16 }} />,
        value:
          `${
            trackingData?.startDate ? moment(trackingData?.startDate).format("DD/MM/YYYY") : ""
          } - ${trackingData?.endDate ? moment(trackingData?.endDate).format("DD/MM/YYYY") : ""}` +
          (trackingData?.daysPassed && trackingData?.daysPassed > 0
            ? ` (ผ่านมาแล้ว ${trackingData?.daysPassed} วัน)`
            : ""),
      },
      {
        label: "เป้าหมายในแคมเปญ",
        icon: <img src={icon.crmGoal} style={{ width: 16, height: 16 }} />,
        value: "เพื่อ Rebate", // ", เพื่อแลกของรางวัล"
      },
      {
        label: "ร้านค้าที่เข้าร่วม",
        icon: <img src={icon.crmStore} style={{ width: 16, height: 16 }} />,
        value: (
          <>
            {`${trackingData?.countShop || 0}/${trackingData?.countShopAll} ร้าน `}
            <Tooltip
              placement='top'
              title={
                <>
                  <Text color='white'>
                    Dealer&nbsp;:&nbsp;{trackingData?.countDealer}&nbsp;ร้าน
                  </Text>
                  <br />
                  <Text color='white'>
                    Sub-Dealer&nbsp;:&nbsp;{trackingData?.countSubDealer}&nbsp;ร้าน
                  </Text>
                </>
              }
              trigger='click'
            >
              <InfoCircleFilled style={{ fontSize: 14, cursor: "pointer" }} />
            </Tooltip>
          </>
        ),
      },
    ];

    const tabsDetail = [
      {
        key: tabs.pointProgram,
        title: "พอยท์โปรแกรม",
        icon: icon.crmPointInactive,
        iconActive: icon.crmPointActive,
      },
      {
        key: tabs.store,
        title: "ร้านค้า",
        icon: icon.crmStoreInactive,
        iconActive: icon.crmStoreActive,
      },
      {
        key: tabs.products,
        title: "ยอดสินค้า",
        icon: icon.crmProductInactive,
        iconActive: icon.crmProductActive,
      },
      // { key: tabs.redeem, title: "พอยท์โปรแกรม", icon: icon.crmRedeemInactive, iconActive: icon.crmRedeemActive  },
    ];

    const pointProgram = (
      <div>
        <Row justify='end'>
          <Text>ทั้งหมด&nbsp;{detailData?.count}&nbsp;รายการ</Text>
        </Row>
        <br />
        <Table
          loading={loadingDetail}
          pagination={pagination}
          dataSource={detailData?.data}
          columns={[
            {
              title: "ชื่อพอยท์โปรแกรม",
              dataIndex: "campaignProgramName",
              key: "campaignProgramName",
            },
            {
              title: "รูปแบบการให้แต้ม",
              dataIndex: "campaignProgramType",
              key: "campaignProgramType",
              render: (value: string) => <Text>{campaignProgramTypeLabel[value]}</Text>,
            },
            {
              title: "เงื่อนไขการได้รับแต้ม",
              dataIndex: "campaignProgramCondition",
              key: "campaignProgramCondition",
              render: (value: string) => <Text>{campaignProgramConditionLabel[value]}</Text>,
            },
            {
              title: "จำนวนร้านค้า",
              dataIndex: "countShop",
              key: "countShop",
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value || 0}</Text>
                    <Text level={6} color='Text3'>
                      ร้าน
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "จำนวนคำสั่งซื้อ",
              dataIndex: "countOrder",
              key: "countOrder",
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value || 0}</Text>
                    <Text level={6} color='Text3'>
                      รายการ
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "ปริมาณที่สั่งซื้อ",
              dataIndex: "sumQuantity",
              key: "sumQuantity",
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value ? numberFormatter(value, 2) : "0.00"}</Text>
                    <Text level={6} color='Text3'>
                      ตัน
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "ยอดแต้ม",
              dataIndex: "sumPoint",
              key: "sumPoint",
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value ? numberFormatter(value, 2) : "0.00"}</Text>
                    <Text level={6} color='Text3'>
                      แต้ม
                    </Text>
                  </FlexCol>
                );
              },
            },
          ]}
        />
      </div>
    );

    const store = (
      <>
        <br />
        <Row gutter={12}>
          <Col span={6}>
            <Input
              placeholder='รหัสร้านค้า / ชื่อร้านค้า'
              suffix={<SearchOutlined />}
              size='large'
              onPressEnter={(e) => {
                const search = (e.target as HTMLTextAreaElement).value;
                setFilterStore({ ...filterStore, search });
                resetPage();
              }}
            />
          </Col>
          <Col span={4}>
            <Select
              data={[
                {
                  key: "ALL",
                  value: "ALL",
                  label: "ประเภทคู่ค้าทั้งหมด",
                },
                {
                  key: "DEALER",
                  value: "DEALER",
                  label: dealerTypeName["DEALER"],
                },
                {
                  key: "SUB_DEALER",
                  value: "SUB_DEALER",
                  label: dealerTypeName["SUB_DEALER"],
                },
              ]}
              defaultValue={"ALL"}
              value={filterStore?.userType}
              onChange={(userType) => {
                setFilterStore({ ...filterStore, userType });
                resetPage();
              }}
              style={{ width: "100%" }}
              allowClear={false}
            />
          </Col>
          <Col span={4}>
            <Select
              placeholder='เขตทั้งหมด'
              data={zoneData || []}
              value={filterStore?.zone}
              onChange={(zone) => {
                setFilterStore({ ...filterStore, zone });
                resetPage();
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={6}>
            <Space.Compact>
              <Input
                style={{ width: "46%", height: 40 }}
                placeholder='ปริมาณต่ำสุด'
                size='large'
                onChange={({ target: { value } }) => {
                  const minQuantity = parseFloat(value);
                  setMMStore([minQuantity, minMaxQtyStore[1]]);
                  if (!value || value.length <= 0) {
                    setFilterStore({ ...filterStore, minQuantity, maxQuantity: minMaxQtyStore[1] });
                  }
                }}
                onClear={() => {
                  setMMStore([null, minMaxQtyStore[1]]);
                  setFilterStore({
                    ...filterStore,
                    minQuantity: undefined,
                    maxQuantity: minMaxQtyStore[1],
                  });
                  resetPage();
                }}
                onPressEnter={(e) => {
                  const minQuantity = parseFloat((e.target as HTMLTextAreaElement).value);
                  setFilterStore({ ...filterStore, minQuantity, maxQuantity: minMaxQtyStore[1] });
                  resetPage();
                }}
                onKeyDown={onInputNumberKeyPress}
                allowClear
              />
              <Text style={{ width: "8%", textAlign: "center", lineHeight: "40px" }}>-</Text>
              <Input
                style={{ width: "46%", height: 40 }}
                placeholder='ปริมาณสูงสุด'
                size='large'
                onChange={({ target: { value } }) => {
                  const maxQuantity = parseFloat(value);
                  setMMStore([minMaxQtyStore[0], maxQuantity]);
                  if (!value || value.length <= 0) {
                    setFilterStore({ ...filterStore, maxQuantity, minQuantity: minMaxQtyStore[0] });
                  }
                }}
                onClear={() => {
                  setMMStore([minMaxQtyStore[0], null]);
                  setFilterStore({
                    ...filterStore,
                    maxQuantity: undefined,
                    minQuantity: minMaxQtyStore[0],
                  });
                  resetPage();
                }}
                onPressEnter={(e) => {
                  const maxQuantity = parseFloat((e.target as HTMLTextAreaElement).value);
                  setFilterStore({ ...filterStore, maxQuantity, minQuantity: minMaxQtyStore[0] });
                  resetPage();
                }}
                onKeyDown={onInputNumberKeyPress}
                allowClear
              />
            </Space.Compact>
          </Col>
          <Col span={4}>
            <FlexCol style={{ width: "100%", height: "100%" }} justify='end' align='end'>
              <Text>ทั้งหมด&nbsp;{detailData?.count}&nbsp;ร้านค้า</Text>
            </FlexCol>
          </Col>
        </Row>
        <br />
        <Table
          loading={loadingDetail}
          pagination={pagination}
          dataSource={detailData?.data}
          columns={[
            {
              title: "ชื่อร้านค้า",
              dataIndex: "shopName",
              key: "shopName",
              render: (value: number, row: any) => {
                return (
                  <FlexCol>
                    <Text>{value}</Text>
                    <Text level={6} color='Text3'>
                      {row?.shopNo}&nbsp;•&nbsp;{dealerTypeName[row?.shopType]}&nbsp;
                      <InfoCircleFilled
                        style={{ fontSize: 12, cursor: "pointer" }}
                        onClick={() => {
                          setModalParner(!modalParner);
                          getParner(row.shopId, row.shopType);
                        }}
                      />
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "พอยท์โปรแกรมที่ร่วม",
              dataIndex: "countProgram",
              key: "countProgram",
              render: (value: number, row: any) => {
                return (
                  <FlexCol>
                    <Text>
                      {value || 0}&nbsp;
                      <Tooltip
                        placement='top'
                        title={
                          <>
                            <Text fontWeight={700} color='white'>
                              พอยท์โปรแกรมที่เข้าร่วม
                            </Text>
                            {row?.program?.map((p, i) => {
                              return (
                                <>
                                  <br />
                                  <Text color='white'>
                                    {i + 1}.&nbsp;{p.campaignProgramName}
                                  </Text>
                                </>
                              );
                            })}
                          </>
                        }
                        trigger='click'
                      >
                        <InfoCircleFilled style={{ fontSize: 14, cursor: "pointer" }} />
                      </Tooltip>
                    </Text>
                    <Text level={6} color='Text3'>
                      โปรแกรม
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "เขต",
              dataIndex: "shopZone",
              key: "shopZone",
              render: (value: string) => <Text>{value}</Text>,
            },
            {
              title: "จำนวนคำสั่งซื้อ",
              dataIndex: "countOrderPoint",
              key: "countOrderPoint",
              sorter: true,
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value || 0}</Text>
                    <Text level={6} color='Text3'>
                      รายการ
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "ปริมาณที่สั่งซื้อ",
              dataIndex: "sumQuantity",
              key: "sumQuantity",
              sorter: true,
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value ? numberFormatter(value, 2) : 0}</Text>
                    <Text level={6} color='Text3'>
                      ตัน
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "แต้มที่ได้รับ",
              dataIndex: "sumPoint",
              key: "sumPoint",
              sorter: true,
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value ? numberFormatter(value, 2) : 0}</Text>
                    <Text level={6} color='Text3'>
                      แต้ม
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: (value: number, row: any) => {
                return (
                  <FlexCol>
                    <UnorderedListOutlined
                      style={{ color: color["primary"] }}
                      onClick={() => {
                        navigate("/crm/shop-details/" + row.shopId, {
                          state: { row, campaignId: pathSplit[3] },
                        });
                      }}
                    />
                  </FlexCol>
                );
              },
            },
          ]}
        />
      </>
    );

    const products = (
      <div>
        <Row justify='end'>
          <Text>ทั้งหมด&nbsp;{detailData?.count}&nbsp;รายการ</Text>
        </Row>
        <br />
        <Table
          loading={loadingDetail}
          pagination={pagination}
          dataSource={detailData?.data}
          columns={[
            {
              title: "สินค้า",
              dataIndex: "productName",
              key: "productName",
              render: (value: string, row: any) => {
                return (
                  <FlexRow>
                    <FlexCol>
                      <img
                        src={row?.productImage}
                        style={{ width: 50, height: 50, objectFit: "contain" }}
                      />
                    </FlexCol>
                    <FlexCol>
                      <Text>{value}</Text>
                      <Text level={6} color='Text3'>
                        {row?.commonName}
                      </Text>
                    </FlexCol>
                  </FlexRow>
                );
              },
            },
            {
              title: "ขนาด",
              dataIndex: "saleUom",
              key: "saleUom",
              render: (value: number, row: any) => {
                return (
                  <FlexCol>
                    <Text>{value || "-"}</Text>
                    <Text level={6} color='Text3'>
                      {row?.productCode}
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "ปริมาณที่สั่งซื้อ",
              dataIndex: "sumQuantity",
              key: "sumQuantity",
              sorter: true,
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value ? numberFormatter(value, 2) : 0}</Text>
                    <Text level={6} color='Text3'>
                      ตัน
                    </Text>
                  </FlexCol>
                );
              },
            },
            {
              title: "ยอดแต้ม",
              dataIndex: "sumPoint",
              key: "sumPoint",
              sorter: true,
              render: (value: number) => {
                return (
                  <FlexCol>
                    <Text>{value ? numberFormatter(value, 2) : 0}</Text>
                    <Text level={6} color='Text3'>
                      แต้ม
                    </Text>
                  </FlexCol>
                );
              },
            },
          ]}
          onChange={handleTableChange}
        />
      </div>
    );

    const tabBody = {
      PROGRAM: pointProgram,
      SHOP: store,
      PRODUCT: products,
    };

    useEffectOnce(() => {
      fetchTrackingData();
    });

    const fetchTrackingData = async () => {
      setLoading(true);
      const id = pathSplit[3];
      await trackingCampaign(id)
        .then((res) => {
          setTrackingData(res);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    useEffect(() => {
      fetchDetail();
    }, [acttiveTab, sortField, sortOrder, filterStore, page]);

    const fetchDetail = async () => {
      setLoadingDetail(true);
      const id = pathSplit[3];
      console.log(sortField, sortOrder);
      await trackingCampaignDetail({
        campaignId: id,
        followCampaignType: acttiveTab,
        ...filterStore,
        minQuantity: minMaxQtyStore[0],
        maxQuantity: minMaxQtyStore[1],
        sortBy: sortField,
        sortDirection: sortOrder,
        page,
        take: pageSize,
      })
        .then((res) => {
          console.log("detail", res);
          setDetailData(res);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoadingDetail(false);
        });
    };

    useEffect(() => {
      if (!zoneData) {
        fetchZone();
      }
    }, [acttiveTab]);

    const fetchZone = async () => {
      await zoneDatasource
        .getAllZoneByCompany(company)
        .then((res) => {
          const data = res.map((item: any) => {
            return {
              label: item.zoneName,
              value: item.zoneName,
              key: item.zoneId,
            };
          });
          setZoneData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getParner = async (id: string, type: string) => {
      if (type === "DEALER") {
        await getPartnerDealer(id).then((res) => {
          setDataParner({ data: res, type: "DL" });
        });
      } else {
        await getPartnerSubdealer(id).then((res) => {
          setDataParner({ data: res, type: "SD" });
        });
      }
    };

    return (
      <>
        <div style={{ padding: 16 }}>
          <Row justify='space-between'>
            <Col span={12}>
              <Text fontWeight={700}>ภาพรวมแคมเปญ</Text>
            </Col>
            <Col span={4}>
              {/* <Button
                title='ดาวน์โหลดแคมเปญ'
                icon={<DownloadOutlined />}
                typeButton='primary-light'
              /> */}
            </Col>
          </Row>
          <Container>
            {details.map((p, i) => (
              <Desc {...p} key={i} />
            ))}
          </Container>
        </div>
        <Row gutter={16}>
          <Col span={8}>
            <TrackingItem
              title='คำสั่งซื้อทั้งหมด (ยอดขน)'
              number={trackingData?.countOrder}
              unit='รายการ'
            />
          </Col>
          <Col span={8}>
            <TrackingItem
              title='ปริมาณสินค้าที่สั่งซื้อทั้งหมด'
              number={numberFormatter(trackingData?.sumQuantity, 2)}
              unit='ตัน'
            />
          </Col>
          <Col span={8}>
            <TrackingItem
              title={
                <>
                  <StarOutlined style={{ fontSize: 16, color: "#F0C300" }} />
                  &nbsp;ยอดแต้มทั้งหมด
                </>
              }
              number={numberFormatter(trackingData?.sumPoint, 2)}
              unit='แต้ม'
            />
          </Col>
        </Row>
        <Divider />
        <div style={{ padding: 16 }}>
          <Row justify='space-between'>
            <Text fontWeight={700}>รายละเอียดแคมเปญ</Text>
          </Row>
        </div>
        <div>
          {tabsDetail.map(({ title, icon, iconActive, key }) => {
            const isActive = acttiveTab === key;
            return (
              <AntdButton
                title={title}
                icon={<img src={isActive ? iconActive : icon} style={{ width: 16, height: 16 }} />}
                key={key}
                type={isActive ? "primary" : "default"}
                onClick={() => {
                  setActiveTab(key);
                  resetPage();
                }}
                style={{
                  marginRight: 12,
                  borderColor: color.secondary,
                  color: isActive ? "white" : color.secondary,
                  background: isActive ? color.secondary : "white",
                }}
              >
                &nbsp;{title}
              </AntdButton>
            );
          })}
        </div>
        <div>{tabBody[acttiveTab]}</div>
        <Modal
          width={800}
          open={modalParner}
          closable={true}
          onCancel={() => setModalParner(!modalParner)}
          title={
            <>
              <Text fontWeight={600} fontSize={20}>
                คู่ค้ากับ {dataParner?.type === "DL" ? "Dealer" : "Sub-Dealer"}
              </Text>
              <br />
              <Text fontWeight={400} fontSize={16} color='Text3'>
                ทั้งหมด {dataParner?.data?.length} ร้านค้า
              </Text>
            </>
          }
          centered
          footer={false}
        >
          <Table
            pagination={false}
            dataSource={dataParner?.data?.map((p, key) => ({ ...p, key })) || []}
            columns={[
              {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{index + 1}</Text>,
                  };
                },
              },
              {
                title: "รหัสร้านค้า",
                dataIndex: "customer_no",
                key: "customer_no",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "ชื่อร้านค้า",
                dataIndex: "customer_name",
                key: "customer_name",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "เขต",
                dataIndex: "zone",
                key: "zone",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "จังหวัด",
                dataIndex: "province",
                key: "province",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
            ]}
          />
        </Modal>
      </>
    );
  };

  const tabsItems = [
    {
      key: "1",
      label: "รายละเอียด",
      children: <TabDetail />,
    },
    {
      key: "2",
      label: "ติดตามแคมเปญ",
      children: <TabTracking />,
    },
  ];

  const ProgramPanelHeader = ({ i, program }: { i: number; program: CampaignProgram }) => {
    return (
      <Row style={{ padding: "24px 32px" }}>
        <Col span={12}>
          <Text color='Text3' level={6}>
            ชื่อพอยท์โปรแกรม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {program.campaignProgramName}
          </Text>
        </Col>
        <Col span={6}>
          <Text color='Text3' level={6}>
            รูปแบบการให้แต้ม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {campaignProgramTypeLabel[program.campaignProgramType]}
          </Text>
        </Col>
        <Col span={6}>
          <Text color='Text3' level={6}>
            เงื่อนไขการได้รับแต้ม
          </Text>
          <br />
          <Text color='Text1' level={5} fontWeight={700}>
            {campaignProgramConditionLabel[program.campaignProgramCondition]}
          </Text>
        </Col>
      </Row>
    );
  };

  const ProgramPanelBody = ({ i, program }: { i: number; program: CampaignProgram }) => {
    const [activeTabId, setActiveTabId] = useState<string>(tabId.store);

    const StoreTab = () => {
      const [searchStoreForm] = Form.useForm();

      const [filteredStore, setFilteredStore] = useState<StoreEntity[]>(program?.stores || []);

      const onSearchStore = (values) => {
        if (program?.stores) {
          setFilteredStore(
            program?.stores
              .filter((s) => {
                if (values.search) {
                  return s?.customerName?.indexOf(values.search) > -1;
                }
                return true;
              })
              .filter((s) => {
                if (values.zone) {
                  return s?.zone === values.zone;
                }
                return true;
              }),
          );
        } else {
          setFilteredStore([]);
        }
      };

      const columns = [
        {
          title: "รหัสร้านค้า",
          dataIndex: "dealerCode",
          key: "dealerCode",
          render: (value: any, row: any, index: number) => {
            return {
              children: <Text>{value}</Text>,
            };
          },
        },
        {
          title: "ชื่อร้านค้า",
          dataIndex: "dealerName",
          key: "dealerName",
          render: (value: any, row: any, index: number) => {
            return {
              children: (
                <>
                  <Text>{value}</Text>
                  <br />
                  <Text level={6} color='Text3'>
                    {row?.customer?.taxNo}
                  </Text>
                </>
              ),
            };
          },
        },
        {
          title: "ประเภทคู่ค้า",
          dataIndex: "dealerType",
          key: "dealerType",
          render: (value: any, row: any, index: number) => {
            console.log(row);
            return {
              children: <Text level={6}>{dealerTypeName[value]}</Text>,
            };
          },
        },
        {
          title: "เขตการขาย",
          dataIndex: "dealerZone",
          key: "dealerZone",
          render: (value: any, row: any, index: number) => {
            return {
              children: <Text>{value}</Text>,
            };
          },
        },
      ];
      return (
        <>
          <Form form={searchStoreForm} onValuesChange={onSearchStore}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name='search'>
                  <Input placeholder='รหัส / ชื่อร้านค้า' suffix={<SearchOutlined />} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name='zone'>
                  <Select placeholder='เขตทั้งหมด' data={zone} style={{ width: 150 }} />
                </Form.Item>
              </Col>
              <Col span={10} />
            </Row>
          </Form>
          <br />
          <TableContainer>
            <Table
              columns={columns}
              dataSource={filteredStore || program.stores}
              scroll={{ y: 600 }}
            />
          </TableContainer>
        </>
      );
    };

    const ProductTab = () => {
      const [searchProductForm] = Form.useForm();

      const [filteredProd, setFilteredProd] = useState<ProductEntity[]>(program?.products || []);

      const onSearchProduct = (values) => {
        if (program?.products) {
          setFilteredProd(
            program?.products.filter((s) => {
              if (values.search) {
                return s?.productName?.indexOf(values.search) > -1;
              }
              return true;
            }),
          );
        } else {
          setFilteredProd([]);
        }
      };

      const columns = [
        {
          title: "ชื่อสินค้า",
          dataIndex: "name",
          key: "productName",
          width: "40%",
          render: (value: any, row: any, index: number) => {
            return {
              children: (
                <Row gutter={8} style={{ padding: "12px 0px" }}>
                  <Col>
                    <Avatar
                      src={row.productImage ? row.productImage : image.product_no_image}
                      size={70}
                      shape='square'
                      onError={() => false}
                    />
                  </Col>
                  <Col>
                    <Text>{row.productName}</Text>
                    <br />
                    <Text level={6}>{row.commonName}</Text>
                    <br />
                    {company !== "ICPL" ? (
                      <Text level={6} color='Text3'>
                        Product Group : {row.productGroup || "-"}
                      </Text>
                    ) : (
                      <Text level={6} color='Text3'>
                        Stragery Group : {row.productStrategy || "-"}
                      </Text>
                    )}
                  </Col>
                </Row>
              ),
            };
          },
        },
        {
          title: "",
          dataIndex: "name",
          key: "productName",
          width: "20%",
          render: (value: any, row: any, index: number) => {
            return (
              <Row>
                <Col>
                  <Text>{row.company}</Text>
                  <br />
                  <Text level={6}>{row.commonName}</Text>
                </Col>
              </Row>
            );
          },
        },
        {
          title: "แต้มที่ได้รับ",
          dataIndex: "productId",
          key: "point",
          width: "42%",
          render: (productId: any, row: any) => {
            return (
              <Row gutter={16} align='middle'>
                <Col span={15}>
                  <Text level={5}>
                    <img src={icon.star} style={{ width: "16px" }} />
                    &nbsp;แต้มทีได้รับ
                  </Text>
                  <Input
                    suffix={<Text level={5}>แต้ม</Text>}
                    disabled
                    value={row.programPoints}
                    size='large'
                  />
                </Col>
                <Col span={9}>
                  <Text level={5}>ต่อหน่วย SKU</Text>
                  <Input disabled value={row.saleUOMTH} size='large' />
                </Col>
              </Row>
            );
          },
        },
      ];
      return (
        <>
          <Form form={searchProductForm} onValuesChange={onSearchProduct}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item name='search'>
                  <Input placeholder='รหัส / ชื่อร้านค้า' suffix={<SearchOutlined />} />
                </Form.Item>
              </Col>
              <Col span={18} />
            </Row>
          </Form>
          <br />
          <TableContainer>
            <Table columns={columns} dataSource={program.products} scroll={{ y: 600 }} />
          </TableContainer>
        </>
      );
    };

    return (
      <ProgramTabsContainer style={{ background: color.background4 }}>
        <Row gutter={4}>
          <TabHeader
            itemId={tabId.store}
            title='ร้านค้า'
            count={program.stores?.length || 0}
            isActive={activeTabId === tabId.store}
            setActive={setActiveTabId}
          />
          <TabHeader
            itemId={tabId.product}
            title='สินค้า'
            count={program.products?.length || 0}
            isActive={activeTabId === tabId.product}
            setActive={setActiveTabId}
          />
        </Row>
        <TabBody>{activeTabId === tabId.store ? <StoreTab /> : <ProductTab />}</TabBody>
      </ProgramTabsContainer>
    );
  };

  return (
    <>
      <CardContainer>
        <PageTitle />
        <br />
        <Tabs data={tabsItems} />
      </CardContainer>
    </>
  );
};
