import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import { Form } from "antd";
import Input from "../../components/Input/Input";
import { defaultPropsForm } from "../../utility/DefaultProps";
import icon from "../../resource/icon";
import { useWatch } from "antd/lib/form/Form";
import { AuthDatasource } from "../../datasource/AuthDatasource";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f3f6f9;
`;
const CardStyled = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  background-color: ${color.white};
  border-radius: 12px;
  min-width: 360px;
`;

const staticConditions = [
  {
    id: 1,
    condition: "ต้องมีตัวอักษรภาษาอังกฤษ อักษรพิเศษ และตัวเลข รวมกันอย่างน้อย 8 ตัว",
    isPassed: false,
  },
  {
    id: 2,
    condition: "ต้องมีตัวอักษรภาษาอังกฤษในรหัสผ่านอย่างน้อย 1 ตัว",
    isPassed: false,
  },
  {
    id: 4,
    condition: "ต้องมีอักษรพิเศษอย่างน้อย 1 ตัว (เช่น * # @ % - +)",
    isPassed: false,
  },
  {
    id: 3,
    condition: "ต้องมีตัวเลขอย่างน้อย 1 ตัว",
    isPassed: false,
  },
];
function SettingPasswordPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const [form] = Form.useForm();
  const password = useWatch(["password"], form);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [conditionList, setConditionList] = React.useState<
    {
      id: number;
      condition: string;
      isPassed: boolean;
    }[]
  >(staticConditions);

  useEffect(() => {
    const checkIsPassword = async () => {
      const decodedToken = jwtDecode(token!);
      AuthDatasource.checkIsPassword(decodedToken!["userStaffId"]).then((res) => {
        if (res.isPassword) {
          navigate("/");
        } else {
          setIsLoading(false);
        }
      });
    };
    checkIsPassword();
  }, []);

  useEffect(() => {
    if (token) {
      const getInitialEmail = async () => {
        const decodedToken: {
          email: string;
        } = jwtDecode(token);
        if (!decodedToken) {
          return;
        }
        const email = decodedToken?.email;
        form.setFieldsValue({ email });
      };
      getInitialEmail();
    }
  }, [token, form]);

  useEffect(() => {
    const validationPassword = () => {
      const conditionList = staticConditions.map((el) => {
        if (!password) {
          el.isPassed = false;
          return el;
        }
        if (el.id === 1) {
          el.isPassed = password.length > 7;
        }
        if (el.id === 2) {
          el.isPassed = new RegExp("^(?=.*[a-zA-Z])").test(password);
        }
        if (el.id === 3) {
          el.isPassed = new RegExp("^(?=.*[0-9])").test(password);
        }
        if (el.id === 4) {
          el.isPassed = new RegExp("^(?=.*[!@#$%^&*])").test(password);
        }
        return el;
      });
      setConditionList(conditionList);
    };
    validationPassword();
  }, [password, form]);
  const onFinish = async (values: { email: string; password: string; confirmPassword: string }) => {
    try {
      const token = searchParams.get("token");
      if (!token) {
        return;
      }
      const result = await AuthDatasource.updatePassword({
        email: values.email,
        password: values.password,
        token: token,
      });
      if (result && result.success) {
        Swal.fire({
          icon: "success",
          title: "ตั้งค่ารหัสผ่านสำเร็จ",
          timer: 3000,
          showConfirmButton: false,
        }).then(() => {
          navigate("/");
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <></>;
  } else {
    return (
      <Container>
        <CardStyled>
          <ReactSVG
            onError={(error) => console.log(error)}
            src={`${process.env.PUBLIC_URL}/logo_sellcoda.svg`}
          />
          <Text
            fontSize={24}
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
            fontWeight={700}
          >
            กำหนดรหัสผ่านใหม่
          </Text>
          <Form
            {...defaultPropsForm}
            style={{
              width: "100%",
            }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label='อีเมล'
              name='email'
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกอีเมล",
                },
                {
                  type: "email",
                  message: "รูปแบบอีเมลไม่ถูกต้อง",
                },
              ]}
            >
              <Input placeholder='ระบุอีเมล' readOnly />
            </Form.Item>
            <Form.Item
              label='รหัสผ่าน'
              name='password'
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกรหัสผ่าน",
                },
                {
                  min: 8,
                  message: "ต้องมีตัวอักษรภาษาอังกฤษ อักษรพิเศษ และตัวเลข รวมกันอย่างน้อย 8 ตัว",
                },
                {
                  pattern: new RegExp("^(?=.*[a-zA-Z])"),
                  message: "ต้องมีตัวอักษรภาษาอังกฤษในรหัสผ่านอย่างน้อย 1 ตัว",
                },
                {
                  pattern: new RegExp("^(?=.*[0-9])"),
                  message: "ต้องมีตัวเลขอย่างน้อย 1 ตัว",
                },
                {
                  pattern: new RegExp("^(?=.*[!@#$%^&*])"),
                  message: "ต้องมีอักษรพิเศษอย่างน้อย 1 ตัว (เช่น * # @ % - +)",
                },
              ]}
            >
              <Input
                type={showPass ? "text" : "password"}
                placeholder='ระบุยืนยันรหัสผ่าน'
                suffix={
                  showPass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowPass(false);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowPass(true);
                      }}
                    />
                  )
                }
              />
            </Form.Item>
            <Form.Item
              label='ยืนยันรหัสผ่าน'
              name='confirmPassword'
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกยืนยันรหัสผ่าน",
                },
                {
                  validator: async (_, value) => {
                    if (value !== form.getFieldValue("password")) {
                      return Promise.reject("รหัสผ่านไม่ตรงกัน");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type={showPass ? "text" : "password"}
                placeholder='ระบุยืนยันรหัสผ่าน'
                suffix={
                  showPass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowPass(false);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowPass(true);
                      }}
                    />
                  )
                }
              />
            </Form.Item>

            {conditionList.map((el) => {
              const isPassed = el.isPassed;
              return (
                <div
                  key={el.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={isPassed ? icon.iconCheck : icon.iconCircle}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 4,
                    }}
                  />
                  <Text fontSize={12} color={isPassed ? "success" : "Text1"} fontFamily='Sarabun'>
                    {el.condition}
                  </Text>
                </div>
              );
            })}

            <Button
              type='primary'
              htmlType='submit'
              title='ยืนยันรหัสผ่าน'
              style={{
                width: "100%",
                marginTop: 16,
                marginBottom: 16,
              }}
            />
          </Form>
        </CardStyled>
      </Container>
    );
  }
}

export default SettingPasswordPage;
