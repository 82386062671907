import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownloadOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Col, Row, Tabs, Tag } from "antd";
import Table from "antd/es/table";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Button from "../../components/Button/Button";
import { CardContainer } from "../../components/Card/CardContainer";
import { RangePicker } from "../../components/DatePicker/DatePicker";
import Input from "../../components/Input/Input";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import Select from "../../components/Select/Select";
import Text from "../../components/Text/Text";
import { getExcelAllProReport, getPromotionListReport } from "../../datasource/PromotionDatasource";
import { PromotionStatus, PROMOTION_TYPE_NAME } from "../../definitions/promotion";
import color from "../../resource/color";
import { numberFormatter } from "../../utility/Formatter";

type FixedType = "left" | "right" | boolean;

export const ReportPromotionList: React.FC = () => {
  const navigate = useNavigate();
  const company = JSON.parse(localStorage.getItem("company")!);

  const [statusFilter, setStatusFilter] = useState<any>(undefined);
  const [filter, setFilter] = useState({
    company: company.companyCode,
    search: "",
    sortDirections: "ASC",
    sortFields: "startDate",
    page: 1,
    startDate: "",
    endDate: "",
    take: 10,
    promotionType: undefined,
  });
  const [data, setData] = useState<{
    count: number;
    count_status: [{ count: number; promotion_status: boolean }];
    resultPromotion: [any];
  }>();

  useEffect(() => {
    getReport();
  }, [filter, statusFilter]);

  const getReport = async () => {
    await getPromotionListReport({
      company: filter.company,
      page: filter.page,
      take: filter.take,
      startDate: filter.startDate,
      endDate: filter.endDate,
      sortField: filter.sortFields,
      sortDirections: filter.sortDirections,
      searchText: filter.search,
      promotionStatus: statusFilter,
      promotionType: filter.promotionType,
    }).then((res) => {
      setData(res);
    });
  };

  const downloadExcel = async (
    company: string,
    search: string,
    startDate: string,
    endDate: string,
    promotionStatus: string,
  ) => {
    getExcelAllProReport(company, search, 1, startDate, endDate, promotionStatus)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Promotion All_Promotion_${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        } - ${endDate ? moment(endDate).format("YYYY-MM-DD") : ""}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => console.log(err));
  };

  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col span={6}>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        onBack={() => navigate(-1)}
        title={"รายการโปรโมชันทั้งหมด"}
        showBack
        extra={
          <Row gutter={8} justify={"space-between"}>
            <Col>
              <Input
                defaultValue={filter.search}
                onPressEnter={(e) => {
                  const value = (e.target as HTMLTextAreaElement).value;
                  setFilter({
                    ...filter,
                    page: 1,
                    search: value,
                  });
                }}
                placeholder='ค้นหาชื่อโปรโมชัน'
                suffix={<SearchOutlined />}
              />
            </Col>
            <Col>
              <Select
                allowClear
                style={{ width: "100%" }}
                value={filter.promotionType}
                onChange={(val) =>
                  setFilter({
                    ...filter,
                    page: 1,
                    promotionType: val,
                  })
                }
                data={PromotionStatus || []}
                placeholder='ประเภทโปรโมชัน'
              />
            </Col>
            <Col>
              <RangePicker
                enablePast
                allowEmpty={[true, true]}
                value={
                  !filter.startDate ? undefined : [dayjs(filter.startDate), dayjs(filter.endDate)]
                }
                onChange={(date) => {
                  if (date) {
                    setFilter({
                      ...filter,
                      page: 1,
                      startDate: moment(date[0]?.$d).format(),
                      endDate: moment(date[1]?.$d).format(),
                    });
                  } else {
                    setFilter({
                      ...filter,
                      page: 1,
                      startDate: "",
                      endDate: "",
                    });
                  }
                }}
              />
            </Col>
            <Col>
              <Button
                title='ดาวน์โหลด'
                icon={<DownloadOutlined style={{ color: "white", fontSize: "18px" }} />}
                onClick={() =>
                  downloadExcel(
                    filter.company,
                    filter.search,
                    filter.startDate,
                    filter.endDate,
                    statusFilter,
                  )
                }
              />
            </Col>
          </Row>
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              {
                text: "รายงาน",
                path: "/PromotionPage/promotion",
                isState: true,
              },
              {
                text: "โปรโมชัน",
                path: window.location.pathname,
                isState: true,
                callState: () => navigate(-1),
              },
              {
                text: "รายการโปรโมชันทั้งหมด",
                path: window.location.pathname,
                isState: true,
              },
            ]}
          />
        }
      />
    );
  };

  const tabsItems = [
    {
      label: `ทั้งหมด (${
        data?.count_status?.reduce(
          (prev: any, { count }) => parseInt(prev) + parseInt(count.toString()),
          0,
        ) || 0
      })`,
      key: "ALL",
    },
    {
      label: `เปิดการใช้งาน (${data?.count_status.find((x) => x.promotion_status)?.count || 0})`,
      key: "true",
    },
    {
      label: `ปิดการใช้งาน (${data?.count_status.find((x) => !x.promotion_status)?.count || 0})`,
      key: "false",
    },
  ];

  const column = [
    {
      title: (
        <Row justify={"space-between"} gutter={8}>
          <Col>
            <Text fontWeight={600} fontSize={16}>
              ชื่อโปรโมชัน
            </Text>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <CaretUpOutlined
                style={{
                  position: "relative",
                  top: 2,
                }}
                onClick={() =>
                  setFilter({
                    ...filter,
                    page: 1,
                    sortFields: "promotionName",
                    sortDirections: "ASC",
                  })
                }
              />
              <CaretDownOutlined
                style={{
                  position: "relative",
                  bottom: 2,
                }}
                onClick={() =>
                  setFilter({
                    ...filter,
                    page: 1,
                    sortFields: "promotionName",
                    sortDirections: "DESC",
                  })
                }
              />
            </div>
          </Col>
        </Row>
      ),
      dataIndex: "promotionName",
      key: "promotinName",
      fixed: "left" as FixedType | undefined,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text fontFamily='Sarabun' fontSize={16}>
                {row.promotionName}
              </Text>
              <br />
              <Text color='Text3' fontFamily='Sarabun' fontSize={14}>
                {row.promotionCode}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: (
        <Row justify={"space-between"} gutter={8}>
          <Col>
            <Text fontWeight={600} fontSize={16}>
              ประเภทโปรโมชัน
            </Text>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <CaretUpOutlined
                style={{
                  position: "relative",
                  top: 2,
                }}
                onClick={() =>
                  setFilter({
                    ...filter,
                    page: 1,
                    sortFields: "promotionType",
                    sortDirections: "ASC",
                  })
                }
              />
              <CaretDownOutlined
                style={{
                  position: "relative",
                  bottom: 2,
                }}
                onClick={() =>
                  setFilter({
                    ...filter,
                    page: 1,
                    sortFields: "promotionType",
                    sortDirections: "DESC",
                  })
                }
              />
            </div>
          </Col>
        </Row>
      ),
      dataIndex: "promotionType",
      key: "promotionType",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {PROMOTION_TYPE_NAME[row.promotionType]}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Row justify={"space-between"} gutter={8}>
          <Col>
            <Text fontWeight={600} fontSize={16}>
              จำนวนคำสั่งซื้อ
            </Text>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <CaretUpOutlined
                style={{
                  position: "relative",
                  top: 2,
                }}
                onClick={() =>
                  setFilter({
                    ...filter,
                    page: 1,
                    sortFields: "totalOrder",
                    sortDirections: "ASC",
                  })
                }
              />
              <CaretDownOutlined
                style={{
                  position: "relative",
                  bottom: 2,
                }}
                onClick={() =>
                  setFilter({
                    ...filter,
                    page: 1,
                    sortFields: "totalOrder",
                    sortDirections: "DESC",
                  })
                }
              />
            </div>
          </Col>
        </Row>
      ),
      dataIndex: "totalOrder",
      key: "totalOrder",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.totalOrder > 0 ? `${numberFormatter(row.totalOrder, 0)} รายการ` : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Row justify={"space-between"} gutter={8}>
          <Col>
            <Text fontWeight={600} fontSize={16}>
              ช่วงเวลา
            </Text>
          </Col>
        </Row>
      ),
      dataIndex: "preriod",
      key: "preriod",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text fontFamily='Sarabun' fontSize={16}>
                {moment(row.startDate).format("L") || "-"} -{" "}
                {moment(row.endDate).format("L") || "-"}
              </Text>
              <br />
              <Tag color={row.promotionStatus ? color.success : color.Disable}>
                {row.promotionStatus ? "เปิดใช้งาน" : "ปิดใช้งาน"}
              </Tag>
            </>
          ),
        };
      },
    },
    {
      title: (
        <>
          <Row justify={"space-between"} gutter={8}>
            <Col>
              <Text fontWeight={600} fontSize={16}>
                ยอดขายที่ใช้โปร
              </Text>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <CaretUpOutlined
                  style={{
                    position: "relative",
                    top: 2,
                  }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      page: 1,
                      sortFields: "saleAmtTotal",
                      sortDirections: "ASC",
                    })
                  }
                />
                <CaretDownOutlined
                  style={{
                    position: "relative",
                    bottom: 2,
                  }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      page: 1,
                      sortFields: "saleAmtTotal",
                      sortDirections: "DESC",
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text fontWeight={600} fontSize={16}>
                (บาท)
              </Text>
            </Col>
          </Row>
        </>
      ),
      dataIndex: "saleAmtTotal",
      key: "saleAmtTotal",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.saleAmtTotal > 0 ? numberFormatter(row.saleAmtTotal, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <>
          <Row justify={"space-between"} gutter={8}>
            <Col>
              <Text fontWeight={600} fontSize={16}>
                ยอดส่วนลดที่ใช้โปร
              </Text>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <CaretUpOutlined
                  style={{
                    position: "relative",
                    top: 2,
                  }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      page: 1,
                      sortFields: "discountTotal",
                      sortDirections: "ASC",
                    })
                  }
                />
                <CaretDownOutlined
                  style={{
                    position: "relative",
                    bottom: 2,
                  }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      page: 1,
                      sortFields: "discountTotal",
                      sortDirections: "DESC",
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text fontWeight={600} fontSize={16}>
                (บาท)
              </Text>
            </Col>
          </Row>
        </>
      ),
      dataIndex: "discountTotal",
      key: "discountTotal",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.discountTotal > 0 ? numberFormatter(row.discountTotal, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <>
          <Row justify={"space-between"} gutter={8}>
            <Col>
              <Text fontWeight={600} fontSize={16}>
                ยอดของแถมที่ใช้โปร
              </Text>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <CaretUpOutlined
                  style={{
                    position: "relative",
                    top: 2,
                  }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      page: 1,
                      sortFields: "freebiesTotal",
                      sortDirections: "ASC",
                    })
                  }
                />
                <CaretDownOutlined
                  style={{
                    position: "relative",
                    bottom: 2,
                  }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      page: 1,
                      sortFields: "freebiesTotal",
                      sortDirections: "DESC",
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text fontWeight={600} fontSize={16}>
                (บาท)
              </Text>
            </Col>
          </Row>
        </>
      ),
      dataIndex: "freebiesTotal",
      key: "freebiesTotal",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text fontFamily='Sarabun' fontSize={16}>
              {row.freebiesTotal > 0 ? numberFormatter(row.freebiesTotal, 2) : "-"}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text fontWeight={600} fontSize={14}>
          เพิ่มเติม
        </Text>
      ),
      dataIndex: "promotionId",
      key: "promotionId",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <ActionBtn
              onClick={() => navigate(`/PromotionPage/promotion/detail/${row.promotionId}`)}
              icon={<UnorderedListOutlined />}
            />
          ),
        };
      },
    },
  ];

  return (
    <CardContainer>
      <PageTitle />
      <br />
      <Tabs
        items={tabsItems}
        onChange={(key: string) => {
          setStatusFilter(key === "ALL" ? undefined : key);
          setFilter({
            ...filter,
            page: 1,
          });
        }}
      />
      <Table
        tableLayout='fixed'
        scroll={{ x: "max-content" }}
        dataSource={data?.resultPromotion}
        columns={column}
        pagination={{
          pageSize: 10,
          current: filter.page,
          total: data?.count,
          showSizeChanger: false,
          position: ["bottomCenter"],
          onChange: (page) => {
            setFilter({
              ...filter,
              page,
            });
          },
        }}
        size='large'
      />
    </CardContainer>
  );
};
