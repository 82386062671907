import { Col, Divider, Row, Upload, Form, Modal, Radio, Space, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import Text from "../../../components/Text/Text";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import {
  getAllLocation,
  getDistrict,
  getProvince,
  getSubDistrict,
} from "../../../datasource/LocationDatasource";
import {
  getMasterFactoryById,
  patchMasterFactory,
  postMasterFactory,
} from "../../../datasource/FactoryDatasource";
import styled from "styled-components";

export const AntSelectCustom = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    font-family: Sarabun !important;
  }
  .ant-select-selection-placeholder {
    font-family: Sarabun !important;
  }
  .ant-select-selection-search-input {
    font-weight: 400 !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    font-family: Sarabun !important;
  }

  .ant-select-selection-item {
    border-radius: 4px;
    font-family: Sarabun !important;
    font-weight: 400 !important;
    display: flex;
    align-items: center;
  }
`;
export const CreateFactory: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isEdit = pathSplit[3] !== "create";
  const id = pathSplit[3];
  const [isErr, setIsErr] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [visibleWarning, setVisibleWarning] = React.useState(false);
  const [postcode, setPostcode] = useState<any>();
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [searchProvince, setSearchProvince] = useState<any>();
  const [searchDistrict, setSearchDistrict] = useState<any>();
  const [searchSubDistrict, setSearchSubDistrict] = useState<any>();
  const [province, setProvince] = useState<any[]>([]);
  const [district, setDistrict] = useState<any[]>([]);
  const [subdistrict, setSubDistrict] = useState<any[]>([]);
  const [provinceName, setProvinceName] = useState<string>("");
  const [districtName, setDistrictName] = useState<string>("");
  const [subdistrictName, setSubDistrictName] = useState<string>("");

  useEffect(() => {
    if (isErr) {
      form.validateFields(["factory"]);
    }
  }, [isErr, form]);

  useEffect(() => {
    const getProductGroupId = async () => {
      await getMasterFactoryById(id).then((res) => {
        if (isEdit) {
          setSearchProvince(parseInt(res.provinceId));
          setSearchDistrict(parseInt(res.districtId));
          setSearchSubDistrict(parseInt(res.subDistrictId));
          setPostcode(res.postcode);
          setProvinceName(res.province);
          setDistrictName(res.district);
          setSubDistrictName(res.subDistrict);
          form.setFieldsValue({
            provinceId: res.province,
            districtId: res.district,
            subDistrictId: res.subDistrict,
            postcode: res.postcode,
          });
        }

        form.setFieldsValue({
          factoryId: res.factoryId,
          factoryName: res.factoryName,
          status: res.status,
          locationCode: res.locationCode,
          address: res.address,
        });
      });
    };
    getProductGroupId();
  }, []);

  useEffect(() => {
    const fetchProvince = async () => {
      await getProvince().then((res) => {
        setProvince(res || []);
      });
    };
    fetchProvince();
  }, []);
  useEffect(() => {
    fetchDistrict(searchProvince);
  }, [searchProvince]);

  const fetchDistrict = async (e: any) => {
    await getDistrict(e).then((res) => {
      if (res) {
        setDistrict(res);
      } else {
        setDistrict([]);
        form.setFieldsValue({
          districtId: undefined,
          subDistrictId: undefined,
          postcode: undefined,
        });
      }
    });
  };
  useEffect(() => {
    fetchSubDistrict(searchDistrict);
  }, [searchDistrict]);

  const fetchSubDistrict = async (e: any) => {
    await getSubDistrict(searchDistrict).then((res) => {
      if (res) {
        setSubDistrict(res);
      } else {
        setSubDistrict([]);
        form.setFieldsValue({
          subDistrictId: undefined,
          postcode: undefined,
        });
      }
    });
  };
  const onsubmit = () => {
    setShowModal(true);
  };

  const onSaveFactory = async () => {
    const payload = form.getFieldsValue(true);
    const { factoryName, locationCode, address, provinceId, districtId, subDistrictId } = payload;
    const createdBy = `${userProfile.firstname} ${userProfile.lastname}`;
    const postData = {
      factoryName,
      locationCode,
      address,
      province: provinceName,
      district: districtName,
      subDistrict: subdistrictName,
      provinceId: provinceId.toString(),
      districtId: districtId.toString(),
      subDistrictId: subDistrictId.toString(),
      company: userProfile?.company,
      postcode: postcode,
      createdBy,
    };

    const putData = {
      id,
      ...postData,
      status: payload.status,
      updatedBy: createdBy,
    };
    try {
      let response;
      if (isEdit) {
        response = await patchMasterFactory(putData);
      } else {
        response = await postMasterFactory(postData);
      }
      if (response) {
        setShowModal(false);
        setTimeout(() => {
          navigate(-1);
        }, 500);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <CardContainer>
      <PageTitleNested
        title={isEdit ? "แก้ไขโรงงาน" : "เพิ่มโรงงาน"}
        showBack
        onBack={() => navigate(-1)}
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการโรงงาน", path: `/generalSettings/factorySetting` },
              {
                text: isEdit ? "แก้ไขโรงงาน" : "เพิ่มโรงงาน",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
      <Divider />
      <Form form={form} layout='vertical' onFinish={() => onsubmit()}>
        <Row gutter={16} className='pb-4'>
          <Col span={12}>
            <Form.Item
              name='factoryName'
              label='ชื่อโรงงาน'
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุชื่อโรงงาน",
                },
              ]}
            >
              <Input
                placeholder='ระบุชื่อโรงงาน'
                autoComplete='off'
                onChange={() => setIsErr(false)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='locationCode' label='รหัสโรงงาน'>
              <Input
                placeholder='ระบุรหัสโรงงาน'
                autoComplete='off'
                onChange={() => setIsErr(false)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Text level={5} fontWeight={700}>
          ที่อยู่โรงงาน
        </Text>
        <Row gutter={16} className='pb-4 pt-2'>
          <Col span={12}>
            <Form.Item
              name='address'
              label='ที่อยู่ (เลขที่ หมู่ ซอย ชั้น อาคาร)'
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุที่อยู่",
                },
              ]}
            >
              <Input
                placeholder='ระบุที่อยู่'
                autoComplete='off'
                onChange={() => setIsErr(false)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              name='provinceId'
              label='จังหวัด'
              rules={[
                {
                  required: true,
                  message: "กรุณาเลือกจังหวัด",
                },
              ]}
            >
              <AntSelectCustom
                showSearch
                allowClear
                options={
                  province?.map((x: any) => ({
                    key: x.provinceName,
                    value: x.provinceId,
                    label: x.provinceName,
                  })) || []
                }
                value={searchProvince}
                placeholder='เลือกจังหวัด'
                onChange={(e) => {
                  if (searchProvince !== e) {
                    form.setFieldsValue({
                      districtId: undefined,
                      subDistrictId: undefined,
                      postcode: undefined,
                    });
                    fetchDistrict(e);
                  } else {
                    setSearchProvince(e);
                  }
                  const provName = province.filter((item) => item?.provinceId === e);
                  setProvinceName(provName[0]?.provinceName);
                  setSearchProvince(e);
                }}
                optionFilterProp='children'
                filterOption={(input: any, option: any) => {
                  return (option?.key ?? "").includes(input);
                }}
                filterSort={(optionA: any, optionB: any) => {
                  return (optionA?.key ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.key ?? "").toLowerCase());
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='districtId'
              label='อำเภอ / เขต'
              rules={[
                {
                  required: true,
                  message: "กรุณาเลือกอำเภอ / เขต",
                },
              ]}
            >
              <AntSelectCustom
                showSearch
                allowClear
                options={
                  district?.map((x: any) => ({
                    key: x.districtName,
                    value: x.districtId,
                    label: x.districtName,
                  })) || []
                }
                value={searchDistrict}
                placeholder='เลือกอำเภอ'
                onChange={(e) => {
                  if (searchDistrict !== e) {
                    form.setFieldsValue({
                      subDistrictId: undefined,
                      postcode: undefined,
                    });
                    fetchSubDistrict(e);
                  } else {
                    setSearchDistrict(e);
                  }
                  const distName = district.filter((item) => item?.districtId === e);
                  setDistrictName(distName[0]?.districtName);
                  setSearchDistrict(e);
                }}
                disabled={!searchProvince}
                optionFilterProp='children'
                filterOption={(input: any, option: any) => {
                  return (option?.key ?? "").includes(input);
                }}
                filterSort={(optionA: any, optionB: any) => {
                  return (optionA?.key ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.key ?? "").toLowerCase());
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='subDistrictId'
              label='ตำบล / แขวง'
              rules={[
                {
                  required: true,
                  message: "กรุณาเลือกตำบล / แขวง",
                },
              ]}
            >
              <AntSelectCustom
                showSearch
                allowClear
                options={
                  subdistrict?.map((x: any) => ({
                    key: x.subDistrictName,
                    value: x.subDistrictId,
                    label: x.subDistrictName,
                  })) || []
                }
                value={searchSubDistrict}
                placeholder='เลือกตำบล'
                onChange={(value) => {
                  const selectedSubDistrict = subdistrict.find((x) => x.subDistrictId === value);
                  setSubDistrictName(selectedSubDistrict?.subDistrictName);
                  if (selectedSubDistrict) {
                    setPostcode(selectedSubDistrict.postcode);
                    form.setFieldsValue({
                      postcode: selectedSubDistrict.postcode,
                    });
                  } else {
                    setPostcode(undefined);
                    form.setFieldsValue({
                      postcode: undefined,
                    });
                  }
                }}
                disabled={!searchProvince || !searchDistrict}
                optionFilterProp='children'
                filterOption={(input: any, option: any) => {
                  return (option?.key ?? "").includes(input);
                }}
                filterSort={(optionA: any, optionB: any) => {
                  return (optionA?.key ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.key ?? "").toLowerCase());
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='postcode' label='รหัสไปรษณีย์'>
              <Input
                disabled
                placeholder='รหัสไปรษณีย์'
                autoComplete='off'
                onChange={() => setIsErr(false)}
              />
            </Form.Item>
          </Col>
        </Row>
        {isEdit && (
          <Col span={10}>
            <Form.Item
              name='status'
              label='สถานะ'
              rules={[
                {
                  required: true,
                  message: "*โปรดระบุสถานะ",
                },
              ]}
            >
              <Radio.Group style={{ fontFamily: "IBM Plex Sans Thai" }}>
                <Space direction='vertical'>
                  <Radio value='ACTIVE'>เปิดใช้งาน</Radio>
                  <Radio value='INACTIVE'>ปิดใช้งาน</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
        <Divider />
        <Row justify='space-between' gutter={12}>
          <Col xl={3} sm={6}>
            <Button
              typeButton='primary-light'
              title='ยกเลิก'
              onClick={() => setVisibleWarning(!visibleWarning)}
            />
          </Col>
          <Col xl={15} sm={6}></Col>
          <Col xl={3} sm={6}>
            <Button typeButton='primary' title='บันทึก' htmlType='submit' />
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        visible={showModal}
        onConfirm={() => {
          onSaveFactory();
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        title={<Text level={2}>{isEdit ? "ยืนยันบันทึกโรงงาน" : "ยืนยันเพิ่มโรงงาน"}</Text>}
        desc={
          isEdit ? (
            <>
              <Text level={5} color='Text3'>
                โปรดตรวจสอบรายละเอียดโรงงานอีกครั้งก่อนกดยืนยัน
              </Text>
              <br />
              <Text level={5} color='Text3'>
                เพราะอาจส่งผลต่อการแสดงผลข้อมูลในระบบ
              </Text>
            </>
          ) : (
            <Text level={5} color='Text3'>
              โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน
            </Text>
          )
        }
      />
      <ConfirmModal
        visible={visibleWarning}
        onConfirm={() => {
          setVisibleWarning(false);
        }}
        onCancel={() => {
          setVisibleWarning(false);
        }}
        title='ยกเลิกการแก้ไขโรงงาน'
        desc='โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน'
      />
    </CardContainer>
  );
};
