export enum CampaignObjective {
  REBATE = "REBATE",
}

export enum CampaignProgramType {
  ONLY_DEALER = "ONLY_DEALER",
  ONLY_SUB_DEALER = "ONLY_SUB_DEALER",
}

export enum CampaignProgramCondition {
  DELIVERY_SUCCESS = "DELIVERY_SUCCESS",
  OPEN_ORDER = "DELIVERY_SUCCESS",
  SUBMIT_DELIVERY_DOC = "SUBMIT_DELIVERY_DOC",
}

export enum CampaignSearchStatus {
  ALL = "ALL",
  ONLY_ENABLED = "ONLY_ENABLED",
  ONLY_DISABLED = "ONLY_DISABLED",
}

export const campaignProgramTypeLabel = {
  ONLY_DEALER: "ให้ Dealer เท่านั้น",
  ONLY_SUB_DEALER: "ให้ Sub-Dealer เท่านั้น",
};

export const campaignProgramConditionLabel = {
  DELIVERY_SUCCESS: "ขึ้นของสำเร็จ",
  OPEN_ORDER: "กดรับสินค้า",
  SUBMIT_DELIVERY_DOC: "ส่งหลักฐานใบขน",
};

export enum DealerType {
  DEALER = "DEALER",
  SUB_DEALER = "SUB_DEALER",
}

export const dealerTypeName = {
  DEALER: "Dealer",
  SUB_DEALER: "Sub-Dealer",
};

export enum HistoryCampaignType {
  DRAFT = "DRAFT",
  WAIT_APPROVE = "WAIT_APPROVE",
  REJECT = "REJECT",
  APPROVE = "APPROVE",
  CANCEL = "CANCEL",
}

export const historyCampaignTypeLabel = {
  DRAFT: "รออนุมัติ",
  WAIT_APPROVE: "รออนุมัติ",
  REJECT: "ไม่อนุมัติ",
  APPROVE: "อนุมัติแต้ม",
  CANCEL: "ยกเลิกแต้ม",
};
