import React from "react";
import styled from "styled-components";
import { color } from "../../resource";

const BaseText = styled.div<{ bgColor: string }>`
  font-family: "IBM Plex Sans Thai";
  color: ${color.white};
  font-size: 14px;
  font-weight: 700;
  background-color: ${(props) => props.bgColor};
  border-radius: 3px;
  padding: 0px 10px;
  width: max-content;
  height: 26px;
  display: flex;
  align-items: center;
`;

interface Props {
  label: string;
  bgColor: string;
}

function StatusBadge({ label, bgColor }: Props) {
  return <BaseText bgColor={bgColor}>{label}</BaseText>;
}

export default StatusBadge;
