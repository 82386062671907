import React from "react";
import Text from "../Text/Text";
import color from "../../resource/color";
import { InfoCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
interface Props {
  title: string;
  period: string;
  amount: React.ReactNode;
  icon?: any;
  tooltip?: boolean;
  handleModal?: () => void;
}

const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
`;

function CardStatistics({ title, period, amount, icon, tooltip = false, handleModal }: Props) {
  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        width: "100%",
        height: "138px",
        padding: "20px",
        borderRadius: "12px",
        border: `0.5px solid ${color.Disable}`,
      }}
    >
      <div style={{ display: "flex", alignItems: "normal" }}>
        <Text fontSize={18} fontWeight={700}>
          {icon && <img src={icon} style={{ width: "20px", height: "20px", marginRight: 6 }} />}
          {title}
        </Text>
        {tooltip && (
          <InfoIconWrapper>
            <InfoCircleFilled
              style={{ fontSize: "16px", color: "#464E5F" }}
              onClick={handleModal}
            />
          </InfoIconWrapper>
        )}
      </div>
      <Text fontSize={14} fontWeight={400}>
        {period}
      </Text>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {amount === "0" || amount === "0.00" ? (
          <Text fontSize={16} fontWeight={400} style={{ marginTop: 20 }}>
            ไม่พบข้อมูล
          </Text>
        ) : (
          <Text fontSize={40} fontWeight={700}>
            {amount}
          </Text>
        )}
      </div>
    </div>
  );
}

export default CardStatistics;
