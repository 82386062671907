import { Col, Divider, message, Row, Upload, Form, Modal, Radio, Space, Image } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RcFile } from "antd/lib/upload";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import { getBase64 } from "../../../utility/uploadHelper";
import { ImageWithDeleteButton, UploadIcon } from "../../../components/Image/Image";
import Text from "../../../components/Text/Text";
import { color } from "../../../resource";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import {
  getMasterProductCateById,
  patchMasterProductCate,
  postMasterProductCate,
} from "../../../datasource/ProductDatasource";

const UploadVertical = styled(Upload)`
  .ant-upload,
  .ant-upload-list-picture-card-container,
  .ant-upload-picture-card-wrapper,
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 136px;
    width: 136px;
  }
`;
const UploadArea = styled.div`
  background: ${color.background1};
  border: 1px dashed;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

export const CreateProdCategory: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isEdit = pathSplit[3] !== "create";
  const id = pathSplit[3];
  const [imageIsErr, setImageIsErr] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [isErr, setIsErr] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [file, setFile] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [visibleWarning, setVisibleWarning] = React.useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);

  const getById = async () => {
    const data = await getMasterProductCateById(id).then((res) => res);
    if (data.productCategoryImage) {
      setFile({
        uid: "-1",
        name: "image.png",
        status: "done",
        url: data.productCategoryImage,
      });
      setImageUrl(data.productCategoryImage);
    }
    form.setFieldsValue({
      productCategoryId: data.productCategoryId,
      productCategoryName: data.productCategoryName,
      status: data.status,
      productCategoryImage: data.productCategoryImage,
    });
  };
  useEffect(() => {
    if (isEdit) {
      getById();
    } else {
      form.setFieldsValue({
        productBrandId: "",
        isActive: true,
      });
    }
  }, []);

  useEffect(() => {
    if (isErr) {
      form.validateFields(["productCategoryName"]);
    }
  }, [isErr, form]);

  const onsubmit = () => {
    if (!file) {
      setImageIsErr(true);
    } else {
      setImageIsErr(false);
      setShowModal(true);
    }
  };

  const onSaveProductCate = async () => {
    try {
      const payload = form.getFieldsValue(true);
      const data = new FormData();
      data.append("productCategoryName", payload.productCategoryName);
      if (isEdit) {
        data.append("updatedBy", `${userProfile.firstname} ${userProfile.lastname}`);
      } else {
        data.append("createdBy", `${userProfile.firstname} ${userProfile.lastname}`);
      }

      data.append("company", userProfile.company);
      data.append("status", payload.status);

      if (file && file.uid !== "-1") {
        data.append("productCategoryImage", file);
      }

      const apiCall = isEdit ? patchMasterProductCate(id, data) : postMasterProductCate(data);
      const res = await apiCall;
      if (res) {
        setShowModal(false);
        setTimeout(() => {
          navigate(-1);
        }, 500);
      } else {
        setIsErr(true);
        setErrMessage(res.developerMessage);
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error while saving product category:", error);
      setIsErr(true);
      setErrMessage("An error occurred while saving the product category.");
      setShowModal(false);
    }
  };

  const getRules = () => {
    const rules = [
      {
        required: true,
        message: "กรุณาระบุชื่อหมวดสินค้า",
      },
      isErr
        ? {
            validator: async (_, value) => {
              if (isErr) {
                throw new Error(errMessage);
              }
            },
          }
        : {},
    ];
    return rules;
  };

  return (
    <CardContainer>
      <PageTitleNested
        title={isEdit ? "แก้ไขหมวดสินค้า" : "เพิ่มหมวดสินค้า"}
        showBack
        onBack={() => navigate(-1)}
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการหมวดสินค้า", path: `/generalSettings/prodCategory` },
              {
                text: isEdit ? "แก้ไขหมวดสินค้า" : "เพิ่มหมวดสินค้า",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
      <Divider />
      <Form form={form} layout='vertical' onFinish={() => onsubmit()}>
        <Col span={24}>
          <Row>
            <Col span={3}>
              {!imageUrl ? (
                <UploadVertical
                  listType='picture-card'
                  maxCount={1}
                  beforeUpload={(file: any) => {
                    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
                    if (!isJpgOrPng) {
                      message.error("You can only upload JPG/PNG file!");
                      return true;
                    }
                    setFile(file);
                    getBase64(file as RcFile, (url) => {
                      setImageUrl(url);
                    });
                    return false;
                  }}
                  onRemove={() => {
                    setFile(undefined);
                  }}
                  showUploadList={false}
                >
                  <UploadArea
                    style={{
                      width: "136px",
                      height: "136px",
                    }}
                  >
                    {UploadIcon}
                  </UploadArea>
                </UploadVertical>
              ) : (
                <div
                  style={{
                    width: "138px",
                    height: "138px",
                    overflow: "hidden",
                    border: `1px dashed ${color.Disable}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ImageWithDeleteButton
                    width='138px'
                    height='60px'
                    src={imageUrl}
                    isHorizontal={true}
                    handleDelete={() => {
                      setFile(undefined);
                      setImageUrl(undefined);
                    }}
                  />
                </div>
              )}
            </Col>

            <Col span={21}>
              <Text level={6}>รูปภาพหมวดสินค้า</Text>
              <br />
              <Text level={6} color='Text3'>
                JPG or PNG. Size of
                <br />
                60*180px
              </Text>
            </Col>
          </Row>

          {imageIsErr && (
            <Text level={6} color='error'>
              กรุณาอัพโหลดรูปภาพหมวดสินค้า
            </Text>
          )}
        </Col>
        <br />
        <Col span={10}>
          <Form.Item
            name='productCategoryName'
            label='ชื่อหมวดสินค้า (Product Category)'
            rules={getRules()}
          >
            <Input
              placeholder='ระบุชื่อหมวดสินค้า'
              autoComplete='off'
              onChange={() => setIsErr(false)}
            />
          </Form.Item>
        </Col>
        {isEdit && (
          <Col span={10}>
            <Form.Item
              name='status'
              label='สถานะ'
              rules={[
                {
                  required: true,
                  message: "*โปรดระบุสถานะ",
                },
              ]}
            >
              <Radio.Group style={{ fontFamily: "IBM Plex Sans Thai" }}>
                <Space direction='vertical'>
                  <Radio value='ACTIVE'>เปิดใช้งาน</Radio>
                  <Radio value='INACTIVE'>ปิดใช้งาน</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
        <Divider />
        <Row justify='space-between' gutter={12}>
          <Col xl={3} sm={6}>
            <Button
              typeButton='primary-light'
              title='ยกเลิก'
              onClick={() => setVisibleWarning(!visibleWarning)}
            />
          </Col>
          <Col xl={15} sm={6}></Col>
          <Col xl={3} sm={6}>
            <Button typeButton='primary' title='บันทึก' htmlType='submit' />
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        visible={showModal}
        onConfirm={() => {
          onSaveProductCate();
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        title={<Text level={2}>{isEdit ? "ยืนยันบันทึกหมวดสินค้า" : "ยืนยันเพิ่มหมวดสินค้า"}</Text>}
        desc={
          isEdit ? (
            <>
              <Text level={5} color='Text3'>
                โปรดตรวจสอบรายละเอียดหมวดสินค้าอีกครั้งก่อนกดยืนยัน
              </Text>
              <br />
              <Text level={5} color='Text3'>
                เพราะอาจส่งผลต่อการแสดงผลข้อมูลในระบบ
              </Text>
            </>
          ) : (
            <Text level={5} color='Text3'>
              โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน
            </Text>
          )
        }
      />
      <ConfirmModal
        visible={visibleWarning}
        onConfirm={() => {
          setVisibleWarning(false);
        }}
        onCancel={() => {
          setVisibleWarning(false);
        }}
        title='ยกเลิกการแก้ไขหมวดสินค้า'
        desc='โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน'
      />
    </CardContainer>
  );
};
