import { AxiosResponse } from "axios";
import { DEV_DNDS_URL, httpClient } from "../config/develop-config";

export const getProvince = async () => {
  return await httpClient
    .get(`${DEV_DNDS_URL}/distance/location/province`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export const getDistrict = async (id: string) => {
  return await httpClient
    .get(`${DEV_DNDS_URL}/distance/location/district/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export const getSubDistrict = async (id: string) => {
  return await httpClient
    .get(`${DEV_DNDS_URL}/distance/location/subdistrict/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
export const getAllLocation = async (
  subdistrictId: string,
  districtId: string,
  provinceId: string,
) => {
  return await httpClient
    .get(`${DEV_DNDS_URL}/distance/location/address/${subdistrictId}/${districtId}/${provinceId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
