export interface MaintenanceSystem {
  header: string;
  text: string;
  footer: string;
  dateStart: string;
  dateEnd: string;
  dateNotiStart: string;
  dateNotiEnd: string;
  imagePath: string;
  status: string;
  typeService: string;
  createdAt: string;
  updatedAt: string;
  textDate: string;
}
export const MaintenanceSystem_INIT: MaintenanceSystem = {
  header: "",
  text: "",
  footer: "",
  dateStart: "",
  dateEnd: "",
  dateNotiStart: "",
  dateNotiEnd: "",
  imagePath: "",
  status: "",
  typeService: "",
  createdAt: "",
  updatedAt: "",
  textDate: "",
};
