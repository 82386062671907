import { atom } from "recoil";

export enum tabId {
  store = "store",
  product = "product",
}

interface CreateCampaignState {
  condition?: string;
  pointData?: any;
  isLaunched?: boolean;
}

interface CreateCampaignStateStep2 {
  activeKeys: string | string[];
  activeTabs: tabId[];
  errorStoreTabs: boolean[];
  errorProductTabs: boolean[];
}

export const campaignState = atom<CreateCampaignState>({
  key: "createCampaign",
  default: {
    condition: "-",
    pointData: null,
    isLaunched: false,
  },
});

export const step2State = atom<CreateCampaignStateStep2>({
  key: "createCampaignStep2",
  default: {
    activeKeys: [],
    activeTabs: [],
    errorStoreTabs: [],
    errorProductTabs: [],
  },
});
