import React, { useEffect, useState } from "react";
import PageTitleNested from "../../../../components/PageTitle/PageTitleNested";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexCol } from "../../../../components/Container/Container";
import Text from "../../../../components/Text/Text";
import BreadCrumb from "../../../../components/BreadCrumb/BreadCrumb";
import { CardContainer } from "../../../../components/Card/CardContainer";
import Button from "../../../../components/Button/Button";
import { DownloadOutlined } from "@ant-design/icons";
import ParticipateCampaign from "./ParticipateCampaign";
import { Divider, Tabs } from "antd";
import TabListTable from "./TabListTable";
import { campaignShop, campaignShopDetail } from "../../../../datasource/CampaignDatasource";
import {
  CampaignShopEntities,
  CampaignShopEntities_INIT,
  OrderEntities,
  ProductEntities,
  ProgramEntities,
} from "../../../../entities/CampaignShopEntity";
import useSorting from "../../../../hook/useSorting";

function CRMShopDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState<"ORDER" | "PRODUCT" | "PROGRAM">("ORDER");
  const location = useLocation();
  const { row, campaignId } = location.state || {};
  const [participateCampData, setParticipateCampData] =
    useState<CampaignShopEntities>(CampaignShopEntities_INIT);

  useEffect(() => {
    const fetchCampShopData = async () => {
      setLoading(true);
      try {
        await campaignShop(campaignId, row?.shopType, row?.shopId).then((res) => {
          setParticipateCampData(res);
          setLoading(false);
        });
      } catch (error) {
        console.log("err crm/campaign/campaign-shop", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampShopData();
  }, []);

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={`ร้านค้า : ${participateCampData.shopName}`}
        showBack
        onBack={() => navigate(-1)}
        // extra={
        //   <FlexCol align='end'>
        //     <Button title='ดาวน์โหลด' icon={<DownloadOutlined />} typeButton='primary-light' />
        //   </FlexCol>
        // }
        customBreadCrumb={
          <BreadCrumb
            data={[
              {
                text: "รายการแคมเปญพอยท์",
                path: "/crm/campaignPoint",
              },
              { text: "รายละเอียดแคมเปญพอยท์", path: -1 },
              { text: "รายละเอียดร้านค้า", path: -1 },
            ]}
          />
        }
      />
    );
  };
  const tabsData: { key: string; label: React.ReactNode; children?: JSX.Element | undefined }[] = [
    {
      key: "ORDER",
      label: (
        <Text fontSize={14} fontWeight={400}>
          รายการคำสั่งซื้อ
        </Text>
      ),
    },
    {
      key: "PROGRAM",
      label: (
        <Text fontSize={14} fontWeight={400}>
          พอยท์โปรแกรม
        </Text>
      ),
    },
    {
      key: "PRODUCT",
      label: (
        <Text fontSize={14} fontWeight={400}>
          ยอดสินค้าที่สั่งซื้อ
        </Text>
      ),
    },
  ];
  const handleTabChange = (key: "ORDER" | "PRODUCT" | "PROGRAM") => {
    setTabs(key);
  };

  return (
    <CardContainer>
      <PageTitle />
      <Divider style={{ marginTop: 50 }} />
      <ParticipateCampaign type={row?.shopType} data={participateCampData} loading={loading} />
      <Tabs items={tabsData} onChange={(e: any) => handleTabChange(e)} />
      <TabListTable
        tabs={tabs}
        type={row?.shopType}
        campaignId={campaignId}
        shopType={row?.shopType}
        shopId={row?.shopId}
      />
    </CardContainer>
  );
}

export default CRMShopDetail;
