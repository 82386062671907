import { SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Col, Row, Table, Tag } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import { FlexCol } from "../../components/Container/Container";
import { RangePicker } from "../../components/DatePicker/DatePicker";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import Text from "../../components/Text/Text";
import { getShipmentOrder } from "../../datasource/ShipmentOrderDatasource";
import { zoneDatasource } from "../../datasource/ZoneDatasource";
import { MAPPING_SHIPMENT_STATUS_COLOR, shipmentStatus } from "../../definitions/shipmentStatus";
import color from "../../resource/color";
import dayjs from "dayjs";

export const IndexReceiveGoods: React.FC = () => {
  const userCompany = JSON.parse(localStorage.getItem("company")!);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    searchText: "",
    sortDirections: "DESC",
    sortFields: "postingDate",
    page: 1,
    take: 10,
    zone: undefined,
    receiveStatus: undefined,
    startDate: "",
    endDate: "",
  });

  const [data, setData] = useState<{ count: number; data: [] }>();
  const [zone, setZone] = useState<any>();

  const getShipment = async () => {
    await getShipmentOrder({
      company: userCompany.companyCode,
      page: filter.page,
      take: filter.take,
      sortDirection: filter.sortDirections,
      sortField: filter.sortFields,
      zone: filter.zone,
      receiveStatus: filter.receiveStatus,
      searchText: filter.searchText,
      startDate: filter.startDate,
      endDate: filter.endDate,
      isBO: true,
    }).then((res) => {
      setData(res);
    });
  };

  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(userCompany.companyCode);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZone(data);
  };

  useEffect(() => {
    getShipment();
    getZoneByCompany();
  }, [filter]);

  const columns = [
    {
      title: "เลขที่ใบขน (PDO)",
      dataIndex: "pdoNo",
      key: "pdoNo",
      render: (value: any, row: any, index: number) => {
        return (
          <FlexCol>
            <Text level={5}>{value || "-"}</Text>
            <Text level={6} color='Text3'>
              {moment(row.postingDate).format("DD/MM/YYYY") || "-"}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "เลขที่ใบสั่งขาย (SO No.)",
      dataIndex: "documentNo",
      key: "documentNo",
      width: "15%",
      render: (value: any, row: any, index: number) => {
        return (
          <FlexCol>
            <Text level={5}>{value || "-"}</Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "shipToName",
      key: "shipToName",
      width: "35%",
      render: (value: any, row: any, index: number) => {
        return (
          <FlexCol>
            <Text level={5}>{value || "-"}</Text>
            <Text level={6} color='Text3'>
              {row.zone || "-"} | {row.shipToCounty}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "รายการสินค้า",
      dataIndex: "qty",
      key: "qty",
      render: (value: any, row: any, index: number) => {
        return (
          <FlexCol>
            <Text level={5}>{row?.shipmentOrderDetail?.length || "-"}</Text>
            <Text level={6} color='Text3'>
              รายการ
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "สถานะ",
      dataIndex: "receiveStatus",
      key: "receiveStatus",
      render: (value: any, row: any, index: number) => {
        const mapStatus: any = {
          CONFIRM_RECEIVED: "ยืนยันแล้ว",
          WAIT_RECEIVE: "รอยืนยัน",
          CANCELED: "ยกเลิก",
        };
        return (
          <FlexCol>
            <Tag color={MAPPING_SHIPMENT_STATUS_COLOR[value]}>
              <Text level={6} color='white'>
                {mapStatus[value]}
              </Text>
            </Tag>
            {value !== "WAIT_RECEIVE" && (
              <Text level={6} color='Text3'>
                {moment(value === "CONFIRM_RECEIVED" ? row.receiveDate : row.cancelDate).format(
                  "DD/MM/YYYY HH:mm",
                )}
              </Text>
            )}
          </FlexCol>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        return (
          <div className='d-flex flex-row justify-content-between'>
            <div
              className='btn btn-icon btn-light btn-hover-primary btn-sm'
              onClick={() => navigate("/ReceiveGoods/" + row.shipmentOrderId)}
            >
              <span className='svg-icon svg-icon-primary svg-icon-2x'>
                <UnorderedListOutlined style={{ color: color["primary"] }} />
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  const PageTitle = () => {
    return (
      <Row align='middle' gutter={16}>
        <Col className='gutter-row' span={7}>
          <div>
            <span
              className='card-label font-weight-bolder text-dark'
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              รายการรับสินค้า
            </span>
          </div>
        </Col>
        <Col span={3}>
          <Select
            data={zone || []}
            value={filter.zone}
            style={{ width: "100%" }}
            placeholder='เขตทั้งหมด'
            maxTagCount='responsive'
            showArrow
            onChange={(e) => {
              setFilter({
                ...filter,
                page: 1,
                zone: e,
              });
            }}
          />
        </Col>
        <Col span={4}>
          <Select
            data={shipmentStatus || []}
            value={filter.receiveStatus}
            style={{ width: "100%" }}
            placeholder='สถานะทั้งหมด'
            maxTagCount='responsive'
            showArrow
            onChange={(e) => {
              setFilter({
                ...filter,
                page: 1,
                receiveStatus: e,
              });
            }}
          />
        </Col>
        <Col span={5}>
          <Input
            placeholder='เลขที่ใบขน/ใบสั่งขาย/ชื่อร้านค้า'
            suffix={<SearchOutlined />}
            defaultValue={filter.searchText}
            onPressEnter={(e) => {
              const value = (e.target as HTMLTextAreaElement).value;
              setFilter({
                ...filter,
                page: 1,
                searchText: value,
              });
            }}
          />
        </Col>
        <Col span={5}>
          <RangePicker
            enablePast
            allowEmpty={[true, true]}
            value={!filter.startDate ? undefined : [dayjs(filter.startDate), dayjs(filter.endDate)]}
            onChange={(date) => {
              if (date) {
                setFilter({
                  ...filter,
                  page: 1,
                  startDate: moment(date[0]?.$d).format(),
                  endDate: moment(date[1]?.$d).format(),
                });
              } else {
                setFilter({
                  ...filter,
                  page: 1,
                  startDate: "",
                  endDate: "",
                });
              }
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <CardContainer>
      <PageTitle />
      <br />
      <br />
      <Table
        className='rounded-lg'
        columns={columns}
        dataSource={data?.data || []}
        pagination={{
          pageSize: 10,
          current: filter.page,
          total: data?.count,
          showSizeChanger: false,
          onChange: (page) => {
            setFilter({
              ...filter,
              page,
            });
          },
          position: ["bottomCenter"],
        }}
        size='large'
        tableLayout='fixed'
      />
    </CardContainer>
  );
};
