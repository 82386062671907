import React from "react";
import styled from "styled-components";
import Text from "../../components/Text/Text";
import { ReactSVG } from "react-svg";
import { defaultPropsForm } from "../../utility/DefaultProps";
import { Form } from "antd";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import color from "../../resource/color";
import { useNavigate } from "react-router-dom";
import { AuthDatasource } from "../../datasource/AuthDatasource";
import Swal from "sweetalert2";
import image from "../../resource/image";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  background-color: ${color.background1};
`;
const CardStyled = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  background-color: #ffffff;
  border-radius: 12px;
  height: fit-content;
  margin-top: 6%;
`;
const Footer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 32px;
  justify-content: center;
`;
function ResetPasswordAddMailPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values: { email: string }) => {
    await AuthDatasource.resetPassword({
      email: values.email,
    })
      .then((result) => {
        if (result?.developerMessage === "user not exists") {
          form.setFields([
            {
              name: "email",
              errors: ["ไม่มีอีเมลผู้ใช้ดังกล่าวในระบบ กรุณาตรวจสอบใหม่อีกครั้ง"],
              value: form.getFieldValue("email"),
            },
          ]);
        } else {
          navigate("/reset-password/sent-success", {
            state: { email: values.email },
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "ตั้งค่ารหัสผ่านไม่สำเร็จ",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  const onClickBackToLogin = () => {
    navigate("/");
  };

  return (
    <Container>
      <CardStyled>
        {/* <ReactSVG
          onError={(error) => console.log(error)}
          src={`${process.env.PUBLIC_URL}/logo_sellcoda.svg`}
        /> */}
        <img src={image.sellcodaLogo} style={{ marginBottom: 12 }} />

        <Text
          fontSize={24}
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          fontWeight={700}
        >
          ตั้งค่ารหัสผ่านใหม่
        </Text>
        <Text
          fontSize={14}
          style={{
            marginBottom: 16,
          }}
        >
          ระบุอีเมลที่ท่านต้องการตั้งค่ารหัสผ่าน
        </Text>
        <Form {...defaultPropsForm} style={{ width: "100%" }} onFinish={onFinish} form={form}>
          <Form.Item
            label='อีเมล'
            name='email'
            rules={[
              {
                required: true,
                message: "กรุณากรอกอีเมล",
              },
              {
                type: "email",
                message: "รูปแบบอีเมลไม่ถูกต้อง",
              },
            ]}
          >
            <Input placeholder='name@example.com' />
          </Form.Item>
          <Button
            htmlType='submit'
            title='ยืนยัน'
            style={{
              width: "100%",
              marginTop: 32,
            }}
          />
          <Footer>
            <Text fontSize={12} fontFamily='Sarabun'>
              กลับไปหน้า
            </Text>
            <Text color='primary' fontSize={12} onClick={onClickBackToLogin} fontFamily='Sarabun'>
              ล็อกอินเข้าสู่ระบบ
            </Text>
          </Footer>
        </Form>
      </CardStyled>
    </Container>
  );
}

export default ResetPasswordAddMailPage;
