export interface programEntities {
  countOrder: number;
  sumQuantity: number;
  sumPointCancel: number;
  shopParticipate: number;
  sumPointApprove: number;
  sumPointWaiting: number;
  campaignProgramId: string;
  shopParticipateAll: number;
  campaignProgramName: string;
  campaignProgramType: string;
  campaignProgramCondition: string;
}
export const programEntities_INIT : programEntities = {
  countOrder: 0,
  sumQuantity: 0,
  sumPointCancel: 0,
  shopParticipate: 0,
  sumPointApprove: 0,
  sumPointWaiting: 0,
  campaignProgramId: "",
  shopParticipateAll: 0,
  campaignProgramName: "",
  campaignProgramType: "",
  campaignProgramCondition: "",
}
export interface crmPointCampaignEntities {
  campaignId: string;
  campaignCode: string;
  campaignName: string;
  company: string;
  startDate: string;
  endDate: string;
  shopParticipate: number;
  countOrder: number;
  sumQuantity: number;
  sumPointWaiting: number;
  sumPointApprove: number;
  sumPointCancel: number;
  program: programEntities[];
}
export const crmPointCampaignEntities_INIT : crmPointCampaignEntities = {
  campaignId: "",
  campaignCode: "",
  campaignName: "",
  company: "",
  startDate: "",
  endDate: "",
  shopParticipate: 0,
  countOrder: 0,
  sumQuantity: 0,
  sumPointWaiting: 0,
  sumPointApprove: 0,
  sumPointCancel: 0,
  program: [programEntities_INIT],
}
export interface allPointCampaignEntities {
  count: number;
  campaignList: crmPointCampaignEntities[];
}
export const allPointCampaignEntities_INIT : allPointCampaignEntities =  {
  count: 0,
  campaignList: [crmPointCampaignEntities_INIT],
}
export interface programDetailEntities {
  campaignId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  campaignProgramId: string;
  campaignProgramName: string;
  campaignProgramType: string;
  campaignProgramCondition: string;
  countShop: number;
}
export const programDetailEntities_INIT: programDetailEntities = {
  campaignId: "",
  campaignName: "",
  startDate: "",
  endDate: "",
  campaignProgramId: "",
  campaignProgramName: "",
  campaignProgramType: "",
  campaignProgramCondition: "",
  countShop: 0,
};
export interface summary {
  participate: number;
  noParticipate: number;
}
export interface shopParticipateEntities {
  cusComId: number;
  cusComNo: string;
  cusComName: string;
  cusComZone: string;
  countOrder: number;
  sumQuantity: number;
  sumPoint: number;
}
export interface allShopParticipateEntities {
  summary: summary;
  data: shopParticipateEntities[];
}
