import { Col, Divider, Row, Form, Radio, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import Text from "../../../components/Text/Text";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import {
  getMasterUnitById,
  patchMasterUnit,
  postMasterUnit,
} from "../../../datasource/UnitMeaDatasource";

export const CreateUnit: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isEdit = pathSplit[3] !== "create";
  const id = pathSplit[3];
  const [errMessage, setErrMessage] = useState<string>("");
  const [isErr, setIsErr] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [visibleWarning, setVisibleWarning] = React.useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  useEffect(() => {
    if (isErr) {
      form.validateFields(["unit"]);
    }
  }, [isErr, form]);
  useEffect(() => {
    const getProductGroupId = async () => {
      await getMasterUnitById(id).then((res) => {
        form.setFieldsValue({
          unitMeasureId: res.unitMeasureId,
          unitMeasureName: res.unitMeasureName,
          status: res.isActive,
        });
      });
    };
    getProductGroupId();
  }, []);

  const onSaveProductUnit = async () => {
    const payload = form.getFieldsValue(true);
    const submitData = {
      unitMeasureName: payload?.unitMeasureName,
      createdBy: `${userProfile.firstname} ${userProfile.lastname}`,
      company: userProfile.company,
    };
    const submitDataHaveId = {
      id: id,
      unitMeasureName: payload?.unitMeasureName,
      isActive: payload?.status,
      updatedBy: `${userProfile.firstname} ${userProfile.lastname}`,
    };

    try {
      let res;
      if (isEdit) {
        res = await patchMasterUnit(submitDataHaveId);
      } else {
        res = await postMasterUnit(submitData);
      }

      if (res) {
        setShowModal(false);
        setTimeout(() => {
          navigate(-1);
        }, 500);
      } else {
        setErrMessage(res.developerMessage);
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrMessage("An error occurred while saving the unit.");
      setShowModal(false);
    }
  };

  const onsubmit = () => {
    setShowModal(true);
  };

  const getRules = () => {
    const rules = [
      {
        required: true,
        message: "กรุณาระบุชื่อหน่วยสินค้า",
      },
      isErr
        ? {
            validator: async (_, value) => {
              if (isErr) {
                throw new Error(errMessage);
              }
            },
          }
        : {},
    ];
    return rules;
  };

  return (
    <CardContainer>
      <PageTitleNested
        title={isEdit ? "แก้ไขหน่วยสินค้า" : "เพิ่มหน่วยสินค้า"}
        showBack
        onBack={() => navigate(-1)}
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการหน่วยสินค้า", path: `/generalSettings/productGroup` },
              {
                text: isEdit ? "แก้ไขหน่วยสินค้า" : "เพิ่มหน่วยสินค้า",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
      <Divider />
      <Form form={form} layout='vertical' onFinish={() => onsubmit()}>
        <Col span={10}>
          <Form.Item name='unitMeasureName' label='ชื่อหน่วยสินค้า' rules={getRules()}>
            <Input
              placeholder='ระบุชื่อหน่วยสินค้า'
              autoComplete='off'
              onChange={() => setIsErr(false)}
            />
          </Form.Item>
        </Col>
        {isEdit && (
          <Col span={10}>
            <Form.Item
              name='status'
              label='สถานะ'
              rules={[
                {
                  required: true,
                  message: "*โปรดระบุสถานะ",
                },
              ]}
            >
              <Radio.Group style={{ fontFamily: "IBM Plex Sans Thai" }}>
                <Space direction='vertical'>
                  <Radio value={true}>เปิดใช้งาน</Radio>
                  <Radio value={false}>ปิดใช้งาน</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
        <Divider />
        <Row justify='space-between' gutter={12}>
          <Col xl={3} sm={6}>
            <Button
              typeButton='primary-light'
              title='ยกเลิก'
              onClick={() => setVisibleWarning(!visibleWarning)}
            />
          </Col>
          <Col xl={15} sm={6}></Col>
          <Col xl={3} sm={6}>
            <Button typeButton='primary' title='บันทึก' htmlType='submit' />
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        visible={showModal}
        onConfirm={() => {
          onSaveProductUnit();
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        title={
          <Text level={2}>{isEdit ? "ยืนยันบันทึกหน่วยสินค้า" : "ยืนยันเพิ่มหน่วยสินค้า"}</Text>
        }
        desc={
          isEdit ? (
            <>
              <Text level={5} color='Text3'>
                โปรดตรวจสอบรายละเอียดหน่วยสินค้าอีกครั้งก่อนกดยืนยัน
              </Text>
              <br />
              <Text level={5} color='Text3'>
                เพราะอาจส่งผลต่อการแสดงผลข้อมูลในระบบ
              </Text>
            </>
          ) : (
            <Text level={5} color='Text3'>
              โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน
            </Text>
          )
        }
      />
      <ConfirmModal
        visible={visibleWarning}
        onConfirm={() => {
          setVisibleWarning(false);
        }}
        onCancel={() => {
          setVisibleWarning(false);
        }}
        title='ยกเลิกการแก้ไขหน่วยสินค้า'
        desc='โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน'
      />
    </CardContainer>
  );
};
