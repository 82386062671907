import axios from "axios";
import { convertBuddhistYear } from "../utility/Formatter";
import moment from "moment";

export const NAV_DEV_URL = "https://api-dev-sellcoda.iconkaset.com/nav";
export const DEV_DNDS_URL = "https://api-dnds-dev.iconkaset.com";

export const NAV_URL =
  process.env.REACT_APP_NODE_ENV === "development" ? NAV_DEV_URL : process.env.REACT_APP_NAV_URL_HOST;
export const DEV_URL = "https://sellcoda-api-dev.iconkaset.com";
export const BASE_URL =
  process.env.REACT_APP_NODE_ENV === "development" ? DEV_URL : process.env.REACT_APP_URL_HOST;
export const DEV_ICONKASET_URL = "https://iconkasetshop-api-dev.iconkaset.com";
export const BASE_ICONKASET_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? DEV_ICONKASET_URL
    : process.env.REACT_APP_ICONKASET_URL_HOST;

export const BASE_DNDS_URL =
  process.env.REACT_APP_NODE_ENV === "development" ? DEV_DNDS_URL : process.env.REACT_APP_DNDS_URL_HOST;

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token") || "";

    const newConfig = {
      ...config,
      headers: {
        accept: "application/json",
        Authorization: token ? `Bearer ` + JSON.parse(token) : "",
      },
    };

    return newConfig;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      const url = window.location.href;
      const arr = url.split("/");
      const resultUrlHost = arr[0] + "//" + arr[2];

      window.location.href =
        "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
        resultUrlHost;
    }
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      const url = window.location.href;
      const arr = url.split("/");
      const resultUrlHost = arr[0] + "//" + arr[2];

      window.location.href =
        "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
        resultUrlHost;
    }
    return error;
  },
);

axios.interceptors.request.use(async (config: any) => {
  const start: any = localStorage.getItem("dateStart");
  const end: any = localStorage.getItem("dateEnd");

  const dateNow = convertBuddhistYear.toBuddhistYear(
    moment(new Date()),
    "DD MMMM YYYY ช่วงเวลา HH:mm  ",
  );

  if (dateNow >= start && dateNow <= end) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  }
  const token = JSON.parse(localStorage.getItem("token") || "{  }");
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export const httpClient = axios;

export const intanceAuth = axios.create({
  baseURL: BASE_URL,
});
