import { Col, Divider, Row, Upload, Form, Radio, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CardContainer } from "../../../components/Card/CardContainer";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import Text from "../../../components/Text/Text";
import { color } from "../../../resource";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import {
  getProductGroupById,
  patchProductGroup,
  postProductGroup,
} from "../../../datasource/ProductDatasource";

export const CreateProductGroup: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const navigate = useNavigate();
  const [form] = useForm();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isEdit = pathSplit[3] !== "create";
  const id = pathSplit[3];
  const [errMessage, setErrMessage] = useState<string>("");
  const [isErr, setIserr] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [visibleWarning, setVisibleWarning] = React.useState(false);

  useEffect(() => {
    if (isErr) {
      form.validateFields(["productGroupName"]);
    }
  }, [isErr, form]);

  useEffect(() => {
    const getProductGroupId = async () => {
      await getProductGroupById(id).then((res) => {
        form.setFieldsValue({
          productGroupId: res.productGroupId,
          productGroupName: res.productGroupName,
          status: res.status,
        });
      });
    };
    getProductGroupId();
  }, []);

  const onSaveProductGroup = async () => {
    const payload = form.getFieldsValue(true);
    const submitData = isEdit
      ? {
          ...payload,
          id,
          updatedBy: `${userProfile.firstname} ${userProfile.lastname}`,
        }
      : {
          ...payload,
          createdBy: `${userProfile.firstname} ${userProfile.lastname}`,
          company: userProfile.company,
        };

    try {
      const res = isEdit ? await patchProductGroup(submitData) : await postProductGroup(submitData);
      if (res) {
        setShowModal(false);
        setTimeout(() => navigate(-1), 500);
      } else {
        setErrMessage(res.developerMessage);
        setShowModal(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrMessage("An error occurred while saving the product group.");
      setShowModal(false);
    }
  };

  const getRules = () => {
    const rules = [
      {
        required: true,
        message: "กรุณาระบุชื่อกลุ่มสินค้า",
      },
      isErr
        ? {
            validator: async (_, value) => {
              if (isErr) {
                throw new Error(errMessage);
              }
            },
          }
        : {},
    ];
    return rules;
  };

  return (
    <CardContainer>
      <PageTitleNested
        title={isEdit ? "แก้ไขกลุ่มสินค้า" : "เพิ่มกลุ่มสินค้า"}
        showBack
        onBack={() => navigate(-1)}
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการกลุ่มสินค้า", path: `/generalSettings/productGroup` },
              {
                text: isEdit ? "แก้ไขกลุ่มสินค้า" : "เพิ่มกลุ่มสินค้า",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
      <Divider />
      <Form form={form} layout='vertical' onFinish={() => setShowModal(true)}>
        <Col span={10}>
          <Form.Item
            name='productGroupName'
            label='ชื่อกลุ่มสินค้า (Product Group)'
            rules={getRules()}
          >
            <Input
              placeholder='ระบุชื่อกลุ่มสินค้า'
              autoComplete='off'
              onChange={() => setIserr(false)}
            />
          </Form.Item>
        </Col>
        {isEdit && (
          <Col span={10}>
            <Form.Item
              name='status'
              label='สถานะ'
              rules={[
                {
                  required: true,
                  message: "*โปรดระบุสถานะ",
                },
              ]}
            >
              <Radio.Group style={{ fontFamily: "IBM Plex Sans Thai" }}>
                <Space direction='vertical'>
                  <Radio value='ACTIVE'>เปิดใช้งาน</Radio>
                  <Radio value='INACTIVE'>ปิดใช้งาน</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
        <Divider />
        <Row justify='space-between' gutter={12}>
          <Col xl={3} sm={6}>
            <Button
              typeButton='primary-light'
              title='ยกเลิก'
              onClick={() => setVisibleWarning(!visibleWarning)}
            />
          </Col>
          <Col xl={15} sm={6}></Col>
          <Col xl={3} sm={6}>
            <Button typeButton='primary' title='บันทึก' htmlType='submit' />
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        visible={showModal}
        onConfirm={() => {
          onSaveProductGroup();
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        title={
          <Text level={2}>{isEdit ? "ยืนยันบันทึกกลุ่มสินค้า" : "ยืนยันเพิ่มกลุ่มสินค้า"}</Text>
        }
        desc={
          isEdit ? (
            <>
              <Text level={5} color='Text3'>
                โปรดตรวจสอบรายละเอียดกลุ่มสินค้าอีกครั้งก่อนกดยืนยัน
              </Text>
              <br />
              <Text level={5} color='Text3'>
                เพราะอาจส่งผลต่อการแสดงผลข้อมูลในระบบ
              </Text>
            </>
          ) : (
            <Text level={5} color='Text3'>
              โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน
            </Text>
          )
        }
      />
      <ConfirmModal
        visible={visibleWarning}
        onConfirm={() => {
          setVisibleWarning(false);
        }}
        onCancel={() => {
          setVisibleWarning(false);
        }}
        title='ยกเลิกการแก้ไขกลุ่มสินค้า'
        desc='โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน'
      />
    </CardContainer>
  );
};
