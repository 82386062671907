import {
  CheckCircleTwoTone,
  EditOutlined,
  InfoCircleFilled,
  PlusOutlined,
  SearchOutlined,
  ShopOutlined,
  SyncOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Col, Modal, Row, Table, Tag, Form, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { CardContainer } from "../../../components/Card/CardContainer";
import SearchInput from "../../../components/Input/SearchInput";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Select from "../../../components/Select/Select";
import color from "../../../resource/color";
import Text from "../../../components/Text/Text";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "../../../components/Input/Input";
import styled from "styled-components";
import { checkTaxNo, getCusCorporate } from "../../../datasource/CustomerDatasource";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import { checkCompany } from "../../../utility/CompanyName";
import { shopDatasource } from "../../../datasource/ShopDatasource";
import { FlexCol } from "../../../components/Container/Container";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../../store/RoleAtom";
import { checkPermission } from "../../../components/Permission/Permission";
import { getPartnerDealer, getPartnerSubdealer } from "../../../datasource/PartnerDatasource";

const Header = styled(Col)`
  border-radius: 8px;
  background-color: ${color.background1};
  padding: 20px;
  //display: flex;
  //gap: 16px;
  align-items: center;
  width: "100%";
`;

const Image = styled.img`
  height: 52px;
  padding: 16px 8px;
  background-color: white;
  border-radius: 8px;
`;

function IndexCorporateShop(): JSX.Element {
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("profile")!);
  const company = JSON.parse(localStorage.getItem("company")!);

  const roleData = useRecoilValue(roleAtom);
  const isView = checkPermission(["corporateShop", "view"], roleData);
  const isUser = checkPermission(["corporateShop", "manageUser"], roleData);
  const isSync = checkPermission(["corporateShop", "sync"], roleData);
  const isSubDealer = checkPermission(["corporateShop", "subdealer"], roleData);

  const [searchParams, setSearchParams] = useSearchParams();
  const isInternal = checkCompany(company.companyCode);
  const [form] = Form.useForm();
  const [dataState, setDataState] = useState<{
    count: number;
    data: any[];
    count_customer_type: any[];
  }>({
    count: 0,
    count_customer_type: [],
    data: [],
  });
  const pageSize = 8;
  const [page, setPage] = useState<string>(searchParams.get("page") || "1");
  const [search, setSearch] = useState<string>(searchParams.get("search") || "");
  const [searchZone, setSearchZone] = useState<string>(searchParams.get("searchZone") || "");
  const [isActive, setIsActive] = useState(searchParams.get("isActive") || "");
  const [cusComId, setCusComId] = useState<string>("");
  const [dataCusCom, setDataCusCom] = useState<any>();
  const [cusComLength, setCusComLength] = useState<number>(0);
  const [statusFilter, setStatusFilter] = useState<string>(
    searchParams.get("statusFilter") || "ALL",
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [checkTaxId, setCheckTaxId] = useState<boolean>(false);
  const [cusId, setCusId] = useState<any>("");
  const [isCreating, setIsCreating] = React.useState(false);
  const [zone, setZone] = useState<any>([]);

  const [modalParner, setModalParner] = useState<boolean>(false);
  const [dataParner, setDataParner] = useState<any>();

  const setParams = () => {
    setSearchParams({
      isActive,
      page,
      searchZone,
      search,
      statusFilter,
    });
  };
  const getCusList = async () => {
    await getCusCorporate({
      page: page,
      take: pageSize,
      companyCode: `${company.companyCode}`,
      search,
      sortField: "updateDate",
      sortDirection: "DESC",
      zone: searchZone,
      isActive,
      customerType: statusFilter === "ALL" ? undefined : statusFilter,
    }).then((res) => {
      setDataState({
        count: res.count_total || 0,
        data: res.data,
        count_customer_type: res.count_customer_type,
      });
    });
  };
  const getZone = async () => {
    await zoneDatasource.getAllZoneByCompany(company?.companyCode).then((res) => {
      setZone(res);
    });
  };

  useEffect(() => {
    setParams();
    getCusList();
    getZone();
  }, [search, page, searchZone, isActive, statusFilter]);

  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };
  const tabsItems = [
    {
      label: `ทั้งหมด (${
        dataState?.count_customer_type?.reduce(
          (acc: number, cur: any) => acc + parseInt(cur.count),
          0,
        ) || 0
      })`,
      key: "ALL",
    },
    {
      label: `Dealer (${
        (dataState?.count_customer_type?.find((c: any) => c.customer_type === "DL") as any)
          ?.count || 0
      })`,
      key: "DL",
    },
    {
      label: `Sub-Dealer (${
        (dataState?.count_customer_type?.find((c: any) => c.customer_type === "SD") as any)
          ?.count || 0
      })`,
      key: "SD",
    },
  ];
  const columns: any = [
    {
      title: "รหัสร้านค้า",
      dataIndex: "customerNo",
      key: "customerNo",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{value}</Text>,
        };
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "customerName",
      key: "customerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                {row?.customer?.taxNo}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "ชื่อเจ้าของร้าน",
      dataIndex: "userName",
      key: "userName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>
                {row.customer.ownerFirstname || "-"} {row.customer.ownerLastname}
              </Text>
              <br />
              <Text color='Text3'>{row.customer.telephone || "-"}</Text>
            </>
          ),
        };
      },
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{row.customer.province}</Text>,
        };
      },
    },
    {
      title: "ประเภทคู่ค้า",
      dataIndex: "partner",
      key: "partner",
      render: (value: any, row: any, index: number) => {
        const mapType = {
          DL: "Dealer",
          SD: "Sub Dealer",
        };
        return {
          children: (
            <>
              <Text level={6}>{mapType[row.customerType]}</Text>{" "}
              <InfoCircleFilled
                style={{ fontSize: "18px", cursor: "pointer" }}
                onClick={() => {
                  setModalParner(!modalParner);
                  getParner(row.customerCompanyId, row.customerType);
                }}
              />
              <br />
              <Text level={6} color='Text3'>
                {row?.zone !== "undefined" ? row.zone || "-" : ""}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Tag color={value ? color.success : color.error}>
              {value ? "เปิดใช้งาน" : "ปิดใช้งาน"}
            </Tag>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      //width: "12%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify='start' gutter={8}>
              {isView && (
                <ActionBtn
                  onClick={() =>
                    navigate(`/ShopManagementPage/detailCorporateShop/${row.customerCompanyId}`)
                  }
                  icon={<UnorderedListOutlined />}
                />
              )}
              {isUser && (
                <ActionBtn
                  onClick={() =>
                    navigate(
                      `/ShopManagementPage/userCorporateShop/${row.customerId}/${row.customerCompanyId}`,
                    )
                  }
                  icon={<TeamOutlined />}
                />
              )}
              {isInternal && isSync && row.customerType === "DL" && (
                <ActionBtn
                  onClick={() => syncByCustomerCode(row.customerNo)}
                  icon={<SyncOutlined />}
                />
              )}
            </Row>
          ),
        };
      },
    },
  ];
  const syncByCustomerCode = async (cusCode: string) => {
    const updateBy = profile?.firstname + " " + profile?.lastname;
    setIsCreating(true);
    await shopDatasource.syncCustomerTel(cusCode, company?.companyCode, updateBy).then((res) => {
      if (res.success) {
        setTimeout(() => {
          setIsCreating(false);
          getCusList();
        }, 1000);
      }
    });
  };
  const onSyncCustomer = async () => {
    const updateBy = profile?.firstname + " " + profile?.lastname;
    Modal.confirm({
      title: "ยืนยันการเชื่อมต่อ Navision",
      onOk: async () => {
        await shopDatasource
          .syncAllCustomer(company?.companyCode, updateBy)
          .then((res) => {
            setIsCreating(true);
            setTimeout(() => {
              setIsCreating(false);
              getCusList();
            }, 1000);
          })
          .catch((err) => console.log("err", err))
          .finally(() => console.log("sync customer done"));
      },
    });
  };
  const checkTax = async (e: string) => {
    const payload = await checkTaxNo(e).then((res) => {
      localStorage.setItem("taxNo", e);
      let isCreate: any = "";
      if (res.responseData) {
        setCusId(
          res.responseData.customerCompany.length > 0
            ? res.responseData.customerCompany[0].customerCompanyId
            : "0",
        );
        const cusCom = res?.responseData?.customerCompany?.filter(
          (c) => c?.company === company.companyCode,
        );
        if (cusCom.length) {
          setCusComId(cusCom[0].customerCompanyId);
          setCusComLength(cusCom.length);
          setDataCusCom(res?.responseData);
        }
        isCreate = cusCom.length ? true : false;
      } else {
        setCusId("0");
        isCreate = false;
      }
      return isCreate;
    });
    return payload;
  };
  const getParner = async (id: string, type: string) => {
    if (type === "DL") {
      await getPartnerDealer(id).then((res) => {
        setDataParner({ data: res, type: "DL" });
      });
    } else {
      await getPartnerSubdealer(id).then((res) => {
        setDataParner({ data: res, type: "SD" });
      });
    }
  };
  const PageTitle = () => {
    return (
      <>
        <Row align='middle' gutter={16} justify='start'>
          <Col className='gutter-row' span={5}>
            <div>
              <span
                className='card-label font-weight-bolder text-dark'
                style={{ fontSize: 20, fontWeight: "bold" }}
              >
                รายชื่อร้านค้า
              </span>
            </div>
          </Col>
          <Col span={19}>
            <Row align='middle' gutter={16} justify='end'>
              <Col span={7}>
                <Input
                  defaultValue={search || searchParams.get("search") || ""}
                  onPressEnter={(e: any) => {
                    setSearch(e.nativeEvent.target.value);
                    setPage("1");
                    setParams();
                  }}
                  placeholder='รหัสร้านค้า/ชื่อร้านค้า/เลขนิติบุคคล'
                  suffix={<SearchOutlined />}
                />
              </Col>
              <Col span={4}>
                <Select
                  value={searchParams.get("searchZone") || undefined}
                  allowClear
                  placeholder='เขตทั้งหมด'
                  data={
                    zone.map((z) => ({
                      label: z.zoneName,
                      key: z.zoneId,
                      value: z.zoneName,
                    })) || []
                  }
                  style={{
                    width: "100%",
                    fontFamily: "Sarabun",
                  }}
                  onChange={(e) => {
                    if (e) {
                      setSearchZone(e);
                    } else {
                      setSearchZone("");
                    }
                    setPage(searchParams.get("page") || "1");
                    setParams();
                  }}
                />
              </Col>
              <Col span={4}>
                <Select
                  allowClear
                  placeholder='สถานะทั้งหมด'
                  data={[
                    { key: true, label: "เปิดใช้งาน", value: true },
                    { key: false, label: "ปิดใช้งาน", value: false },
                  ]}
                  style={{
                    width: "100%",
                    fontFamily: "Sarabun",
                  }}
                  onChange={(e) => {
                    setIsActive(e);
                    setPage(searchParams.get("page") || "1");
                    setParams();
                  }}
                />
              </Col>
              {isSync && (
                <Col>
                  <Button
                    onClick={() => onSyncCustomer()}
                    title='อัปเดทร้านค้าใหม่'
                    typeButton='primary'
                    icon={<SyncOutlined style={{ color: color.white }} />}
                  />
                </Col>
              )}
              {isSubDealer && (
                <Col>
                  <Button
                    onClick={() => setShowModal(!showModal)}
                    title='เพิ่ม Sub-Dealer'
                    typeButton='primary'
                    icon={<PlusOutlined style={{ color: color.white }} />}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <CardContainer>
        <PageTitle />
        <br />
        <Tabs
          defaultValue={statusFilter}
          items={tabsItems}
          onChange={(key: string) => {
            setStatusFilter(key);
            setPage("1");
          }}
        />
        <Table
          scroll={{
            x: "max-content",
          }}
          dataSource={dataState.data || []}
          columns={columns || []}
          pagination={{
            position: ["bottomCenter"],
            current: Number(page),
            total: dataState?.count || 0,
            pageSize: pageSize,
            onChange: (page) => {
              setPage(String(page));
              setParams();
            },
            showSizeChanger: false,
          }}
        />
      </CardContainer>
      <Modal open={isCreating} footer={null} width={220} closable={false}>
        <FlexCol align='space-around' justify='center' style={{ width: 172, height: 172 }}>
          <CheckCircleTwoTone twoToneColor={color.success} style={{ fontSize: 36 }} />
          <br />
          <Text level={4} align='center'>
            Sync ข้อมูลสำเร็จ
          </Text>
        </FlexCol>
      </Modal>
      {showModal && (
        <Modal
          open={showModal}
          closable={false}
          title={
            <Text fontWeight={600} fontSize={20}>
              เพิ่มร้านค้า Sub-Dealer
            </Text>
          }
          centered
          onCancel={() => {
            setCusComId("0");
            setCusComLength(0);
            setDataCusCom(undefined);
            form.setFieldValue("taxNo", "");
            setShowModal(false);
          }}
          destroyOnClose
          cancelText={"ยกเลิก"}
          okText={!dataCusCom ? "ยืนยัน" : cusComLength === 1 && "แก้ไขร้านค้า"}
          okButtonProps={{
            style: {
              color: color.white,
              borderColor: checkTaxId ? color.primary : color.Disable,
              backgroundColor: checkTaxId ? color.primary : color.Disable,
            },
          }}
          onOk={() =>
            cusComId
              ? navigate(`/ShopManagementPage/createCorporateShop/${cusComId}/edit`)
              : navigate(`/ShopManagementPage/createCorporateShop/create/${cusId}`)
          }
          cancelButtonProps={{
            style: { color: color.primary, borderColor: color.primary },
          }}
        >
          <Form form={form}>
            <Text fontWeight={600}>หมายเลขประจำตัวผู้เสียภาษี (ร้านค้า)</Text>
            <Row>
              <Col span={24}>
                <Form.Item
                  name='taxNo'
                  style={{
                    width: "100%",
                    marginBottom: 0,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอกเลขประจำตัวผู้เสียภาษี",
                    },
                    {
                      pattern: /^[0-9]{13}$/,
                      message: "กรุณากรอกเลขประจำตัวผู้เสียภาษีให้ถูกต้อง",
                    },
                    {
                      async validator(_, value) {
                        if (value.length === 13) {
                          const tax = await checkTax(value);
                          setCheckTaxId(true);
                          return tax
                            ? Promise.reject("ร้านค้าที่ต้องการเพิ่มมีข้อมูลแล้วในระบบ")
                            : Promise.resolve();
                        } else {
                          setDataCusCom(undefined);
                          setCheckTaxId(false);
                          return Promise.reject();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    prefix={<SearchOutlined style={{ color: color.Disable }} />}
                    placeholder='ระบุหมายเลขประจำตัวผู้เสียภาษี'
                    autoComplete='off'
                    maxLength={13}
                  />
                </Form.Item>
              </Col>
              {dataCusCom && (
                <>
                  {dataCusCom?.customerCompany
                    ?.filter((x) => x.company === company.companyCode)
                    .map((item, index) => (
                      <Col span={24} key={index} style={{ padding: "3px" }}>
                        <Header style={{ width: "100%" }}>
                          <Row justify={"start"} gutter={8}>
                            <Col>
                              <ShopOutlined style={{ fontSize: "20px" }} />
                            </Col>
                            <Col span={17}>
                              <Text fontSize={16} fontWeight={700}>
                                {item?.customerName}
                              </Text>
                            </Col>
                            {cusComLength > 1 && (
                              <Col>
                                <Text
                                  fontSize={14}
                                  fontWeight={400}
                                  color='primary'
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/ShopManagementPage/createCorporateShop/${item.customerCompanyId}/edit`,
                                    )
                                  }
                                >
                                  <EditOutlined />
                                  แก้ไขร้านค้า
                                </Text>
                              </Col>
                            )}
                          </Row>
                          <Row justify={"start"} gutter={8}>
                            <Col span={12}>
                              <Text fontSize={14} fontWeight={400}>
                                ประเภทคู่ค้า
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Text fontSize={14} fontWeight={400}>
                                หมายเลขนิติบุคคล
                              </Text>
                            </Col>
                          </Row>
                          <Row justify={"start"} gutter={8}>
                            <Col span={12}>
                              <Text fontSize={14} fontWeight={700}>
                                {item?.customerType === "DL" ? "Dealer" : "Sub-Delaer"}
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Text fontSize={14}>{dataCusCom?.taxNo}</Text>
                            </Col>
                          </Row>
                          <Row justify={"start"} gutter={8}>
                            <Col span={12}>
                              <Text fontSize={14} fontWeight={400}>
                                รหัสร้านค้า
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Text fontSize={14} fontWeight={400}>
                                เขต
                              </Text>
                            </Col>
                          </Row>
                          <Row justify={"start"} gutter={8}>
                            <Col span={12}>
                              <Text fontSize={14}>{item.customerNo || "-"}</Text>
                            </Col>
                            <Col span={12}>
                              <Text fontSize={14}>
                                {item?.zone || "-"}, {dataCusCom.province || "-"}
                              </Text>
                            </Col>
                          </Row>
                        </Header>
                      </Col>
                    ))}
                </>
              )}
            </Row>
          </Form>
        </Modal>
      )}
      {modalParner && (
        <Modal
          width={800}
          open={modalParner}
          closable={true}
          onCancel={() => setModalParner(!modalParner)}
          title={
            <>
              <Text fontWeight={600} fontSize={20}>
                คู่ค้ากับ {dataParner?.type === "DL" ? "Dealer" : "Sub-Dealer"}
              </Text>
              <br />
              <Text fontWeight={400} fontSize={16} color='Text3'>
                ทั้งหมด {dataParner?.data?.length} ร้านค้า
              </Text>
            </>
          }
          centered
          footer={false}
        >
          <Table
            pagination={false}
            dataSource={dataParner?.data?.map((p, key) => ({ ...p, key })) || []}
            columns={[
              {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{index + 1}</Text>,
                  };
                },
              },
              {
                title: "รหัสร้านค้า",
                dataIndex: "customer_no",
                key: "customer_no",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "ชื่อร้านค้า",
                dataIndex: "customer_name",
                key: "customer_name",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "เขต",
                dataIndex: "zone",
                key: "zone",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
              {
                title: "จังหวัด",
                dataIndex: "province",
                key: "province",
                render: (value: any, row: any, index: number) => {
                  return {
                    children: <Text>{value || "-"}</Text>,
                  };
                },
              },
            ]}
          />
        </Modal>
      )}
    </>
  );
}
export default IndexCorporateShop;
