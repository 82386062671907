export interface campaignProgramsEntities {
  campaignProgramId: string;
  campaignProgramName: string;
  campaignProgramType: string;
  campaignProgramCondition: string;
}
export const campaignProgramsEntities_INIT: campaignProgramsEntities = {
  campaignProgramId: "",
  campaignProgramName: "",
  campaignProgramType: "",
  campaignProgramCondition: "",
};
export interface statisticsGraphEntities {
  campaignId: string;
  campaignCode: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  objective: string;
  campaignPrograms: campaignProgramsEntities[];
}
export const statisticsGraphEntities_INIT: statisticsGraphEntities = {
  campaignId: "",
  campaignCode: "",
  campaignName: "",
  startDate: "",
  endDate: "",
  objective: "",
  campaignPrograms: [campaignProgramsEntities_INIT],
};
export interface findCampaignEntities {
  count: number;
  data: statisticsGraphEntities[];
}
export const findCampaignEntities_INIT: findCampaignEntities = {
  count: 0,
  data: [statisticsGraphEntities_INIT],
};
