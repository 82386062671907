import { Select as AntSelect, SelectProps, Tag } from "antd";
import React from "react";
import styled from "styled-components";
import Text from "../Text/Text";
import { CaretDownOutlined } from "@ant-design/icons";
import color from "../../resource/color";

const { Option } = AntSelect;

interface Props {
  data: {
    key: any;
    value?: any;
    label?: string | JSX.Element;
  }[];
  onChange?: (v: any) => void;
  setValue?: (value: any) => void;
  style?: React.CSSProperties;
  defaultValue?: string | number;
  value?: string | number | string[];
  disabled?: boolean;
  edit?: boolean;
  placeholder?: string;
  labelInValue?: boolean;
  onSearch?: (value: string) => void;
  filterOption?: boolean;
  notFoundContent?: JSX.Element | null;
  mode?: "multiple" | "tags" | undefined;
  allowClear?: boolean;
  showArrow?: boolean;
  maxTagCount?: number | "responsive";
  tagColor?: string;
}

export const AntSelectCustom = styled(AntSelect)`
  .ant-select-selector {
    height: 100% !important;
    width: 100% !important;
    min-height: 40px !important;
    display: flex;
    align-items: center;
    font-family: Sarabun !important;
  }
  .ant-select-selection-placeholder {
    font-family: Sarabun !important;
  }

  .ant-select-selection-item {
    border-radius: 4px;
    font-family: Sarabun !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;
const tagRender = (props: {
  label: React.ReactNode;
  value: any;
  onClose: () => void;
  selectedItems: any[];
  tagColor?: string;
}) => {
  const { label, onClose, selectedItems, tagColor } = props;
  const showCloseIcon = selectedItems.length > 1;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={tagColor}
      onMouseDown={onPreventMouseDown}
      closable={false}
      style={{
        marginInlineEnd: 4,
        borderRadius: "4px",
        padding: "4px 8px",
        backgroundColor: tagColor,
        color: "#fff",
      }}
    >
      {label}
      {showCloseIcon && (
        <span
          onClick={onClose}
          style={{
            marginLeft: "8px",
            cursor: "pointer",
            color: color.Text1,
            fontWeight: "bold",
          }}
        >
          ×
        </span>
      )}
    </Tag>
  );
};

function SelectMulti({
  data = [],
  onChange,
  setValue,
  defaultValue,
  value,
  disabled = false,
  placeholder = "กรุณาเลือก",
  mode,
  style,
  tagColor,
  ...props
}: Props): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const handleChange = (selectedItems: any) => {
    if (selectedItems.length === 0) {
      return;
    }
    onChange?.(selectedItems);
    setValue?.(selectedItems);
  };
  return (
    <AntSelectCustom
      {...props}
      onClick={() => {
        setOpen(!open);
      }}
      showSearch={false}
      dropdownStyle={{ fontFamily: "Sarabun" }}
      onChange={handleChange}
      style={{ ...style, width: "100%" }}
      placeholder={placeholder}
      suffixIcon={
        <CaretDownOutlined
          onClick={() => {
            setOpen(!open);
          }}
          style={{
            color: color.Text1,
            fontSize: "20px",
          }}
        />
      }
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      mode='multiple'
      onBlur={() => setOpen(false)}
      tagRender={(props) => tagRender({ ...props, selectedItems: value as any[], tagColor })}
    >
      {data.map((item) => {
        return (
          <Option key={item.key} value={item.value ? item.value : item.key}>
            <Text fontFamily='IBM Plex Sans Thai' fontWeight={400} fontSize={12}>
              {item.label}
            </Text>
          </Option>
        );
      })}
    </AntSelectCustom>
  );
}

export default SelectMulti;
