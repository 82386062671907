import React, { useEffect, useState } from "react";
import { Table, Tabs, Row, Col, Image, Tag, Modal, message } from "antd";
import { CardContainer } from "../../components/Card/CardContainer";
import {
  SearchOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { nameFormatter, numberFormatter } from "../../utility/Formatter";
import { FlexCol, FlexRow } from "../../components/Container/Container";
import Text from "../../components/Text/Text";
import { STATUS_COLOR_MAPPING } from "../../definitions/product";
import { ProductGroupEntity } from "../../entities/ProductGroupEntity";
import color from "../../resource/color";
import image from "../../resource/image";

type FixedType = "left" | "right" | boolean;
import * as _ from "lodash";
import {
  getProductFreebieGroup,
  getProductFreebies,
  syncProductFreebie,
} from "../../datasource/PromotionDatasource";
import { useNavigate } from "react-router-dom";
import Select from "../../components/Select/Select";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Permission, { checkPermission } from "../../components/Permission/Permission";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../store/RoleAtom";
import { ImportModal } from "../../components/Modal/ImportModal";
import { BASE_URL } from "../../config/develop-config";
import { importProductFreebies } from "../../datasource/ProductDatasource";
import PageTitle from "../../components/PageTitle/PageTitle";
import { checkCompany } from "../../utility/CompanyName";
const SLASH_DMY = "DD/MM/YYYY";

export const FreebieListPage: React.FC = () => {
  const style: React.CSSProperties = {
    width: "180px",
  };
  const mapStatus = {
    Active: "เปิดใช้งาน",
    Inactive: "ปิดใช้งาน",
  };

  const pageSize = 8;
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { firstname, lastname } = userProfile;
  const company = JSON.parse(localStorage.getItem("company")!);

  const roleData = useRecoilValue(roleAtom);
  const isView = checkPermission(["freebieList", "view"], roleData);
  const isEdit = checkPermission(["freebieList", "edit"], roleData);

  const navigate = useNavigate();

  const [keyword, setKeyword] = useState<string>();
  const [prodGroup, setProdGroup] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [changeTab, setChangeTab] = useState<boolean>(false);
  const [loadingSyncProduct, setLoadingSyncProduct] = useState(false);
  const [dataState, setDataState] = useState({
    count: 0,
    count_status: [],
    data: [],
    groups: [],
  });

  const [showImportModal, setImportModal] = useState<boolean>(false);
  const [importFile, setImportFile] = useState<any>();

  useEffect(() => {
    fetchProduct();
  }, [keyword, prodGroup, statusFilter, page]);

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const { data, count, count_status } = await getProductFreebies({
        company: company.companyCode,
        take: pageSize,
        productGroup: prodGroup,
        searchText: keyword,
        productFreebiesStatus: statusFilter,
        page,
      });
      const { responseData } = await getProductFreebieGroup(company.companyCode);
      if (changeTab) {
        setDataState({
          data,
          count,
          count_status,
          groups: responseData,
        });
        setLoading(false);
      } else {
        setTimeout(async () => {
          setDataState({
            data,
            count,
            count_status,
            groups: responseData,
          });
          setLoading(false);
        }, 800);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setChangeTab(false);
    }
  };

  const onSyncProduct = async () => {
    Modal.confirm({
      title: "ยืนยันการเชื่อมต่อ Navision",
      onOk: async () => {
        setLoadingSyncProduct(true);
        await syncProductFreebie({ company: company.companyCode })
          .then((res) => {
            const { success } = res;
            if (success) {
              navigate(0);
            } else {
              message.error("เชื่อมต่อ Navision ไม่สำเร็จ");
            }
          })
          .catch((err) => console.log("err", err))
          .finally(() => console.log("sync product done"));
      },
    });
  };

  const tabsItems = [
    {
      label: `ทั้งหมด(${
        dataState?.count_status?.reduce((prev, { count }) => prev + parseInt(count), 0) || 0
      })`,
      key: "ALL",
    },
    ...(dataState?.count_status?.map(({ product_freebies_status, count }) => ({
      label: mapStatus[nameFormatter(product_freebies_status)] + `(${count})`,
      key: product_freebies_status,
    })) || []),
  ];

  const columns = [
    {
      title: "ชื่อของแถม",
      dataIndex: "commonName",
      key: "commonName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexRow align='center'>
              <div style={{ marginRight: 16 }}>
                <Image
                  key={index}
                  src={row.productFreebiesImage || image.product_no_image}
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <FlexCol>
                <Text level={5}>{row.productName || "-"}</Text>
                <Text level={6} color='Text3'>
                  {row.productFreebiesCodeNAV || "-"}
                </Text>
              </FlexCol>
            </FlexRow>
          ),
        };
      },
    },
    // {
    //   title: "หน่วยสินค้า",
    //   dataIndex: "unit",
    //   key: "unit",
    //   render: (value: any, row: any, index: number) => {
    //     return {
    //       children: (
    //         <FlexCol key={index}>
    //           <Text level={5}>{row.baseUnitOfMeaEn || row.baseUnitOfMeaTh || "-"}</Text>
    //         </FlexCol>
    //       ),
    //     };
    //   },
    // },
    {
      title: "หมวด",
      dataIndex: "productGroup",
      key: "productGroup",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text level={5}>{value || "-"}</Text>
              <Text level={6} color='Text3'>
                {row.productStrategy || ""}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "จำนวนคงเหลือ",
      dataIndex: "remainQty",
      key: "remainQty",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text level={5}>
                {numberFormatter(value, 0) || "-"} {row.baseUnitOfMeaTh || ""}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "productFreebiesStatus",
      key: "productFreebiesStatus",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Tag color={STATUS_COLOR_MAPPING[value]}>
              {value === "ACTIVE" ? "เปิดใช้งาน" : "ปิดใช้งาน"}
            </Tag>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      width: "5%",
      fixed: "right" as FixedType | undefined,
      hidden: !isView && !isEdit,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div className='d-flex flex-row justify-content-between'>
                <div
                  className='btn btn-icon btn-light btn-hover-primary btn-sm'
                  onClick={() =>
                    company.companyCode === "ICPL" ||
                    company.companyCode === "ICPI" ||
                    company.companyCode === "ICPF"
                      ? navigate("/freebies/freebies/edit/" + row.productFreebiesId)
                      : navigate("/freebies/freebiesCorporate/" + row.productFreebiesId)
                  }
                >
                  <span className='svg-icon svg-icon-primary svg-icon-2x'>
                    <UnorderedListOutlined style={{ color: color["primary"] }} />
                  </span>
                </div>
              </div>
            </>
          ),
        };
      },
    },
  ].filter((item) => !item.hidden);

  return (
    <>
      <CardContainer>
        <PageTitle
          title='รายการของแถม'
          extra={
            <Row justify={"space-between"} gutter={16}>
              <Col>
                <Select
                  defaultValue={prodGroup}
                  style={style}
                  allowClear
                  onChange={(value: string) => {
                    setProdGroup(value);
                    setPage(1);
                    setChangeTab(true);
                  }}
                  placeholder='หมวดของแถมทั้งหมด'
                  data={dataState.groups.map((group: ProductGroupEntity) => ({
                    key: group.product_group,
                    label: group.product_group,
                    value: group.product_group,
                  }))}
                />
              </Col>
              <Col>
                <div style={style}>
                  <Input
                    placeholder='ค้นหาของแถม'
                    prefix={<SearchOutlined style={{ color: "grey" }} />}
                    defaultValue={keyword}
                    onPressEnter={(e) => {
                      const value = (e.target as HTMLTextAreaElement).value;
                      setKeyword(value);
                      setPage(1);
                    }}
                    onChange={(e) => {
                      const value = (e.target as HTMLTextAreaElement).value;
                      if (!value) {
                        setKeyword("");
                        setPage(1);
                        setChangeTab(true);
                      }
                    }}
                  />
                </div>
              </Col>
              {!checkCompany(company?.companyCode) && (
                <Col>
                  <Button
                    title='อัปโหลดไฟล์'
                    icon={<UploadOutlined style={{ color: color.white }} />}
                    onClick={() => setImportModal(!showImportModal)}
                  />
                </Col>
              )}
              {checkCompany(company?.companyCode) ? (
                <Permission permission={["freebieList", "sync"]}>
                  <Col>
                    <Button
                      title='เชื่อมต่อ Navision'
                      icon={<SyncOutlined />}
                      onClick={onSyncProduct}
                    />
                  </Col>
                </Permission>
              ) : (
                <Col>
                  <Button
                    title='เพิ่มของแถม'
                    icon={<PlusOutlined />}
                    onClick={() => navigate("/freebies/freebiesCorporate/create")}
                  />
                </Col>
              )}
            </Row>
          }
        />
        <br />
        <Tabs
          items={tabsItems}
          onChange={(key: string) => {
            setStatusFilter(key === "ALL" ? undefined : key);
            setChangeTab(true);
            setPage(1);
          }}
        />
        <Table
          className='rounded-lg'
          columns={
            checkCompany(company.companyCode)
              ? columns
              : columns.filter((x) => x.key !== "remainQty")
          }
          scroll={{ x: "max-content" }}
          dataSource={dataState?.data?.map((d: object, i) => ({ ...d, key: i }))}
          pagination={{
            position: ["bottomCenter"],
            pageSize,
            current: page,
            total: dataState?.count,
            onChange: (p) => {
              setPage(p);
              setChangeTab(true);
            },
            showSizeChanger: false,
          }}
          loading={loading}
          size='large'
          tableLayout='fixed'
        />

        <ImportModal
          open={showImportModal}
          toggleOpen={() => setImportModal(!showImportModal)}
          title='อัปโหลดไฟล์ของแถม .CSV'
          linkText='ดาวน์โหลดแบบฟอร์มอัปโหลดไฟล์สินค้า'
          uploadFile={importFile}
          setFile={setImportFile}
          urlTemplate={`${BASE_URL}/master/product-freebies/get-template-import-products`}
          // urlTemplate={"http://localhost:3009/product-freebies/get-template-import-products"}
          onUpload={async () => {
            const data = new FormData();
            data.append("company", company);
            data.append("updateBy", `${firstname} ${lastname}`);
            data.append("file", importFile.originFileObj);
            await importProductFreebies(data)
              .then((res) => {
                if (res.success) {
                  setImportModal(!showImportModal);
                  message.success("นำเข้าข้อมูลสำเร็จ");
                } else {
                  message.error("เกิดข้อผิดพลาด");
                }
              })
              .catch((err) => {
                message.error("เกิดข้อผิดพลาด");
                console.log(err);
              });
          }}
        />
      </CardContainer>
    </>
  );
};
