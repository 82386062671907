import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React from "react";
import color from "../../resource/color";

interface Props {
  title: string;
  sortDirection: string | undefined;
  onSortChange: () => void;
}

const SortFieldCustom: React.FC<Props> = ({ title, sortDirection, onSortChange }) => {
  return (
    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
      {title}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
        onClick={() => onSortChange()}
      >
        <CaretUpOutlined
          style={{
            position: "relative",
            top: 2,
            color: sortDirection === "ASC" ? color.Text1 : color.background4,
            fontSize: "smaller",
          }}
        />
        <CaretDownOutlined
          style={{
            position: "relative",
            bottom: 2,
            color: sortDirection === "DESC" ? color.Text1 : color.background4,
            fontSize: "smaller",
          }}
        />
      </div>
    </div>
  );
};

export default SortFieldCustom;
