import { Modal } from "antd";
import React, { useState } from "react";
import Text from "../Text/Text";
import Input from "../Input/Input";
import color from "../../resource/color";
import Button from "../Button/Button";
import { DateRangePicker } from "react-date-range";
import { th } from "date-fns/locale";
import moment from "moment";

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (date) => void;
}

function ModalCalendar({ visible, onCancel, onSubmit }: Props) {
  const initDate = new Date();

  const [dateRange, setDateRange] = useState({
    startDate: initDate,
    endDate: initDate,
    key: "selection",
  });

  const handleCalendar = (ranges) => {
    setDateRange(ranges.selection);
  };
  const handleSubmit = () => {
    onSubmit({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });

    setDateRange({
      startDate: initDate,
      endDate: initDate,
      key: "selection",
    });
  };
  const isSubmitDisabled = dateRange.startDate.getTime() === dateRange.endDate.getTime();

  return (
    <Modal
      title={
        <Text fontSize={16} fontWeight={600}>
          กำหนดช่วงเวลา
        </Text>
      }
      open={visible}
      width={420}
      onCancel={onCancel}
      onOk={handleSubmit}
      okButtonProps={{ disabled: isSubmitDisabled }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Input
            value={
              dateRange.startDate === dateRange.endDate
                ? ""
                : moment(dateRange.startDate).format("L")
            }
            disabled
            style={{
              fontFamily: "Sarabun",
              width: "140px",
              backgroundColor: "#FFF",
              color: color.Text1,
            }}
            placeholder='วันเรื่มต้น'
          />
          <Input
            value={
              dateRange.startDate === dateRange.endDate ? "" : moment(dateRange.endDate).format("L")
            }
            disabled
            style={{
              fontFamily: "Sarabun",
              width: "140px",
              backgroundColor: "#FFF",
              color: color.Text1,
            }}
            placeholder='วันสิ้นสุด'
          />
          <Button
            onClick={() =>
              setDateRange({
                startDate: initDate,
                endDate: initDate,
                key: "selection",
              })
            }
            style={{
              width: "60px",
            }}
            title='ล้าง'
          />
        </div>
        <DateRangePicker
          key={"calendar"}
          onChange={handleCalendar}
          ranges={[dateRange]}
          maxDate={new Date()}
          locale={th}
        />
      </div>
    </Modal>
  );
}

export default ModalCalendar;
