import { BASE_URL, httpClient } from "../config/develop-config";

export class MaintenanceDataSource {
  static getMaintenance(typeService: string, userStaffId?: string): Promise<any> {
    const params = {
      typeService: typeService,
      userStaffId: userStaffId,
    };
    return httpClient
      .get(BASE_URL + "/ma/system-maintenance/get_notices", { params })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err, "Err MA");
      });
  }
}
