import React from "react";
import { Modal } from "antd";
import { Button } from "antd/lib/radio";
import color from "../../resource/color";
import { MaintenanceSystem } from "../../entities/MaintenanceSystem";
import { FormMaintenance } from "../../pages/MaintenancePage/MaintenancePage";
import Text from "../Text/Text";

interface ModalMaintenceProp {
  show: boolean;
  onClose: () => void;
  data: MaintenanceSystem;
}
export const ModalMaintence: React.FC<ModalMaintenceProp> = ({ show, onClose, data }) => {
  const footerClose = () => {
    return (
      <div className='text-center'>
        <Button style={{ color: color.error, borderColor: color.error }} onClick={onClose}>
          ปิด
        </Button>
      </div>
    );
  };
  return (
    <>
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              textAlign: "center",
            }}
          >
            <Text fontSize={20} fontWeight={700}>
              **แจ้งเตือนการปิดระบบ**
            </Text>
          </div>
        }
        closable={false}
        footer={footerClose()}
        visible={show}
      >
        <FormMaintenance data={data} />
      </Modal>
    </>
  );
};
