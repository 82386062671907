import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";
import { payloadMaster, payloadUnit, updateUnitEntities } from "../entities/MasterDataEntity";

const getUnitMea = async (company: string) => {
  return await httpClient
    .get(`${BASE_URL}/master/unit-mea/${company}`)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
//master data
const getMasterUnit = async (payload: payloadUnit) => {
  return await httpClient
    .get(`${BASE_URL}/master/unit-mea`, { params: payload })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const getMasterUnitById = async (id: string) => {
  return await httpClient
    .get(`${BASE_URL}/master/unit-mea/by-id/${id}`)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const postMasterUnit = async (data: object) => {
  return await httpClient
    .post(`${BASE_URL}/master/unit-mea`, data)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const patchMasterUnit = async (data: updateUnitEntities) => {
  return await httpClient
    .patch(`${BASE_URL}/master/unit-mea/` + data.id, data)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
export { getUnitMea, getMasterUnit, postMasterUnit, patchMasterUnit, getMasterUnitById };
