import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, NAV_URL } from "../config/develop-config";

const baseUrl = `${BASE_URL}/auth`;

const getCustomers = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/customer-company`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getCustomerId = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/customer/get-customer`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getCustomersById = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/customer-company/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getZones = async (company: string) => {
  return await httpClient
    .get(`${baseUrl}/zone/${company}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const checkPhoneByShop = async (params: object) => {
  return await httpClient
    .post(`${NAV_URL}/customer/check-customer`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const checkPhoneAllShop = async (params: object) => {
  return await httpClient
    .post(`${NAV_URL}/customer/check-all-customer`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getCusCorporate = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/customer-company/ex`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const checkTaxNo = async (taxNo: string) => {
  return await httpClient
    .get(`${baseUrl}/customer/get-customer-tax/${taxNo}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const createCustomerEx = async (params: object) => {
  return await httpClient
    .post(`${baseUrl}/customer/create-customer-ex`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const updateCustomerEx = async (data: object) => {
  return await httpClient
    .patch(`${baseUrl}/customer/update-customer-ex`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const uploadFile = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/customer-file`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const deleteFile = async (id: string) => {
  return await httpClient
    .delete(`${baseUrl}/customer-file/delete-task-image/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export {
  getCustomers,
  getCustomerId,
  getCustomersById,
  getZones,
  checkPhoneByShop,
  checkPhoneAllShop,
  getCusCorporate,
  checkTaxNo,
  createCustomerEx,
  updateCustomerEx,
  uploadFile,
  deleteFile,
};
