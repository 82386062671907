import React, { ReactNode, useEffect, useState } from "react";
import { CardContainer } from "../../components/Card/CardContainer";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import {
  getShipmentOrderById,
  updateShipmentOrder,
} from "../../datasource/ShipmentOrderDatasource";
import { useNavigate } from "react-router-dom";
import {
  FlexCol,
  DetailBox as DetailBoxStyled,
  FlexRow,
} from "../../components/Container/Container";
import Text from "../../components/Text/Text";
import color from "../../resource/color";
import moment from "moment";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import {
  MAPPING_SHIPMENT_STATUS,
  MAPPING_SHIPMENT_STATUS_COLOR,
} from "../../definitions/shipmentStatus";
import { Col, Row, Image, Table, Input, Modal, Form } from "antd";
import styled from "styled-components";
import image from "../../resource/image";
import _ from "lodash";
import Button from "../../components/Button/Button";
import { checkPermission } from "../../components/Permission/Permission";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../store/RoleAtom";
const { TextArea } = Input;

const DetailBox = styled(DetailBoxStyled)`
  padding: 32px;
  margin-top: 12px;

  background: ${color.background1};
  border: 1px solid ${color.background2};
  border-radius: 16px;
`;

export const DetailReceiveGoods: React.FC = () => {
  const roleData = useRecoilValue(roleAtom);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[2];
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const cancelRemark = Form.useWatch("cancelRemark", form);
  const isCancel = checkPermission(["receiveGoods", "cancel"], roleData);

  const [data, setData] = useState<any>();
  const [modalCancel, setModalCancel] = useState<boolean>(false);
  const getData = async () => {
    await getShipmentOrderById(id).then((res) => {
      setData(res.responseData);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const DetailItem = ({
    label,
    labelEn,
    value,
    alignRight,
    fontWeight,
    fontSize,
    color,
    style,
    leftSpan,
  }: {
    label: string;
    labelEn?: string;
    value: string | ReactNode;
    alignRight?: boolean;
    fontWeight?: 400 | 500 | 600 | 700;
    fontSize?: 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36 | 38 | 40 | 46 | 50;
    color?:
      | "primary"
      | "secondary"
      | "Text1"
      | "Text2"
      | "Text3"
      | "error"
      | "success"
      | "warning"
      | "white";
    style?: any;
    leftSpan?: number;
  }) => {
    return (
      <Row gutter={16} style={{ margin: "10px 0px" }}>
        <Col span={leftSpan ? leftSpan : alignRight ? 14 : 9}>
          <Text fontWeight={fontWeight} fontSize={fontSize}>
            {label}
          </Text>
          <br />
          {labelEn && (
            <Text fontWeight={fontWeight} fontSize={fontSize} color='Text3'>
              ({labelEn})
            </Text>
          )}
        </Col>
        <Col span={leftSpan ? 24 - leftSpan : alignRight ? 10 : 15}>
          <Row justify={alignRight ? "end" : "start"}>
            <Text fontWeight={fontWeight} color={color ? color : "Text2"} style={style}>
              {value || "-"}
            </Text>
          </Row>
        </Col>
      </Row>
    );
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={`${data?.pdoNo}`}
        showBack
        onBack={() => navigate(-1)}
        extra={
          <FlexCol align='end'>
            <Text
              level={3}
              fontWeight={700}
              style={{
                color: MAPPING_SHIPMENT_STATUS_COLOR[data?.receiveStatus],
                fontWeight: 700,
              }}
            >
              {MAPPING_SHIPMENT_STATUS[data?.receiveStatus]}
            </Text>
            {data?.receiveStatus === "WAIT_RECEIVE" ? (
              <Text level={7}>
                วันที่เอกสาร&nbsp;{moment(data?.postingDate).format("DD/MM/YYYY")}
              </Text>
            ) : (
              <Text level={7}>
                วันที่อัปเดท&nbsp;
                {moment(
                  data?.receiveStatus.includes("CANCELED") ? data?.cancelDate : data?.receiveDate,
                ).format("DD/MM/YYYY HH:mm")}
              </Text>
            )}
          </FlexCol>
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการรับสินค้า", path: "/ReceiveGoods" },
              { text: "รายละเอียดการรับสินค้า", path: window.location.pathname },
            ]}
          />
        }
      />
    );
  };

  const mapTotalUnit = () => {
    let subObj: any = [];
    const g = _.groupBy(data?.shipmentOrderDetail || [], "saleUOMTH" || "shippingUOM");
    const obj = _.map(g, (items, unit) => {
      if (unit === "null") {
        const subGroup = _.groupBy(g[unit] || [], "shippingUOM");
        subObj = _.map(subGroup, (sub, subUnit) => {
          const subMap = `${sub
            .reduce((x, { quantity }, i) => x + Number(quantity), 0)
            .toFixed(2)} ${subGroup[subUnit][0].shippingUOM}`;
          return subMap;
        });
      } else {
        const map = `${items.reduce((x, { quantity }, i) => x + Number(quantity), 0).toFixed(2)} ${
          g[unit][0].saleUOMTH || g[unit][0].shippingUOM
        }`;
        return map;
      }
    });
    return [...subObj, ...obj].filter((x) => !!x).join(", ");
  };

  const onCancel = async () => {
    form.setFieldsValue({
      shipmentOrderId: data.shipmentOrderId,
      receiveStatus: "CANCELED",
      cancelBy: userProfile.firstname + " " + userProfile.lastname,
    });
    const payload = form.getFieldsValue(true);
    await updateShipmentOrder(payload).then((res) => {
      if (res.success) {
        setModalCancel(false);
        getData();
      }
    });
  };

  const columns = [
    {
      title: "ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      width: "20%",
      render: (productName: string, row: any, index: number) => {
        return {
          children: (
            <FlexRow align='center'>
              <div style={{ marginRight: 16 }}>
                <Image
                  src={row?.productImage || image.product_no_image}
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <FlexCol>
                <Text level={5}>{productName}</Text>
                <Text level={6} color='Text3'>
                  {row?.itemNo}
                </Text>
              </FlexCol>
            </FlexRow>
          ),
        };
      },
    },
    {
      title: "ขนาด",
      dataIndex: "packSize",
      key: "packSize",
      width: "8%",
      render: (packSize: string, product: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text level={5}>{packSize}</Text>
              <Text level={6} color='Text3'>
                {product?.productCodeNAV || product?.productFreebiesCodeNAV}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "จำนวน",
      dataIndex: "quantity",
      key: "quantity",
      width: "8%",
      render: (quantity: string, product: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text level={5}>{Number(quantity).toFixed(2)}</Text>
              <Text level={6} color='Text3'>
                {product?.saleUOMTH || product?.shippingUOM || product?.unitOfMeasure}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
  ];

  return (
    <>
      <CardContainer>
        <PageTitle />
      </CardContainer>
      <br />
      <Row gutter={16}>
        <Col span={12}>
          <CardContainer>
            <Text level={4} fontWeight={700}>
              รายละเอียดคำสั่งซื้อ
            </Text>
            <DetailBox style={{ height: 270 }}>
              <DetailItem label='ชื่อร้านค้า' value={data?.shipToName} />
              <DetailItem label='Customer Code' value={data?.customerNo} />
              <DetailItem label='เขต' value={data?.zone} />
              <DetailItem label='PDO NO.' value={data?.pdoNo} />
              <DetailItem label='SO NO.' value={data?.documentNo} />
              <DetailItem label='Order No.' value={data?.orderNo} />
            </DetailBox>
          </CardContainer>
        </Col>
        <Col span={12}>
          <CardContainer>
            <Text level={4} fontWeight={700}>
              รายละเอียดการจัดส่ง
            </Text>
            <DetailBox style={{ height: 270 }}>
              <DetailItem
                label='ที่อยู่'
                value={`${data?.shipToAddress || ""} ${data?.shipToAddress2 || ""} ${
                  data?.shipToCity || ""
                } ${data?.shipToCounty || ""} ${data?.shipToPostCode || ""}`}
              />
              <DetailItem label='ข้อมูลทะเบียนรถ' value={data?.licensePlateNo || "-"} />
              <DetailItem label='ชื่อคนขับ' value={data?.transporterName || "-"} />
            </DetailBox>
          </CardContainer>
        </Col>
      </Row>
      <br />
      <CardContainer>
        <Col span={12}>
          <Text level={4} fontWeight={700}>
            รายการสินค้า
          </Text>
        </Col>
        <Table dataSource={data?.shipmentOrderDetail} pagination={false} columns={columns} />
        <br />
        <Row justify={"space-between"}>
          <Col span={12}>
            <Text fontWeight={600}>สรุปจำนวนทั้งหมด</Text>
          </Col>
        </Row>
        <div style={{ borderRadius: "8px", backgroundColor: color.background1, padding: "10px" }}>
          <Col span={12}>
            <DetailItem
              label='รายการทั้งหมด :'
              value={`${data?.shipmentOrderDetail?.length} รายการ`}
            />
          </Col>
          <Col span={12}>
            <DetailItem
              label='จำนวนสินค้าทั้งหมด'
              value={
                <Text fontWeight={600} fontSize={16}>
                  {mapTotalUnit()}
                </Text>
              }
            />
          </Col>
        </div>
      </CardContainer>
      <br />
      <CardContainer>
        <Row justify={"space-between"}>
          <Col span={12}>
            <Text fontWeight={600} fontSize={20}>
              รวมหมายเหตุ***
            </Text>
          </Col>
        </Row>
        <br />
        <Text level={5} fontWeight={700}>
          หมายเหตุ
        </Text>
        <TextArea
          rows={3}
          value={data?.remark || "-"}
          disabled
          style={{
            borderColor: color.background2,
            color: color.BK,
            borderRadius: "16px",
            backgroundColor: color.background1,
          }}
        />
        <br />
        <br />
        <Text level={5} fontWeight={700}>
          หมายเหตุการจัดส่ง
        </Text>
        <TextArea
          rows={3}
          value={data?.transporterRemark || "-"}
          disabled
          style={{
            borderColor: color.background2,
            color: color.BK,
            borderRadius: "16px",
            backgroundColor: color.background1,
          }}
        />
        <br />
        <br />
        {data?.cancelRemark && (
          <>
            <Text level={5} fontWeight={700}>
              หมายเหตุยกเลิกใบส่งของ
            </Text>
            <TextArea
              rows={3}
              value={data?.cancelRemark || "-"}
              disabled
              style={{
                borderColor: color.background2,
                color: color.BK,
                borderRadius: "16px",
                backgroundColor: color.background1,
              }}
            />
          </>
        )}
      </CardContainer>
      {data?.receiveStatus.includes("WAIT") && isCancel && (
        <>
          <br />
          <CardContainer>
            <Col span={3}>
              <Button
                title='ยกเลิกใบส่งของ'
                typeButton='danger'
                style={{ width: "100%" }}
                onClick={() => {
                  setModalCancel(true);
                  form.setFieldValue("cancelRemark", undefined);
                }}
              />
            </Col>
          </CardContainer>
        </>
      )}
      <Modal open={modalCancel} footer={false} closable={false} width={420} centered>
        <FlexCol align='center'>
          <Text fontWeight={700}>เหตุผลยกเลิกใบส่งของ</Text>
          <br />
        </FlexCol>
        <Form form={form}>
          <Form.Item noStyle name='cancelRemark'>
            <TextArea rows={4} placeholder='โปรดระบุเหตุผล...' />
          </Form.Item>
        </Form>
        <br />
        <Row gutter={16}>
          <Col span={12}>
            <Button
              title='ยกเลิก'
              typeButton='primary-light'
              style={{ width: "100%" }}
              onClick={() => setModalCancel(false)}
            />
          </Col>
          <Col span={12}>
            <Button
              title='ยืนยัน'
              typeButton={!cancelRemark ? "disabled" : "primary"}
              disabled={!cancelRemark}
              style={{ width: "100%" }}
              onClick={() => onCancel()}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
