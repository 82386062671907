import color from "../../resource/color";
import moment from "moment";
import { convertBuddhistYear, dateFormatter } from "../../utility/Formatter";
import Text from "../../components/Text/Text";
import { MaintenanceSystem } from "../../entities/MaintenanceSystem";

interface FormMaintenanceProp {
  data: MaintenanceSystem;
}
export const FormMaintenance: React.FC<FormMaintenanceProp> = (data) => {
  const sameDay = moment(data.data.dateStart).isSame(data.data.dateEnd, "day");
  return (
    <div className='col-lg-12'>
      <div className='text-center' style={{ paddingBottom: "4%" }}>
        <img alt='logo' src={data.data.imagePath} />
      </div>
      <div style={{ width: "100%" }}>
        <div className='text-center'>
          <Text fontSize={22} fontWeight={700}>
            {data.data.header}
          </Text>
        </div>
        <div className='text-center p-1'>
          {sameDay === true ? (
            <Text fontSize={18} fontWeight={700}>
              {data.data.textDate}
            </Text>
          ) : (
            <>
              <Text fontSize={18} fontWeight={700}>
                วันที่{" "}
                {convertBuddhistYear.toBuddhistYear(
                  moment(data.data.dateStart),
                  "DD MMMM YYYY ช่วงเวลา HH:mm - 23:59 น.",
                )}{" "}
              </Text>
              <br />
              <Text fontSize={18} fontWeight={700}>
                ถึงวันที่{" "}
                {convertBuddhistYear.toBuddhistYear(
                  moment(data.data.dateEnd),
                  "DD MMMM YYYY ช่วงเวลา 00:00 - HH:mm น.",
                )}{" "}
              </Text>
            </>
          )}
        </div>
        <div className='text-center'>
          <Text style={{ color: color.Grey, fontSize: "16px" }}>{data.data.text}</Text>
        </div>
        <div className='text-center'>
          <Text style={{ color: color.Grey, fontSize: "16px" }}>{data.data.footer}</Text>
        </div>
      </div>
    </div>
  );
};
