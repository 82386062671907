import { AxiosResponse } from "axios";
import { BASE_URL, NAV_URL, httpClient } from "../config/develop-config";

const baseUrl = `${BASE_URL}/order-cart`;

const getShipmentOrder = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/shipment-order`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getShipmentOrderById = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/shipment-order/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const updateShipmentOrder = async (params: object) => {
  return await httpClient
    .post(`${baseUrl}/shipment-order/update-status`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export { getShipmentOrder, getShipmentOrderById, updateShipmentOrder };
