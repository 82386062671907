import { Col, Divider, Form, FormInstance, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import color from "../../../resource/color";
import {
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { StoreEntity, ZoneEntity } from "../../../entities/StoreEntity";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Select from "../../../components/Select/Select";
import { AlignType } from "rc-table/lib/interface";
import TableContainer from "../../../components/Table/TableContainer";
import { getZones } from "../../../datasource/CustomerDatasource";
import { ModalSelectStore } from "../../Shared/ModalSelectStore";
import Switch from "../../../components/Switch/Switch";
import { priceFormatter } from "../../../utility/Formatter";
import { PromotionType } from "../../../definitions/promotion";
import { getQuotaUnit } from "../../../utility/promotionUtility";
import { onInputNumberKeyPress, validateOnlyNumber } from "../../../utility/validator";
import image from "../../../resource/image";

interface Step2Props {
  form: FormInstance;
  showError?: boolean;
  setError?: any;
  totalQuota?: any;
  promotionType?: PromotionType;
}

export const PromotionCreateStep2 = ({
  form,
  showError,
  setError,
  totalQuota,
  promotionType,
}: Step2Props) => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const defaultFilter = {
    zone: "",
    keyword: "",
  };
  const [filter, setFilter] = useState(defaultFilter);
  const [showSearch, setSearch] = useState(false);
  const [storeList, setStoreList] = useState<StoreEntity[]>(form.getFieldValue("stores") || []);
  const [storeListFiltered, setStoreListFiltered] = useState<StoreEntity[]>(
    form.getFieldValue("stores"),
  );
  const [selectedStoreKeys, setSelectedStoreKeys] = useState<React.Key[]>([]);
  const [selectedStoreList, setSelectedStoreList] = useState<StoreEntity[]>([]);
  const [zones, setZones] = useState<ZoneEntity[]>([]);
  const [hasQuota, _setQuota] = useState(form.getFieldValue("isQuotaShop"));
  const [loading, setLoading] = useState(false);

  const [showQuotaModal, setquotaModal] = useState(false);
  const [quotaModalValue, setQuotaModalValue] = useState<number>();

  const [innerForm] = Form.useForm();

  const setQuota = (value) => {
    _setQuota(value);

    const newValue = {};
    const newStores = [...storeList].map((s) => {
      newValue[s.customerCompanyId] = { quotaReceive: undefined };
      return { ...s, quotaReceive: undefined };
    });
    innerForm.setFieldsValue(newValue);
    setStoreList(newStores);
    setStoreListFiltered(newStores);

    form.setFieldsValue({
      ...form.getFieldsValue(),
      isQuotaShop: value,
      stores: newStores,
    });
  };

  useEffect(() => {
    fetchZones();
  }, []);

  const fetchZones = async () => {
    try {
      setLoading(true);
      const data = await getZones(company);
      setZones(data.map((d: StoreEntity, i: number) => ({ ...d, key: d.customerCompanyId })));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onSetStore = (stores: any) => {
    const newStores = [...storeList, ...stores];
    setStoreList(newStores);
    setStoreListFiltered(newStores);
    setFilter(defaultFilter);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      stores: newStores,
    });
    setError(false);
    setSearch(false);
  };

  const toggleSearchWindow = () => {
    setSearch(!showSearch);
  };

  const columns = [
    {
      title: "รหัสร้านค้า",
      dataIndex: "customerNo",
      align: "center" as AlignType,
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "customerName",
      align: "center" as AlignType,
    },
    {
      title: "เขตการขาย",
      dataIndex: "zone",
      align: "center" as AlignType,
    },
    {
      title: "โควต้า",
      dataIndex: "quotaReceive",
      align: "center" as AlignType,
      disabled: !hasQuota,
      render: (quotaReceive, customer) => {
        return (
          <Form.Item
            name={[customer.customerCompanyId, "quotaReceive"]}
            initialValue={quotaReceive}
            rules={[
              {
                validator(rule, value, callback) {
                  console.log(value, customer.quotaUsed);
                  if (parseFloat(customer.quotaUsed) > parseFloat(value)) {
                    return Promise.reject(
                      new Error(
                        `ไม่สามารถลดจำนวนโควตาที่ระบุได้ เนื่องจากร้านใช้โควตาไปแล้ว ${
                          customer.quotaUsed
                        } ${
                          !promotionType
                            ? ""
                            : [PromotionType.DISCOUNT_MIX, PromotionType.DISCOUNT_NOT_MIX].includes(
                                  promotionType,
                                )
                              ? "บาท"
                              : "สิทธิ์"
                        }`,
                      ),
                    );
                  }
                  return validateOnlyNumber(value)
                    ? Promise.resolve()
                    : Promise.reject(new Error("โควต้าเป็นตัวเลขเท่านั้น"));
                },
              },
            ]}
          >
            <Input
              placeholder='ระบุโควต้า'
              onKeyDown={onInputNumberKeyPress}
              suffix={
                !promotionType
                  ? ""
                  : [PromotionType.DISCOUNT_MIX, PromotionType.DISCOUNT_NOT_MIX].includes(
                        promotionType,
                      )
                    ? "บาท"
                    : "สิทธิ์"
              }
            />
          </Form.Item>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedStoreKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: StoreEntity[]) => {
      setSelectedStoreList(selectedRows);
      setSelectedStoreKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: StoreEntity) => ({
      name: record.customerName,
    }),
  };

  const onFilter = ({ zone, keyword }: any) => {
    setFilter({ zone, keyword });
    setStoreListFiltered(
      storeList.filter((store) => {
        const isInZone = !zone || store.zone === zone;
        const hasKeyword =
          !keyword ||
          store?.customerName?.includes(keyword) ||
          store?.customerNo?.includes(keyword);
        return isInZone && hasKeyword;
      }),
    );
  };

  const onQuotaChange = (changedValues, allValues) => {
    // console.log({ changedValues, allValues, storeList });
    const newStores = [...storeList].map((s) =>
      changedValues[s.customerCompanyId] ? { ...s, ...changedValues[s.customerCompanyId] } : s,
    );
    setStoreList(newStores);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      stores: newStores,
    });
  };

  return (
    <>
      <Row align='middle'>
        <Text level={5} fontWeight={700}>
          รายละเอียดเขต และร้านค้า&nbsp;&nbsp;
        </Text>
        {showError && (
          <Text level={6} color='error'>
            &nbsp;
            {storeList?.length > 0
              ? "โปรดตรวจสอบรายละเอียดให้ถูกต้อง"
              : "โปรดระบุรายละเอียดเขต และร้านค้า*"}
          </Text>
        )}
      </Row>
      <br />
      <br />
      <Row>
        <Col span={14}>
          {storeList?.length > 0 && (
            <Row gutter={8}>
              <Col span={10}>
                <Select
                  style={{ width: "100%" }}
                  data={[
                    { label: "ทั้งหมด", key: "" },
                    ...zones.map((z) => ({ label: z.zoneName, key: z.zoneName })),
                  ]}
                  onChange={(val) => onFilter({ ...filter, zone: val })}
                  value={filter.zone}
                />
              </Col>
              <Col span={10}>
                <Input
                  suffix={<SearchOutlined />}
                  placeholder={"ระบุชื่อร้านค้าหรือรหัสร้านค้า"}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    onFilter({
                      ...filter,
                      keyword: e.target.value,
                    });
                  }}
                  value={filter.keyword}
                />
              </Col>
              <Col span={4}>
                <Button
                  title='ล้างการค้นหา'
                  typeButton='primary-light'
                  onClick={() => onFilter(defaultFilter)}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col span={10}>
          <Row align='middle' justify='end' gutter={22}>
            <Col span={4}>
              {selectedStoreList.length > 0 && (
                <FlexRow align='center' justify='end' style={{ height: "100%" }}>
                  <DeleteFilled
                    style={{ fontSize: 20, color: color.error }}
                    onClick={() => {
                      Modal.confirm({
                        title: (
                          <Text level={3} fontWeight={700}>
                            ลบร้านค้าที่เลือกทั้งหมด
                          </Text>
                        ),
                        icon: "",
                        content: <Text color='Text3'>โปรดยืนยันการลบร้านค้า</Text>,
                        okText: "ยืนยัน",
                        onOk: () => {
                          const newStores = storeList.filter(
                            (s) =>
                              !selectedStoreList.find(
                                (s2) => s.customerCompanyId === s2.customerCompanyId,
                              ),
                          );
                          setStoreList(newStores);
                          setStoreListFiltered(newStores);
                          setFilter(defaultFilter);
                          form.setFieldsValue({
                            ...form.getFieldsValue(),
                            stores: newStores,
                          });
                          setError(false);
                          setSearch(false);
                          setSelectedStoreList([]);
                          setSelectedStoreKeys([]);
                        },
                      });
                    }}
                  />
                </FlexRow>
              )}
            </Col>
            <Col
              span={8}
              style={{
                borderLeft: `1px solid ${color["background2"]}`,
                paddingLeft: 16,
              }}
            >
              <Button title='+ เพิ่มร้านค้า' typeButton='primary' onClick={toggleSearchWindow} />
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row justify='space-between'>
        <Row>
          <div
            style={{
              borderRight: `1px solid ${color["background2"]}`,
              paddingRight: 16,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text fontWeight={700}>จำกัดโควต้ารายร้าน&nbsp;</Text>
            <Tooltip title='ถ้าเปิดการใช้งาน "จำกัดโควตารายร้าน" หากร้านค้าไหนไม่ได้ระบุโควตา ระบบจะไม่จำกัดโควตาร้านค้านั้น'>
              <img src={image.infoIcon} style={{ width: 20, height: 20 }} />
            </Tooltip>
            &nbsp;
            <Switch
              onChange={(checked) => setQuota(checked)}
              value={promotionType === PromotionType.OTHER ? false : hasQuota}
              disabled={
                promotionType === PromotionType.OTHER ||
                storeList.reduce(
                  (acc, cus) => (cus.quotaUsed ? acc + parseFloat(`${cus.quotaUsed}`) : acc),
                  0,
                ) > 0
              }
            />
            {hasQuota && storeListFiltered?.length > 0 && (
              <Button
                icon={<img src={image.conditionIcon} style={{ width: 16, height: 16 }} />}
                title='เพื่มเงื่อนไขโควตาเดียวกัน'
                style={{ width: 240, marginLeft: 24 }}
                onClick={() => setquotaModal(true)}
              />
            )}
            <Modal
              open={showQuotaModal}
              onCancel={() => setquotaModal(false)}
              footer={
                <Row style={{ padding: "4px 0px" }} gutter={16} justify='end'>
                  <Col offset={12} span={6}>
                    <Button
                      height={40}
                      typeButton='primary-light'
                      title='ยกเลิก'
                      onClick={() => setquotaModal(false)}
                    />
                  </Col>
                  <Col span={6}>
                    <Button
                      height={40}
                      title='ยืนยัน'
                      typeButton={!quotaModalValue ? "disabled" : "primary"}
                      disabled={!quotaModalValue}
                      onClick={() => {
                        const newValue = {};
                        const newStores = [...storeList].map((s) => {
                          newValue[s.customerCompanyId] = { quotaReceive: quotaModalValue };
                          return { ...s, quotaReceive: quotaModalValue };
                        });
                        innerForm.setFieldsValue(newValue);
                        setQuotaModalValue(undefined);
                        setquotaModal(false);

                        setStoreList(newStores);
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          stores: newStores,
                        });
                      }}
                    />
                  </Col>
                </Row>
              }
            >
              <Text level={4} fontWeight={700}>
                เพิ่มเงื่อนไขโควตาเดียวกันทั้งหมด
              </Text>
              <br />
              <br />
              <Text fontFamily='Sarabun'>จำนวนโควตา*</Text>
              <Input
                placeholder='ระบุจำนวนโควตา'
                suffix={
                  !promotionType
                    ? ""
                    : [PromotionType.DISCOUNT_MIX, PromotionType.DISCOUNT_NOT_MIX].includes(
                          promotionType,
                        )
                      ? "บาท"
                      : "สิทธิ์"
                }
                onKeyDown={onInputNumberKeyPress}
                onChange={(e) => setQuotaModalValue(e.target.value)}
                value={quotaModalValue}
              />
            </Modal>
          </div>
          <div style={{ paddingLeft: 16 }}>
            {totalQuota && promotionType && (
              <Text>
                โควตารวม:&nbsp;
                <span style={{ color: color.warning }}>
                  {priceFormatter(totalQuota, 0, false, true) + " " + getQuotaUnit(promotionType)}
                </span>
              </Text>
            )}
          </div>
        </Row>
        <Text>จำนวนที่เลือก {storeList?.length || 0} ร้าน</Text>
      </Row>
      <br />
      <TableContainer>
        <Form form={innerForm} onValuesChange={onQuotaChange}>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            scroll={{ y: 480 }}
            columns={columns.filter(({ disabled }) => !disabled)}
            dataSource={storeListFiltered?.map((s, i) => ({ ...s, key: i }))}
            pagination={false}
          />
        </Form>
      </TableContainer>
      {showSearch && (
        <ModalSelectStore
          callBackShop={onSetStore}
          showModalShop={showSearch}
          currentSelectShop={storeList || []}
          company={company}
          onClose={toggleSearchWindow}
        />
      )}
    </>
  );
};
