import React, { useState, useMemo, useEffect } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Col, Pagination, Row, TableColumnsType, Tooltip } from "antd";
import Text from "../../../components/Text/Text";
import styled from "styled-components";
import color from "../../../resource/color";
import { InfoCircleFilled, InfoCircleOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ExpandTable from "../../../components/Table/ExpandTable";
import icon from "../../../resource/icon";
import ModalShopParticipate from "../../../components/Modal/ReportCampaign/ModalShopParticipate";
import {
  CONDITION_POINT_MAPPING,
  GET_POINT_MAPPING,
  showDateText,
} from "../../../definitions/dataFilter";
import SortFieldCustom from "../../../components/SortFieldTable/SortFieldCustom";
import useSorting from "../../../hook/useSorting";
import moment from "moment";
import { reportPointCampaign } from "../../../datasource/ReportCRMPointDatasource";
import {
  allPointCampaignEntities,
  allPointCampaignEntities_INIT,
  crmPointCampaignEntities,
} from "../../../entities/ReportPointCampaignEntity";
import { numberFormatter } from "../../../utility/Formatter";
import { getDateRange } from "../../../utility/dateUtils";

const CustomExpandTableWrapper = styled.div`
  .custom-expand-table {
    :where(.css-dev-only-do-not-override-apn68).ant-table-wrapper tr.ant-table-expanded-row > td {
      padding: 0;
      padding-left: 90px;
    }
    :where(.css-apn68).ant-table-wrapper tr.ant-table-expanded-row > td {
      padding: 0;
      padding-left: 90px;
    }
  }
`;

const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.background4};
  font-weight: 400;
`;

const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
`;

const ActionButton = styled.div`
  .btn-icon {
    color: ${color["primary"]};
    cursor: pointer;
  }
`;
interface Props {
  dateRange: string;
  typeDate: string;
  campaignProgramType: string[];
  campaignProgramCondition: string[];
  objective: string[];
  campaignId: string;
  filterDay: {
    startDate: Date;
    endDate: Date;
  };
}

const CampaignList = ({
  dateRange,
  typeDate,
  filterDay,
  objective,
  campaignProgramType,
  campaignProgramCondition,
  campaignId,
}: Props) => {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<allPointCampaignEntities>(allPointCampaignEntities_INIT);
  const [campaignProgramId, setCampaignProgramId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [campProgramType, setCampProgramType] = useState<string | undefined>(undefined);

  useEffect(() => {
    getReportPointCampaign();
  }, [
    filterDay,
    objective,
    campaignProgramType,
    campaignProgramCondition,
    sortField,
    sortDirection,
    page,
  ]);

  const getReportPointCampaign = async () => {
    setLoading(true);
    try {
      const { startDate, endDate } = getDateRange(typeDate, filterDay);
      const object = {
        company: userProfile?.company,
        startDate: startDate,
        endDate: endDate,
        campaignId: campaignId,
        objective: objective,
        campaignProgramType: campaignProgramType,
        campaignProgramCondition: campaignProgramCondition,
        sortBy: sortDirection === undefined ? undefined : sortField,
        sortDirection: sortDirection,
        page: page,
        take: take,
      };
      await reportPointCampaign(object).then((res) => {
        setData(res);
        setLoading(false);
      });
      setLoading(false);
    } catch (error) {
      console.log("err eport-crm-point-campaign", error);
    } finally {
      setLoading(false);
    }
  };

  const renderBaseText = (value: any) => <BaseText>{value}</BaseText>;
  const renderDescText = (value: string) => (
    <DescText style={{ display: "flex", alignItems: "center" }}>{value}</DescText>
  );
  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setTake(take);
  };
  const columnWidth = [10, 250, 250, 160, 160, 160, 140, 140, 140, 10];

  const columns: any = useMemo(
    () => [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        width: columnWidth[0],
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <BaseText>{index + 1}</BaseText>
              </>
            ),
          };
        },
      },
      {
        title: "ชื่อแคมเปญ",
        dataIndex: "campaignName",
        key: "campaignName",
        width: columnWidth[1],
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <BaseText>{value}</BaseText>
                <DescText style={{ display: "flex", alignItems: "center" }}>
                  {row.campaignCode}
                </DescText>
              </>
            ),
          };
        },
      },
      {
        title: "ระยะเวลา",
        dataIndex: "startDate",
        key: "startDate",
        width: columnWidth[2],
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <BaseText>
                  {moment(row.startDate).format("DD/MM/YYYY") +
                    "-" +
                    moment(row.endDate).format("DD/MM/YYYY")}
                </BaseText>
              </>
            ),
          };
        },
      },
      {
        title: "จำนวนร้านเข้าร่วม",
        dataIndex: "shopParticipate",
        key: "shopParticipate",
        width: columnWidth[3],
        render: (value) => (
          <>
            <Row>
              {renderBaseText(value)}
              <InfoIconWrapper>
                <Tooltip placement='top' title={"ไม่นับรวมรายชื่อร้านที่ซ้ำกัน"}>
                  <InfoCircleOutlined style={{ fontSize: "13px" }} />
                </Tooltip>
              </InfoIconWrapper>
            </Row>
            {renderDescText("ร้าน")}
          </>
        ),
      },
      {
        title: () => (
          <SortFieldCustom
            title='จำนวนคำสั่งซื้อ'
            sortDirection={sortField === "countOrder" ? sortDirection : undefined}
            onSortChange={() => {
              handleSortChange("countOrder");
              setPage(1);
            }}
          />
        ),
        dataIndex: "countOrder",
        key: "countOrder",
        width: columnWidth[4],
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 0))}
            {renderDescText("รายการ")}
          </>
        ),
      },
      {
        title: () => (
          <SortFieldCustom
            title='ปริมาณที่สั่งซื้อ'
            sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
            onSortChange={() => {
              handleSortChange("sumQuantity");
              setPage(1);
            }}
          />
        ),
        dataIndex: "sumQuantity",
        key: "sumQuantity",
        width: columnWidth[5],
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("ตัน")}
          </>
        ),
      },
      {
        title: () => (
          <SortFieldCustom
            title='รอรับแต้ม'
            sortDirection={sortField === "sumPointWaiting" ? sortDirection : undefined}
            onSortChange={() => {
              handleSortChange("sumPointWaiting");
              setPage(1);
            }}
          />
        ),
        dataIndex: "sumPointWaiting",
        key: "sumPointWaiting",
        width: columnWidth[6],
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("แต้ม")}
          </>
        ),
      },
      {
        title: () => (
          <SortFieldCustom
            title='ได้รับแต้ม'
            sortDirection={sortField === "sumPointApprove" ? sortDirection : undefined}
            onSortChange={() => {
              handleSortChange("sumPointApprove");
              setPage(1);
            }}
          />
        ),
        dataIndex: "sumPointApprove",
        key: "sumPointApprove",
        width: columnWidth[7],
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("แต้ม")}
          </>
        ),
      },
      {
        title: () => (
          <SortFieldCustom
            title='ยกเลิกแต้ม'
            sortDirection={sortField === "sumPointCancel" ? sortDirection : undefined}
            onSortChange={() => {
              handleSortChange("sumPointCancel");
              setPage(1);
            }}
          />
        ),
        dataIndex: "sumPointCancel",
        key: "sumPointCancel",
        width: columnWidth[8],
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("แต้ม")}
          </>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: columnWidth[9],
        render: (value, row) => (
          <>
            <ActionButton>
              <UnorderedListOutlined
                style={{ color: color.primary }}
                onClick={() => window.open(`/crm/campaignPoint/${row.campaignId}`)}
              />
            </ActionButton>
          </>
        ),
      },
    ],
    [navigate, data?.count],
  );

  const expandedRowRender = (record: crmPointCampaignEntities) => {
    const expandColumns = [
      {
        dataIndex: "campaignProgramName",
        key: "campaignProgramName",
        width: columnWidth[1] + columnWidth[2] + 200,
        render: (value: any, row: any, index: number) => {
          setCampProgramType(row.campaignProgramType);
          return {
            children: (
              <>
                {renderBaseText(value)}
                {renderDescText(
                  GET_POINT_MAPPING[row.campaignProgramType] +
                    " | " +
                    CONDITION_POINT_MAPPING[row.campaignProgramCondition],
                )}
              </>
            ),
          };
        },
      },
      {
        dataIndex: "shopParticipate",
        key: "shopParticipate",
        width: columnWidth[3] + 50,
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <Row>
                  {renderBaseText(
                    numberFormatter(value, 0) + "/" + numberFormatter(row.shopParticipateAll, 0),
                  )}
                  <InfoIconWrapper>
                    <InfoCircleFilled
                      style={{ fontSize: "13px" }}
                      onClick={() => {
                        setCampaignProgramId(row.campaignProgramId);
                        setShowModal(true);
                      }}
                    />
                  </InfoIconWrapper>
                </Row>
                {renderDescText("ร้าน")}
              </>
            ),
          };
        },
      },
      {
        dataIndex: "countOrder",
        key: "countOrder",
        width: columnWidth[3] + 50,
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 0))}
            {renderDescText("รายการ")}
          </>
        ),
      },
      {
        dataIndex: "sumQuantity",
        key: "sumQuantity",
        width: columnWidth[3] + 50,
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("รายการ")}
          </>
        ),
      },
      {
        dataIndex: "sumPointWaiting",
        key: "sumPointWaiting",
        width: columnWidth[3] + 10,
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("ตัน")}
          </>
        ),
      },
      {
        dataIndex: "sumPointApprove",
        key: "sumPointApprove",
        width: columnWidth[3] + 10,
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("แต้ม")}
          </>
        ),
      },
      {
        dataIndex: "sumPointCancel",
        key: "sumPointCancel",
        width: columnWidth[3] + 150,
        render: (value) => (
          <>
            {renderBaseText(numberFormatter(value, 2))}
            {renderDescText("แต้ม")}
          </>
        ),
      },
    ];

    const expandDataSource = record.program.map((product: any, index: number) => ({
      key: index.toString(),
      campaignProgramId: product.campaignProgramId,
      campaignProgramName: product.campaignProgramName,
      campaignProgramCondition: product.campaignProgramCondition,
      campaignProgramType: product.campaignProgramType,
      shopParticipate: product.shopParticipate,
      shopParticipateAll: product.shopParticipateAll,
      countOrder: product.countOrder,
      sumQuantity: product.sumQuantity,
      sumPointWaiting: product.sumPointWaiting,
      sumPointCancel: product.sumPointCancel,
      sumPointApprove: product.sumPointApprove,
    }));

    return (
      <ExpandTable
        showHeader={false}
        rowBackgroundColor='#E4E7E5'
        columns={expandColumns}
        data={expandDataSource || []}
        loading={false}
        size='small'
        scrollY={300}
      />
    );
  };

  const handleExpand = (expanded: boolean, record: crmPointCampaignEntities) => {
    setExpandedRowKeys(expanded ? [record.campaignId.toString()] : []);
  };

  return (
    <CardContainer>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "20px" }}>
        <Col span={12}>
          <Text fontSize={18} fontWeight={700} color='Text2'>
            รายการแคมเปญ
          </Text>
          <br />
          <Text fontSize={14} fontWeight={400} color='Text2'>
            {typeDate === "Custom" ? dateRange : showDateText(typeDate)}
          </Text>
        </Col>
        <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
          <Text fontSize={14} fontWeight={400} color='Text3'>
            ทั้งหมด {numberFormatter(data?.count || 0, 0)} แคมเปญ
          </Text>
        </Col>
      </Row>
      <CustomExpandTableWrapper>
        <ExpandTable
          className='custom-expand-table'
          loading={loading}
          columns={columns}
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            onExpand: handleExpand,
          }}
          expandRowBackgroundColor={color.Disable}
          data={data?.campaignList || []}
          scrollX='max-content'
          scrollY={420}
          textEmpty='ไม่มีรายการแคมเปญ'
          imgEmpty={icon.emptyHistoryPoint}
          rowKey='campaignId'
          isOpenExpand={true}
        />
      </CustomExpandTableWrapper>

      <div style={{ padding: "22px  0px 22px 0px", display: "flex", justifyContent: "end" }}>
        <Pagination
          current={page}
          total={data.count}
          onChange={(pageNumber) => handlePageChange(pageNumber, take)}
          pageSize={take}
          showSizeChanger={false}
        />
      </div>
      <ModalShopParticipate
        visible={showModal}
        campaignProgramId={campaignProgramId}
        onCancel={() => setShowModal(false)}
        campaignProgramType={campProgramType}
      />
    </CardContainer>
  );
};

export default CampaignList;
