import { CameraOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import { Col, Row, Form, Upload, UploadFile, Radio, Divider, Modal, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Button from "../../components/Button/Button";
import { CardContainer } from "../../components/Card/CardContainer";
import Input from "../../components/Input/Input";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import { createFreebieCorporate, updateFreebieCorporate } from "../../datasource/FreebieDatasource";
import { getProductFreebiePromotionDetail } from "../../datasource/PromotionDatasource";
import { getUnitMea } from "../../datasource/UnitMeaDatasource";
import Select from "../../components/Select/Select";
import { FlexCol } from "../../components/Container/Container";

const ProdImage = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateFreebieCorporate: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const company = JSON.parse(localStorage.getItem("company")!);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const isEdit = pathSplit[3] !== "create";
  const id = parseInt(pathSplit[3]);

  const [file, setFile] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [isRemoved, setRemoved] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [unitMea, setUnitMea] = useState<any>();
  const [isDone, setIsDone] = useState<boolean>(false);

  const getFreebieById = async () => {
    await getProductFreebiePromotionDetail(id).then((res) => {
      const url = res.productFreebies.productFreebiesImage;
      if (url) {
        setFile({
          uid: "-1",
          name: "image.png",
          status: "done",
          url,
        });
        setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url,
          },
        ]);
      }
      form.setFieldsValue({
        productFreebiesId: res.productFreebies?.productFreebiesId,
        productName: res?.productFreebies?.productName,
        productFreebiesStatus: res?.productFreebies?.productFreebiesStatus,
        productFreebiesCodeNAV: res?.productFreebies?.productFreebiesCodeNAV,
        productGroup: res?.productFreebies?.productGroup,
        //baseUnitOfMeaEn: res?.productFreebies?.baseUnitOfMeaEn,
        baseUnitOfMeaTh: res?.productFreebies?.baseUnitOfMeaTh,
        description: res?.productFreebies?.description,
        productFreebiesImage: res.productFreebies.productFreebiesImage,
      });
    });
  };
  const getUnit = async () => {
    await getUnitMea(company?.companyCode).then((res) => {
      setUnitMea(res);
    });
  };

  useEffect(() => {
    isEdit && getFreebieById();
    form.setFieldValue("productFreebiesStatus", "ACTIVE");
    getUnit();
  }, []);

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={!isEdit ? "เพิ่มของแถม" : "แก้ไขของแถม"}
        showBack
        onBack={() => navigate(`/freebies/freebies`)}
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการของแถม", path: `/freebies/freebies` },
              { text: !isEdit ? "เพิ่มของแถม" : "แก้ไขของแถม", path: window.location.pathname },
            ]}
          />
        }
      />
    );
  };
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as any);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const createFreebie = async () => {
    const payload = form.getFieldsValue(true);
    setIsDone(true);
    form
      .validateFields()
      .then(async (f) => {
        const data = new FormData();
        data.append("productName", payload.productName);
        data.append("productFreebiesStatus", payload.productFreebiesStatus || "ACTIVE");
        data.append("company", company.companyCode);
        data.append("productFreebiesCodeNAV", payload.productFreebiesCodeNAV);
        data.append("productGroup", payload.productGroup || "");
        data.append("baseUnitOfMeaTh", payload.baseUnitOfMeaTh);
        data.append("description", payload.description || "");
        data.append("updateBy", `${userProfile.firstname} ${userProfile.lastname}`);

        if (!isRemoved && payload.productFreebiesImage) {
          data.append("productFreebiesImage", payload.productFreebiesImage);
        }
        if (file && file.uid !== "-1") {
          data.append("file", file!);
        }
        if (payload.productFreebiesId) {
          data.append("productFreebiesId", payload.productFreebiesId);
          await updateFreebieCorporate(data).then((res) => {
            if (res.success) {
              setTimeout(() => {
                setIsDone(false);
                navigate("/freebies/freebies");
              }, 500);
            }
          });
        } else {
          await createFreebieCorporate(data).then((res) => {
            setTimeout(() => {
              setIsDone(false);
              navigate("/freebies/freebies");
            }, 500);
          });
        }
        setShowModal(!showModal);
      })
      .catch((e) => {
        console.log("e", e);
        return false;
      });
  };

  return (
    <>
      <CardContainer>
        <PageTitle />
        <br />
        <Form form={form} layout='vertical'>
          <Row style={{ padding: "16px 0px" }}>
            <Form.Item valuePropName='file' name={"productFreebiesImage"}>
              <Upload
                listType='picture-card'
                maxCount={1}
                beforeUpload={() => false}
                fileList={fileList}
                onChange={({ file, fileList: newFileList }: any) => {
                  setFile(file);
                  setFileList(newFileList);
                  return "success";
                }}
                onRemove={() => {
                  setFile(undefined);
                  setFileList([]);
                  setRemoved(true);
                }}
                onPreview={onPreview}
              >
                {!fileList?.length && (
                  <ProdImage>
                    <CameraOutlined />
                  </ProdImage>
                )}
              </Upload>
              <span style={{ color: color.error }}>
                รูปภาพประกอบสินค้า JPG, PNG. Size of 800*800px 1:1
              </span>
            </Form.Item>
          </Row>
          <Row justify={"space-between"} gutter={8}>
            <Col span={12}>
              <Form.Item
                name='productName'
                label='ชื่อของแถม'
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุชื่อของแถม",
                  },
                ]}
              >
                <Input placeholder='ระบุชื่อของแถม' autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='productFreebiesCodeNAV'
                label='รหัสของแถม'
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุรหัสของแถม",
                  },
                ]}
              >
                <Input placeholder='ระบุรหัสของแถม' autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"space-between"} gutter={8}>
            <Col span={12}>
              <Form.Item name='productGroup' label='ชื่อหมวด'>
                <Input placeholder='ระบุชื่อหมวด' autoComplete='off' />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item name='baseUnitOfMeaEn' label='ขนาด'>
                <Input placeholder='ระบุขนาด' autoComplete='off' />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name='baseUnitOfMeaTh'
                label='หน่วยสินค้า'
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกหน่วยสินค้า",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder='ระบุหน่วย'
                  data={
                    unitMea?.map((z: any) => ({
                      label: z.unitMeasureName,
                      key: z.unitMeasureId,
                      value: z.unitMeasureName,
                    })) || []
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label='คุณสมบัติ' name='description'>
                <TextArea rows={4} placeholder='ระบุคุณสมบัติ' autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          {isEdit && (
            <Form.Item label={"สถานะสินค้า"} name='productFreebiesStatus'>
              <Radio.Group>
                <Radio value={"ACTIVE"}>ใช้งาน</Radio>
                <Radio value={"INACTIVE"}>ปิดการใช้งาน</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Divider />
          <Row justify='space-between' gutter={16}>
            <Col span={21}>
              <Button
                typeButton='primary-light'
                htmlType='submit'
                size='large'
                style={{ width: 136 }}
                onClick={() => setShowCancel(!showCancel)}
                title='ยกเลิก'
              />
            </Col>
            <Col span={3}>
              <Button
                typeButton='primary'
                htmlType='submit'
                size='large'
                style={{ width: 136 }}
                loading={uploading}
                onClick={() =>
                  form
                    .validateFields()
                    .then((f) => {
                      setShowModal(!showModal);
                    })
                    .catch((e) => {
                      return false;
                    })
                }
                title='บันทึก'
              />
            </Col>
          </Row>
        </Form>
      </CardContainer>
      {showModal && (
        <Modal
          centered
          open={showModal}
          closable={false}
          onOk={createFreebie}
          onCancel={() => setShowModal(false)}
          destroyOnClose
          okText={"ยืนยัน"}
          cancelButtonProps={{ style: { color: color.primary, borderColor: color.primary } }}
        >
          {isEdit ? (
            <>
              <Text level={2}>ยืนยันการบันทึกสินค้า</Text>
              <br />
              <Text level={5} color='Text3'>
                โปรดตรวจสอบรายละเอียดของแถมอีกครั้งก่อนกดยืนยัน
              </Text>
              <br />
              <Text level={5} color='Text3'>
                เพราะอาจส่งผลต่อการแสดงผลข้อมูลในระบบ
              </Text>
            </>
          ) : (
            <>
              {" "}
              <Text level={2}>ยืนยันการบันทึกสินค้า</Text>
              <br />
              <Text level={5} color='Text3'>
                โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน
              </Text>
            </>
          )}
        </Modal>
      )}
      {showCancel && (
        <Modal
          centered
          open={showCancel}
          closable={false}
          onOk={() => navigate(`/freebies/freebies`)}
          onCancel={() => setShowCancel(false)}
          destroyOnClose
          okText={"ยืนยัน"}
          cancelButtonProps={{ style: { color: color.primary, borderColor: color.primary } }}
        >
          <Text level={2}>ยกเลิกการแก้ไขของแถม</Text>
          <br />
          <Text level={5} color='Text3'>
            โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน
          </Text>
        </Modal>
      )}
      <Modal open={isDone} footer={null} width={220} closable={false}>
        <FlexCol align='space-around' justify='center' style={{ width: 172, height: 172 }}>
          {isDone ? (
            <CheckCircleTwoTone twoToneColor={color.success} style={{ fontSize: 36 }} />
          ) : (
            <Spin size='large' />
          )}
          <br />
          <Text level={4} align='center'>
            {isDone && (
              <>
                {isEdit ? "แก้ไข" : "สร้าง"}ของแถม
                <br />
                สำเร็จ
              </>
            )}
          </Text>
        </FlexCol>
      </Modal>
    </>
  );
};
