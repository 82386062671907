import React from "react";
import styled from "styled-components";
import { image } from "../../resource";
import Text from "../../components/Text/Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: var(--Gredient-1, linear-gradient(180deg, #8ff2de 0%, #23b7e4 46.88%, #3b78ed 100%));
  padding: 32px;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    display: none;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

function LeftContainer() {
  const width = window.innerWidth / 2;

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image
          src={image.imageLogin}
          style={{
            width: width * 0.6,
          }}
        />
        <Text
          fontWeight={700}
          color='white'
          fontSize={32}
          style={{ marginTop: 24, textAlign: "center", lineHeight: 1.3 }}
        >
          ระบบจัดการการขาย
          <br />
          Sellcoda Back Office
        </Text>
        <Text color='white' align='center'>
          ระบบที่ช่วยคุณจัดการคำสั่งซื้อ และบริหารลูกค้าขององค์กร <br />
          ได้อย่างมีประสิทธิภาพมากยิ่งขึ้น
        </Text>
      </div>
    </Container>
  );
}

export default LeftContainer;
