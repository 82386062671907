import { AxiosResponse } from "axios";
import { BASE_URL, NAV_URL, httpClient } from "../config/develop-config";

const baseUrl = `${BASE_URL}/master`;

const getProductFreebies = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/product-freebies`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const syncProductFreebie = async (params: object) => {
  return await httpClient
    .post(`${NAV_URL}/freebies`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductFreebieDetail = async (productId: number) => {
  return await httpClient
    .get(`${baseUrl}/product-freebies/${productId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductFreebiePromotionDetail = async (productId: number) => {
  return await httpClient
    .get(`${baseUrl}/product-freebies/freebies-promotion/${productId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductFreebieGroup = async (company: string) => {
  return await httpClient
    .get(`${baseUrl}/product-freebies/product-group/${company}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updateProductFreebie = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product-freebies/update-product`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getPromotion = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/promotion`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getPromotionById = async (promotionId: string) => {
  return await httpClient
    .get(`${baseUrl}/promotion/${promotionId}`, { params: { promotionId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const checkPromotionCode = async (params: object) => {
  return await httpClient
    .post(`${baseUrl}/promotion/check-promotion-code`, null, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const createPromotion = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/promotion/create`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updatePromotion = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/promotion/update`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updatePromotionStatus = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/promotion/update-status`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updatePromotionFile = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/promotion/update-file`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const deletePromotion = async (data: object) => {
  return await httpClient
    .delete(`${baseUrl}/promotion`, { data })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getPromotionLog = async (promotionId: string) => {
  return await httpClient
    .post(`${baseUrl}/promotion/promotion-history?promotionId=${promotionId}`, { promotionId })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getReportExcel = async (id: string, company: string) => {
  return await httpClient
    .post(`${baseUrl}/report/report-excel/${id}/${company}`, { id, company })
    .then((res: AxiosResponse) => res)
    .catch((err) => console.log(err));
};

const getActivePromotion = async (company: string, isShowPromotion: boolean) => {
  return await httpClient
    .get(`${baseUrl}/promotion/promotion-active`, { params: { company, isShowPromotion } })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getReportPromotion = async (
  startDate: string,
  endDate: string,
  search: string,
  zone: string[],
  company: string,
) => {
  return await httpClient
    .post(
      `${BASE_URL}/order-cart/report-promotion/promotion-report`,
      zone.length === 0
        ? {
            startDate,
            endDate,
            search,
            company,
          }
        : {
            startDate,
            endDate,
            search,
            zone,
            company,
          },
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getReportPromotionByZone = async (
  zone: string,
  search: string,
  sortFields: string,
  sortDirections: string,
  page: number,
  startDate: string,
  endDate: string,
) => {
  return await httpClient
    .post(`${BASE_URL}/order-cart/report-promotion/promotion-report-by-zone`, {
      zone,
      search,
      sortFields,
      sortDirections,
      page,
      perPage: 10,
      startDate,
      endDate,
    })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getReportWithCustomer = async (id: string, startDate?: string, endDate?: string) => {
  return await httpClient
    .get(`${BASE_URL}/order-cart/report-promotion/customer`, {
      params: {
        id,
        startDate,
        endDate,
      },
    })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getExcelProReportZone = async (
  startDate: string,
  endDate: string,
  search: string,
  zone: string[],
  company: string,
) => {
  return await httpClient
    .post(
      `${BASE_URL}/order-cart/report-document/report-excel/all-zone`,
      zone.length === 0
        ? {
            startDate,
            endDate,
            search,
            company,
          }
        : {
            startDate,
            endDate,
            search,
            zone,
            company,
          },
      { responseType: "arraybuffer" },
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getExcelProReportPromo = async (
  startDate: string,
  endDate: string,
  search: string,
  zone: string[],
  company: string,
) => {
  return await httpClient
    .post(
      `${BASE_URL}/order-cart/report-document/report-excel/all-promotion`,
      zone.length === 0
        ? {
            startDate,
            endDate,
            search,
            company,
          }
        : {
            startDate,
            endDate,
            search,
            zone,
            company,
          },
      { responseType: "arraybuffer" },
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getExcelProReportByZone = async (
  zone: string,
  search: string,
  page: number,
  startDate: string,
  endDate: string,
) => {
  return await httpClient
    .post(
      `${BASE_URL}/order-cart/report-document/report-excel/promotion-by-zone`,
      {
        zone,
        search,
        page,
        perPage: 100,
        startDate,
        endDate,
      },
      { responseType: "arraybuffer" },
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getPromotionQuotaHistory = async (promotionId: string, customerCompanyId: number) => {
  return await httpClient
    .get(`${BASE_URL}/order-cart/promotion-quota/get-order-quota`, {
      params: { promotionId, customerCompanyId },
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getPromotionListReport = async (params: object) => {
  return await httpClient
    .get(`${BASE_URL}/order-cart/report-promotion`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getPromotionOrderDetailReport = async (params: object) => {
  console.log(params);

  return await httpClient
    .get(`${BASE_URL}/order-cart/report-promotion/promotion-order`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getExcelAllProReport = async (
  company: string,
  search: string,
  page: number,
  startDate: string,
  endDate: string,
  promotionStatus: string,
) => {
  return await httpClient
    .post(
      `${BASE_URL}/order-cart/report-document/report-excel/view-all-promotion`,
      {
        company,
        search,
        page,
        take: 100,
        startDate,
        endDate,
        promotionStatus,
      },
      { responseType: "arraybuffer" },
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getExcelOrderProReport = async (
  company: string,
  promotionId: string,
  searchText: string,
  page: number,
  startDate: string,
  endDate: string,
) => {
  return await httpClient
    .post(
      `${BASE_URL}/order-cart/report-document/report-excel/order-in-promotion`,
      {
        company,
        promotionId,
        searchText,
        page,
        take: 100,
        startDate,
        endDate,
      },
      { responseType: "arraybuffer" },
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export {
  getProductFreebies,
  syncProductFreebie,
  getProductFreebieDetail,
  getProductFreebieGroup,
  updateProductFreebie,
  getPromotion,
  getPromotionById,
  checkPromotionCode,
  createPromotion,
  updatePromotion,
  updatePromotionFile,
  updatePromotionStatus,
  deletePromotion,
  getPromotionLog,
  getProductFreebiePromotionDetail,
  getReportExcel,
  getActivePromotion,
  getReportPromotion,
  getReportPromotionByZone,
  getReportWithCustomer,
  getExcelProReportZone,
  getExcelProReportPromo,
  getExcelProReportByZone,
  getPromotionQuotaHistory,
  getPromotionListReport,
  getPromotionOrderDetailReport,
  getExcelAllProReport,
  getExcelOrderProReport,
};
