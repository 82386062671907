import React, { useEffect } from "react";
import Modal from "./Modal";
import { useRecoilState } from "recoil";
import { permissionDenied } from "../../store/permissionDenied";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "antd";
import TextArea from "../Input/TextArea";

interface Props {
  visible: boolean;
  onCancel: () => void;
  callBack: (val) => void;
}

function ModalCancelCRMPoint({ visible, onCancel, callBack }: Props) {
  const [form] = Form.useForm();

  const onFinish = () => {
    const values = form.getFieldsValue();
    callBack(values);
    form.resetFields();
  };
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal visible={visible} onCancel={handleCancel} maskClosable={false} width={500}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "0px 0px 22px 0px",
        }}
      >
        <Text align='left' fontWeight={700} fontSize={24}>
          ยกเลิกเอกสาร
        </Text>
        <Text align='left' fontWeight={400} fontFamily='Sarabun' fontSize={16}>
          โปรดระบุเหตุผลและยืนยันการยกเลิก
        </Text>
      </div>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name='reason'
          rules={[
            {
              required: true,
              message: (
                <Text fontFamily='Sarabun' fontSize={12} fontWeight={400} color='error'>
                  โปรดระบุเหตุลที่ยกเลิก
                </Text>
              ),
            },
          ]}
        >
          <TextArea placeholder='ระบุเหตุผลที่ยกเลิก...' />
        </Form.Item>
      </Form>

      <Row style={{ marginTop: 22 }}>
        <Col span={12} style={{ paddingRight: 5 }}>
          <Button
            typeButton='border-danger'
            title='ยกเลิก'
            style={{ width: "100%" }}
            onClick={handleCancel}
          />
        </Col>
        <Col span={12} style={{ paddingLeft: 5 }}>
          <Button
            typeButton='danger'
            title='ยืนยัน'
            style={{ width: "100%" }}
            onClick={() => form.submit()}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalCancelCRMPoint;
