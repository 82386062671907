export const LIST_DATE = [
  { label: "กำหนดเอง", value: "Custom" },
  { label: "วันนี้", value: "Today" },
  { label: "7 วัน", value: "7Day" },
  { label: "30 วัน", value: "30Day" },
  { label: "3 เดือน", value: "3Month" },
  { label: "6 เดือน", value: "6Month" },
  { label: "12 เดือน", value: "12Month" },
];
export const TARGET = [
  { key: 1, label: "Rebate", value: "REBATE" },
  // { key: 2, label: "แลกของรางวัล", value: "Redeem" },
];
export const GET_POINT = [
  { key: 1, label: "ให้ Sub-Dealer เท่านั้น", value: "ONLY_SUB_DEALER" },
  { key: 2, label: "ให้ Dealer เท่านั้น", value: "ONLY_DEALER" },
];
export const GET_POINT_MAPPING: any = {
  ONLY_SUB_DEALER: "ให้ Sub-Dealer เท่านั้น",
  ONLY_DEALER: "ให้ Dealer เท่านั้น",
};
export const CONDITION_POINT = [
  { key: 1, label: "ขึ้นของสำเร็จ", value: "DELIVERY_SUCCESS" },
  { key: 2, label: "ส่งหลักฐานใบขน", value: "SUBMIT_DELIVERY_DOC" },
  { key: 3, label: "กดรับสินค้า", value: "OPEN_ORDER" },
];
export const CONDITION_POINT_DEALER = [
  { key: 1, label: "ขึ้นของสำเร็จ", value: "DELIVERY_SUCCESS" },
  { key: 2, label: "กดรับสินค้า", value: "OPEN_ORDER" },
];
export const CONDITION_POINT_MAPPING: any = {
  DELIVERY_SUCCESS: "ขึ้นของสำเร็จ",
  SUBMIT_DELIVERY_DOC: "ส่งหลักฐานใบขน",
  OPEN_ORDER: "กดรับสินค้า",
};
export const showDateText = (value: string) => {
  const selectedDate = LIST_DATE.find((date) => date.value === value);
  const dateLabel = selectedDate?.label || "";
  const isToday = dateLabel === "วันนี้";

  return isToday ? dateLabel : `${dateLabel}ล่าสุด`;
};
