import React, { useEffect, useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Col, Pagination, Row } from "antd";
import Text from "../../../components/Text/Text";
import styled from "styled-components";
import color from "../../../resource/color";
import ExpandTable from "../../../components/Table/ExpandTable";
import icon from "../../../resource/icon";
import { showDateText } from "../../../definitions/dataFilter";
import useSorting from "../../../hook/useSorting";
import SortFieldCustom from "../../../components/SortFieldTable/SortFieldCustom";
import { reportPointProduct } from "../../../datasource/ReportCRMPointDatasource";
import moment from "moment";
import { allPointProductEntities } from "../../../entities/ReportPointProductEntity";
import { numberFormatter } from "../../../utility/Formatter";
import { getDateRange } from "../../../utility/dateUtils";

interface Props {
  dateRange: string;
  typeDate: string;
  campaignProgramType: string[];
  campaignProgramCondition: string[];
  objective: string[];
  campaignId: string;
  filterDay: {
    startDate: Date;
    endDate: Date;
  };
}
const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;
const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.background4};
  font-weight: 400;
`;
function ProductList({
  dateRange,
  typeDate,
  campaignProgramType,
  filterDay,
  campaignProgramCondition,
  objective,
  campaignId,
}: Props) {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<allPointProductEntities>();
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);

  useEffect(() => {
    getReportPointProduct();
  }, [
    filterDay,
    objective,
    campaignProgramType,
    campaignProgramCondition,
    sortField,
    sortDirection,
    campaignId,
    page,
  ]);

  const getReportPointProduct = async () => {
    setLoading(true);
    try {
      const { startDate, endDate } = getDateRange(typeDate, filterDay);
      const object = {
        company: userProfile?.company,
        startDate: startDate,
        endDate: endDate,
        campaignId: campaignId,
        objective: objective,
        campaignProgramType: campaignProgramType,
        campaignProgramCondition: campaignProgramCondition,
        sortBy: sortDirection === undefined ? undefined : sortField,
        sortDirection: sortDirection,
        page: page,
        take: take,
      };
      await reportPointProduct(object).then((res) => {
        setData(res);
        setLoading(false);
      });
    } catch (error) {
      console.log("err report-crm-point-product", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setTake(take);
  };

  const column: any[] = [
    {
      title: "ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={row.productImage || icon.emptyProduct}
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                />
                <div>
                  <BaseText>{value}</BaseText>
                </div>
              </div>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ปริมาณที่สั่งซื้อ'
          sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumQuantity");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 2)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>ตัน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ยอดแต้ม'
          sortDirection={sortField === "sumPoint" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumPoint");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumPoint",
      key: "sumPoint",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 2)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>แต้ม</DescText>
            </>
          ),
        };
      },
    },
  ];

  return (
    <CardContainer>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "20px" }}>
        <Col className='gutter-row' span={12}>
          <Text fontSize={18} fontWeight={700} color='Text2'>
            อันดับสินค้า
          </Text>
          <br />
          <Text fontSize={14} fontWeight={400} color='Text2'>
            {typeDate === "Custom" ? dateRange : showDateText(typeDate)}
          </Text>
        </Col>
        <Col className='gutter-row' span={12} style={{ display: "flex", justifyContent: "end" }}>
          <Text fontSize={14} fontWeight={400} color='Text3'>
            ทั้งหมด {data?.count} รายการ
          </Text>
        </Col>
      </Row>
      <ExpandTable
        loading={loading}
        columns={column}
        data={data?.productList || []}
        scrollY={420}
        textEmpty='ไม่มีรายการสินค้า'
        imgEmpty={icon.emptyHistoryPoint}
      />
      <div style={{ padding: "22px  0px 22px 0px", display: "flex", justifyContent: "end" }}>
        <Pagination
          current={page}
          total={data?.count}
          onChange={(pageNumber) => handlePageChange(pageNumber, take)}
          pageSize={take}
          showSizeChanger={false}
        />
      </div>
    </CardContainer>
  );
}

export default ProductList;
