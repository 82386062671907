import { DownloadOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Table, Divider, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import { CardContainer } from "../../../../components/Card/CardContainer";
import Input from "../../../../components/Input/Input";
import { FlexCol, FlexRow } from "../../../../components/Container/Container";
import Text from "../../../../components/Text/Text";
import moment from "moment";
import { color } from "../../../../resource";
import { useNavigate } from "react-router-dom";
import { reportShopDetail } from "../../../../datasource/CampaignDatasource";
import { DealerType, dealerTypeName } from "../../../../definitions/campaign";
import PageTitleNested from "../../../../components/PageTitle/PageTitleNested";
import BreadCrumb from "../../../../components/BreadCrumb/BreadCrumb";
import Descriptions from "../../../../components/Description/Descriptions";
import { PrimaryTabsContainer } from "../../../../components/AntdTabs/AntdTabs";
import TabCurrentCampaign from "./TabCurrentCampaign";
import TabProduct from "./TabProduct";
import TabCampaignHistory from "./TabCampaignHistory";
import { BASE_URL } from "../../../../config/develop-config";
import axios, { AxiosRequestConfig } from "axios";
import { numberFormatter } from "../../../../utility/Formatter";

export const ShopReportDetail: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const type = pathSplit[3];
  const id = pathSplit[4];

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [shopData, setShopData] = useState<any>();

  useEffect(() => {
    if (!loading) fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await reportShopDetail(
      type?.toUpperCase() === DealerType.DEALER ? { dealerId: id } : { subDealerId: id },
    )
      .then((res) => {
        console.log(res);
        setShopData(res);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDownloadExcel = async () => {
    const headers = { "Content-Type": "blob" };
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${BASE_URL}/crm/report-order-point/excel-report-campaign-shop`,
      responseType: "arraybuffer",
      headers,
      data: {
        dealerId: type?.toUpperCase() === DealerType.DEALER ? parseInt(id) : undefined,
        subDealerId: type?.toUpperCase() === DealerType.DEALER ? undefined : parseInt(id),
      },
    };
    const response = await axios(config);

    const file = new Blob([response.data], {
      type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `report-${type?.toUpperCase() === DealerType.DEALER ? "DL" : "SD"}-${shopData?.customerNo}-${
        new Date().toISOString().split("T")[0]
      }.xlsx`,
    );

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={"ร้านค้า : " + (shopData?.dealerName || "")}
        showBack
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการแคมเปญพอยท์", path: "/crm/customerPointReport" },
              {
                text: "รายละเอียดร้านค้า",
                path: window.location.pathname,
              },
            ]}
          />
        }
        extra={
          <Button
            title='ดาวน์โหลด'
            icon={<DownloadOutlined />}
            typeButton='primary-light'
            onClick={onDownloadExcel}
          />
        }
      />
    );
  };

  const shopDetails = [
    {
      label: "รหัสร้าน | ชื่อร้านค้า",
      value: (shopData?.customerNo || "-") + " | " + (shopData?.customerName || "-"),
    },
    {
      label: "ประเภทคู่ค้า",
      value: shopData?.customerType || "-",
    },
    {
      label: "เขต",
      value: shopData?.zone || "-",
    },
    {
      label: "แต้มสะสม",
      value: `${numberFormatter(shopData?.currentPoint, 2) || 0} แต้ม`,
    },
  ];

  const tabsItems = [
    {
      key: "1",
      label: "แคมเปญที่เข้าร่วม (ปัจจุบัน)",
      children: <TabCurrentCampaign />,
    },
    {
      key: "2",
      label: "ยอดสินค้าที่สั่งซื้อ",
      children: <TabProduct />,
    },
    { key: "3", label: "ประวัติแคมเปญที่ผ่านมา", children: <TabCampaignHistory /> },
  ];

  return (
    <>
      <CardContainer>
        <PageTitle />
        <br />
        <Divider />
        <Text fontWeight={700}>ข้อมูลร้านค้า</Text>
        <Row gutter={16}>
          {shopDetails.map((p, i) => (
            <Col span={12} key={i}>
              <Descriptions {...p} key={i} noColon />
            </Col>
          ))}
        </Row>
        <br />
        <br />
        <PrimaryTabsContainer>
          <Tabs items={tabsItems} className='tabs' />
        </PrimaryTabsContainer>
      </CardContainer>
    </>
  );
};
