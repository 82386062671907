import { Col, Row } from "antd";
import React, { useState } from "react";

import icon from "../../resource/icon";
import Button from "../Button/Button";
import ConfirmModal from "../Modal/ConfirmModal";
import { color } from "../../resource";
import { RetweetOutlined } from "@ant-design/icons";
import { SaleEntity } from "../../entities/SaleEntity";
import Text from "../Text/Text";
import { SaleListDatasource } from "../../datasource/SaleListDatasource";
import ModalSendMail from "../Modal/ModalSendMail";

interface Props {
  data: SaleEntity;
  onClick: () => void;
  text?: string;
}

export default function SendMailAgain({ onClick, text, data }: Props) {
  const [countDown, setCountDown] = useState<number>(0);
  const [modalMail, setModalMail] = useState(false);

  const onSendMail = async () => {
    const result = await SaleListDatasource.sendMailAgain(data.userStaffId);
    if (result.success) {
      setCountDown(120);
      const countdownInterval = setInterval(() => {
        setCountDown((prevCountDown) => {
          if (prevCountDown > 0) {
            return prevCountDown - 1;
          } else {
            clearInterval(countdownInterval);
            return prevCountDown;
          }
        });
      }, 1000);
    }
  };

  return (
    <>
      <span
        style={{
          fontSize: "14px",
          fontFamily: "Sarabun",
          fontWeight: "lighter",
          color: color.primary,
          cursor: countDown > 0 ? "no-drop" : "pointer",
        }}
        onClick={
          countDown > 0
            ? () => {
                undefined;
              }
            : () => {
                onSendMail();
                setModalMail(true);
              }
        }
      >
        {countDown > 0 ? (
          <>
            <Text
              fontWeight={400}
              fontSize={14}
              fontFamily='Sarabun'
              color='Text3'
              style={{ textDecoration: "underline" }}
            >
              {text}
            </Text>
            <RetweetOutlined style={{ color: color.Disable }} />
            <br />
            <Text fontFamily='Sarabun' color='Text1' fontSize={14}>
              {String(Math.floor(countDown / 60)).padStart(2, "0")}:
              {String(countDown % 60).padStart(2, "0")} น.
            </Text>
          </>
        ) : (
          <>
            <Text
              color='primary'
              fontSize={14}
              fontFamily='Sarabun'
              style={{ textDecoration: "underline" }}
            >
              {text}
            </Text>
            <RetweetOutlined />
          </>
        )}
      </span>

      <ModalSendMail
        visible={modalMail}
        label1={
          <Text color='Text1' fontFamily='Sarabun'>
            ลิ้งยืนยันตัวตนผู้ใช้ถูกส่งไปอีเมลผู้ใช้งาน{" "}
            <Text color='Text1' fontFamily='Sarabun' fontWeight={600}>
              {data?.email}
            </Text>{" "}
            เรียบร้อยแล้ว
          </Text>
        }
        label2='กรุณาแจ้งผู้ใช้งาน เพื่อดำเนินการยืนยันตัวตนผู้ใช้งานในอีเมล และกำหนดรหัสผ่านสำหรับเข้าสู่ระบบ'
        label3={
          <Text color='Text1' fontFamily='Sarabun'>
            สามารถกดส่งอีเมลอีกครั้งภายใน{" "}
            <Text color='Text1' fontFamily='Sarabun'>
              {String(Math.floor(countDown / 60)).padStart(2, "0")}:
              {String(countDown % 60).padStart(2, "0")}
            </Text>{" "}
            น.
          </Text>
        }
        onConfirm={() => {
          setModalMail(false);
        }}
      />
    </>
  );
}
