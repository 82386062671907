import { AxiosResponse } from "axios";
import { BASE_URL, NAV_URL, httpClient } from "../config/develop-config";

const getUserShop = async (params: object) => {
  return await httpClient
    .get(`${BASE_URL}/auth/user-shop/usershop-company`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getUserShopById = async (usershopId: string) => {
  return await httpClient
    .get(`${BASE_URL}/auth/user-shop/${usershopId}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const createUserShop = async (params: object) => {
  return await httpClient
    .post(`${BASE_URL}/auth/user-shop`, params)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const updateUserShop = async (params: object) => {
  return await httpClient
    .patch(`${BASE_URL}/auth/user-shop/update-user-shop-ex`, params)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const deleteUserShop = async (id: string) => {
  return await httpClient
    .delete(`${BASE_URL}/auth/user-shop/${id}`)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
export { getUserShop, createUserShop, updateUserShop, getUserShopById, deleteUserShop };
