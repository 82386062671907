import React from "react";

interface HistoryOrderPoint {
  statusAfter?: string;
}

interface EstimatePointFormatterProps {
  historyOrderPoint: HistoryOrderPoint[];
}

export const EstimatePointFormatter: React.FC<EstimatePointFormatterProps> = ({
  historyOrderPoint,
}) => {
  const lastStatus = historyOrderPoint.slice(-1)[0]?.statusAfter;
  const result = lastStatus && lastStatus !== "APPROVE" ? "≈" : null;

  return <span>{result}</span>;
};
