import { UnorderedListOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import color from "../../../../resource/color";
import ExpandTable from "../../../../components/Table/ExpandTable";
import icon from "../../../../resource/icon";
import Text from "../../../../components/Text/Text";
import { Col, Row } from "antd";
import {
  CampaignProductEntities,
  OrderEntities,
  ProductEntities,
  ProgramEntities,
} from "../../../../entities/CampaignShopEntity";
import styled from "styled-components";
import {
  campaignProgramConditionLabel,
  campaignProgramTypeLabel,
} from "../../../../definitions/campaign";
import { numberFormatter } from "../../../../utility/Formatter";
import moment from "moment";
import Tag from "../../../../components/Tag/Tag";
import { getMapStatusWord, MAP_STATUS_CRM } from "../../../../definitions/approvePoint";
import { campaignShopDetail } from "../../../../datasource/CampaignDatasource";
import useSorting from "../../../../hook/useSorting";
import SortFieldCustom from "../../../../components/SortFieldTable/SortFieldCustom";
import Pagination from "../../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { getOrdersNO } from "../../../../datasource/OrderDatasourc";

const LabelText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

interface Props {
  tabs: "ORDER" | "PRODUCT" | "PROGRAM";
  type: any;
  campaignId: string;
  shopType: string;
  shopId: number;
}
function TabListTable({ tabs, type, campaignId, shopType, shopId }: Props) {
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [tabData, setTabData] = useState<{
    count: number;
    data: OrderEntities[] | ProgramEntities[] | ProductEntities[];
    sumQuantityAll?: number;
    sumAmountAll?: number;
  }>({
    count: 0,
    data: [],
  });
  const navigate = useNavigate();
  const company = JSON.parse(localStorage.getItem("company")!);
  useEffect(() => {
    fetchCampShopDetailData();
  }, [tabs, campaignId, shopType, shopId, sortField, sortDirection]);

  const fetchCampShopDetailData = async () => {
    setLoading(true);
    try {
      await campaignShopDetail(
        campaignId,
        shopType,
        shopId,
        tabs,
        sortDirection === undefined ? undefined : sortField,
        sortDirection,
        page,
        10,
      ).then((res) => {
        if (tabs === "PRODUCT") {
          setTabData({
            count: res.count,
            data: res.data,
            sumQuantityAll: res.sumQuantityAll,
            sumAmountAll: res.sumAmountAll,
          });
        } else {
          setTabData({
            count: res.count,
            data: res.data,
          });
        }
      });
    } catch (error) {
      console.log("err crm/campaign/campaign-shop-detail", error);
    } finally {
      setLoading(false);
    }
  };
  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys((prev) => [...prev, record.orderPointId]);
    } else {
      setExpandedRowKeys((prev) => prev.filter((key) => key !== record.orderPointId));
    }
  };
  const onChangePage = async (id) => {
    try {
      const res = await getOrdersNO(id);
      if (res?.orderId) {
        navigate(`/order/${res.orderId}`);
      } else {
        console.warn("Order ID not found in response.");
      }
    } catch (error) {
      console.error("Error fetching order details in onChangePage:", error);
    }
  };

  const columnWidths = {
    base: [150, 400, 150, 130, 100, 130, 30],
    dealer: [70, 150, 70, 80, 60, 60, 20, 70],
  };
  const columnsOrder: any[] = [
    {
      title: company.companyCode !== "ICPL" ? "เลขที่ส่งเอกสาร" : "เลขที่สั่งขาย (SO)",
      dataIndex: company.companyCode !== "ICPL" ? "orderPointCode" : "soNo",
      key: company.companyCode !== "ICPL" ? "orderPointCode" : "soNo",
      width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][0],
      fixed: "left",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{row.status === "DRAFT" ? "กำลังอัปเดท" : value || "-"}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>
                {moment(row.dateDelivery).format("DD/MM/YYYY , HH:mm")}
              </LabelText>
            </>
          ),
        };
      },
    },
    ...(company.companyCode === "ICPL"
      ? [
          {
            title: "เลขที่ใบขน (PDO)",
            dataIndex: " pdoNo",
            key: " pdoNo",
            width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][7],
            render: (value: any, row: any, index: number) => {
              return {
                children: <LabelText>{value || "-"}</LabelText>,
              };
            },
          },
        ]
      : []),
    {
      title: () => (
        <SortFieldCustom
          title={"จำนวนสินค้า"}
          sortDirection={sortField === "countProduct" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("countProduct");
            setPage(1);
          }}
        />
      ),
      dataIndex: "countProduct",
      key: "countProduct",
      width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][1],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{numberFormatter(value, 0)}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>รายการ</LabelText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title={company.companyCode !== "ICPL" ? "ปริมาณที่สั่งซื้อ" : "ปริมาณลัง"}
          sortDirection={sortField === "sumAmount" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumAmount");
            setPage(1);
          }}
        />
      ),
      dataIndex: company.companyCode !== "ICPL" ? "sumAmount" : " sumQuantity",
      key: company.companyCode !== "ICPL" ? "sumAmount" : " sumQuantity",
      width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][2],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{numberFormatter(value, 0)}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>
                {company.companyCode !== "ICPL" ? row.unitName : "ลัง"}
              </LabelText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title={company.companyCode !== "ICPL" ? "จำนวนตัน" : "ปริมาณกระสอบ"}
          sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumQuantity");
            setPage(1);
          }}
        />
      ),
      dataIndex: company.companyCode !== "ICPL" ? "sumQuantity" : "sumAmount",
      key: company.companyCode !== "ICPL" ? "sumQuantity" : "sumAmount",
      width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][3],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{numberFormatter(value, 2)}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>
                {company.companyCode !== "ICPL" ? "ตัน" : "กระสอบ"}
              </LabelText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='สถานะ'
          sortDirection={sortField === "status" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("status");
            setPage(1);
          }}
        />
      ),
      dataIndex: "status",
      key: "status",
      width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][4],
      render: (value: any, row: any, index: number) => {
        const statusWord = getMapStatusWord(type);
        return {
          children: (
            <Tag color={MAP_STATUS_CRM[value]}>
              <Text color='white' fontFamily='IBM Plex Sans Thai' fontSize={14} fontWeight={700}>
                {statusWord[value]}
              </Text>
            </Tag>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='แต้มที่ได้รับ'
          sortDirection={sortField === "sumPoint" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumPoint");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumPoint",
      key: "sumPoint",
      width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][5],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.status === "DRAFT" ? (
                <LabelText>กำลังคำนวณ</LabelText>
              ) : (
                <LabelText>{numberFormatter(value, 2)}</LabelText>
              )}

              <LabelText style={{ color: color.Text3, fontSize: 14 }}>แต้ม</LabelText>
            </>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: columnWidths[company.companyCode !== "ICPL" ? "base" : "dealer"][6],
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                <UnorderedListOutlined
                  style={{ color: color["primary"] }}
                  onClick={() => {
                    type === "SUB_DEALER"
                      ? navigate(`/crm/approvePointList/${row.orderPointId}`)
                      : onChangePage(row.orderPointCode);
                  }}
                />
              </div>
            </>
          ),
        };
      },
    },
  ];
  const columnsPointProgram: any[] = [
    {
      title: "ชื่อพอยท์โปรแกรม",
      dataIndex: "campaignProgramName",
      key: "campaignProgramName",
      render: (value: any, row: any, index: number) => {
        return {
          children: <LabelText>{value}</LabelText>,
        };
      },
    },
    {
      title: "รูปแบบการให้แต้ม",
      dataIndex: "campaignProgramType",
      key: "campaignProgramType",
      render: (value: any, row: any, index: number) => {
        return {
          children: <LabelText>{campaignProgramTypeLabel[value]}</LabelText>,
        };
      },
    },
    {
      title: "เงื่อนไขการได้รับแต้ม",
      dataIndex: "campaignProgramCondition",
      key: "campaignProgramCondition",
      render: (value: any, row: any, index: number) => {
        return {
          children: <LabelText>{campaignProgramConditionLabel[value]}</LabelText>,
        };
      },
    },
    {
      title: "จำนวนคำสั่งซื้อ",
      dataIndex: "countOrder",
      key: "countOrder",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{numberFormatter(value, 0)}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>รายการ</LabelText>
            </>
          ),
        };
      },
    },
    {
      title: company.companyCode !== "ICPL" ? "ปริมาณที่สั่งซื้อ" : "ปริมาณลัง",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{numberFormatter(value, 0)}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>
                {company.companyCode !== "ICPL" ? "ตัน" : "ลัง"}
              </LabelText>
            </>
          ),
        };
      },
    },
    ...(company.companyCode === "ICPL"
      ? [
          {
            title: "ปริมาณกระสอบ",
            dataIndex: "sumAmount",
            key: "sumAmount",
            render: (value: any, row: any, index: number) => {
              return {
                children: (
                  <>
                    <LabelText>{numberFormatter(value, 0)}</LabelText>
                    <LabelText style={{ color: color.Text3, fontSize: 14 }}>กระสอบ</LabelText>
                  </>
                ),
              };
            },
          },
        ]
      : []),
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "sumPoint",
      key: "sumPoint",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{numberFormatter(value, 2)}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>แต้ม</LabelText>
            </>
          ),
        };
      },
    },
  ];
  const columnsProductOrder: any[] = [
    {
      title: "สินค้า",
      dataIndex: "productName",
      key: "productName",
      width: "40%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={row.productImage || icon.emptyProduct}
                style={{ width: "50px", height: "50px", objectFit: "cover", marginRight: "10px" }}
              />
              <div>
                <LabelText>{value}</LabelText>
                <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                  {row.productCommonName}
                </Text>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LabelText style={{ color: color.Text3, fontSize: 14 }}>
                    {row.commonName || "-"}
                  </LabelText>
                </div>
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: "ขนาด",
      dataIndex: "saleUom",
      key: "saleUom",
      width: "15%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{value}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>{row.productCode}</LabelText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title={company.companyCode !== "ICPL" ? "ปริมาณที่สั่งซื้อ" : "ปริมาณลัง"}
          sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumQuantity");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      width: "15%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>
                {numberFormatter(value, company.companyCode !== "ICPL" ? 2 : 0)}
              </LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>
                {company.companyCode !== "ICPL" ? row.saleUomTh : "ลัง"}
              </LabelText>
            </>
          ),
        };
      },
    },
    ...(company.companyCode === "ICPL"
      ? [
          {
            title: () => (
              <SortFieldCustom
                title='ปริมาณกระสอบ'
                sortDirection={sortField === "sumAmount" ? sortDirection : undefined}
                onSortChange={() => {
                  handleSortChange("sumAmount");
                  setPage(1);
                }}
              />
            ),
            dataIndex: "sumAmount",
            key: "sumAmount",
            width: "15%",
            render: (value: any, row: any, index: number) => {
              return {
                children: (
                  <>
                    <LabelText>{numberFormatter(value, 0)}</LabelText>
                    <LabelText style={{ color: color.Text3, fontSize: 14 }}>กระสอบ</LabelText>
                  </>
                ),
              };
            },
          },
        ]
      : []),
    {
      title: () => (
        <SortFieldCustom
          title='ยอดแต้ม'
          sortDirection={sortField === "sumPoint" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumPoint");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumPoint",
      key: "sumPoint",
      width: "15%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <LabelText>{numberFormatter(value, 2)}</LabelText>
              <LabelText style={{ color: color.Text3, fontSize: 14 }}>แต้ม</LabelText>
            </>
          ),
        };
      },
    },
  ];
  const expandedRowRender = (record: OrderEntities) => {
    const expandColumns: any = [
      {
        dataIndex: "productName",
        key: "productName",
        width: 170,
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={row.productImage || icon.emptyProduct}
                  style={{ width: "50px", height: "50px", objectFit: "cover", marginRight: "10px" }}
                />
                <div>
                  <LabelText>{value}</LabelText>
                  <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                    {row.productCommonName}
                  </Text>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LabelText style={{ color: color.Text3, fontSize: 14 }}>
                      {row.commonName || ""}
                    </LabelText>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={icon.rating}
                      style={{ width: "14px", height: "14px", marginRight: "4px" }}
                    />

                    <LabelText style={{ fontSize: 14 }}>
                      {numberFormatter(row.programPoint) || 0} แต้ม{row.saleUomProgramTh ? "/" : ""}
                      {row.saleUomProgramTh}
                    </LabelText>
                  </div>
                </div>
              </div>
            ),
          };
        },
      },
      {
        dataIndex: "saleUom",
        key: "saleUom",
        width: 60,
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <LabelText>{value}</LabelText>
                <LabelText style={{ fontSize: 14, color: color.Text3 }}>
                  {row.productCode}
                </LabelText>
              </>
            ),
          };
        },
      },
      {
        dataIndex: "amount",
        key: "amount",
        width: 90,
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <LabelText>{numberFormatter(value, 0)}</LabelText>
                <LabelText style={{ fontSize: 14, color: color.Text3 }}>{row.unitName}</LabelText>
              </>
            ),
          };
        },
      },
      {
        dataIndex: "quantity",
        key: "quantity",
        width: 150,
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <LabelText>{numberFormatter(value, 2)}</LabelText>
                <LabelText style={{ fontSize: 14, color: color.Text3 }}>
                  {row.saleUomProgramTh}
                </LabelText>
              </>
            ),
          };
        },
      },
      {
        dataIndex: "point",
        key: "point",
        width: 150,
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <LabelText>{numberFormatter(value, 2)}</LabelText>
                <LabelText style={{ fontSize: 14, color: color.Text3 }}>แต้ม</LabelText>
              </>
            ),
          };
        },
      },
    ];

    const expandDataSource = record.campaignProduct.map(
      (product: CampaignProductEntities, index: number) => ({
        key: index.toString(),
        productId: product.productId,
        productImage: product.productImage,
        productName: product.productName,
        productCommonName: product.productCommonName,
        programPoint: product.programPoint,
        quantity: product.quantity,
        saleUomProgramTh: product.saleUomProgramTh,
        point: product.point,
        saleUom: product.saleUom,
        productCode: product.productCode,
        amount: product.amount,
        unitName: product.unitName,
      }),
    );

    return (
      <ExpandTable
        showHeader={false}
        rowBackgroundColor='#E4E7E5'
        columns={expandColumns}
        data={expandDataSource}
        loading={false}
        size='small'
        scrollY={300}
      />
    );
  };
  const renderSummary = () => {
    if (tabs === "ORDER" || tabs === "PRODUCT") {
      return (
        <Row style={{ padding: "12px 0px 16px 0px" }}>
          <Col span={4}>
            <Text fontFamily='Sarabun' fontSize={16} fontWeight={400} color='Text3'>
              {tabs === "ORDER"
                ? "คำสั่งซื้อทั้งหมด"
                : tabs === "PRODUCT"
                  ? "ปริมาณที่สั่งซื้อทั้งหมด"
                  : undefined}
            </Text>
          </Col>
          <Col span={8}>
            <Text fontFamily='Sarabun' fontSize={16} fontWeight={400} color='Text1'>
              {company.companyCode === "ICPL" && tabs === "PRODUCT" ? (
                <>
                  {numberFormatter(tabData.sumQuantityAll || 0, 0)} ลัง, &nbsp; &nbsp;
                  {numberFormatter(tabData.sumAmountAll || 0, 0)} กระสอบ
                </>
              ) : (
                <>
                  {tabs === "ORDER"
                    ? `${numberFormatter(tabData.count, 0)} รายการ`
                    : tabs === "PRODUCT"
                      ? `${numberFormatter(tabData.sumQuantityAll || 0, 2)} ตัน`
                      : undefined}
                </>
              )}
            </Text>
          </Col>
          <Col span={12} style={{ direction: "rtl" }}>
            <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text1'>
              {tabs === "PRODUCT"
                ? `ทั้งหมด ${numberFormatter(tabData.count, 0)} รายการ`
                : undefined}
            </Text>
          </Col>
        </Row>
      );
    }
    return null;
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  return (
    <>
      {renderSummary()}
      <ExpandTable
        left='160px'
        columns={
          tabs === "ORDER"
            ? columnsOrder
            : tabs === "PROGRAM"
              ? columnsPointProgram
              : tabs === "PRODUCT"
                ? columnsProductOrder
                : []
        }
        scrollX={tabs === "ORDER" && company.companyCode === "ICPL" ? 1400 : "max-content"}
        loading={loading}
        data={(tabData.data || []).map((item: any, index: number) => {
          return {
            ...item,
            id: index + 1,
          };
        })}
        imgEmpty={icon.emptyHistoryPoint}
        textEmpty={
          tabs === "ORDER"
            ? "ไม่มีรายการคำสั่งซื้อ"
            : tabs === "PROGRAM"
              ? "ไม่มีรายการพอยท์โปรแกรม"
              : tabs === "PRODUCT"
                ? "ไม่มีรายการสินค้า"
                : undefined
        }
        expandable={{
          expandedRowRender: tabs === "ORDER" ? expandedRowRender : undefined,
          expandedRowKeys,
          onExpand: handleExpand,
        }}
        rowKey='orderPointId'
      />
      {tabData.count > 0 && (
        <Pagination
          currentPage={page}
          total={tabData?.count || 0}
          pageSize={10}
          onPageChange={(pageNumber) => handlePageChange(pageNumber)}
        />
      )}
    </>
  );
}

export default TabListTable;
