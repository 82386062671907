import moment from "moment";
//report crm
//startDate, endDate สลับกัน
export const getDateRange = (type, filterDay) => {
  const startDate =
    type === "Today"
      ? moment().startOf("day").format("YYYY-MM-DD HH:mm:ss ")
      : moment(filterDay.endDate).format("YYYY-MM-DD");

  const endDate =
    type === "Today"
      ? moment().endOf("day").format("YYYY-MM-DD HH:mm")
      : moment(filterDay.startDate).format("YYYY-MM-DD");

  return { startDate, endDate };
};

export const extractTime = (dateTime: string): string => {
  const time = dateTime.split(" ")[1];
  return time;
};
