import { Col, Divider, Modal, Row, Upload, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import Text from "../Text/Text";
import { LinkOutlined, UploadOutlined } from "@ant-design/icons";
import Button from "../Button/Button";
import axios, { AxiosRequestConfig } from "axios";

const UploadArea = styled.div`
  padding: 12px;
  border-radius: 8px;
  background: #eff2f9;
`;

export const ImportModal = ({
  open,
  toggleOpen,
  title,
  linkText,
  uploadFile,
  setFile,
  urlTemplate,
  onUpload,
}: {
  open: boolean;
  toggleOpen: () => void;
  title: string;
  linkText?: string;
  uploadFile?: any;
  setFile: (file: any) => void;
  urlTemplate: string;
  onUpload: () => void;
}) => {
  const [showFileError, setFileError] = useState(false);
  return (
    <Modal open={open} footer={null} closable={false}>
      <Text level={2}>{title}</Text>
      <br />
      <br />
      {linkText && (
        <>
          <Text
            level={6}
            color='primary'
            onClick={async () => {
              console.log("download");
              const headers = { "Content-Type": "blob" };
              const config: AxiosRequestConfig = {
                method: "POST",
                url: urlTemplate,
                responseType: "arraybuffer",
                headers,
              };
              const response = await axios(config);
              const file = new Blob([response.data], {
                type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });

              const url = window.URL.createObjectURL(file);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `import-product.xlsx`);

              document.body.appendChild(link);
              link.click();
              link.parentNode?.removeChild(link);
            }}
            style={{ cursor: "pointer" }}
          >
            <LinkOutlined />
            &nbsp;{linkText}
          </Text>
          <br />
          <br />
        </>
      )}
      <UploadArea>
        <Upload
          listType='picture'
          className='upload-list-inline'
          beforeUpload={(file) => {
            const isExcel =
              file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              file.type === "text/csv";
            if (!isExcel) {
              //   message.error(`อัปโหลดเฉพาะไฟล์ .CSV หรือ .XLSX เท่านั้น`);
              setFileError(true);
              return false;
            }
            setFileError(false);
            return isExcel || Upload.LIST_IGNORE;
          }}
          customRequest={({ file, onSuccess }) => {
            if (onSuccess) {
              onSuccess(file);
            }
          }}
          onChange={({ file }: any) => {
            console.log(file);
            const isExcel =
              file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              file.type === "text/csv";
            if (!isExcel) {
              setFile(undefined);
              return;
            }

            if (file.status === "uploading") {
              setFile(file);
              file.status = "done";
            }
            if (file.status === "done") {
              setFile(file);
            }
            return "success";
          }}
          onRemove={() => {
            setFileError(false);
            setFile(undefined);
          }}
          maxCount={1}
        >
          {!uploadFile && !showFileError && (
            <Button title='อัปโหลดไฟล์' icon={<UploadOutlined />} />
          )}
        </Upload>
      </UploadArea>
      {showFileError && (
        <Text level={6} color='error'>
          อัปโหลดเฉพาะไฟล์ .CSV หรือ .XLSX เท่านั้น
        </Text>
      )}
      <br />
      <Divider />
      <Row justify='space-between'>
        <Col span={4}>
          <Button title='ยกเลิก' typeButton='primary-light' onClick={toggleOpen} />
        </Col>
        <Col span={6}>
          <Button
            title='ยืนยันอัปโหลด'
            typeButton={!uploadFile ? "disabled" : "primary"}
            disabled={!uploadFile}
            onClick={onUpload}
          />
        </Col>
      </Row>
    </Modal>
  );
};
