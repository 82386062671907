import React, { useEffect, useState } from "react";
import { Empty, Spin, Table, type TablePaginationConfig, type TableProps } from "antd";
import styled from "styled-components";
import { ColumnsType } from "antd/es/table";
import { SortOrder, TableRowSelection } from "antd/es/table/interface";
import Text from "../Text/Text";
import icon from "../../resource/icon";
import color from "../../resource/color";

interface CustomTableProps {
  columns: ColumnsType<any>;
  data: any;
  size?: any;
  loading?: boolean;
  isPagination?: boolean;
  summary?: any;
  rowSelection?: TableRowSelection<any> | undefined;
  scrollX?: any;
  scrollY?: any;
  onScroll?: React.UIEventHandler<HTMLDivElement> | undefined;
  rowKey?: any | undefined;
  imgEmpty?: any;
  textEmpty?: string;
  colorHead?: boolean;
  footer?: any;
  expandable?: any;
  rowBackgroundColor?: string;
  isOpenExpand?: boolean;
  showHeader?: boolean;
  expandRowBackgroundColor?: any;
  left?: string;
  className?: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: SortOrder;
  filters?: Record<string, any>;
}

const StyledTable = styled(Table)<
  TableProps<any> & {
    rowBackgroundColor?: string;
    expandRowBackgroundColor?: string;
    left?: string;
  }
>`
  .ant-table-cell {
    font-family: "IBM Plex Sans Thai", sans-serif;
    font-weight: 700 !important;
    font-size: 16px;
    color: ${color.Text1};
  }

  .ant-table-thead > tr > th {
    background-color: ${color.background1};
    border-bottom: 0px;
    padding: 12px 16px;
  }

  .ant-table-expanded-row-fixed {
    font-weight: 400 !important;
  }

  .ant-table-row-expand-icon {
    width: 26px;
    height: 26px;
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 0px;
    background: none;
  }
  :where(.css-dev-only-do-not-override-apn68).ant-table-wrapper .ant-table-expanded-row-fixed {
    padding: 0px;
    padding-left: ${(props) => props.left || "64px"};
  }
  :where(.css-apn68).ant-table-wrapper .ant-table-expanded-row-fixed {
    padding: 0px;
    padding-left: ${(props) => props.left || "64px"};
  }

  .ant-table-expanded-row {
    background-color: ${(props) => props.expandRowBackgroundColor || "#f0f0f0"};
  }
`;

const ExpandTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  summary,
  loading = false,
  rowSelection,
  scrollX = "auto",
  scrollY,
  onScroll,
  rowKey = "key",
  imgEmpty,
  textEmpty = "ไม่พบข้อมูล",
  size,
  colorHead = false,
  footer,
  expandable,
  rowBackgroundColor,
  expandRowBackgroundColor,
  isOpenExpand = false,
  showHeader = true,
  left,
  className,
}) => {
  const [value, setValue] = useState<any>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setValue(data);
        if (isOpenExpand) {
          const initialExpandedKeys = [
            data[0] ? (typeof rowKey === "function" ? rowKey(data[0], 0) : data[0][rowKey]) : "",
          ];
          setExpandedRowKeys(initialExpandedKeys);
        } else {
          setExpandedRowKeys([]);
        }
        setTableParams((prevParams) => ({
          ...prevParams,
          pagination: {
            ...prevParams.pagination,
            total: data?.length,
          },
        }));
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };
    fetchTableData();
  }, [tableParams.pagination?.pageSize, data, isOpenExpand]);

  return (
    <Spin size='large' spinning={loading}>
      <StyledTable
        className={className}
        showHeader={showHeader}
        rowBackgroundColor={rowBackgroundColor}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={value || []}
        pagination={false}
        rowKey={rowKey}
        size={size}
        footer={footer}
        onScroll={onScroll}
        showSorterTooltip={false}
        expandable={
          expandable
            ? {
                ...expandable,
                expandIconColumnIndex: columns.length,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <img
                      style={{ display: "flex" }}
                      src={icon.expandUp}
                      onClick={(e) => onExpand(record, e)}
                      className='ant-table-row-expand-icon'
                    />
                  ) : (
                    <img
                      style={{ display: "flex" }}
                      src={icon.expandDown}
                      onClick={(e) => onExpand(record, e)}
                      className='ant-table-row-expand-icon'
                    />
                  ),
                expandedRowKeys,
                onExpand: (expanded, record) => {
                  const keys = expanded
                    ? [...expandedRowKeys, record[rowKey]]
                    : expandedRowKeys.filter((key) => key !== record[rowKey]);
                  setExpandedRowKeys(keys);
                },
              }
            : undefined
        }
        scroll={{
          x: scrollX,
          y: scrollY,
        }}
        locale={{
          emptyText: (
            <div style={{ padding: "62px 0px 62px 0px" }}>
              <Empty image={imgEmpty || Empty.PRESENTED_IMAGE_SIMPLE} description={null} />
              <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                {textEmpty}
              </Text>
            </div>
          ),
        }}
        summary={summary}
        expandRowBackgroundColor={expandRowBackgroundColor}
        left={left}
      />
    </Spin>
  );
};

export default ExpandTable;
