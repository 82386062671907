import { useState } from "react";

const useSorting = () => {
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC" | undefined>(undefined);

  const handleSortChange = (field: string) => {
    setSortField((prevSortField) => {
      if (prevSortField === field) {
        setSortDirection((prevDirection) => {
          if (prevDirection === "ASC") return "DESC";
          if (prevDirection === "DESC") return undefined;
          return "ASC";
        });
      } else {
        setSortField(field);
        setSortDirection("ASC");
      }
      return field;
    });
  };

  return { sortField, sortDirection, handleSortChange };
};

export default useSorting;
