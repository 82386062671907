import { SearchOutlined } from "@ant-design/icons";
import { Col, ConfigProvider, Image, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text/Text";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Select/Select";
import { image } from "../../../resource";
import { dateFormatter } from "../../../utility/Formatter";
import { CardContainer } from "../../../components/Card/CardContainer";
import Input from "../../../components/Input/Input";
import icon from "../../../resource/icon";
import { STATUS_COLOR_MAPPING } from "../../../definitions/product";
import { productGroupEntities } from "../../../entities/MasterDataEntity";
import { getMasterProductGroup } from "../../../datasource/ProductDatasource";
import TablePagination from "../../../components/Table/TablePagination";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../../store/RoleAtom";
import { checkPermission } from "../../../components/Permission/Permission";

export const ProductGroupPage: React.FC = () => {
  const navigate = useNavigate();
  const roleData = useRecoilValue(roleAtom);
  const isCreate = checkPermission(["productGroup", "create"], roleData);
  const isEdit = checkPermission(["productGroup", "edit"], roleData);
  const [productGroup, setProductGroup] = useState<{
    count: number;
    groups: productGroupEntities[];
  }>({
    count: 0,
    groups: [],
  });
  const pageSize = 6;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const userProfile = JSON.parse(localStorage.getItem("profile")!);

  const getProductGroupList = async () => {
    await getMasterProductGroup({
      page: page,
      take: pageSize,
      status: status,
      sortBy: "updatedAt",
      sortDirection: "DESC",
      search: search,
      company: userProfile.company,
    }).then((res) => setProductGroup(res));
  };

  useEffect(() => {
    getProductGroupList();
  }, [status, search, page]);

  const PageTitle = (
    <>
      <Row align='middle' gutter={16}>
        <Col span={20}>
          <Text level={3} fontWeight={700}>
            รายการกลุ่มสินค้า
          </Text>
        </Col>
        {isCreate && (
          <Col className='gutter-row' span={4}>
            <Button
              type='primary'
              title='+ เพิ่มกลุ่มสินค้า'
              height={40}
              onClick={() => navigate("/generalSettings/CreateProductGroup/create")}
            />
          </Col>
        )}
      </Row>
      <br />
      <Row justify={"space-between"} gutter={8}>
        <Col span={20}>
          <Input
            placeholder='ค้นหาชื่อกลุ่มสินค้า'
            prefix={<SearchOutlined style={{ color: "grey" }} />}
            onChange={(e) => setSearch(e.target.value)}
            autoComplete='off'
          />
        </Col>
        <Col span={4}>
          <Select
            allowClear
            placeholder='สถานะทั้งหมด'
            data={[
              { key: "", value: "", label: "สถานะทั้งหมด" },
              { key: "ACTIVE", value: "ACTIVE", label: "เปิดใช้งาน" },
              { key: "INACTIVE", value: "INACTIVE", label: "ปิดใช้งาน" },
            ]}
            style={{ width: "100%" }}
            onChange={(e) => {
              setPage(1);
              setStatus(e);
            }}
          />
        </Col>
      </Row>
    </>
  );
  const columns: any = [
    {
      title: "ชื่อกลุ่มสินค้า",
      dataIndex: "productGroupName",
      key: "productGroupName",
      width: "60%",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text style={{ textAlign: "center" }}>{value}</Text>,
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"start"} gutter={8}>
              <Tag color={STATUS_COLOR_MAPPING[value]}>
                {value === "ACTIVE" ? "เปิดใช้งาน" : "ปิดใช้งาน"}
              </Tag>
            </Row>
          ),
        };
      },
    },
    {
      title: "อัปเดตโดย",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={5}>{dateFormatter(value, false)}</Text>
              <br />
              <Text level={6} color='Text3'>
                {row.updatedBy || "-"}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      hidden: !isEdit,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"start"} gutter={8}>
              <Col span={6}>
                <Button
                  onClick={() =>
                    navigate(`/generalSettings/CreateProductGroup/${row.productGroupId}`)
                  }
                  typeButton='secondary'
                  icon={<img src={icon.iconEdit} style={{ width: "18px", height: "18px" }} />}
                />
              </Col>
            </Row>
          ),
        };
      },
    },
  ].filter((x) => !x.hidden);
  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center", padding: "10%" }}>
      <Image src={image.emptyTableBrand} preview={false} style={{ width: 70, height: 70 }} />
      <br />
      <Text color='Text3'>ไม่มีรายการกลุ่มสินค้า</Text>
    </div>
  );

  return (
    <>
      <CardContainer>
        {PageTitle}
        <br />
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <TablePagination
            columns={columns}
            data={
              (productGroup.groups || []).map((el: any, idx: number) => {
                return {
                  ...el,
                  key: el.productGroupId,
                  id: (page - 1) * 10 + idx + 1,
                };
              }) || []
            }
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: 6,
              total: productGroup?.count || 0,
              onChange: (page) => {
                setPage(page);
              },
            }}
          />
        </ConfigProvider>
      </CardContainer>
    </>
  );
};
