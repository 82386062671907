import React, { useEffect, useState } from "react";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import styled from "styled-components";
import { Spin } from "antd";

const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

function LoginPage() {
  const [loading, setLoading] = React.useState(false);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Spin size='large' />
      </div>
    );
  }
  return (
    <Container>
      <LeftContainer />
      <RightContainer setLoading={setLoading} />
    </Container>
  );
}

export default LoginPage;
