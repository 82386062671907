import { Col, Modal, Pagination, Row, Tabs } from "antd";
import Text from "../../Text/Text";
import MinMaxInput from "../../Input/MinMaxInput";
import styled from "styled-components";
import color from "../../../resource/color";
import ExpandTable from "../../Table/ExpandTable";
import icon from "../../../resource/icon";
import { useEffect, useState } from "react";
import useSorting from "../../../hook/useSorting";
import SortFieldCustom from "../../SortFieldTable/SortFieldCustom";
import {
  reportPointShopParticipate,
  reportProgramDetail,
} from "../../../datasource/ReportCRMPointDatasource";
import {
  allShopParticipateEntities,
  programDetailEntities,
  programDetailEntities_INIT,
} from "../../../entities/ReportPointCampaignEntity";
import moment from "moment";
import { CONDITION_POINT_MAPPING, GET_POINT_MAPPING } from "../../../definitions/dataFilter";
import { numberFormatter } from "../../../utility/Formatter";

interface Props {
  visible: boolean;
  onCancel: () => void;
  campaignProgramId: string;
  campaignProgramType: string | undefined;
}
const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 0;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;
const Header = styled.div`
  font-family: "IBM Plex Sans Thai";

  font-size: 14px;
  color: ${color.Text1};
  font-weight: 700;
`;
function ModalShopParticipate({
  visible,
  onCancel,
  campaignProgramId,
  campaignProgramType,
}: Props) {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [isMin, setIsMin] = useState<number | undefined>(undefined);
  const [isMax, setIsMax] = useState<number | undefined>(undefined);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [loading, setLoading] = useState(false);
  const [programDetail, setProgramDetail] = useState<programDetailEntities>(
    programDetailEntities_INIT,
  );
  const [shopParticipate, setShopParticipate] = useState<allShopParticipateEntities>();
  const [participateType, setParticipateType] = useState<string>("PARTICIPATE");
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (visible) {
        fetchAllData();
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [visible, campaignProgramId, participateType, isMin, isMax, page]);

  const fetchAllData = async () => {
    setLoading(true);
    try {
      const object = {
        campaignProgramType: campaignProgramType,
        participateType: participateType,
        campaignProgramId: campaignProgramId,
        minQuantity: isMin,
        maxQuantity: isMax,
        sortBy: sortField,
        sortDirection: sortDirection,
        page: page,
        take: take,
      };
      const [programDetailRes, shopParticipateRes] = await Promise.all([
        reportProgramDetail(campaignProgramId),
        reportPointShopParticipate(object),
      ]);
      setProgramDetail(programDetailRes);
      setShopParticipate(shopParticipateRes);
      const sumCount =
        shopParticipateRes.summary.noParticipate + shopParticipateRes.summary.participate;
      setCount(sumCount);
    } catch (error) {
      console.error("Error fetching data programDetail or shopParticipate", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setTake(take);
  };
  const handleValuesChange = (newMin: number | undefined, newMax: number | undefined) => {
    setIsMin(newMin);
    setIsMax(newMax);
  };

  const tabsItems = [
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          เข้าร่วมแล้ว {`(${numberFormatter(shopParticipate?.summary.participate || 0, 0)})`}
        </Text>
      ),
      key: "PARTICIPATE",
    },
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          ยังไม่เข้าร่วม {`(${numberFormatter(shopParticipate?.summary.noParticipate || 0, 0)})`}
        </Text>
      ),
      key: "NO_PARTICIPATE",
    },
  ];

  const column: any = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "5%",
      render: (value: any, row: any, index: number) => {
        return {
          children: <>{index + 1}</>,
        };
      },
    },
    {
      title: "รหัสร้าน",
      dataIndex: "cusComNo",
      key: "cusComNo",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
            </>
          ),
        };
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "cusComName",
      key: "cusComName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
            </>
          ),
        };
      },
    },
    {
      title: "เขต",
      dataIndex: "cusComZone",
      key: "cusComZone",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='คำสั่งซื้อ'
          sortDirection={sortField === "countOrder" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("countOrder")}
        />
      ),
      dataIndex: "countOrder",
      key: "countOrder",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 0)}</BaseText>
            </>
          ),
        };
      },
    },
  ];
  if (userProfile?.company === "ICPL") {
    column.push(
      {
        title: () => (
          <SortFieldCustom
            title='ปริมาณที่สั่งซื้อ (ลัง)'
            sortDirection={sortField === "amount" ? sortDirection : undefined}
            onSortChange={() => handleSortChange("amount")}
          />
        ),
        dataIndex: "amount",
        key: "amount",
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <BaseText>{value}</BaseText>
              </>
            ),
          };
        },
      },
      {
        title: () => (
          <SortFieldCustom
            title='ปริมาณที่สั่งซื้อ (กระสอบ)'
            sortDirection={sortField === "amount" ? sortDirection : undefined}
            onSortChange={() => handleSortChange("amount")}
          />
        ),
        dataIndex: "amount",
        key: "amountSack",
        render: (value: any, row: any, index: number) => {
          return {
            children: (
              <>
                <BaseText>{value}</BaseText>
              </>
            ),
          };
        },
      },
    );
  }

  column.push({
    title: () => (
      <SortFieldCustom
        title='ปริมาณที่สั่งซื้อ (ตัน)'
        sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
        onSortChange={() => handleSortChange("sumQuantity")}
      />
    ),
    dataIndex: "sumQuantity",
    key: "sumQuantity",
    width: "18%",
    render: (value: any, row: any, index: number) => {
      return {
        children: (
          <>
            <BaseText>{numberFormatter(value, 2)}</BaseText>
          </>
        ),
      };
    },
  });

  column.push({
    title: () => (
      <SortFieldCustom
        title='แต้มที่ได้รับ (แต้ม)'
        sortDirection={sortField === "sumPoint" ? sortDirection : undefined}
        onSortChange={() => handleSortChange("sumPoint")}
      />
    ),
    dataIndex: "sumPoint",
    key: "sumPoint",
    width: "16%",
    render: (value: any, row: any, index: number) => {
      return {
        children: (
          <>
            <BaseText>{numberFormatter(value, 2)}</BaseText>
          </>
        ),
      };
    },
  });
  return (
    <ModalStyled
      open={visible}
      onCancel={onCancel}
      maskClosable={false}
      width={1200}
      footer={false}
      centered
    >
      <div
        style={{
          display: "flex",
          padding: "24px 24px 10px 24px",
        }}
      >
        <Text fontSize={20} fontWeight={700}>
          ร้านค้า
        </Text>
        <Text
          fontSize={14}
          fontWeight={400}
          fontFamily='Sarabun'
          color='grayishBlue'
          style={{ display: "flex", alignItems: "center", marginLeft: 8 }}
        >
          ทั้งหมด {count} ร้าน
        </Text>
      </div>
      <div style={{ backgroundColor: color.background1 }}>
        <div style={{ padding: "24px" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Header>แคมเปญ:</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header> ระยะเวลา:</Header>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>{programDetail.campaignName}</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>
                {moment(programDetail.startDate).format("DD/MM/YYYY") +
                  " - " +
                  moment(programDetail.endDate).format("DD/MM/YYYY")}
              </Header>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: "14px" }}>
            <Col className='gutter-row' span={8}>
              <Header>พอยท์โปรแกรม:</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header>รูปแบบการให้แต้ม:</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header>เงื่อนไขการให้แต้ม:</Header>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>{programDetail.campaignProgramName}</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>
                {GET_POINT_MAPPING[programDetail.campaignProgramType]}
              </Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>
                {CONDITION_POINT_MAPPING[programDetail.campaignProgramCondition]}
              </Header>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ padding: "24px" }}>
        <Row gutter={{ xs: 8, sm: 16 }} justify='space-between' style={{ width: "100%" }}>
          <Col
            className='gutter-row'
            span={participateType === "PARTICIPATE" ? 18 : 24}
            style={{ marginRight: "auto" }}
          >
            <Tabs
              items={tabsItems}
              onChange={(key: string) => {
                setParticipateType(key);
              }}
              defaultValue={"PARTICIPATE"}
            />
          </Col>
          {participateType === "PARTICIPATE" && (
            <Col className='gutter-row' span={6} style={{ left: "3%" }}>
              <MinMaxInput
                initialMin={isMin}
                initialMax={isMax}
                onChange={(min, max) => handleValuesChange(min, max)}
              />
            </Col>
          )}
        </Row>

        <ExpandTable
          loading={loading}
          columns={column}
          data={shopParticipate?.data}
          textEmpty='ไม่มีรายการ'
          imgEmpty={icon.emptyHistoryPoint}
          scrollY={300}
        />
        <div style={{ padding: "22px  0px 22px 0px", display: "flex", justifyContent: "end" }}>
          <Pagination
            current={page}
            total={10}
            onChange={(pageNumber) => handlePageChange(pageNumber, take)}
            pageSize={take}
            showSizeChanger={false}
          />
        </div>
      </div>
    </ModalStyled>
  );
}

export default ModalShopParticipate;
