import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row, Tabs } from "antd";
import Text from "../../../../components/Text/Text";
import color from "../../../../resource/color";
import { icon } from "../../../../resource";
import TablePagination from "../../../../components/Table/TablePagination";
import { dateFormatter, numberFormatter } from "../../../../utility/Formatter";
import Button from "../../../../components/Button/Button";
import ModalCancelCRMPoint from "../../../../components/Modal/ModalCancelCRMPoint";
import { OrderProductEntity, PointDetailEntity } from "../../../../entities/PointApproveEntity";
import { cancelOrderPoint, getPointDetail } from "../../../../datasource/OrderPointDatasource";
import { EstimatePointFormatter } from "../../../../utility/EstimatePointFormatter";
import SumProducts from "../../../../components/ApprovePoint/SumTotalOrderProduct";

const Texts = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
  word-break: break-word;
  max-width: 100%;
  white-space: normal;
`;

const DetailContainer = styled.div`
  margin-top: 22px;
`;

const DetailBox = styled.div`
  background-color: ${color.background1};
  border-radius: 10px;
  padding: 24px;
  margin: 22px 0px 22px 0px;
  border: 0.3px solid #e5eaee;
`;

const ClickableImage = styled.img`
  width: 20px;
  height: 22px;
  margin-right: 8px;
`;
interface Props {
  data: PointDetailEntity;
  newFetch: () => void;
}

function DetailTab({ data, newFetch }: Props) {
  const [showModal, setShowModal] = useState(false);
  const orderPointId = window.location.pathname.split("/")[3];
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [programData, setProgramData] = useState<OrderProductEntity[]>();
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [tabKeyCampaign, setTabKeyCampaign] = useState<string>("true");

  const lastHistoryOrderPoint = data.historyOrderPoint.slice(-1)[0];
  const statusAfter = lastHistoryOrderPoint?.statusAfter;

  useEffect(() => {
    if (data.orderProduct) {
      setProgramData(data.orderProduct);
      setActiveButton(data.campaigns.length > 0 ? data.campaigns[0].campaignId : null);
    }
  }, [data.orderProduct]);

  const handlePrograms = async (id: string) => {
    setLoading(true);
    setActiveButton(id);
    try {
      const params = {
        orderPointId: orderPointId,
        campaignId: id,
      };
      const res = await getPointDetail(params);
      setProgramData(res.orderProduct || []);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching program details:", error);
    } finally {
      setLoading(false);
    }
  };

  const groupProducts = (products: OrderProductEntity[]) => {
    return products.reduce((acc: any, item) => {
      const existing = acc.find((i: OrderProductEntity) => i.unitName === item.unitName);
      if (existing) {
        existing.amount += item.amount;
      } else {
        acc.push({
          unitName: item.unitName,
          amount: item.amount,
        });
      }
      return acc;
    }, []);
  };
  const groupedOrderProduct = data.orderProduct.length > 0 ? groupProducts(programData || []) : [];
  const groupedOrderProductWithoutProduct =
    data.orderProductWithOutCampaign.length > 0
      ? groupProducts(data.orderProductWithOutCampaign)
      : [];

  const grouped =
    tabKeyCampaign === "true" ? groupedOrderProduct : groupedOrderProductWithoutProduct;

  const onChange = (key) => {
    setTabKeyCampaign(key);
    if (key === "true") {
      setProgramData(data.orderProduct);
    } else {
      setProgramData(data.orderProductWithOutCampaign);
    }
  };
  const tabsItems = [
    {
      label: <Text fontSize={14}>เข้าร่วม {`(${data.orderProductCount || 0})`}</Text>,
      key: "true",
    },
    {
      label: (
        <Text fontSize={14}>ไม่เข้าร่วม {`(${data.orderProductWithOutCampaign.length || 0})`}</Text>
      ),
      key: "false",
    },
  ];

  const columns: any[] = [
    {
      title: "ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={row.image || icon.emptyProduct}
                style={{ width: "50px", height: "50px", objectFit: "cover", marginRight: "10px" }}
              />
              <div>
                <Texts>{value}</Texts>
                <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                  {row.productCommonName}
                </Text>
                {tabKeyCampaign === "true" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={icon.rating}
                      style={{ width: "14px", height: "14px", marginRight: "4px" }}
                    />

                    <Texts>
                      {numberFormatter(row.programPoints) || 0} แต้ม{row.saleUomTh ? "/" : ""}
                      {row.saleUomTh}
                    </Texts>
                  </div>
                )}
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: "ขนาด",
      dataIndex: "saleUom",
      key: "saleUom",
      width: "16%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Texts>{value}</Texts>
            </>
          ),
        };
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "amount",
      key: "amount",
      width: "16%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Texts>{numberFormatter(value, 0)}</Texts>
              <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                {row.unitName}
              </Text>
            </>
          ),
        };
      },
    },
    tabKeyCampaign === "true"
      ? {
          title: "แต้มที่ได้รับ",
          dataIndex: "point",
          key: "point",
          width: "16%",
          sorter: (a, b) => a.point - b.point,
          render: (value: any, row: any, index: number) => {
            return {
              children: (
                <>
                  <Texts>{numberFormatter(value, 2)}</Texts>
                  <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                    แต้ม
                  </Text>
                </>
              ),
            };
          },
        }
      : {},
  ];

  return (
    <DetailContainer>
      <Text fontSize={16} fontWeight={700}>
        รายละเอียด
      </Text>

      <DetailBox>
        <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={10}>
                <Texts>เลขที่ส่งเอกสาร :</Texts>
              </Col>
              <Col span={14} style={{ display: "flex", alignItems: "center" }}>
                <Texts style={{ marginRight: 8 }}>{data?.orderPointCode}</Texts>
                <Texts style={{ color: color.placeholder }}>{`(${dateFormatter(
                  data.dateDelivery,
                  true,
                )})`}</Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={10}>
                <Texts>ร้านที่ซื้อ (Sub-Dealer) :</Texts>
              </Col>
              <Col span={14}>
                <Texts>{data.subDealerName}</Texts>
                <Texts style={{ color: color.placeholder }}>
                  {data.subDealerNo} {data.subDealerNo || data.subDealerZone ? "•" : "-"}{" "}
                  {data.subDealerZone}
                </Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]}>
              <Col span={10}>
                <Texts>ร้านที่ขาย (Dealer) :</Texts>
              </Col>
              <Col span={14}>
                <Texts>{data.dealerName}</Texts>
                <Texts style={{ color: color.placeholder }}>
                  {data.dealerNo} {data.dealerNo || data.dealerZone ? "•" : "-"} {data.dealerZone}
                </Texts>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Texts>วันที่ขน :</Texts>
              </Col>
              <Col span={12}>
                <Texts>{dateFormatter(data.dateDelivery)}</Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Texts>ภาพใบขน :</Texts>
              </Col>
              <Col span={12}>
                <div
                  style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                  onClick={() => window.open(`${data.deliverySheet}`, "_blank")}
                >
                  <ClickableImage src={icon.viewDoc} alt='View Document' />
                  <Texts style={{ color: color.primary }}>ดูภาพใบขน</Texts>
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Texts>ยอดแต้มที่ได้รับ :</Texts>
              </Col>
              <Col span={12}>
                <Texts>
                  <EstimatePointFormatter historyOrderPoint={data.historyOrderPoint} />
                  &nbsp;
                  {numberFormatter(data.sumPoint, 2)} แต้ม
                </Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: data.status === "REJECT" ? 10 : 0 }}>
              <Col span={12}>
                <Texts>
                  {userProfile?.company === "ICPL" ? "ยอดสินค้าที่เข้าร่วม" : "ยอดตันที่เข้าร่วม :"}
                </Texts>
              </Col>
              <Col span={12}>
                {userProfile?.company === "ICPL" ? (
                  <Texts>
                    {grouped.map(
                      (item, index) =>
                        numberFormatter(item?.amount, 0) +
                        " " +
                        item?.unitName +
                        (grouped.length > 1 && index < grouped.length - 1 ? ", " : ""),
                    )}
                  </Texts>
                ) : (
                  <Texts>{numberFormatter(data.sumQuantity, 2)} ตัน</Texts>
                )}
              </Col>
            </Row>
            {data.status === "REJECT" && (
              <Row gutter={[0, 12]}>
                <Col span={12}>
                  <Texts style={{ color: color.error }}>เหตุผลไม่อนุมัติ :</Texts>
                </Col>
                <Col span={12}>
                  <Texts>{data.reason}</Texts>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </DetailBox>

      <Text fontSize={16} fontWeight={700}>
        สินค้าและแคมเปญที่เข้าร่วม
      </Text>
      <div style={{ margin: "18px 0px 0px 0px" }}>
        <Tabs
          type='card'
          items={tabsItems}
          onChange={(key: string) => {
            onChange(key);
          }}
          defaultValue={"true"}
        />
      </div>

      {tabKeyCampaign === "true" && (
        <div style={{ display: "flex", padding: "10px 0px", gap: "10px" }}>
          {data.campaigns.map((item, index) => (
            <Button
              key={item.campaignId}
              typeButton={activeButton === item.campaignId ? "navy-blue" : "primary-light"}
              title={item.campaignName}
              style={{
                width: "max-content",
              }}
              onClick={() => handlePrograms(item.campaignId)}
            />
          ))}
        </div>
      )}

      <TablePagination
        columns={columns}
        isLoading={loading}
        data={(programData || []).map((item: any, index: number) => {
          return {
            ...item,
            id: index + 1,
          };
        })}
        scroll={{ x: "max-content" }}
      />
      {tabKeyCampaign === "true" ? (
        <>
          {data.orderProduct.length > 0 && (
            <SumProducts
              products={programData || []}
              grouped={grouped}
              tabKeyCampaign={tabKeyCampaign}
              statusAfter={statusAfter}
              historyOrderPoint={data.historyOrderPoint}
            />
          )}
        </>
      ) : (
        <>
          {data.orderProductWithOutCampaign.length > 0 && (
            <SumProducts
              products={data.orderProductWithOutCampaign}
              grouped={grouped}
              tabKeyCampaign={tabKeyCampaign}
              statusAfter={statusAfter}
              historyOrderPoint={data.historyOrderPoint}
            />
          )}
        </>
      )}
      {data.status === "CANCEL" ? (
        <>
          <Text fontSize={16} fontWeight={700}>
            หมายเหตุการยกเลิก
          </Text>
          <DetailBox>
            <Texts>{data.reason}</Texts>
          </DetailBox>
        </>
      ) : (
        <Button
          typeButton='danger'
          title='ยกเลิกเอกสาร'
          style={{ width: 143 }}
          onClick={() => setShowModal(true)}
        />
      )}

      <ModalCancelCRMPoint
        visible={showModal}
        onCancel={() => setShowModal(false)}
        callBack={async (value) => {
          if (value) {
            const params = {
              orderPointId: orderPointId,
              userType: userProfile?.role,
              reason: value.reason,
              updateBy: `${userProfile.firstname} ${userProfile.lastname}`,
            };
            try {
              const res = await cancelOrderPoint(params);
              if (res.success) {
                newFetch();
                console.log("Cancel order point successful:", res);
              }
            } catch (error) {
              console.error("Error canceling order point:", error);
            } finally {
              setShowModal(false);
            }
          }
        }}
      />
    </DetailContainer>
  );
}

export default DetailTab;
