import { SearchOutlined } from "@ant-design/icons";
import { Col, ConfigProvider, Image, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text/Text";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Select/Select";
import { image } from "../../../resource";
import { dateFormatter } from "../../../utility/Formatter";
import { CardContainer } from "../../../components/Card/CardContainer";
import Input from "../../../components/Input/Input";
import icon from "../../../resource/icon";
import { STATUS_COLOR_MAPPING } from "../../../definitions/product";
import { getMasterProductCate } from "../../../datasource/ProductDatasource";
import { productCateEntities } from "../../../entities/MasterDataEntity";
import TablePagination from "../../../components/Table/TablePagination";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../../store/RoleAtom";
import { checkPermission } from "../../../components/Permission/Permission";

export const ProdCategoryPage: React.FC = () => {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;
  const roleData = useRecoilValue(roleAtom);
  const isCreate = checkPermission(["prodCategory", "create"], roleData);
  const isEdit = checkPermission(["prodCategory", "edit"], roleData);

  const [productCate, setProductCate] = useState<{ count: number; groups: productCateEntities[] }>({
    count: 0,
    groups: [],
  });
  const pageSize = 6;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const getProductCate = async () => {
    const payload: any = {
      page: page,
      take: pageSize,
      company: company,
      search: search,
      status: status,
      sortBy: "updatedAt",
      sortDirection: "DESC",
    };

    const res = await getMasterProductCate(payload);
    setProductCate({ count: res.count || 0, groups: res.groups });
  };

  useEffect(() => {
    getProductCate();
  }, [search, status, page]);

  const PageTitle = (
    <>
      <Row align='middle' gutter={16}>
        <Col span={20}>
          <Text level={3} fontWeight={700}>
            รายการหมวดสินค้า
          </Text>
        </Col>
        {isCreate && (
          <Col className='gutter-row' span={4}>
            <Button
              type='primary'
              title='+ เพิ่มหมวดสินค้า'
              height={40}
              onClick={() => navigate("/generalSettings/CreateProdCategory/create")}
            />
          </Col>
        )}
      </Row>
      <br />
      <Row justify={"space-between"} gutter={8}>
        <Col span={20}>
          <Input
            placeholder='ค้นหาชื่อหมวดสินค้า'
            prefix={<SearchOutlined style={{ color: "grey" }} />}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
            autoComplete='off'
            allowClear
          />
        </Col>
        <Col span={4}>
          <Select
            allowClear
            placeholder='สถานะทั้งหมด'
            data={[
              { key: "", value: "", label: "สถานะทั้งหมด" },
              { key: "ACTIVE", value: "ACTIVE", label: "เปิดใช้งาน" },
              { key: "INACTIVE", value: "INACTIVE", label: "ปิดใช้งาน" },
            ]}
            style={{ width: "100%" }}
            onChange={(e) => {
              setPage(1);
              setStatus(e);
            }}
          />
        </Col>
      </Row>
    </>
  );
  const columns: any = [
    {
      title: "ชื่อหมวดสินค้า",
      dataIndex: "productCategoryName",
      key: "productCategoryName",
      width: "60%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify='center' align='middle'>
              <Col span={8}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "66px",
                    width: "180px",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    src={row.productCategoryImage || image.emptyProductBrand}
                    height={66}
                    width={180}
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </Col>
              <Col span={16}>
                <Text style={{ textAlign: "center" }}>{value}</Text>
              </Col>
            </Row>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"start"} gutter={8}>
              <Tag color={STATUS_COLOR_MAPPING[value]}>
                {value === "ACTIVE" ? "เปิดใช้งาน" : "ปิดใช้งาน"}
              </Tag>
            </Row>
          ),
        };
      },
    },
    {
      title: "อัปเดตโดย",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text level={5}>{dateFormatter(value, false)}</Text>
              <br />
              <Text level={6} color='Text3'>
                {row.updatedBy || "-"}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      hidden: !isEdit,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"start"} gutter={8}>
              <Col span={6}>
                <Button
                  onClick={() =>
                    navigate(`/generalSettings/CreateProdCategory/${row.productCategoryId}`)
                  }
                  typeButton='secondary'
                  icon={<img src={icon.iconEdit} style={{ width: "18px", height: "18px" }} />}
                />
              </Col>
            </Row>
          ),
        };
      },
    },
  ].filter((x) => !x.hidden);

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center", padding: "10%" }}>
      <Image src={image.emptyTableBrand} preview={false} style={{ width: 70, height: 70 }} />
      <br />
      <Text color='Text3'>ไม่มีรายการหมวดสินค้า</Text>
    </div>
  );

  return (
    <>
      <CardContainer>
        {PageTitle}
        <br />
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <TablePagination
            columns={columns}
            data={
              (productCate.groups || []).map((el: any, idx: number) => {
                return {
                  ...el,
                  key: el.productCategoryId,
                  id: (page - 1) * 10 + idx + 1,
                };
              }) || []
            }
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: 6,
              total: productCate?.count || 0,
              onChange: (page) => {
                setPage(page);
              },
            }}
          />
        </ConfigProvider>
      </CardContainer>
    </>
  );
};
