import React, { useEffect, useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Col, Pagination, Row, Tabs } from "antd";
import Text from "../../../components/Text/Text";
import styled from "styled-components";
import color from "../../../resource/color";
import ExpandTable from "../../../components/Table/ExpandTable";
import icon from "../../../resource/icon";
import { showDateText } from "../../../definitions/dataFilter";
import useSorting from "../../../hook/useSorting";
import SortFieldCustom from "../../../components/SortFieldTable/SortFieldCustom";
import { reportPointZone } from "../../../datasource/ReportCRMPointDatasource";
import moment from "moment";
import { allCrmPointZoneEntities } from "../../../entities/ReportPointZoneEntity";
import { numberFormatter } from "../../../utility/Formatter";
import { getDateRange } from "../../../utility/dateUtils";

interface Props {
  dateRange: string;
  typeDate: string;
  campaignProgramType: string[];
  campaignProgramCondition: string[];
  objective: string[];
  campaignId: string;
  filterDay: {
    startDate: Date;
    endDate: Date;
  };
}
const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;
const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.background4};
  font-weight: 400;
`;

const ZoneList = ({
  dateRange,
  typeDate,
  campaignProgramType,
  filterDay,
  campaignProgramCondition,
  objective,
  campaignId,
}: Props) => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<allCrmPointZoneEntities>();
  const [userType, setUserType] = useState<string | undefined>(
    campaignProgramType.includes("ONLY_SUB_DEALER") ? "SUB_DEALER" : "DEALER",
  );
  const [sumUserType, setSumUserType] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);

  useEffect(() => {
    getReportPointZone();
  }, [
    filterDay,
    objective,
    campaignProgramType,
    campaignProgramCondition,
    sortField,
    sortDirection,
    userType,
    campaignId,
    page,
  ]);

  const getReportPointZone = async () => {
    setLoading(true);
    try {
      const { startDate, endDate } = getDateRange(typeDate, filterDay);
      const object = {
        company: userProfile?.company,
        startDate: startDate,
        endDate: endDate,
        campaignId: campaignId,
        objective: objective,
        campaignProgramType: campaignProgramType,
        campaignProgramCondition: campaignProgramCondition,
        userType: userType,
        sortBy: sortDirection === undefined ? undefined : sortField,
        sortDirection: sortDirection,
        page: page,
        take: take,
      };
      await reportPointZone(object).then((res) => {
        if (campaignProgramType.length === 1) {
          const summary = res.summary?.dealer + res.summary?.subDealer;
          setSumUserType(summary);
        }
        setData(res);
        setLoading(false);
      });
    } catch (error) {
      console.log("err report-crm-point-zone", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setTake(take);
  };
  const column: any[] = [
    {
      title: "เขต",
      dataIndex: "zone",
      key: "zone",
      render: (value: any, row: any, index: number) => {
        return {
          children: <BaseText>{value}</BaseText>,
        };
      },
    },
    {
      title: "จำนวนร้านค้า",
      dataIndex: "countShop",
      key: "countShop",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 0)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>ร้าน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='จำนวนคำสั่งซื้อ'
          sortDirection={sortField === "countOrder" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("countOrder");
            setPage(1);
          }}
        />
      ),
      dataIndex: "countOrder",
      key: "countOrder",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 0)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>รายการ</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ปริมาณที่สั่งซื้อ'
          sortDirection={sortField === "sumQuantity" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumQuantity");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 2)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>ตัน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='รอรับแต้ม'
          sortDirection={sortField === "sumPointWaitApprove" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumPointWaitApprove");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumPointWaitApprove",
      key: "sumPointWaitApprove",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 2)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>แต้ม</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ได้รับแต้ม'
          sortDirection={sortField === "sumPointApprove" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumPointApprove");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumPointApprove",
      key: "sumPointApprove",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 2)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>แต้ม</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ยกเลิกแต้ม'
          sortDirection={sortField === "sumPointCancel" ? sortDirection : undefined}
          onSortChange={() => {
            handleSortChange("sumPointCancel");
            setPage(1);
          }}
        />
      ),
      dataIndex: "sumPointCancel",
      key: "sumPointCancel",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{numberFormatter(value, 2)}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>แต้ม</DescText>
            </>
          ),
        };
      },
    },
  ];
  const tabsItems = [
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          Dealer ({numberFormatter(data?.summary.dealer || 0, 0)})
        </Text>
      ),
      key: "DEALER",
    },
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          Sub-Dealer ({numberFormatter(data?.summary.subDealer || 0, 0)})
        </Text>
      ),
      key: "SUB_DEALER",
    },
  ];
  return (
    <CardContainer>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "20px" }}>
        <Col className='gutter-row' span={12}>
          <Text fontSize={18} fontWeight={700} color='Text2'>
            เขตการขาย
          </Text>
          <br />
          <Text fontSize={14} fontWeight={400} color='Text2'>
            {typeDate === "Custom" ? dateRange : showDateText(typeDate)}
          </Text>
        </Col>
        {campaignProgramType.length === 1 && (
          <Col className='gutter-row' span={12} style={{ display: "flex", justifyContent: "end" }}>
            <Text fontSize={14} fontWeight={400} color='Text3'>
              ทั้งหมด {numberFormatter(sumUserType, 0)} เขต
            </Text>
          </Col>
        )}
      </Row>
      {campaignProgramType.length > 1 && (
        <Tabs
          items={tabsItems}
          onChange={(key: string) => {
            setUserType(key);
          }}
          defaultValue={userType}
        />
      )}
      <ExpandTable
        loading={loading}
        columns={column}
        data={data?.data || []}
        scrollY={420}
        textEmpty='ไม่มีรายการเขตการขาย'
        imgEmpty={icon.emptyHistoryPoint}
      />
      <div style={{ padding: "22px  0px 22px 0px", display: "flex", justifyContent: "end" }}>
        <Pagination
          current={page}
          total={sumUserType}
          onChange={(pageNumber) => handlePageChange(pageNumber, take)}
          pageSize={take}
          showSizeChanger={false}
        />
      </div>
    </CardContainer>
  );
};

export default ZoneList;
