import { Col, Modal, Row } from "antd";
import React from "react";
import styled from "styled-components";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { color } from "../../resource";

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    width: 400px;
  }
  .ant-modal-body {
    padding: 0;
  }
`;
interface Props {
  title?: string | React.ReactNode;
  visible?: boolean;
  onConfirm?: () => void;
  label1?: string | React.ReactNode;
  label2?: string | React.ReactNode;
  label3?: string | React.ReactNode;
  bodyStyle?: React.CSSProperties;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
}
function ModalSendMail({
  label1,
  label2,
  label3,
  onConfirm,
  title = "ส่งอีเมลยืนยันตัวตนอีกครั้ง",
  visible,
  bodyStyle,
  confirmText = "ตกลง",
  loading,
}: Props) {
  return (
    <ModalStyled
      open={visible}
      closable={false}
      bodyStyle={{ ...bodyStyle }}
      centered
      footer={null}
    >
      <div
        style={{
          padding: "32px 32px",
          textAlign: "center",
        }}
      >
        <Text level={2}>{title}</Text>
        <div style={{ textAlign: "center" }}>
          <div className='pt-3'>
            <Text fontFamily='Sarabun'>{label1}</Text>
          </div>
          <div className='pt-3 pb-3'>
            <Text fontFamily='Sarabun'>{label2}</Text>
          </div>
          <div className='p-3' style={{ backgroundColor: "#FAFAFA", borderRadius: 2 }}>
            <Text fontFamily='Sarabun' color='Text3'>
              {label3}
            </Text>
          </div>
        </div>
      </div>
      <Row
        style={{
          padding: " 8px 16px",
          display: "flex",
          justifyContent: "center",
          borderTop: "1px solid #E5E5E5",
        }}
      >
        <Button title={confirmText} onClick={onConfirm} loading={loading} />
      </Row>
    </ModalStyled>
  );
}

export default ModalSendMail;
