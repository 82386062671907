import React from "react";
import styled from "styled-components";
import Text from "../../components/Text/Text";
import { ReactSVG } from "react-svg";
import Button from "../../components/Button/Button";
import color from "../../resource/color";
import { useNavigate } from "react-router-dom";
import icon from "../../resource/icon";
import image from "../../resource/image";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  background-color: ${color.background1};
`;
const CardStyled = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  background-color: #ffffff;
  border-radius: 12px;
  height: fit-content;
  margin-top: 6%;
`;
const ImageStyled = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 16px;
`;

function ResetPasswordSuccessPage() {
  const navigate = useNavigate();

  const onClickBackToLogin = () => {
    navigate("/");
  };
  return (
    <Container>
      <CardStyled>
        {/* <ReactSVG
          onError={(error) => console.log(error)}
          src={`${process.env.PUBLIC_URL}/logo_sellcoda.svg`}
        /> */}
        <img src={image.sellcodaLogo} style={{ marginBottom: 12 }} />
        <ImageStyled src={icon.checkCircle} />

        <Text
          fontSize={24}
          style={{
            marginTop: 16,
          }}
          fontWeight={700}
        >
          ตั้งค่ารหัสผ่านสำเร็จ!
        </Text>
        <Text
          fontSize={16}
          align='center'
          style={{
            marginBottom: 0,
          }}
        >
          คุณสามารถใช้อีเมลและรหัสผ่านใหม่ <br />
          เข้าสู่ระบบ Sellcoda ได้แล้ว
        </Text>

        <Button
          onClick={onClickBackToLogin}
          title='กลับไปหน้าเข้าสู่ระบบ'
          style={{
            width: "100%",
            marginTop: 32,
          }}
        />
      </CardStyled>
    </Container>
  );
}

export default ResetPasswordSuccessPage;
