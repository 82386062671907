import color from "../resource/color";

export const shipmentStatus = [
  { key: "WAIT_RECEIVE", value: "WAIT_RECEIVE", label: "รอยืนยัน" },
  { key: "CONFIRM_RECEIVED", value: "CONFIRM_RECEIVED", label: "ยืนยันแล้ว" },
  { key: "CANCELED", value: "CANCELED", label: "ยกเลิก" },
];

export const MAPPING_SHIPMENT_STATUS: any = {
  CONFIRM_RECEIVED: "ยืนยันรับสินค้าแล้ว",
  WAIT_RECEIVE: "รอยืนยันการรับ",
  CANCELED: "ยกเลิกใบส่งของ",
};

export const MAPPING_SHIPMENT_STATUS_COLOR: any = {
  CONFIRM_RECEIVED: color.success,
  WAIT_RECEIVE: color.warning,
  CANCELED: color.error,
};
