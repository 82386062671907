import { Col, Row } from "antd";
import React from "react";
import { numberFormatter } from "../../utility/Formatter";
import styled from "styled-components";
import { color, icon } from "../../resource";
import { EstimatePointFormatter } from "../../utility/EstimatePointFormatter";
import { historyOrderPoint } from "../../entities/PointApproveEntity";
interface Product {
  point: number;
  amount: number;
  quantity: number;
}
interface SumProductsProps {
  products: Product[];
  grouped: any;
  tabKeyCampaign: string;
  statusAfter: string;
  historyOrderPoint: historyOrderPoint[];
}
const Texts = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
  word-break: break-word;
  max-width: 100%;
  white-space: normal;
`;
const DetailBox = styled.div`
  background-color: ${color.background1};
  border-radius: 10px;
  padding: 24px;
  margin: 22px 0px 22px 0px;
  border: 0.3px solid #e5eaee;
`;

const SumProducts: React.FC<SumProductsProps> = ({
  products,
  grouped,
  tabKeyCampaign,
  statusAfter,
  historyOrderPoint,
}) => {
  const totalList = products.length;
  const totalQuantity = products.reduce((sum, product) => sum + product.quantity, 0);
  const totalPoints = products.reduce((sum, product) => sum + product.point, 0);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);

  return (
    <DetailBox style={{ backgroundColor: "#FAFAFA", marginBottom: 72 }}>
      <Row gutter={[16, 16]} style={{ marginBottom: 12, justifyContent: "end" }}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Texts>รายการทั้งหมด :</Texts>
            </Col>
            <Col span={12} style={{ display: "flex" }}>
              <Texts>
                {totalList}
                &nbsp; รายการ
              </Texts>
              &nbsp;
            </Col>
          </Row>
          <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Texts>จำนวนสินค้าทั้งหมด :</Texts>
            </Col>
            <Col span={12}>
              <Texts style={{ fontWeight: "600" }}>
                {grouped.map(
                  (item, index) =>
                    numberFormatter(item?.amount, 0) +
                    " " +
                    item?.unitName +
                    (grouped.length > 1 && index < grouped.length - 1 ? ", " : ""),
                )}
              </Texts>
            </Col>
          </Row>
          {userProfile?.company !== "ICPL" && (
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Texts>จำนวนตัน :</Texts>
              </Col>
              <Col span={12}>
                <Texts style={{ fontWeight: "600" }}>{numberFormatter(totalQuantity, 2)} ตัน</Texts>
              </Col>
            </Row>
          )}
          {tabKeyCampaign === "true" && (
            <Row gutter={[0, 12]}>
              <Col span={12}>
                <Texts>ยอดแต้มที่ได้รับ :</Texts>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={icon.rating}
                    style={{ width: "18px", height: "18px", marginRight: "4px" }}
                  />
                  <Texts style={{ color: statusAfter === "APPROVE" ? color.gold : color.Text3 }}>
                    <EstimatePointFormatter historyOrderPoint={historyOrderPoint} />
                    &nbsp;
                    {numberFormatter(totalPoints, 2)} แต้ม
                  </Texts>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </DetailBox>
  );
};

export default SumProducts;
