import React, { useEffect, useState } from "react";
import { color } from "../../resource";
import { Col, Radio, Row } from "antd";
import {
  CONDITION_POINT,
  CONDITION_POINT_DEALER,
  GET_POINT,
  LIST_DATE,
  TARGET,
} from "../../definitions/dataFilter";
import { CalendarOutlined } from "@ant-design/icons";
import Text from "../Text/Text";
import SelectMulti from "../Select/SelectMulti";
import CustomAutoComplete from "../AutoComplete/CustomAutoComplete";
import icon from "../../resource/icon";
import ModalCalendar from "../Modal/ModalCalendar";
import { formatDate } from "../../utility/Formatter";
import { getSearchReportCRM } from "../../datasource/ReportCRMPointDatasource";
import {
  findCampaignEntities,
  findCampaignEntities_INIT,
} from "../../entities/searchReportCRMEntity";
import moment from "moment";
import { checkTodayInRange } from "../../utility/checkTodayInRange";

export interface FilterItem {
  value: string;
  label: string;
}

interface Props {
  searchText: string;
  setSearchText: (text: string) => void;
  filterDate: (range: { startDate: Date; endDate: Date; key: string }) => void;
  filterTarget: any[];
  setFilterTarget: (target: any[]) => void;
  filterPoint: any[];
  setFilterPoint: (point: any[]) => void;
  filterCondition: any[];
  setFilterCondition: (condition: any[]) => void;
  typeDate: (type: string) => void;
}

function FilterCampaign({
  searchText,
  setSearchText,
  filterDate,
  filterTarget,
  setFilterTarget,
  filterPoint,
  setFilterPoint,
  filterCondition,
  setFilterCondition,
  typeDate,
}: Props) {
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [initDate] = useState(new Date());
  const [defaultDate, setDefaultDate] = useState<string>("3Month");
  const [selectedDateRange, setSelectedDateRange] = useState<string | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<any>();
  const currentDate = new Date();
  const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [dataSearch, setDataSearch] = useState<findCampaignEntities>(findCampaignEntities_INIT);
  const SLASH_DMY = "DD/MM/YYYY";
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (filterPoint.includes("ONLY_DEALER") && filterPoint.includes("ONLY_SUB_DEALER")) {
      setFilterCondition(CONDITION_POINT.map((x) => x.value));
    } else if (filterPoint.includes("ONLY_DEALER")) {
      setFilterCondition(CONDITION_POINT_DEALER.map((x) => x.value));
    } else {
      setFilterCondition(["SUBMIT_DELIVERY_DOC"]);
    }
  }, [filterPoint]);

  useEffect(() => {
    searchCampaign(true);
  }, []);

  const searchCampaign = async (firstTime: boolean) => {
    setTimeout(async () => {
      try {
        const shouldFetchNewData = dataSearch.count !== dataSearch.data?.length;
        const res = shouldFetchNewData
          ? await getSearchReportCRM(userProfile?.company, firstTime ? 1 : page + 1, 10)
          : { count: dataSearch.count, data: [] };

        if (!firstTime) {
          setDataSearch({
            data: [...dataSearch.data, ...res.data],
            count: res.count,
          });
          setPage(page + 1);
        } else {
          setDataSearch(res);
        }
      } catch (error) {
        console.log(error);
      }
    }, 300);
  };
  useEffect(() => {
    if (defaultDate) {
      let startDate, endDate;
      switch (defaultDate) {
        case "Today":
          startDate = endDate = new Date();
          break;
        case "7Day":
          startDate = new Date();
          endDate = new Date(new Date().setDate(startDate.getDate() - 7));
          break;
        case "30Day":
          startDate = new Date();
          endDate = new Date(new Date().setDate(startDate.getDate() - 30));
          break;
        case "3Month":
          startDate = new Date();
          endDate = new Date(new Date().setMonth(startDate.getMonth() - 3));
          break;
        case "6Month":
          startDate = new Date();
          endDate = new Date(new Date().setMonth(startDate.getMonth() - 6));
          break;
        case "12Month":
          startDate = new Date();
          endDate = new Date(new Date().setMonth(startDate.getMonth() - 12));
          break;
        default:
          startDate = endDate = new Date();
      }
      setSelectedDateRange(`${formatDate(endDate)} - ${formatDate(startDate)}`);
      filterDate({
        startDate,
        endDate,
        key: "selection",
      });
    }
    typeDate(defaultDate);
  }, [defaultDate]);

  const onChangeFilter = (e) => {
    const selectedValue = e.target.value;
    setDefaultDate(selectedValue);
  };

  const resetFilters = () => {
    filterDate({
      startDate: new Date(),
      endDate: startDate,
      key: "selection",
    });
    setDefaultDate("3Month");
    setFilterTarget(["REBATE"]);
    setFilterPoint(["ONLY_SUB_DEALER"]);
    setFilterCondition(["SUBMIT_DELIVERY_DOC"]);
    setSearchText("");
    setSelectedDate(undefined);
    setSelectedDateRange(`${formatDate(startDate)} - ${formatDate(new Date())}`);
  };

  const optionsData = dataSearch.data.map((item) => {
    const dateRangeStatus = checkTodayInRange(item.startDate, item.endDate);
    const formattedDateRange =
      moment(item.startDate).format(SLASH_DMY) + " - " + moment(item.endDate).format(SLASH_DMY);

    return {
      data: item,
      value: item.campaignName,
      code: item.campaignCode,
      label: (
        <Row gutter={{ xs: 8 }}>
          <Col className='gutter-row' span={12}>
            <Text fontSize={14} fontWeight={400} fontFamily='Sarabun'>
              {item.campaignName.length > 22
                ? `${item.campaignName.slice(0, 22)}...`
                : item.campaignName}
            </Text>
            <br />
            <Text fontSize={12} color='Text3' fontFamily='Sarabun'>
              {item.campaignCode}
            </Text>
          </Col>
          <Col
            className='gutter-row'
            span={12}
            style={{ display: "flex", alignItems: "end", justifyContent: "end" }}
          >
            <Text
              fontFamily='Sarabun'
              fontSize={12}
              color={dateRangeStatus === "Earlier" ? "Text3" : "grayishBlue"}
            >
              {dateRangeStatus !== "Earlier" && dateRangeStatus === "Within the time range" && (
                <img
                  style={{ width: "18px", height: "18px", marginRight: 4 }}
                  src={icon.activeTime}
                />
              )}
              {dateRangeStatus !== "Earlier" && dateRangeStatus === "Later" && (
                <img
                  style={{ width: "18px", height: "18px", marginRight: 4 }}
                  src={icon.pendingTime}
                />
              )}
              {formattedDateRange}
            </Text>
          </Col>
        </Row>
      ),
    };
  });

  const onPopupScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      setTimeout(async () => {
        await searchCampaign(false);
      }, 300);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        width: "100%",
        borderBottomRightRadius: "12px",
        borderBottomLeftRadius: "12px",
        border: `1px solid ${color.background2}`,
        padding: "12px 24px 20px 24px",
      }}
    >
      <Row gutter={{ xs: 8 }}>
        <Col className='gutter-row' span={15}>
          <Radio.Group
            style={{ paddingRight: 12 }}
            onChange={onChangeFilter}
            optionType='button'
            value={defaultDate}
          >
            {LIST_DATE.map((val) => (
              <Radio.Button
                onClick={() => {
                  if (val.value === "Custom") {
                    setCalendarVisible(true);
                    setDefaultDate(val.value);
                  } else {
                    filterDate({
                      startDate: initDate,
                      endDate: initDate,
                      key: "selection",
                    });
                    setDefaultDate(val.value);
                    setSelectedDate(undefined);
                    setCalendarVisible(false);
                  }
                }}
                key={val.value}
                value={val.value}
                aria-label={val.label}
              >
                {val.value === "Custom" ? (
                  <>
                    <CalendarOutlined />
                    <Text style={{ marginLeft: "5px" }} fontSize={14}>
                      {selectedDate || val.label}
                    </Text>
                  </>
                ) : (
                  <>{val.label}</>
                )}
              </Radio.Button>
            ))}
          </Radio.Group>
          {selectedDateRange && (
            <Text fontSize={12} fontWeight={400}>{`(${selectedDateRange})`}</Text>
          )}
        </Col>
        <Col className='gutter-row' span={7}>
          <CustomAutoComplete
            placeholder='ค้นหารหัส / ชื่อแคมเปญ'
            data={optionsData}
            value={searchText}
            setValue={setSearchText}
            onCampaignSelect={(value) => {
              setSearchText(value);
            }}
            onPopupScroll={onPopupScroll}
          />
        </Col>
        <Col
          className='gutter-row'
          span={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <u
            style={{ color: color.error, cursor: "pointer", margin: "0 auto" }}
            onClick={() => resetFilters()}
          >
            <Text fontSize={14} color='error'>
              คืนค่าเริ่มต้น
            </Text>
          </u>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16 }} style={{ marginTop: 14 }}>
        <Col className='gutter-row' span={8}>
          <Text fontFamily='Sarabun' fontSize={12}>
            เป้าหมายแคมเปญ
          </Text>
          <br />
          <SelectMulti
            data={TARGET}
            tagColor='#FCE7FF'
            value={filterTarget}
            onChange={(value) => {
              setFilterTarget(value);
            }}
          />
        </Col>
        <Col className='gutter-row' span={8}>
          <Text fontFamily='Sarabun' fontSize={12}>
            รูปแบบการให้แต้ม
          </Text>
          <br />
          <SelectMulti
            data={GET_POINT}
            tagColor='#D4F4FF'
            value={filterPoint}
            onChange={(value) => {
              setFilterPoint(value);
            }}
          />
        </Col>
        <Col className='gutter-row' span={8}>
          <Text fontFamily='Sarabun' fontSize={12}>
            เงื่อนไขการให้แต้ม
          </Text>
          <br />
          <SelectMulti
            data={
              filterPoint.includes("ONLY_SUB_DEALER") ? CONDITION_POINT : CONDITION_POINT_DEALER
            }
            value={filterCondition}
            tagColor='#FFF8C5'
            onChange={(value) => {
              setFilterCondition(value);
            }}
          />
        </Col>
      </Row>
      <ModalCalendar
        visible={isCalendarVisible}
        onCancel={() => setCalendarVisible(false)}
        onSubmit={(range) => {
          const formattedRange = `${formatDate(range.startDate)} - ${formatDate(range.endDate)}`;
          filterDate({
            endDate: range.startDate,
            startDate: range.endDate,
            key: "selection",
          });
          setSelectedDate(formattedRange);
          setSelectedDateRange(formattedRange);
          setCalendarVisible(false);
        }}
      />
    </div>
  );
}

export default FilterCampaign;
