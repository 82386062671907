import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, NAV_URL } from "../config/develop-config";

const baseUrl = `${BASE_URL}/auth`;

const getPartnerSubdealer = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/customer-partner/all-dealer/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getPartnerDealer = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/customer-partner/all-sub/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export { getPartnerSubdealer, getPartnerDealer };
