import { intanceAuth } from "../config/develop-config";

export class AuthDatasource {
  static login(email: any) {
    // const app_key = "15782536645_sellcoda_bo";
    return intanceAuth
      .post(
        `/auth/auth/login-user-staff`,
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Ick-Secret " + process.env.REACT_APP_SECRET_KEY!,
          },
        },
      )
      .then((response) => {
        console.log("auth", response.data);
        localStorage.setItem("profile", response.data.data);
        localStorage.setItem("company", JSON.stringify(response.data.company));
        //const userProfile = JSON.parse(localStorage.getItem("profile");
        //console.log("final", userProfile);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static loginWithEmail({ email, password }: { email: string; password: string }) {
    return intanceAuth
      .post(`/auth/auth/login-user-staff-auth`, {
        email: email,
        password: password,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  static async updatePassword({
    email,
    password,
    token,
  }: {
    email: string;
    password: string;
    token: string;
  }) {
    return intanceAuth
      .patch(
        `/auth/user-staff/update-password`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static async resetPassword({ email }: { email: string }) {
    return intanceAuth
      .patch(`/auth/user-staff/reset-password`, {
        email: email,
      })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static async checkIsPassword(id: string) {
    return intanceAuth
      .get(`/auth/user-staff/check-is-password/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static async checkIsReset(id: string) {
    return intanceAuth
      .get(`/auth/user-staff/check-is-reset/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
