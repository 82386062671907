import React, { useEffect, useState } from "react";
import Descriptions from "../../../../components/Description/Descriptions";
import { Col, Row, Table } from "antd";
import { reportHistoryCampaignShop } from "../../../../datasource/CampaignDatasource";
import { DealerType } from "../../../../definitions/campaign";
import { FlexCol } from "../../../../components/Container/Container";
import Text from "../../../../components/Text/Text";
import { numberFormatter } from "../../../../utility/Formatter";
import moment from "moment";

const TabCampaignHistory: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (!loading) fetchData();
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    const type = pathSplit[3];
    const id = pathSplit[4];
    await reportHistoryCampaignShop({
      dealerId: type?.toUpperCase() === DealerType.DEALER ? parseInt(id) : undefined,
      subDealerId: type?.toUpperCase() === DealerType.DEALER ? undefined : parseInt(id),
      page,
      take: 10,
    })
      .then((res) => {
        console.log(res);
        setData(res.data);
        setCount(res.count);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "รหัสแคมเปญ",
      dataIndex: "campaignCode",
      key: "campaignCode",
    },
    {
      title: "ชื่อแคมเปญ",
      dataIndex: "campaignName",
      key: "campaignName",
    },
    {
      title: "ระยะเวลา",
      dataIndex: "startDate",
      key: "startDate",
      render: (value, row) => {
        return (
          <Text>{`${row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : ""} - ${
            row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : ""
          }`}</Text>
        );
      },
    },
    {
      title: "จำนวนคำสั่งซื้อ",
      dataIndex: "countOrder",
      key: "countOrder",
      render: (value) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              รายการ
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              ตัน
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "sumPoint",
      key: "sumPoint",
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              แต้ม
            </Text>
          </FlexCol>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={12}>
          <Descriptions label='แคมเปญที่ผ่านมาทั้งหมด' value={count + " รายการ"} noColon />
        </Col>
      </Row>
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          current: page,
          total: count,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
        loading={loading}
      />
    </>
  );
};

export default TabCampaignHistory;
