import React from "react";
import Text from "../Text/Text";
import { Col, Row } from "antd";

interface Props {
  title: string;
  amount: string;
  pointProgram: string;
  label?: string;
}

function CardPointCampaign({ title, amount, pointProgram, label }: Props) {
  return (
    <div
      style={{
        backgroundColor: "#EFF2F9",
        width: "100%",
        padding: "20px",
        borderRadius: "12px",
      }}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify='space-between'>
        <Col className='gutter-row' span={12}>
          <Text fontSize={18} fontWeight={700} color='Text2'>
            {title}
          </Text>
          <Text fontSize={18} fontWeight={400} color='Text2'>
            {label}
          </Text>
        </Col>
        <Col className='gutter-row' span={12} style={{ textAlign: "end" }}>
          <Text fontSize={40} fontWeight={700}>
            {amount}
          </Text>
          <br />
          <Text fontSize={16} fontWeight={400}>
            {pointProgram} พอยท์โปรแกรม
          </Text>
        </Col>
      </Row>
    </div>
  );
}

export default CardPointCampaign;
