import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";
const baseUrl = `${BASE_URL}/crm`;

export const getPointApprove = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/order-point/history-point-approve`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
export const getPointDetail = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/order-point/history-point-detail`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
export const getPointHistory = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/order-point/history-point-history/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
export const cancelOrderPoint = async (params: object) => {
  return await httpClient
    .delete(`${baseUrl}/order-point/cancel-order-point`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
