import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import Text from "../Text/Text";
import { getPartnerDealer, getPartnerSubdealer } from "../../datasource/PartnerDatasource";
import { PartnerEntity } from "../../entities/PartnerEntities";

interface Props {
  open: boolean;
  onCancel: () => void;
  shopId: any;
  typePartner: string;
}

function ModalPartnerTypeCRM({ open, onCancel, shopId, typePartner }: Props) {
  const [dataPartner, setDataPartner] = useState<{
    count: number;
    data: PartnerEntity[];
  }>({
    count: 0,
    data: [],
  });

  useEffect(() => {
    if (open) {
      const getPartner = async () => {
        try {
          const res =
            typePartner === "DEALER"
              ? await getPartnerDealer(shopId)
              : await getPartnerSubdealer(shopId);
          setDataPartner({
            data: res || [],
            count: res ? res.length : 0,
          });
        } catch (error) {
          console.error("Error fetching partner data:", error);
        }
      };
      getPartner();
    }
  }, [shopId, typePartner, open]);

  return (
    <Modal
      width={800}
      open={open}
      closable
      onCancel={onCancel}
      title={
        <>
          <Text fontWeight={600} fontSize={20}>
            คู่ค้ากับ {typePartner === "DEALER" ? "Dealer" : "Sub-Dealer"}
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14} color='Text3' fontFamily='Sarabun'>
            ทั้งหมด {dataPartner.count} ร้านค้า
          </Text>
        </>
      }
      centered
      footer={null}
    >
      <Table
        showHeader={false}
        pagination={false}
        dataSource={dataPartner.data}
        scroll={{ y: 400, x: "max-content" }}
        columns={[
          {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (_, __, index) => <Text>{index + 1}</Text>,
          },
          {
            title: "รหัสร้านค้า",
            dataIndex: "customer_no",
            key: "customer_no",
            render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
          },
          {
            title: "ชื่อร้านค้า",
            dataIndex: "customer_name",
            key: "customer_name",
            render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
          },
          {
            title: "เขต",
            dataIndex: "zone",
            key: "zone",
            render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
          },
          {
            title: "จังหวัด",
            dataIndex: "province",
            key: "province",
            render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
          },
        ]}
      />
    </Modal>
  );
}

export default ModalPartnerTypeCRM;
