import React, { useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Col, Row } from "antd";
import Text from "../../../components/Text/Text";
import styled from "styled-components";
import color from "../../../resource/color";
import ExpandTable from "../../../components/Table/ExpandTable";
import icon from "../../../resource/icon";
import { numberFormatter } from "../../../utility/Formatter";
import { showDateText } from "../../../definitions/dataFilter";
import useSorting from "../../../hook/useSorting";
import SortFieldCustom from "../../../components/SortFieldTable/SortFieldCustom";

interface Props {
  data: any[];
  dateRange: string;
  typeDate: string;
}
const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;
const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.background4};
  font-weight: 400;
`;
function RewardList({ dateRange, typeDate }: Props) {
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const data = Array.from({ length: 4 }).map<any>((_, i) => ({
    key: i + 1,
    rewardName: "บัตรน้ำมัน มูลค่า 500 บาท",
    img: "https://b2b.bangchakmarketplace.com/img/cards/cash/cash-card@2x.png?1685346376",
    programPoint: "1000",
    redeem: "30",
    exchange: "50",
    point: "2200",
  }));
  const column: any[] = [
    {
      title: "ชื่อของรางวัล",
      dataIndex: "rewardName",
      key: "rewardName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={row.img || icon.emptyProduct}
                  style={{
                    width: "45px",
                    height: "45px",
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                />
                <div>
                  <BaseText>{value}</BaseText>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={icon.rating}
                      style={{ width: "14px", height: "14px", marginRight: "4px" }}
                    />

                    <BaseText style={{ fontSize: "14px" }}>
                      {numberFormatter(row.programPoint) || 0} แต้ม / หน่วย
                    </BaseText>
                  </div>
                </div>
              </div>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='แลกแล้ว'
          sortDirection={sortField === "redeem" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("redeem")}
        />
      ),
      dataIndex: "redeem",
      key: "redeem",
      width: "15%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>หน่วย</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ร้านที่แลก'
          sortDirection={sortField === "exchange" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("exchange")}
        />
      ),
      dataIndex: "exchange",
      key: "exchange",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>ร้าน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ยอดแต้มที่แลก'
          sortDirection={sortField === "point" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("point")}
        />
      ),
      dataIndex: "point",
      key: "point",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
              <DescText style={{ display: "flex", alignItems: "center" }}>แต้ม</DescText>
            </>
          ),
        };
      },
    },
  ];

  return (
    <CardContainer>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className='gutter-row' span={12}>
          <Text fontSize={18} fontWeight={700} color='Text2'>
            อันดับของรางวัล
          </Text>
          <br />
          <Text fontSize={14} fontWeight={400} color='Text2'>
            {typeDate === "Custom" ? dateRange : showDateText(typeDate)}
          </Text>
        </Col>
        <Col className='gutter-row' span={12} style={{ display: "flex", justifyContent: "end" }}>
          <Text fontSize={14} fontWeight={400} color='Text3'>
            ทั้งหมด 4 รายการ
          </Text>
        </Col>
      </Row>
      <ExpandTable
        loading={false}
        columns={column}
        data={data}
        scrollX='700px'
        textEmpty='ไม่มีรายการของรางวัล'
        imgEmpty={icon.emptyHistoryPoint}
      />
    </CardContainer>
  );
}

export default RewardList;
