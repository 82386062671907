import React from "react";
import { ContainerIcon, Image, PageButton } from "../Table/TablePagination";
import { icon } from "../../resource";

interface PaginationProps {
  currentPage: number;
  total: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, total, pageSize, onPageChange }) => {
  const totalPage = Math.ceil(total / pageSize);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
      <ContainerIcon left>
        <Image
          src={currentPage === 1 ? icon.iconDoubleLeftDisable : icon.iconDoubleLeft}
          onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        />
      </ContainerIcon>
      {Array.from({ length: totalPage }, (_, i) => i + 1).map((page) => (
        <PageButton key={page} focus={currentPage === page} onClick={() => onPageChange(page)}>
          {page}
        </PageButton>
      ))}
      <ContainerIcon right>
        <Image
          src={currentPage === totalPage ? icon.iconDoubleRightDisable : icon.iconDoubleRight}
          onClick={() => currentPage < totalPage && onPageChange(currentPage + 1)}
        />
      </ContainerIcon>
    </div>
  );
};

export default Pagination;
